import { ModelBase } from 'store/model-base';

export class CmsLegalLanding extends ModelBase {
    public static storeName: string = 'cmsLegalLanding';

    public pages: CmsLegalLandingPages[];
    public subtitle: string;
    public title: string;
    public arrowIcon: CmsMediaImage;
    public breadcrumbLink: string;
}
