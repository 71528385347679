import { ModelBase } from 'store/model-base';
import { AdditionalChargeType } from 'core/constants';

export class AdditionalCharges extends ModelBase {
    public code: AdditionalChargeType;
    public description: string;
    public price: RecurringPrice;
    public isDeviceCredit: boolean;
    public isDeviceAccessFee: boolean;
    public isEWasteFee: boolean;
    public isActivationFee: boolean;

    public static create<T extends ModelBase>(initData: object): T {
        const toReturn: AdditionalCharges = super.create<AdditionalCharges>(initData);

        toReturn.isDeviceCredit = toReturn.code === AdditionalChargeType.DEVICE_SUBSIDY;
        toReturn.isDeviceAccessFee = toReturn.code === AdditionalChargeType.DAF;
        toReturn.isEWasteFee = toReturn.code === AdditionalChargeType.EWSTF;
        toReturn.isActivationFee = toReturn.code === AdditionalChargeType.ACTIVATION_FEE;
        

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }
}
