import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'IMEI'
})
export class IMEIPipe implements PipeTransform {
    public transform(imei: number | string): string {

        if (!imei) {
            return '';
        }

        const imeiString: string[] = imei.toString().split(':');

        if (imeiString.length < 1) {
            return '';
        }
        
        const imeiNumber: string[] = imeiString[1].toString().replace(/\D/g, '').match(/(\d{0,2})(\d{0,6})(\d{0,6})(\d{0,1})/);
        imeiNumber.shift();

        return `${imeiString[0] || ''}: ${imeiNumber.shift()} ${imeiNumber.shift()} ${imeiNumber.shift()} ${imeiNumber.shift()}`;
    }
}
