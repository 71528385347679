import { ModelBase } from 'store/model-base';

export class NutritionLabel extends ModelBase {
    public labelId: string;
    public title: string;
    public serviceTitle: string;
    public tierTitle: string;
    public disclosure: string;
    public planId: string;
    public regions: NutritionLabelRegion[];
    public meta: Meta;
}
