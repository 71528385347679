import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'xm-expandable',
    styleUrls: [ './expandable.scss' ],
    templateUrl: './expandable.html'
})
export class XmExpandableComponent implements AfterViewInit, OnChanges {
    private static ANIMATION_SPEED: number = 250;

    @Input() public expanded: boolean;
    @Output() public callback: EventEmitter<void> = new EventEmitter<void>();

    public noTransitionOnLoad: boolean = true;

    private elementRef: ElementRef;
    private expandable: HTMLElement;

    constructor(elementRef: ElementRef) {
        Object.assign(this, { elementRef });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.expanded && !changes.expanded.isFirstChange()) {
            this.setExpanded();
        }
    }

    public ngAfterViewInit(): void {
        this.expandable = this.elementRef.nativeElement.getElementsByTagName('div')[0];
        this.applyElementStyles();

        setTimeout(() => {
            this.noTransitionOnLoad = false;
        });
    }

    public animationComplete(): void {
        this.callback.emit();
    }

    private setExpanded(): void {
        if (this.expanded) {
            this.expandable.style.display = 'block';
            this.expandable.style.height = `${ this.expandable.scrollHeight }px`;
            this.expandable.style.opacity = '1';

            setTimeout(() => {
                this.finishedAnimation();
            }, XmExpandableComponent.ANIMATION_SPEED);
        } else {
            this.expandable.style['overflow-y'] = 'hidden';
            this.expandable.style.height = `${ this.expandable.scrollHeight }px`;
            this.expandable.style.opacity = '0';

            setTimeout(() => {
                this.expandable.style.height = '0px';
            });

            setTimeout(() => {
                this.finishedAnimation();
            }, XmExpandableComponent.ANIMATION_SPEED);
        }
    }

    private finishedAnimation(): void {
        this.applyElementStyles();

        if (this.callback) {
            this.callback.emit();
        }
    }

    private applyElementStyles(): void {
        if (this.expanded) {
            this.expandable.removeAttribute('aria-hidden');
            this.expandable.removeAttribute('tabindex');
            this.expandable.style.height = 'auto';
            this.expandable.style.opacity = '1';
            this.expandable.style['overflow-y'] = 'visible';
        } else {
            this.expandable.setAttribute('aria-hidden', 'false');
            this.expandable.setAttribute('tabindex', '-1');
            this.expandable.style.display = 'none';
            this.expandable.style.opacity = '0';
        }
    }
}
