import { ChildModel, ModelBase } from 'store/model-base';
import { LineDetail } from './detail';

export class LineCollection extends ModelBase {
    public static storeName: string = 'lineCollection';

    public lines: LineDetail[];

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'lines',
            model: LineDetail
        }];
    }

    /**
     * get Line By ID
     * @param id id.value from Line Summary
     */

    public getLineByValue(value: string): LineDetail {
        return this.lines.find((line: LineDetail) => line.id.value === value);
    }

    /**
     * get Line By KEY
     * @param id id.key from Line Summary
     */

    public getLineByKey(key: string): LineDetail {
        return this.lines.find((line: LineDetail) => line.id.key === key);
    }

    public getLineByOrderId(orderId: string): LineDetail[] {
        return this.lines.filter((line: LineDetail) => line.orderId?.value === orderId);
    }
}
