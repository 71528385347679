import { ModelBase } from 'store/model-base';

export class CmsABTest extends ModelBase {
    public static storeName: string = 'cmsABTest';

    public supportDeviceCardCta: string;
    public shopModule: CmsShopModule;
    public pdpCustomizerBanner: CmsPdpCustomizerBanner;
    public pdpCtaText: CmsPdpCtaText;
}
