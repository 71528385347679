import { ModelBase } from 'store/model-base';
import { WatchesEligibilityCode } from 'src/xm/watches/constants';

export class WatchesPairEligibilityDetails extends ModelBase {
    public static storeName: string = 'watchesPairEligibilityDetails';

    public isEligible: boolean;
    public conditionalReason: WatchesEligibilityReason[];
    public denialReason: WatchesEligibilityReason[];

    public isConditionallyEligible: boolean;
    public isAlreadyPaired: boolean;
    public hasOngoingActivation: boolean;

    public static create<T extends ModelBase>(data: ApiResponse): T {
        data.isConditionallyEligible = data.isEligible && data.conditionalReason.length > 0;
        data.isAlreadyPaired = data.conditionalReason && data.conditionalReason.some((reason: WatchesEligibilityReason) => reason.reasonCode === WatchesEligibilityCode.ALREADY_PAIRED);
        data.hasOngoingActivation = data.conditionalReason && data.conditionalReason.some((reason: WatchesEligibilityReason) => reason.reasonCode === WatchesEligibilityCode.ONGOING_ACTIVATION);

        return super.create<T>(data);
    }
}
