import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Util } from 'services/util';
import { BreakpointWidth } from 'core/constants';

@Injectable({
    providedIn: 'root'
})
export class WindowReference implements OnDestroy {
    public static RESIZE_DEBOUNCE_TIME: number = 300;
    private static SCROLL_DEBOUNCE_TIME: number = 200;
    private static watchingBreakpoint: Breakpoint = {
        currentBreakpoint: '',
        isLargeDesktop: false,
        isMobile: false,
        isSmallDesktop: false,
        isTablet: false,
        currentBreakpointMax: 9999,
        currentBreakpointMin: 0
    };

    public breakpoint: Observable<Breakpoint>;
    public windowWidth: Observable<number>;
    public scroll: Observable<Event>;
    public scrollDebounce: Observable<Event>;
    private subscriptions: Subscription[] = [];

    public static replaceCurrentHistory(url: string): void {
        window.history.replaceState(undefined, undefined, url);
    }

    constructor() {
        
        const windowRect: BehaviorSubject<Breakpoint> = new BehaviorSubject(this.getBreakpoint());
        this.breakpoint = windowRect.pipe(distinctUntilChanged());

        this.subscriptions.push(fromEvent(window, 'resize').pipe(map(this.getBreakpoint)).subscribe(windowRect));
        this.scroll = fromEvent(window, 'scroll').pipe(map((event: Event) => event));

        this.windowWidth = fromEvent(window, 'resize').pipe(
            debounceTime(WindowReference.RESIZE_DEBOUNCE_TIME),
            distinctUntilChanged(),
            map((event: Event) => (<Window> event.target).innerWidth)
        );

        this.scrollDebounce = fromEvent(window, 'scroll').pipe(
            debounceTime(WindowReference.SCROLL_DEBOUNCE_TIME),
            distinctUntilChanged(),
            map((event: Event) => event)
        );
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public reload(): void {
        window.location.reload();
    }

    public getBreakpoint(): Breakpoint {
        if (window.innerWidth < BreakpointWidth.TABLET &&
            !WindowReference.watchingBreakpoint.isMobile) {
            WindowReference.watchingBreakpoint = { ...WindowReference.defaultBreakpoint, isMobile: true, currentBreakpoint: 'mobile',
                currentBreakpointMax: BreakpointWidth.TABLET - 1, currentBreakpointMin: 0 };
        } else if (window.innerWidth >= BreakpointWidth.TABLET &&
                    window.innerWidth < BreakpointWidth.SMALL_DESKTOP &&
                    !WindowReference.watchingBreakpoint.isTablet) {
            WindowReference.watchingBreakpoint = { ...WindowReference.defaultBreakpoint, isTablet: true, currentBreakpoint: 'tablet',
                currentBreakpointMax: BreakpointWidth.SMALL_DESKTOP - 1, currentBreakpointMin: BreakpointWidth.TABLET };
        } else if (window.innerWidth >= BreakpointWidth.SMALL_DESKTOP &&
                    window.innerWidth < BreakpointWidth.LARGE_DESKTOP &&
                    !WindowReference.watchingBreakpoint.isSmallDesktop) {
            WindowReference.watchingBreakpoint = {...WindowReference.defaultBreakpoint, isSmallDesktop: true, currentBreakpoint: 'smallDesktop',
                currentBreakpointMax: BreakpointWidth.LARGE_DESKTOP - 1, currentBreakpointMin: BreakpointWidth.SMALL_DESKTOP };
        } else if (window.innerWidth >= BreakpointWidth.LARGE_DESKTOP &&
                    !WindowReference.watchingBreakpoint.isLargeDesktop) {
            WindowReference.watchingBreakpoint = { ...WindowReference.defaultBreakpoint, isLargeDesktop: true, currentBreakpoint: 'largeDesktop',
                currentBreakpointMax: 9999, currentBreakpointMin: BreakpointWidth.LARGE_DESKTOP };
        }

        return WindowReference.watchingBreakpoint;
    }

    private static get defaultBreakpoint(): Breakpoint {
        return {
            currentBreakpoint: '',
            isLargeDesktop: false,
            isMobile: false,
            isSmallDesktop: false,
            isTablet: false,
            currentBreakpointMax: 9999,
            currentBreakpointMin: 0
        };
    }
}
