import { Directive, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { RadioControlRegistry } from 'core/services';

@Directive({
    selector: '[xmRadioControl]',
    host: {
        '(change)': 'onChange()',
        '(blur)': 'onTouched()'
    },
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: XmRadioControl,
        multi: true
    }]
})
export class XmRadioControl implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
    @Input() public name: string;
    @Input() public formControlName: string;
    @Input() public value: string;

    // used to set a single radio control of a group disabled
    @Input() public singleDisabled: boolean;

    @Output() public disabledFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public enabledFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

    // used when radio buttons are not within a form
    @Input() public initialActive: string | object;

    // only outputs data when not in a form control
    @Output() public onSelect: EventEmitter<string> = new EventEmitter<string>();

    public onChange: Function;
    public onTouched: Function;
    public formControl: NgControl;

    private withinForm: boolean;
    private inputElement: HTMLInputElement;
    private element: HTMLElement;
    private injector: Injector;
    private registry: RadioControlRegistry;

    constructor(element: ElementRef, injector: Injector, registry: RadioControlRegistry) {
        Object.assign(this, { element: element.nativeElement, injector, registry });
    }

    public ngOnInit(): void {
        this.checkProperties();

        if (this.formControlName) {
            this.formControl = this.injector.get(NgControl);
            this.withinForm = true;
            this.registry.add(this.formControl, this);
        } else {
            this.registry.add(undefined, this);
            this.onChange = this.valueSelected;
        }

        this.inputElement = this.element.querySelector('input');
        if (this.inputElement) {
            this.inputElement.setAttribute('name', this.name);
        } else {
            throw new Error('could not find nested input for radio control');
        }

        this.setDisabledState(this.singleDisabled);

        // only manually set active state when not within a form
        if (!this.withinForm) {
            this.setSelectedState(this.initialActive);
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.singleDisabled && !changes.singleDisabled.firstChange) {
            this.setDisabledState(this.singleDisabled);
        }
    }

    public ngOnDestroy(): void {
        this.registry.remove(this);
    }

    public registerOnChange(fn: Function): void {
        this.onChange = () => {
            fn(this.value);
            this.valueSelected();
        };
    }

    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.element.setAttribute('disabled', '');
            this.inputElement.setAttribute('aria-disabled', '');

        } else {
            this.element.removeAttribute('disabled');
            this.inputElement.removeAttribute('aria-disabled');
        }
    }

    public writeValue(value: string | object): void {
        this.setSelectedState(value);
    }

    public isChecked(): boolean {
        return this.inputElement.checked;
    }

    private valueSelected(): void {
        if (!this.withinForm) {
            this.onSelect.emit(this.value);
        }

        this.setSelectedState(this.value);
        this.registry.select(this);
    }

    private setSelectedState(value: string | object): void {
        const state: boolean = value === this.value;

        if (state) {
            this.element.classList.add('active');
            this.inputElement.checked = true;
            if (this.singleDisabled) {
                this.disabledFocus.emit(true);

            } else if (!this.singleDisabled && this.inputElement.checked) {
                this.enabledFocus.emit(true);
            }
        } else {
            this.element.classList.remove('active');
            this.inputElement.checked = false;
            // this.disabledFocus.emit(false);

        }
    }

    private checkProperties(): void {
        if (!this.name && !this.formControlName) {
            throw new Error('must provide a name and/or formControlName for radio control');
        } else if (this.name && this.formControlName && this.name !== this.formControlName) {
            throw new Error('name and formControlName must be the same string for radio control');
        } else if (this.value === undefined) {
            throw new Error('value is missing for radio control');
        }

        if (!this.name && this.formControlName) {
            this.name = this.formControlName;
        }
    }
}
