import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { buildHeaders, buildParams, XmStore } from 'core/services';
import { AuthByodEligibility, ImeiProductDetails, UnauthByodEligibility } from 'store/byod/models';
import { StoreAction } from 'store/actions';
import { ApiChannel, ByodEligibilityMode } from 'core/constants';
import { ModelBase } from 'store/model-base';
import { SimCompatibility } from './models/sim-compatibility';
import { BuyInfo, User } from 'store/user/models';
import { IXMOptions } from 'core/interfaces';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class ByodApi {
    public static byodEligibility(_xmStore: XmStore, http: HttpClient, params: ApiParams, config: IXMOptions): Observable<UnauthByodEligibility> {
        const apiChannel: ApiChannel = config.ENABLE_AKAMAI_PROXY ? ApiChannel.AKAMAI_PROXY : ApiChannel.PROXY;

        return http.get(`/devices/${params.imei}/byodeligibility`, {
            headers: buildHeaders({ apiChannel }),
            params: buildParams({ intent: 'PROSPECT', mode: config.ENABLE_ESIM_DIGITAL ? ByodEligibilityMode.TRIAL : ByodEligibilityMode.PRODUCTION })
        }).pipe(
            map((response: ApiResponse) => UnauthByodEligibility.create<UnauthByodEligibility>(response)), 
            catchError(err => logAndHandleError(err, '/devices/{params.imei}/byodeligibility', OperationType.GET))               
        );
    }

    public static authenticatedByodEligibility(_xmStore: XmStore, http: HttpClient, params: ApiParams, config: IXMOptions): Observable<AuthByodEligibility> {
        return http.get(`/devices/${params.imei}/byodeligibility`, {
            headers: buildHeaders({
                apiChannel: ApiChannel.GATEWAY
            }),
            params: buildParams({ intent: 'BUY', mode: config.ENABLE_ESIM_DIGITAL ? ByodEligibilityMode.TRIAL : ByodEligibilityMode.PRODUCTION })
        }).pipe(
            map((response: ApiResponse) => AuthByodEligibility.create<AuthByodEligibility>(response)), 
            catchError(err => logAndHandleError(err, '/devices/{params.imei}/byodeligibility', OperationType.GET)) 
        );
    }

    public static getByodProductDetail(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<ImeiProductDetails> {
        return http.get(`/devices/byod/imei/${params.imei}/productdetails`, {
            headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY })
        }).pipe(
            map((response: ApiResponse) => ImeiProductDetails.create<ImeiProductDetails>(response)), 
            catchError(err => logAndHandleError(err, '/devices/byod/imei/{params.imei}/productdetails', OperationType.GET))     
        );
    }

    public static getSimCompatibility(xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<SimCompatibility> {
        return xmStore.findChild<BuyInfo, User>(StoreAction.GET_BUY_INFO, 'buyInfo', BuyInfo, User).pipe(
            mergeMap((buyInfo: BuyInfo) => http.get(`/session/validateSim/${params.iccid}`, {
                headers: buildHeaders({
                    apiChannel: ApiChannel.GATEWAY,
                    sessionID: buyInfo.sessionId
                })
            }).pipe(
                map((response: ApiResponse) => SimCompatibility.create<SimCompatibility>({ ...response, iccid: params.iccid })), 
                catchError(err => logAndHandleError(err, '/session/validateSim/{params.iccid}', OperationType.GET, undefined, undefined, undefined, undefined, buyInfo.sessionId))
            )
            ));
    }
}

ModelBase.fetchMapping[StoreAction.GET_BYOD_ELIGIBILITY] = ByodApi.byodEligibility;
ModelBase.fetchMapping[StoreAction.GET_AUTH_BYOD_ELIGIBILITY] = ByodApi.authenticatedByodEligibility;
ModelBase.fetchMapping[StoreAction.GET_BYOD_PRODUCT_DETAIL] = ByodApi.getByodProductDetail;
ModelBase.fetchMapping[StoreAction.GET_SIM_COMPATIBILITY] = ByodApi.getSimCompatibility;
