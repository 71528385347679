import { AfterViewInit, Directive, Input, NgZone, OnDestroy } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';

import { Util } from 'core/services/util';
import { delay } from 'rxjs/operators';

@Directive({
    selector: '[swiper]'
})
export class SwiperA11yDirective implements AfterViewInit, OnDestroy {
    @Input() public focusSelector: string;
    @Input() public a11yDisable: boolean;
    @Input() public a11yForce: boolean;
    @Input() public a11yLabel: string;

    /* eslint-disable @typescript-eslint/no-explicit-any */
    private swiperInstance: any;
    private swiperParams: SwiperConfigInterface;
    /* eslint-enable @typescript-eslint/no-explicit-any */

    private swiperContainer: HTMLElement;
    private autoplayConfigured: boolean = false;
    private a11yLabelGenerated: boolean = false;

    private swiperDirective: SwiperDirective;
    private zone: NgZone;
    private subscriptions: Subscription[] = [];

    constructor(swiperDirective: SwiperDirective, zone: NgZone) {
        Object.assign(this, { swiperDirective, zone });
    }

    public ngAfterViewInit(): void {
        if (this.a11yDisable) {
            return;
        }

        // for now, force EVERYONE to have performance enabled
        //this.swiperDirective.performance = true;

        // run all code outside angular life-cycle to no cause change detection
        this.zone.runOutsideAngular(() => {
            this.swiperInstance = this.swiperDirective.swiper();

            // watch for a delay init
            this.subscriptions.push(this.swiperDirective.S_INIT.subscribe(() => {
                this.swiperInstance = this.swiperDirective.swiper();
                this.initialSetup();
            }));

            // watch for slide changes to apply a11y attributes
            this.subscriptions.push(this.swiperDirective.S_SLIDECHANGETRANSITIONEND.subscribe(() => {
                this.hideVisibleSlides();
                this.showVisibleSlides(true);
            }));

            // watch for breakpoint changes to update a11y attributes
            this.subscriptions.push(this.swiperDirective.S_BREAKPOINT.pipe(
                delay(100)
            ).subscribe(() => {
                this.hideVisibleSlides();
                this.showVisibleSlides(false);
            }));

            if (this.swiperInstance) {
                this.initialSetup();
            }
        });
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    private initialSetup(): void {
        if (!this.swiperInstance.initialized) {
            return;
        }

        this.swiperParams = this.swiperInstance.params;

        this.autoplayConfigured = Boolean(this.swiperInstance?.params?.autoplay?.enabled);

        if (this.autoplayConfigured) {
            this.subscriptions.push(this.swiperDirective.S_AUTOPLAYSTOP.subscribe(() => {
                this.hideVisibleSlides();
                this.showVisibleSlides(false);
            }));

            this.subscriptions.push(this.swiperDirective.S_AUTOPLAYSTART.subscribe(() => {
                this.showHiddenSlides();
            }));
        }

        this.swiperContainer = this.swiperInstance.el;
        this.hideVisibleSlides();
        this.showVisibleSlides(false);

        // add aria label
        if (this.a11yLabel && !this.a11yLabelGenerated) {
            const id: string = Util.generateUuid().toString();
            const parentElement: HTMLElement = this.swiperContainer.parentElement;
            parentElement.setAttribute('aria-labelledby', id);

            const labelElement: HTMLElement = document.createElement('div');
            labelElement.classList.add('accessibility-hidden');
            labelElement.id = id;
            labelElement.innerText = this.a11yLabel;

            parentElement.insertBefore(labelElement, parentElement.childNodes[0]);
            this.a11yLabelGenerated = true;
        }
    }

    private findActiveElement(): HTMLElement {
        return this.swiperContainer.querySelector(`.${this.swiperParams.slideActiveClass}`);
    }

    private hideVisibleSlides(): void {
        if (this.autoplayConfigured && this.swiperInstance.autoplay.running) {
            return;
        }

        const visibleSlides: NodeListOf<HTMLElement> = this.swiperContainer.querySelectorAll(`.${this.swiperParams.slideClass}:not([aria-hidden])`);
        visibleSlides.forEach((slide: HTMLElement) => {
            this.updateTabIndex(slide, false);
        });
    }

    private showHiddenSlides(): void {
        const visibleSlides: NodeListOf<HTMLElement> = this.swiperContainer.querySelectorAll(`.${this.swiperParams.slideClass}[aria-hidden]`);
        visibleSlides.forEach((slide: HTMLElement) => {
            this.updateTabIndex(slide, true);
        });
    }

    private showVisibleSlides(focusNext: boolean): void {
        // if autoplay is running, we don't want to change any attributes
        if (this.autoplayConfigured && this.swiperInstance.autoplay.running) {
            return;
        }

        if (this.a11yForce || this.swiperParams.slidesPerView && this.swiperParams.slidesPerView !== 'auto') {
            const slideCount: number = this.a11yForce ? 1 : <number> this.swiperParams.slidesPerView;

            // update new slides
            const activeElement: HTMLElement = this.findActiveElement();
            let elementToProcess: HTMLElement = activeElement;
            for (let i: number = 0; i < slideCount; i++) {
                if (!elementToProcess) {
                    return;
                }

                this.updateTabIndex(elementToProcess, true);
                elementToProcess = <HTMLElement> elementToProcess.nextElementSibling;
            }

            if (focusNext) {
                this.focusNextSlide(activeElement);
            }
        } else {
            this.showHiddenSlides();
        }
    }

    private updateTabIndex(swiperSlide: HTMLElement, enabled: boolean): void {
        // just to be safe
        if (!swiperSlide) {
            return;
        }

        const elementsWithin: NodeListOf<HTMLElement> = swiperSlide.querySelectorAll('a, button, input');
        elementsWithin.forEach((element: HTMLElement) => {
            // enable the elements for actionable
            if (enabled) {
                element.removeAttribute('tabindex');
                element.removeAttribute('aria-hidden');
            } else {
                element.setAttribute('tabindex', '-1');
                element.setAttribute('aria-hidden', 'true');
            }
        });

        // also need to mark the entire slide
        if (enabled) {
            swiperSlide.removeAttribute('aria-hidden');
        } else {
            swiperSlide.setAttribute('aria-hidden', 'true');
        }
    }

    private focusNextSlide(activeElement: HTMLElement): void {
        // just to be safe
        if (!activeElement) {
            return;
        }

        const headerElement: HTMLElement = activeElement.querySelector('h2, h3, h4, h5');
        if (headerElement) {
            // focus the single header
            headerElement.setAttribute('tabindex', '-1');
            headerElement.focus();

            return;
        }
        const focusElement: HTMLElement = this.focusSelector ? activeElement.querySelector(this.focusSelector) : undefined;
        if (focusElement) {
            if (focusElement.tagName !== 'A' && focusElement.tagName !== 'BUTTON' && !focusElement.hasAttribute('tabindex')) {
                focusElement.setAttribute('tabindex', '-1');
            }

            focusElement.focus();
        } else {
            // just focus the entire slide
            activeElement.setAttribute('tabindex', '-1');
            activeElement.focus();
        }
    }
}
