import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Util, XmStore, XmStoreUtil } from 'core/services';
import { CmsCore } from 'store/cms/models';

@Component({
    selector: 'xm-checkout-nav',
    styleUrls: [ './checkout.scss' ],
    templateUrl: './checkout.html'
})
export class NavCheckoutComponent implements OnDestroy, OnInit {

    public pageData: SiteNav;
    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;

    constructor(xmStore: XmStore) {
        Object.assign(this, { xmStore });
    }

    public ngOnInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (result: CmsCore) => {
            this.pageData = result.nav;
        }));
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }
}
