import { ChildModel, ModelBase } from 'store/model-base';
import { ShippingMethod } from './shipping-method';

export class ShippingMethodCollection extends ModelBase {
    public static storeName: string = 'shippingMethodCollection';

    private static DEFAULT_SHIPPING_ID: string = '2Days';

    public shippingMethods: ShippingMethod[];

    public defaultShippingMethod: ShippingMethod;

    public static create<T extends ModelBase>(initData: object): T {
        const toReturn: ShippingMethodCollection = super.create<ShippingMethodCollection>(initData);

        toReturn.defaultShippingMethod = toReturn.shippingMethods.find((method: ShippingMethod) => method.id === ShippingMethodCollection.DEFAULT_SHIPPING_ID);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'defaultShippingMethod',
            model: ShippingMethod
        }];
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'shippingMethods',
            model: ShippingMethod
        }];
    }

    public getMethodById(id: string): ShippingMethod {
        return this.shippingMethods.find((method: ShippingMethod) => method.id === id);
    }
}
