import { ModelBase } from 'store/model-base';

export class ShippingMethod extends ModelBase {
    public static storeName: string = 'shippingMethod';

    public id: string;
    public name: string;
    public price: number;
    public description: string;
}
