import { ModelBase } from 'store/model-base';

export class DevicePaymentPlan extends ModelBase {
    public static storeName: string = 'devicePaymentPlan';

    public id: Id;
    public total: number;
    public balance: number;
    public paymentAmount: number;
    public paymentTermCompletionDate: string;
    public paymentTerm: number;
    public finalPaymentAmount: number;
    public paymentsRemaining: number;
    public hasDPP: boolean;
}
