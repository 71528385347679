import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { StateService } from '@uirouter/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionStorage } from 'core/services';
import { CimaUrl } from '../cima/url';
import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN, StorageToken } from 'core/constants';
import { HttpError } from './error';
import { getOperationType, logAndHandleError } from 'services/log/LogHelper';

@Injectable()
export class GenericErrorInterceptor implements HttpInterceptor {
    private config: IXMOptions;
    private state: StateService;
    private cimaUrl: CimaUrl;
    private sessionStorage: SessionStorage;

    constructor(state: StateService, cimaUrl: CimaUrl, @Inject(CONFIG_TOKEN) config: IXMOptions, sessionStorage: SessionStorage) {
        Object.assign(this, { state, cimaUrl, config, sessionStorage });
    }

    public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {  
                if (error.status === 401 && (error.url.includes(this.config.GATEWAY_BASE_URL))) {
                    logAndHandleError(error, request.url, getOperationType(request.method));
                    this.sessionStorage.set(StorageToken.CBM_SAVED_LAST_STATE, JSON.stringify({ state: this.state.current.name, params: this.state.params }));
                    this.cimaUrl.redirectToLogin({ state: this.state.current.name, params: this.state.params });
                    
                    return EMPTY;
                } else {
                    return throwError(new HttpError(error));
                }
            })
        );
    }
}
