import { ChildModel, ModelBase } from 'store/model-base';
import { StreetAddress } from './street';

export class FullAddress extends ModelBase {
    public static idAttribute: string = 'firstName';

    public firstName: string;
    public middleName: string;
    public lastName: string;
    public address: StreetAddress;

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'address',
            model: StreetAddress
        }];
    }

    public get hasAddress(): boolean {
        return Boolean(this.address.address1);
    }

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public transformToHopBilling(email: string, ipAddress: string): object {
        return {
            action: 'submit',
            billTo_firstName: this.firstName || '',
            billTo_lastName: this.lastName || '',
            billTo_street1: this.address.address1 || '',
            billTo_street2: this.address.address2 || '',
            billTo_city: this.address.city || '',
            billTo_email: email || '',
            billTo_state: this.address.state || '',
            billTo_postalCode: this.address.zip.substr(0, 5) || '',
            billTo_ipAddress: ipAddress || '',
            billTo_country: 'US' // funny story, DO NOT CHANGE THIS (even to lowercase)
        };
    }

    public transformToHopShipping(phoneNumber: string, email: string): object {
        // for orders that do not ship, don't send address info
        if (!this.hasAddress) {
            return {
                shipTo_phoneNumber: phoneNumber || '',
                shipTo_email: email || ''
            };
        }

        return {
            shipTo_firstName: this.firstName || '',
            shipTo_lastName: this.lastName || '',
            shipTo_street1: this.address.address1 || '',
            shipTo_street2: this.address.address2 || '',
            shipTo_city: this.address.city || '',
            shipTo_phoneNumber: phoneNumber || '',
            shipTo_email: email || '',
            shipTo_state: this.address.state || '',
            shipTo_postalCode: this.address.zip || '',
            shipTo_country: 'US' // funny story, DO NOT CHANGE THIS (even to lowercase)
        };
    }
}
