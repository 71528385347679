import { ChildModel, ModelBase } from 'store/model-base';
import { ProductDetails } from '../../product/models';

export class ImeiProductDetails extends ModelBase {
    public static storeName: string = 'imeiProductDetails';

    public imei: string;
    public product: ProductDetails;

    public static get hasOne(): ChildModel[] {
        return [{
            attrName: 'product',
            model: ProductDetails
        }];
    }
}
