import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { fromEvent, interval, Subject, Subscription } from 'rxjs';
import { filter, throttle } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';
import * as MobileDetect from 'mobile-detect';

import { Util } from 'services/util';

@Injectable({
    providedIn: 'root'
})
export class Accessibility implements OnDestroy {
    public static DELAY_FOCUS: number = 250;
    private static ACCESSIBILITY_TIME_DELAY: number = 0;

    public usingKeyboard: boolean = false;

    private subscriptions: Subscription[] = [];
    private screenReaderTextQueue: Subject<string> = new Subject<string>();
    private htmlBody: HTMLElement;
    private screenReaderElement: HTMLElement;
    private mobileDetect: MobileDetect = new MobileDetect(navigator.userAgent);
    private isDesktop: boolean;
    private zone: NgZone;

    constructor(overlayContainer: OverlayContainer, zone: NgZone) {
        Object.assign(this, { overlayContainer, zone });
        this.htmlBody = window.document.getElementsByTagName('body')[0];
        this.isDesktop = !this.mobileDetect.mobile() && !this.mobileDetect.tablet();

        // run all code outside angular life-cycle to no cause change detection
        this.zone.runOutsideAngular(() => {
            this.subscriptions.push(this.screenReaderTextQueue.subscribe((screenReaderText: string) => {
                if (!this.screenReaderElement) {
                    this.screenReaderElement = window.document.getElementById('screen-reader');
                }

                if (this.screenReaderElement) {
                    this.screenReaderElement.innerHTML = screenReaderText;
                }
            }));

            if (this.isDesktop) {
                this.subscriptions.push(fromEvent(window, 'keydown').subscribe(() => {
                    if (!this.usingKeyboard) {
                        this.htmlBody.classList.add('show-outline');
                        this.usingKeyboard = true;
                    }
                }));

                this.subscriptions.push(fromEvent(window, 'mousemove').pipe(
                    filter((event: MouseEvent) => event.movementX !== 0 && event.movementY !== 0),
                    throttle(() => interval(2000))
                ).subscribe(() => {
                    if (this.usingKeyboard) {
                        this.htmlBody.classList.remove('show-outline');
                        this.usingKeyboard = false;
                    }
                }));
            }
        });

    }

    public init(): boolean {
        // nothing to do here...just want to make sure the keydown/click hooks are initialized
        return true;
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public focusHeader(isSubPage: boolean = false): void {
        if (this.atScrollTop || this.usingKeyboard) {
            setTimeout(() => {
                this.searchForElement(this.htmlBody, true, isSubPage);
            }, Accessibility.DELAY_FOCUS);
        }
    }

    public focusHash(hash: string): void {
        setTimeout(() => {
            this.focusElement(window.document.getElementById(hash));
        });
    }

    public focusElement(element: HTMLElement): void {
        if (element) {
            const tagName: string = element.tagName.toLowerCase();
            // add tabindex for none-interative elements
            if (tagName === 'input' ||
                tagName === 'textarea' ||
                tagName === 'button' ||
                tagName === 'select' || element.hasAttribute('tabindex')) {
                element.focus();
            } else {
                this.searchForElement(element);
            }

            // save the desired Y-offset before element get focused and scroll again
            const pageYOffset: number = window.pageYOffset;
            window.scrollTo(0, pageYOffset);
        }
    }

    /*
     * Screen reader text passed as global context
    */
    public announceReaderText(screenReaderText: string, override: boolean = false, overrideTimeout: boolean = false): void {
        if (!overrideTimeout && (this.screenReaderElement && this.screenReaderElement.innerHTML || override)) {
            // Added timeout for subsequent call text replace
            setTimeout(() => {
                this.screenReaderTextQueue.next(screenReaderText);
            }, Accessibility.ACCESSIBILITY_TIME_DELAY);
        } else {
            this.screenReaderTextQueue.next(screenReaderText);
        }
    }

    private get atScrollTop(): boolean {
        return window.scrollY === 0;
    }

    /*
     * Search in priortiy order given the context of the starting element
     * 1. H1 tag
     * 2. H2 tag
     * 3a. The main content section if we navigated from a tranisition
     * 3b. The element itself
    */
    private searchForElement(element: HTMLElement, onTransition: boolean = false, isSubPage: boolean = false): void {
        if (!element) {
            return;
        }

        const h1Collection: NodeList = element.querySelectorAll('h1');
        let elementToFocus: HTMLElement = this.selectVisibleElement(h1Collection);

        if (isSubPage || !Util.isElementVisible(elementToFocus)) {
            const h2Collection: NodeList = element.querySelectorAll('h2');
            elementToFocus = this.selectVisibleElement(h2Collection);
        }

        if (!elementToFocus) {
            elementToFocus = onTransition ? element.querySelector('#content') : element;
        }

        if (elementToFocus) {
            elementToFocus.setAttribute('tabindex', '-1');
            elementToFocus.focus();
        }
    }

    private selectVisibleElement(elements: NodeList): HTMLElement {
        for (let i: number = 0; i < elements.length; i++) {
            const ele: HTMLElement = <HTMLElement> elements.item(i);
            if (Util.isElementVisible(ele)) {
                return ele;
            }
        }
    }
}
