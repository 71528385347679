import { DigitalDefault } from './digital-default';
import { Util } from 'services/util';

export class DigitalData extends DigitalDefault {

    public static forRoute(pageName: string, primaryCategory: string, subCategory: string, params: object = {}, pageType: string = '', viewPortSize: string, flowType: string = ''): DigitalData {
        const defaults: TDigitalData = DigitalDefault.cloneDefaults();

        Util.mergeDeepWith(defaults, {
            page: {
                attributes: {
                    flowType: flowType || ''
                },
                category: {
                    pageType: pageType || '',
                    primaryCategory: primaryCategory || '',
                    subCategory: subCategory || ''
                },
                pageInfo: {
                    pageName: pageName || ''
                }
            },
            siteInfo: {
                siteVersion: viewPortSize
            }
        });
        
        return new DigitalData(params, defaults.page, defaults.siteInfo, defaults.users, defaults.cart, defaults.event);
    }

    constructor(
        customData: object,
        page?: TDDPage,
        siteInfo?: TDDSiteInfo,
        users?: TDDUser[],
        cart?: TDDCart,
        event?: TDigitalEvent[]
    ) {
        super(page, siteInfo, users, cart, event);
        Object.assign(this, customData);
    }
}
