import { ChildModel, ModelBase } from 'store/model-base';
import { ProductDetails } from '../../product/models';

export class ImeiDevice extends ModelBase {
    public static storeName: string = 'imeiDevice';

    public compatibleProducts: CompatibleProducts[];
    public byodEligibleStatus: boolean;
    public defaultVariant: number;
    public imei: string;
    public iccid: string;
    public byodConditionalReasons: EligibilityReason[];
    public byodDenialReasons: EligibilityReason[];
    public product: ProductDetails;

    public byodDenialReasonCodes: string[];

    public byodReasons: EligibilityReason[];

    public static get hasOne(): ChildModel[] {
        return [{
            attrName: 'product',
            model: ProductDetails
        }];
    }

    public static create<T extends ModelBase>(initData: object = {}): T {
        const toReturn: ImeiDevice = super.create<ImeiDevice>(initData);

        if (!toReturn.byodDenialReasons) {
            toReturn.byodDenialReasons = [];
        }

        toReturn.byodDenialReasonCodes = toReturn.byodDenialReasons.map((reason: EligibilityReason) => reason.code);

        if (!toReturn.byodConditionalReasons) {
            toReturn.byodConditionalReasons = [];
        }

        toReturn.byodReasons = [ ...toReturn.byodDenialReasons, ...toReturn.byodConditionalReasons ];

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }
}
