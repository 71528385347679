import { ChildModel, ModelBase } from 'store/model-base';
import { Account } from './account';
import { BuyInfo } from './buy-info';
import { AllowedLines } from './allowed-lines';
import { UserState } from 'core/constants';
import { StreetAddress } from 'store/user/models/address/street';
import { AccountDetails } from './account-details';

export class User extends ModelBase {
    public static storeName: string = 'user';

    public buyInfo: BuyInfo;
    public account: Account;
    public allowedLines: AllowedLines;
    public accountDetails: AccountDetails;

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'account',
            model: Account
        }, {
            attrName: 'buyInfo',
            model: BuyInfo
        }, {
            attrName: 'allowedLines',
            model: AllowedLines
        }];
    }

    public get accountNo(): string {
        return (this.account.id ? this.account.id.value : '') || '';
    }

    public get displayName(): string {
        return this.account.displayName || this.buyInfo.displayName;
    }

    public get displayFirstName(): string {
        return this.account.firstName || this.buyInfo.firstName || '';
    }

    public get serviceAddress(): StreetAddress {
        return this.account.serviceAddress;
    }

    public get notificationPhone(): string {
        return this.account.notificationContacts ? this.account.notificationContacts.sms : '';
    }

    public get notificationEmail(): string {
        return this.account.notificationContacts ? this.account.notificationContacts.email : '';
    }

    public get nextCycleDate(): Date {
        return this.account ? this.account.nextCycleDate : new Date();
    }

    public get isCBMCustomer(): boolean {
        return this.account.state === UserState.ACTIVE;
    }
}
