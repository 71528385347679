import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BUTTON_LOADER_CLASS } from 'core/constants';

@Directive({
    selector: '[promiseClick]',
    host: {
        '(click)': 'click($event)'
    }
})
export class XmButtonClick implements OnInit, OnDestroy {
    @Output() private promiseClick: EventEmitter<Event> = new EventEmitter<Event>();

    private observer: MutationObserver;
    private element: HTMLElement;

    constructor(element: ElementRef) {
        Object.assign(this, { element: element.nativeElement });
    }

    public ngOnInit(): void {
        this.observer = new MutationObserver(this.callback.bind(this));
        this.observer.observe(this.element, {
            attributes: true,
            childList: true,
            characterData: true,
            attributeFilter: [ 'class' ]
        });
    }

    public ngOnDestroy(): void {
        this.observer.disconnect();
    }

    public click(event: MouseEvent): void {
        if (!this.element.classList.contains(BUTTON_LOADER_CLASS)) {
            this.promiseClick.emit(event);
        }
    }

    private callback(records: MutationRecord[]): void {
        records.forEach((record: MutationRecord) => {
            if (record.type === 'attributes' && record.attributeName === 'class') {
                const ele: HTMLElement = <HTMLElement> record.target;

                if (ele.classList.contains(BUTTON_LOADER_CLASS)) {
                    this.element.setAttribute('aria-disabled', 'true');
                } else {
                    // Disabling remove action if xmAriaDisabled is present to support buttons with PromiseClicks
                    if (!this.element.hasAttribute('xmAriaDisabled')) {
                        this.element.removeAttribute('aria-disabled');
                    }
                }
            }
        });
    }
}
