import { ChildModel, ModelBase } from 'store/model-base';
import { ByodImeiCapability, DataPlanType, OrderItemCategory, OrderItemType, OS } from 'core/constants';
import { OrderItemCharge } from './charge';
import { OrderItemPromotion } from './promotion';

export class OrderItem extends ModelBase {
    public static storeName: string = 'orderItem';
    public static idAttribute: string = 'orderItemId';

    public orderItemId: string;
    public type: OrderItemType;
    public planDetails: OrderItemPlanDetails;
    public deviceIccid: string;
    public deviceImei: string;
    public displayName: string;
    public lineNumber: string; // can be undefined for new number
    public productId: string;
    public byodCarrier: string;
    public productDetails: LineDeviceDetail;
    public byod: boolean;
    public charges: OrderItemCharge[];
    public promotions: OrderItemPromotion[];
    public category: OrderItemCategory;
    public displayItemTotal: number;
    public simType: ByodImeiCapability;

    public isTablet: boolean;
    public isRegularOrder: boolean;
    public isPreOrderItem: boolean;
    public isBackOrderItem: boolean;
    public isByodDeviceLocked: boolean;
    public isNdelItem: boolean;
    public isNdelBackOrderItem: boolean;
    public isNdelPreOrderItem: boolean;
    public isAccessory: boolean;
    public isPhone: boolean;
    public isWatch: boolean;
    public isUnlimited: boolean;
    public hasExtendedPromo: boolean;
    public hasPrepaidPromo: boolean;
    public hasSinglePromo: boolean;
    public hasAnyExtendedPromo: boolean;
    public isIosDevice: boolean;
    public isEsim: boolean;

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'charges',
            model: OrderItemCharge
        }, {
            attrName: 'promotions',
            model: OrderItemPromotion
        }];
    }

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'hasSinglePromo',
            model: OrderItemPromotion
        }, {
            attrName: 'hasPrepaidPromo',
            model: OrderItemPromotion
        }, {
            attrName: 'hasExtendedPromo',
            model: OrderItemPromotion
        }, {
            attrName: 'hasAnyExtendedPromo',
            model: OrderItemPromotion
        }];
    }

    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const toReturn: OrderItem = super.create<OrderItem>(initData);
        toReturn.isTablet = toReturn.category === OrderItemCategory.TABLET;
        toReturn.isRegularOrder = toReturn.type === OrderItemType.REGULAR;
        toReturn.isPreOrderItem = toReturn.type === OrderItemType.PREORDER;
        toReturn.isBackOrderItem = toReturn.type === OrderItemType.BACKORDER;
        toReturn.isNdelItem = toReturn.type === OrderItemType.NDEL;
        toReturn.isNdelBackOrderItem = toReturn.type === OrderItemType.NDEL_BACKORDER;
        toReturn.isNdelPreOrderItem = toReturn.type === OrderItemType.NDEL_PREORDER;
        toReturn.isAccessory = Boolean(toReturn.category && toReturn.category?.toUpperCase() === OrderItemCategory.ACCESSORY);
        toReturn.isPhone = Boolean(toReturn.category && toReturn.category?.toUpperCase() === OrderItemCategory.PHONE);
        toReturn.isWatch = Boolean(toReturn.category && toReturn.category?.toUpperCase() === OrderItemCategory.SMART_WATCH);
        toReturn.isIosDevice = Boolean(toReturn.productDetails && toReturn.productDetails.os && toReturn.productDetails.os?.toUpperCase() === OS.IOS);
        toReturn.isEsim = Boolean(toReturn.byod && (toReturn.simType === ByodImeiCapability.ESIM || toReturn.simType === ByodImeiCapability.PSIM_ESIM ));


        if (toReturn.promotions) {
            toReturn.hasSinglePromo = toReturn.promotions.length === 1;
            toReturn.hasExtendedPromo = toReturn.promotions.some((promotion: OrderItemPromotion) => promotion.isExtendedPromo);
            toReturn.hasPrepaidPromo = toReturn.promotions.some((promotion: OrderItemPromotion) => promotion.isPrepaid);
            toReturn.hasAnyExtendedPromo = toReturn.promotions.some((promotion: OrderItemPromotion) => promotion.isExtendedPromo || promotion.isExtendedPromoDollar || promotion.isExtendedPromoPercentage);
        }

        if (toReturn.planDetails) {
            toReturn.isUnlimited = toReturn.planDetails.planGroup === DataPlanType.UNLIMITED;
        }

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    public get productName(): string {
        if (this.productDetails) {
            const product: LineDeviceDetail = this.productDetails;

            return `${product.brand || ''} ${product.model || ''}, ${product.colorName || ''}, ${product.capacity || ''}`;
        }
    }

    public get isPlanUnlimited(): boolean {
        return this.charges.some((charge: OrderItemCharge) => charge.isUnlimited);
    }

    public get productDisplayName(): string {
        return `${this.displayName}'s ${this.productDetails.name || this.productDetails.model || ''}`;
    }

    public get lastFourIccid(): string {
        return this.deviceIccid && this.deviceIccid.substring(this.deviceIccid.length - 4);
    }
}
