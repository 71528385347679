import { ModelBase } from 'store/model-base';

export class CmsSupportNotificationBanner extends ModelBase {
    public static storeName: string = 'cmsSupportNotificationBanner';

    public showOnLanding: boolean;
    public showOnArticles: boolean;
    public showOnCategories: string[];
    public showOnAllPages: boolean;
    public header: string;
    public description: string;
    public link: string;
    public closeIcon: CmsMediaImage;
    public closeAriaLabel: string;
}
