import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ModelBase } from 'store/model-base';
import { buildHeaders, XmStore } from 'core/services';
import { PastDue } from 'store/past-due/models';
import { StoreAction } from 'store/actions';
import { ApiChannel } from 'core/constants';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class PastDueApi {
    public static getPastDue(_xmStore: XmStore, http: HttpClient): Observable<PastDue> {
        return http.get('/account/pastduepayment', { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((response: PastDue) => PastDue.create<PastDue>(response), 
                catchError(err => logAndHandleError(err, '/account/pastduepayment', OperationType.GET)))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_PAST_DUE] = PastDueApi.getPastDue;
