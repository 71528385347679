import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Accessibility, CimaCore, CimaToken, Util, XmStore, XmStoreUtil } from 'core/services';
import { CmsCore } from 'store/cms/models';
import { User } from 'store/user/models';
import { MY_ACCOUNT } from 'core/constants';

@Component({
    selector: 'xm-primary-nav',
    styleUrls: [ './primary.scss' ],
    templateUrl: './primary.html'
})

export class NavPrimaryComponent implements OnDestroy, OnInit {
    // @ViewChild('xmNavHamburgerRoot', { read: ElementRef, static: true }) public root: ElementRef;
    @Input() public hideSkipToContent: boolean;

    public isMobileNavOpen: boolean = false;
    public isNavOpen: boolean = false;
    public checkHamburgerNavExpanded: boolean = false;
    public abTestExperience: string;

    public pageData: SiteNav;
    public myAccountMenuItems: CmsNavMenuItems;
    public mobileAccountLink: CmsNavMenuItems[];
    private subscriptions: Subscription[] = [];

    private accessibility: Accessibility;
    private cimaToken: CimaToken;
    private xmStore: XmStore;
    private cimaCore: CimaCore;

    constructor(accessibility: Accessibility, cimaToken: CimaToken, xmStore: XmStore, cimaCore: CimaCore) {
        Object.assign(this, { accessibility, cimaToken, xmStore, cimaCore });
    }

    public ngOnInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (result: CmsCore) => {
            this.pageData = result.nav;
            this.myAccountMenuItems = this.pageData.desktopMyAccountLinksUnauthenticated;
        }));

        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<User>(User, { returnUndefined: true }), (user: User) => {
            const displayName: string = user ? user.displayName : '';
            if (this.cimaToken.isLoggedIn) {
                if (displayName) {
                    this.myAccountMenuItems.linkDecoration = this.myAccountMenuItems.linkDecoration.replace(MY_ACCOUNT, displayName);
                }

                const children: CmsNavMenuItems[] = displayName ? [ ...this.pageData.desktopMyAccountLinksAuthenticated.children ] : [];

                this.myAccountMenuItems = { ... this.myAccountMenuItems, children };
            }
            this.mobileAccountLink = displayName ? this.pageData.mobileAccountLinksAuthenticated : this.pageData.mobileAccountLinksUnauthenticated;
        }));
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public focusContent(): void {
        this.accessibility.focusHeader();
    }

    public closeHamburgerNavMenu(): void {
        this.checkHamburgerNavExpanded = !this.checkHamburgerNavExpanded;
    }

    // public focusoutHamburgerMenu(event: FocusEvent): void {
    //     // Support for IE
    //     const relatedTargetElement: EventTarget = document.activeElement === document.body ? event.relatedTarget : document.activeElement;

    //     if (this.isNavOpen && (!relatedTargetElement || !this.root.nativeElement.contains(relatedTargetElement))) {
    //         this.accessibility.announceReaderText(this.pageData.navCloseReaderText);
    //     }
    // }

    public isMobileNavExpand(isNavOpen: boolean): void {
        this.isNavOpen = isNavOpen;
    }

    public get isBusinessUser(): boolean {
        return this.cimaCore && this.cimaCore.isBusinessUser;
    }
}
