import { Ng2StateDeclaration } from '@uirouter/angular';
import { Param, StateService, Transition, UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AbTestService, Accessibility, Breadcrumbs, CimaCore, CimaUrl, DataLayer, DownPayment, TrialService, XmStore, XmStoreUtil } from 'core/services';
import { CoreRoute } from 'core/route';
import { StoreAction } from 'store/actions';
import { IXMOptions } from 'core/interfaces';
import { CmsCore } from 'store/cms/models';
import { AuthLogout } from 'src/xm/route-guards';
import { ParamType, PASSIVE_OAUTH_BLACKLIST, TransitionHooks } from 'core/constants';

import { BaseComponent } from 'src/xm/base/base';
import { NavPrimaryComponent } from 'shared/nav/primary/primary';
import { XmFooterComponent } from 'shared/footer/footer';
import { VisitorSessionService } from 'services/log/VisitorSessionService';

export function AppInit(
    cimaCore: CimaCore,
    downPayment: DownPayment,
    accessibility: Accessibility,
    coreRoute: CoreRoute,
    breadcrumb: Breadcrumbs,
    state: StateService,
    transition: Transition,
    trialService: TrialService,
    visitorSessionService: VisitorSessionService,
    config: IXMOptions): Promise<string> {

    breadcrumb.init();
    coreRoute.init();
    accessibility.init();
    trialService.init(transition.params().TRIAL_ID);
    visitorSessionService.init();

    return cimaCore.loadCimaToken().then((token: string) => {
        downPayment.init();

        const initPassiveOauth: boolean = !PASSIVE_OAUTH_BLACKLIST.some((stateRegex: RegExp) => stateRegex.test(transition.to().name));

        if (initPassiveOauth) {
            cimaCore.tryPassiveAuthLogin();
        }

        return token;
    })
        .catch((err) => {
            // eslint-disable-next-line no-console
            console.log('Routes error', err);

            // if we cannot get a token, then go to downtime
            if (!config.DISABLE_DOWNTIME) {
                state.go('downtime');
            }
            
            return Promise.reject('no token');
        });
}


export function LoadDataLayer(dataLayer: DataLayer): Promise<void> {
    return dataLayer.loadDataLayer();
}

export function GetAbTests(abTestService: AbTestService): Promise<string | void> {
    return abTestService.loadTarget();
}

export function LoadCmsCore(xmStore: XmStore): Promise<CmsCore> {
    return XmStoreUtil.defaultCatch(xmStore.fetchPromise<CmsCore>(StoreAction.GET_CORE));
}

export function transitionStart(uiRouter: UIRouter): Observable<Transition> {
    return uiRouter.globals.start$.pipe(
        map((transition: Transition) => transition),
        distinctUntilChanged()
    );
}

export function transitionSuccess(uiRouter: UIRouter): Observable<Transition> {
    return uiRouter.globals.success$.pipe(
        map((transition: Transition) => transition),
        distinctUntilChanged()
    );
}

export function paramsChanged(uiRouter: UIRouter): Observable<Param> {
    return uiRouter.globals.params$.pipe(
        map((params: Param) => params),
        distinctUntilChanged()
    );
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const XM_ROUTES: Ng2StateDeclaration[] = [
    {
        abstract: true,
        name: 'xm',
        url: '?TRIAL_ID&AdobeNoBueno',
        resolve: [
            {
                token: 'appInit',
                deps: [ CimaCore, DownPayment, Accessibility, CoreRoute, Breadcrumbs, StateService, Transition, TrialService, VisitorSessionService ],
                resolveFn: AppInit
            },
            {
                token: 'pageDataCore',
                deps: [XmStore, 'appInit'],
                resolveFn: LoadCmsCore
            },
            {
                token: 'dataLayerInit',
                deps: [ DataLayer ],
                resolveFn: LoadDataLayer
            },
            {
                token: 'abTests',
                deps: [AbTestService],
                resolveFn: GetAbTests
            },
            {
                token: TransitionHooks.START,
                deps: [UIRouter],
                resolveFn: transitionStart
            },
            {
                token: TransitionHooks.SUCCESS,
                deps: [UIRouter],
                resolveFn: transitionSuccess
            },
            {
                token: TransitionHooks.PARAMS,
                deps: [UIRouter],
                resolveFn: paramsChanged
            }
        ],
        views: {
            '': {
                component: BaseComponent
            },
            'nav@xm': {
                component: NavPrimaryComponent
            },
            'footer@xm': {
                component: XmFooterComponent
            }
        },
        params: {
            TRIAL_ID: {
                type: ParamType.encoded
            }
        }
    },
    {
        name: 'home',
        url: '/',
        redirectTo: 'landing.landing'
    },
    {
        name: 'landing',
        abstract: true,
        url: '/learn/mobile',
        loadChildren: () => import('./learn/landing/module').then((m: typeof import('./learn/landing/module')) => m.LandingModule)
    },
    { name: 'landing.landing', url: '' },
    { name: 'landing.verification', url: 'verification' },
    {
        name: 'byod',
        abstract: true,
        url: '/learn/mobile/byod',
        loadChildren: () => import('./learn/byod/module').then((m: typeof import('./learn/byod/module')) => m.XmByodModule)
    },
    // {
    //     name: 'network',
    //     url: '/network',
    //     abstract: true,
    //     loadChildren: () => import('./learn/network/module').then((m: typeof import('./learn/network/module')) => m.NetworkModule)
    // },
    // {
    //     name: 'network.map',
    //     url: '/map?zip',
    //     params: {
    //         zip: {
    //             type: ParamType.query,
    //             dynamic: true
    //         }
    //     }
    // },
    { name: 'byod.compatibility', url: '/compatibility' },
    { name: 'byod.compatibility.confirm', url: '/confirm' },
    { name: 'byod.compatibility.device', url: '/device' },
    { name: 'byod.compatibility.sim-result', url: '/sim-result' },
    { name: 'byod.compatibility.unavailable', url: '/device/unavailable' },
    { name: 'byod.compatibility.carrier', url: '/carrier' },
    { name: 'byod.compatibility.imei', url: '/imei' },
    { name: 'byod.compatibility.imei2', url: '/imei2' },
    { name: 'byod.compatibility.imei1', url: '/imei1' },
    { name: 'byod.compatibility.results', url: '/result' },
    { name: 'byod.compatibility.error', url: '/error' },
    { name: 'byod.compatibility.eligibility', url: '?prompt' },
    { name: 'byod.compatibility.order', url: '/order?prompt' },
    { name: 'byod.compatibility.ineligible', url: '/ineligible?prompt' },
    { name: 'byod.compatibility.confirmation', url: '/confirmation?q' },
    { name: 'byod.compatibility.sim', url: '/sim' },
    {
        abstract: true,
        name: 'shop',
        url: '/shop/mobile',
        loadChildren: () => import('./buy/shop/module').then((m: typeof import('./buy/shop/module')) => m.ShopModule)
    },
    {
        name: 'shop.catalog',
        url: '?category&subcategory&brand&color&capacity&deviceOS&compatibility&sortBy&page&lineId&compatibleWith',
        params: {
            category: {
                type: ParamType.encoded,
                dynamic: false
            },
            subcategory: {
                type: ParamType.encoded,
                dynamic: true
            },
            brand: {
                type: ParamType.encoded,
                dynamic: true
            },
            color: {
                type: ParamType.encoded,
                dynamic: true
            },
            capacity: {
                type: ParamType.encoded,
                dynamic: true
            },
            compatibility: {
                type: ParamType.encoded,
                dynamic: true
            },
            deviceOS: {
                type: ParamType.encoded,
                dynamic: true
            },
            sortBy: {
                type: ParamType.encoded,
                dynamic: true
            },
            page: {
                type: ParamType.encoded,
                dynamic: true
            },
            lineId: {
                type: ParamType.encoded,
                dynamic: true
            },
            compatibleWith: {
                type: ParamType.encoded,
                dynamic: true
            }
        }
    },
    {
        name: 'shop.device',
        url: '/phone/:slug?colorName',
        params: {
            colorName: {
                type: ParamType.encoded,
                dynamic: true
            }
        }
    },
    {
        name: 'shop.tablet',
        url: '/tablet/:slug?colorName',
        params: {
            colorName: {
                type: ParamType.encoded,
                dynamic: true
            }
        }
    },
    {
        name: 'shop.watch',
        url: '/watch/:slug?colorName',
        params: {
            colorName: {
                type: ParamType.encoded,
                dynamic: true
            }
        }
    },
    {
        name: 'shop.accessory',
        url: '/accessory/:slug?colorName',
        params: {
            colorName: {
                type: ParamType.encoded,
                dynamic: true
            }
        }
    },
    {
        name: 'shop.customize',
        url: '',
        loadChildren: () => import('./buy/shop/customizer/module').then((m: typeof import('./buy/shop/customizer/module')) => m.CustomizerModule)
    },
    { name: 'shop.customize.watch', url: '/watch/customize' },
    { name: 'shop.customize.tablet', url: '/tablet/customize' },
    { name: 'shop.customize.tablet.plan', url: '/plan' },
    { name: 'shop.customize.tablet.line', url: '/line' },
    { name: 'shop.customize.tablet.combined', url: '/current-plan' },
    { name: 'shop.customize.phone', url: '/phone/customize' },
    { name: 'shop.customize.phone.combined', url: '/current-plan' },
    { name: 'shop.customize.phone.plan', url: '/plan' },
    { name: 'shop.customize.phone.line', url: '/line' },
    { name: 'shop.customer-eligibility', url: '/customer-eligibility' },
    { name: 'shop.iphone-compare', url: '/iphone-compare' },
    { name: 'shop.healthcheck', url: '/healthcheck' },
    { name: 'shop.policies', url: '/policies' },
    { name: 'shop.services', url: '/shop/offers' },
    { name: 'shop.product-not-found', url: '/product-not-found' },
    { name: 'shop.customize.phone.trade-in-info', url: '/trade-in-info' },
    {
        abstract: true,
        name: 'trade-in',
        url: '/trade-in',
        loadChildren: () => import('./buy/trade-in/module').then((m: typeof import('./buy/trade-in/module')) => m.XmTradeInModule)
    },
    
    { name: 'shop.specs', url: '/phone/:slug/specs' },
    {
        abstract: true,
        name: 'checkout',
        url: '/shop/mobile/checkout',
        loadChildren: () => import('./buy/checkout/module').then((m: typeof import('./buy/checkout/module')) => m.CheckoutModule)
    },
    { name: 'checkout.landing', url: '' },
    { name: 'checkout.eligibility', url: '/eligibility' },
    { name: 'checkout.contact-info', url: '/contact-info' },
    { name: 'checkout.shipping', url: '/shipping' },
    { name: 'checkout.payment', url: '/payment' },
    { name: 'checkout.review', url: '/review' },
    // {
    //     abstract: true,
    //     name: 'support',
    //     url: '/support',
    //     loadChildren: () => import('./support/module').then((m: typeof import('./support/module')) => m.SupportModule)
    // },
    // { name: 'support.landing', url: '' },
    // { name: 'support.category', url: '/category/:category_id' },
    // {
    //     name: 'support.troubleshooting',
    //     url: '/device-troubleshooting?page',
    //     params: {
    //         page: {
    //             type: ParamType.query,
    //             raw: true
    //         }
    //     }
    // },
    // {
    //     name: 'support.article',
    //     url: '/article/:article_id',
    //     params: {
    //         click_callback_id: '',
    //         content_id: ''
    //     }
    // },
    // {
    //     name: 'support.lite',
    //     url: '/article/:article_id/lite'
    // },
    // {
    //     name: 'support.search',
    //     url: '/search?q',
    //     params: {
    //         q: {
    //             type: ParamType.encoded
    //         }
    //     }
    // },
    {
        name: 'login.**',
        url: '/shop/mobile/login',
        loadChildren: () => import('./login/module').then((m: typeof import('./login/module')) => m.XmLoginModule)
    },
    {
        name: 'logout',
        url: '/shop/mobile/logout',
        resolve: [
            {
                token: 'cimaLogout',
                deps: [CimaUrl],
                resolveFn: AuthLogout
            }
        ]
    },
    {
        abstract: true,
        name: 'cart',
        url: '/shop/mobile/cart',
        loadChildren: () => import('./buy/cart/module').then((m: typeof import('./buy/cart/module')) => m.CartModule)
    },
    { name: 'cart.landing', url: '' }, 
    { name: 'cart.eligibility', url: '/eligibility'},    
    {
        abstract: true,
        name: 'eligibility',
        url: '/shop/mobile/eligibility',
        loadChildren: () => import('./buy/new-eligibility/module').then((m: typeof import('./buy/new-eligibility/module')) => m.EligibilityModule)
    },
    { name: 'eligibility.landing', url: '/options?checkStatus',
        params: {
            checkStatus: {
                type: ParamType.encoded,
                dynamic: false
            }
        } 
    },
    { name: 'eligibility.personal-start', url: '/personal/start' },
    { name: 'eligibility.personal-review', url: '/personal/review?offer',
        params: {
            offer: {
                type: ParamType.encoded,
                dynamic: true
            }
        } 
    },
    { name: 'eligibility.business-start', url: '/business/start' },
    { name: 'eligibility.business-review', url: '/business/review?offer',
        params: {
            offer: {
                type: ParamType.encoded,
                dynamic: true
            }
        } 
    },   
    {
        name: 'confirmation.**',
        url: '/shop/mobile/confirmation',
        loadChildren: () => import('./buy/confirmation/module').then((m: typeof import('./buy/confirmation/module')) => m.ConfirmationModule)
    },
    {
        name: 'error.**',
        url: '/shop/mobile/error',
        loadChildren: () => import('./error/error/module').then((m: typeof import('./error/error/module')) => m.ErrorModule)
    },
    {
        name: 'product-downtime.**',
        url: '/shop/mobile/product-downtime',
        loadChildren: () => import('./error/pdp-downtime/module').then((m: typeof import('./error/pdp-downtime/module')) => m.PdpDowntimeModule)
    },
    {
        name: 'downtime.**',
        url: '/shop/mobile/downtime',
        loadChildren: () => import('./error/downtime/module').then((m: typeof import('./error/downtime/module')) => m.DowntimeModule)
    },
    {
        name: 'non-xfinity-customer.**',
        url: '/shop/mobile/add-xfinity-mobile',
        loadChildren: () => import('./error/non-xfinity-customer/module').then((m: typeof import('./error/non-xfinity-customer/module')) => m.NonCustomerModule)
    },
    {
        name: '404.**',
        loadChildren: () => import('./error/404/module').then((m: typeof import('./error/404/module')) => m.PageNotFoundModule)
    },
    {
        name: 'support-error.**',
        loadChildren: () => import('./error/support-error/module').then((m: typeof import('./error/support-error/module')) => m.SupportContentNotFoundComponent)
    },
    {
        name: 'activation-error.**',
        url: '/shop/mobile/activate-error',
        loadChildren: () => import('./error/activation-error/module').then((m: typeof import('./error/activation-error/module')) => m.ActivationErrorComponent)
    },
    {
        name: 'watch.**',
        url: '/shop/mobile/watches',
        loadChildren: () => import('./watches/module').then((m: typeof import('./watches/module')) => m.WatchModule)
    }
];
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
