import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Decorator, LinkDecorationInfo } from 'services/decorator';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UrlService } from '@uirouter/core';
import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';

@Component({
    selector: 'xm-decorated-text',
    styleUrls: [ './decorated-text.scss' ],
    templateUrl: './decorated-text.html'
})
export class XmDecoratedTextComponent implements OnChanges {
    @Input() public inputString: string;
    @Input() public analyticsText: string;
    @Input() public theme?: string = 'light';

    public decorationInfoArray: LinkDecorationInfo[];
    public sanitizer: DomSanitizer;

    private decorator: Decorator;

    constructor(sanitizer: DomSanitizer, decorator: Decorator, urlService: UrlService, @Inject(CONFIG_TOKEN) config: IXMOptions) {
        Object.assign(this, { sanitizer, decorator, urlService, config });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && (changes.inputString || changes.analyticsText && !changes.analyticsText.firstChange)) {
            this.parseInput();
        }
    }

    public safeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    private parseInput(): void {
        this.decorationInfoArray = this.decorator.parseLinkDecoration(this.inputString);
    }
}
