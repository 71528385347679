<div class="container" *ngIf="negotiableItem.isSelectable">
    <label>
        <input type="radio" name="nagotiableItem" class="card-input-element" (click)="selectNegotiableItem(false)"/>
            <div id="financeButton" class="item-input"  [ngClass]="{'item-selected': !negotiableItem.selected}">
                <div class="header">{{ mitigationData.monthlyDevicePayment }}</div>
                <span class="body">{{ negotiableItem.item.variant.downPayment | xmCurrency }} {{ mitigationData.downText }}</span>
                <span class="body">{{ negotiableItem.item.financedPlanVariant.price.monthlyCharge | xmCurrency }}/mo</span>
            </div>
    </label>
    <label>
        <input type="radio" name="nagotiableItem" class="card-input-element" (click)="selectNegotiableItem(true)"/>
            <div  id="paidFullButton" class="item-input" [ngClass]="{'item-selected': negotiableItem.selected}">
                <div class="header">{{ mitigationData.fullPrice }}</div>
                <span class="body">{{ mitigationData.msrpText }}</span>
            </div>
    </label>

</div>