import { ChildModel, ModelBase } from 'store/model-base';
import { AssociatedProductDetails } from './associated-product-detail';

export class AssociatedLineInfo extends ModelBase {
    public static storeName: string = 'associatedLineInfo';

    public line: {
        id: Id;
    };
    public device: {
        id: Id;
    };
    public imei: string;
    public displayName: string;
    public productDetails: AssociatedProductDetails;

    public static get hasOne(): ChildModel[] {
        return [{
            attrName: 'productDetails',
            model: AssociatedProductDetails
        }];
    }
}
