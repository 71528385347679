import { StoreAction, XmAction } from 'store/actions';
import { LineCollection } from './models';

export function lineCollectionReducer(state: LineCollection, action: XmAction<LineCollection>): LineCollection {
    switch (action.type) {
        case StoreAction.GET_LINE_COLLECTION:
            return action.payload;
        default:
            return state;
    }
}
