import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, NgModuleRef } from '@angular/core';
import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN, REWRITE_TOKEN } from 'core/constants';
import { XMModule } from './xm/module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
    Error.stackTraceLimit = 50;
}

export async function optionsLoaded(apiData: Response): Promise<NgModuleRef<XMModule>> {
    const options: IXMOptions = await apiData.json();
    const rewriteRulesApi: string = `${options.CMS_URL}/core/rewrite-rules.json`;
    let rewriteRules: CmsRewriteRules;

    try {
        const cmsRewriteRulesApiData: Response = await fetch(rewriteRulesApi, { credentials: 'same-origin' });
        rewriteRules = await cmsRewriteRulesApiData.json();
    } catch (e) {
        // NOOP
    }

    options.ROUTE_GUARDS = options.ROUTE_GUARDS || [];
    options.ROUTE_GUARDS_REG = options.ROUTE_GUARDS.map((route: string) => new RegExp(route));

    return platformBrowserDynamic(
        [
            {
                provide: CONFIG_TOKEN,
                useValue: options
            },
            {
                provide: REWRITE_TOKEN,
                useValue: rewriteRules
            }
        ]
    ).bootstrapModule(XMModule);
}

fetch(window.xmOptions.fetchUrl, { credentials: 'same-origin' }).then(optionsLoaded);
