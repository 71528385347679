import { ChildModel, ModelBase } from 'store/model-base';
import { LineDevice } from './device';
import { LineStatus } from 'core/constants';

export class LineDetail extends ModelBase {
    public static storeName: string = 'lineDetail';
    public static idAttribute: string = 'id.key';
    public id: Id;
    public displayName: string;
    public lineNumber: string;
    public status: LineStatus;
    public devices: LineDevice[];
    public orderId: Id;

    public static create<T extends ModelBase>(data: ApiResponse = {}): T {
        data.id = data.id || { key: '', value: '' };
        data.displayName = data.displayName || '';
        data.lineNumber = data.lineNumber || '';
        data.devices = data.devices || [];
        data.devices = data.devices.map((device: LineDevice) => ({ ...device, lineDisplayName: data.displayName, lineId: data.id }));

        const toReturn: LineDetail = super.create<LineDetail>(data);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'devices',
            model: LineDevice
        }];
    }
}
