<nav class="product-nav" role="navigation">
    <div class="links" hidden>
        <div class="left">
            <div *ngFor="let menuItem of pageData.desktopProductLeftMenuItems" class="desktop-menu-items">
                <xm-nav-menu-item [pageData]="menuItem" [showChildren]="true" [isFromProductMenu]="true"></xm-nav-menu-item>
            </div>
        </div>
        <div class="right">
            <div *ngFor="let menuItem of pageData.desktopProductRightMenuItems" class="desktop-menu-items">
                <xm-nav-menu-item [pageData]="menuItem" [showChildren]="true" [isFromProductMenu]="true"></xm-nav-menu-item>
            </div>
        </div>
    </div>
</nav>