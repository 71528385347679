import { ModelBase } from 'store/model-base';

export class CmsCore extends ModelBase {
    public static storeName: string = 'cmsCore';

    public costSavings: CostSavings;
    public modals: SiteModals;
    public productCard: CmsProductCard;
    public metaData: SiteMetaData;
    public footer: SiteFooter;
    public nav: SiteNav;
    public icons: CmsIcons;
    public carousel: CmsCarousel;
    public schema: CmsSchema;
    public ratePlanName: CmsRatePlanName;
    public byodPromo: CmsByodPromoBanner;
    public utilityPromo: CmsUtilityPromoBanner;
}
