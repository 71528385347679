import { ModelBase } from 'store/model-base';
import { InbentaSourceType } from 'support/constants';

export class SearchResult extends ModelBase {
    public static storeName: string = 'searchResult';
    public static idAttribute: string = 'query';

    public query: string;
    public metadata: SupportSearchMetadata;
    public results: SupportSearchResult[];
}

export class SupportSearchResult extends ModelBase {
    // eslint-disable  @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    public category_name: string;
    public click_callback_id: string;
    public content_id: number;
    public labels: string[];
    public preview_text: string;
    public rating_callback_id: string;
    public section_name: string;
    public source: InbentaSourceType;
    public support_id: string;
    public title: string;
    public url: string;
    // eslint-enable  @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match

    public plainTitle: string;
    public vanityTitle: string;
}
