import { TradeInFlowState } from 'buy/trade-in/models/flow-state';
import { StoreAction, XmAction } from 'store/actions';
import { OfferDetail, TradeInDetail, TradeInDeviceDetail, TradeInMakeAndModel } from './models';

export function tradeInFlowDetailsReducer(state: TradeInFlowState, action: XmAction<TradeInFlowState>): TradeInFlowState {
    switch (action.type) {
        case StoreAction.PERSIST_TRADE_IN_FLOW_DETAILS:
        case StoreAction.DELETE_TRADE_IN_FLOW_DETAILS:
            return action.payload;
        default:
            return state;
    }
}

export function tradeInDetailsReducer(state: TradeInDetail, action: XmAction<TradeInDetail>): TradeInDetail {
    switch (action.type) {
        case StoreAction.PERSIST_CBM_TRADE_IN_DETAILS:
        case StoreAction.DELETE_CBM_TRADE_IN_DETAILS:
            return action.payload;
        default:
            return state;
    }
}

export function tradeInDeviceDetailReducer(state: TradeInDeviceDetail[], action: XmAction<TradeInDeviceDetail[]>): TradeInDeviceDetail[] {
    switch (action.type) {
        case StoreAction.GET_UNAUTH_TRADE_IN_DEVICE_DETAILS:
        case StoreAction.GET_AUTH_TRADE_IN_DEVICE_DETAILS:
        case StoreAction.GET_UNAUTH_TRADE_IN_DEVICE_STORAGE_CARRIER:
        case StoreAction.GET_AUTH_TRADE_IN_DEVICE_STORAGE_CARRIER:        
            return action.payload;
        case StoreAction.DELETE_TRADE_IN_DEVICE_DETAILS:
            return undefined;
        default:
            return state;
    }
}

export function tradeInDeviceOfferReducer(state: OfferDetail, action: XmAction<OfferDetail>): OfferDetail {
    switch (action.type) {
        case StoreAction.GET_UNAUTH_TRADE_IN_DEVICE_OFFER:
        case StoreAction.GET_AUTH_TRADE_IN_DEVICE_OFFER:
            return action.payload;
        case StoreAction.DELETE_TRADE_IN_OFFER:
            return undefined;
        default:
            return state;
    }
}

export function tradeInMakeAndModelReducer(state: TradeInMakeAndModel, action: XmAction<TradeInMakeAndModel>): TradeInMakeAndModel {
    switch (action.type) {
        case StoreAction.GET_TRADE_IN_MAKE_AND_MODEL:
            return action.payload;
        default:
            return state;
    }
}
