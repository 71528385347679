import { MBCMessageCodes } from 'core/constants';
import { ChildModel, ModelBase } from 'store/model-base';
import { AssociatedLineInfo } from './associated-line-info';

export class SimCompatibility extends ModelBase {
    public static storeName: string = 'simCompatibility';

    public eligibilityStatus: boolean;
    public messages: Message[];
    public associatedLineInfo: AssociatedLineInfo;
    public iccid: string;

    public isActive: boolean;
    public isReadyToActivate: boolean;
    public isNotXmSim: boolean;
    public isErrorLimitReached: boolean;

    public static create<T extends ModelBase>(initData: object = {}): T {
        const toReturn: SimCompatibility = super.create<SimCompatibility>(initData);

        if (toReturn.messages) {
            toReturn.isActive = toReturn.messages.some((message: Message) => message.code === MBCMessageCodes.ACTIVE_SIM);
            toReturn.isReadyToActivate = toReturn.messages.some((message: Message) => message.code === MBCMessageCodes.READY_TO_ACTIVATE_SIM);
            toReturn.isNotXmSim = toReturn.messages.some((message: Message) => message.code === MBCMessageCodes.BAD_SIM || message.code === MBCMessageCodes.SIM_NOT_XM_RANGE);
            toReturn.isErrorLimitReached = toReturn.messages.some((message: Message) => message.code === MBCMessageCodes.ERROR_LIMIT_REACHED);
        }

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    public static get hasOne(): ChildModel[] {
        return [{
            attrName: 'associatedLineInfo',
            model: AssociatedLineInfo
        }];
    }
}
