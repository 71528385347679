import { Pipe, PipeTransform } from '@angular/core';
/*
 * Capitalize the first letter of the string
 * Takes a string as a value.
 * Usage:
 *  value | sentenceCase
*/
@Pipe({
    name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {
    public transform(value: string): string {
        if (value === undefined) return '';

        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}