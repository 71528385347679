import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'

})

export class IsSessionExpired {

    private static sessionExpired: boolean = false;

    public static setSessionExpired(value: boolean): void {
        this.sessionExpired = value;
    }

    public static getSessionExpiredValue(): boolean {
        return this.sessionExpired;
    }

}