import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {ApiChannel, StorageToken } from 'core/constants';

import { buildHeaders } from '../http/options';
import { SessionStorage } from '../storage/session';

interface VisitorData {
    visitorSessionId: string;   
}

@Injectable({
    providedIn: 'root'
})

export class VisitorSessionService {
    private http: HttpClient;
    private sessionStorage: SessionStorage;

    constructor( sessionStorage: SessionStorage, http: HttpClient) {
        Object.assign(this, { sessionStorage, http});
    }

    public init(): void {
        if (!this.sessionStorage.hasKey(StorageToken.VISITOR_SESSION_ID) || this.sessionStorage.get(StorageToken.VISITOR_SESSION_ID) === 'undefined') {
            this.getVisitorSessionID();
        }
    }  

    private getVisitorSessionID(): void {
        this.http.get('/businesswebapi/visitorsession', { 
            headers: buildHeaders({ apiChannel: ApiChannel.SESSION_API })
        }).subscribe((result: VisitorData) => {            
            this.sessionStorage.set(StorageToken.VISITOR_SESSION_ID, result.visitorSessionId);
        },
        (error) => {
            // eslint-disable-next-line no-console
            console.log('Visitor session error', error);
        });        
    }    
}
