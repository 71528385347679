import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export class ByodDeviceDetails {
    public device: string;
    public carrier: string;
    public carrierName: string;
    public imei: string;
    public iccid: string;
    public deviceType: string;

    public get isSimLed(): boolean {
        return Boolean(this.iccid);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ByodCompatibility {
    public deviceDetailSubject: Subject<ByodDeviceDetails> = new Subject();
    public deviceDetails: ByodDeviceDetails = new ByodDeviceDetails();

    public updateIccid(iccid: string): void {
        this.deviceDetails.iccid = iccid;
        this.deviceDetailSubject.next(this.deviceDetails);
    }

    public clearCompatibilityInfoKeepIccid(): void {
        const iccid: string = this.deviceDetails.iccid;
        this.deviceDetails = new ByodDeviceDetails();
        this.deviceDetails.iccid = iccid;
        this.deviceDetailSubject.next(this.deviceDetails);
    }

    public clearCompatibilityInfo(): void {
        this.deviceDetails = new ByodDeviceDetails();
        this.deviceDetailSubject.next(this.deviceDetails);
    }
}
