import { Rejection, StateService, Transition, TransitionPromise } from '@uirouter/core';

import { ByodCompatibility, CimaToken, CimaUrl, SessionStorage } from 'core/services';
import { Account } from 'store/user/models';
import { HttpError } from 'core/services/http/error';
import { CHECKOUT_NO_SHIP_BREADCRUMB_STATES, CHECKOUT_SHIP_BREADCRUMB_STATES, CONFIG_TOKEN, ContentPath, UserErrorType, UserState } from 'core/constants';
import { Injector } from '@angular/core';
import { IXMOptions } from 'core/interfaces';
import { BuyErrorReasons } from 'buy/constants';
import { Cart } from 'store/cart/models';
import { BuyModal } from 'buy/services/modal';
import { CbmSignInModal } from 'buy/shared/modals/sign-in/sign-in';
import { SitecoreClient } from 'services/sitecore/SitecoreClient';

export function AuthGuard(cimaUrl: CimaUrl, cimaToken: CimaToken, state: StateService, buyModal: BuyModal, transition: Transition): Promise<void> {
    const sitecoreWebClient: SitecoreClient = transition.injector().get(SitecoreClient);
    const LOADING_DELAY: number = 250;
    const stateName = state.transition.to().name;
    const params = state.transition.params();
    const config: IXMOptions = transition.injector().get(CONFIG_TOKEN);

    if (!cimaToken.isLoggedIn) {
        if (config.ENABLE_SIGN_IN_MODAL && ((stateName === 'shop.customize.phone') || (stateName === 'shop.customize.tablet') || (stateName === 'shop.customize.watch'))) {
            let modalData: FlexibleImageBannerPageData;

            sitecoreWebClient.getData(ContentPath.SIGN_IN_MODAL_PATH).then((data) => {
                setTimeout(() => {
                    modalData = data.imageBanner;

                    /* eslint-disable  @typescript-eslint/no-explicit-any */
                    buyModal.open<FlexibleImageBannerPageData>(CbmSignInModal, modalData).toPromise().then<any, TransitionPromise>((response: ModalResponse) => {
                        if (!response) {
                            return;
                        }
                        if (response.primary) {
                            cimaUrl.redirectToLogin({ state: stateName, params: params });
                        }
                    });
                }, LOADING_DELAY);
            });
        } else {
            cimaUrl.redirectToLogin({ state: stateName, params: params });
        }

        return Promise.reject(Rejection.aborted('login first'));
    } else {
        return Promise.resolve();
    }
}

export function WatchesAuthGuard(cimaUrl: CimaUrl, cimaToken: CimaToken, state: StateService): Promise<void> {
    if (!cimaToken.isLoggedIn) {
        cimaUrl.redirectToLogin({ state: state.transition.to().name, params: state.transition.params() }, 'watches');

        return Promise.reject(Rejection.aborted('login first'));
    } else {
        return Promise.resolve();
    }
}

export function WatchSecondaryUserGuard(accountResponse: Account | HttpError, state: StateService, transition: Transition): Promise<void> {
    if (accountResponse instanceof Account) {
        return Promise.resolve();
    }

    const options: WatchSecondaryUserRouteOptions = {
        isWatch: true,
        previousStateName: transition.to().name,
        params: transition.params()
    };

    return handleMyAccount403Error(state, options);
}

export function CustomerGuard(accountResponse: Account | HttpError, state: StateService): Promise<void> {

    if (accountResponse instanceof Account) {
        if (!accountResponse || (accountResponse && accountResponse.state !== UserState.NOT_SUBSCRIBED)) {
            return Promise.resolve();
        } else {
            state.go('non-xfinity-customer', { error_type: UserErrorType.NOT_USER });

            return Promise.reject(Rejection.aborted('non xfinity customer'));
        }
    } else {
        const status: number = 403;
        const error: ApiError = accountResponse.errors ? accountResponse.errors[0] : undefined;
        if (accountResponse.status === status && (error && error.code === 'NON_PRIMARY_USER')) {
            return handleMyAccount403Error(state);
        }

        // case when api fails by any reason, still we wanna user let go in my-account
        return Promise.resolve();
    }
}

export function ByoSimledGuard(byodCompatibility: ByodCompatibility, transition: Transition): Promise<void> {
    // This will prevent browser forward button to go to the next byo simled steps without providing iccid
    if (transition.from().name === 'byod.compatibility.confirm' && !byodCompatibility.deviceDetails.isSimLed) {
        return Promise.reject(Rejection.aborted('need iccid'));
    }

    return Promise.resolve();
}

export function CheckoutGuard(sessionStorage: SessionStorage, state: StateService, injector: Injector, cart: Cart): Promise<void> {
    const config: IXMOptions = injector.get(CONFIG_TOKEN);
    if (config.IGNORE_CHECKOUT_RULES) {
        return Promise.resolve();
    }

    const checkout_no_ship_breadcrumbs = CHECKOUT_NO_SHIP_BREADCRUMB_STATES;
    const checkout_ship_breadcrumbs = CHECKOUT_SHIP_BREADCRUMB_STATES;
    const CHECKOUT_BREADCRUMB_STATES: BreadcrumbState[] = cart.isSimLedType ? checkout_no_ship_breadcrumbs : checkout_ship_breadcrumbs;

    const checkoutState: BreadcrumbState = sessionStorage.hasKey('CHECKOUT_STATE') ? sessionStorage.get('CHECKOUT_STATE') : { name: undefined, displayName: undefined };
    let previousStateIndex: number = CHECKOUT_BREADCRUMB_STATES.findIndex((breadcrumbState: BreadcrumbState) => breadcrumbState.name === checkoutState.name);
    const currentStateIndex: number = CHECKOUT_BREADCRUMB_STATES.findIndex((breadcrumbState: BreadcrumbState) => breadcrumbState.name === state.transition.to().name);

    const firstLoad: boolean = previousStateIndex < 0;
    if (firstLoad) {
        previousStateIndex = 0;
    }

    if (previousStateIndex >= currentStateIndex) {
        sessionStorage.set('CHECKOUT_STATE', JSON.stringify(CHECKOUT_BREADCRUMB_STATES[currentStateIndex]));

        return Promise.resolve();
    }

    state.go(CHECKOUT_BREADCRUMB_STATES[previousStateIndex].name);

    return Promise.reject(false);
}

export function AuthLogout(cimaUrl: CimaUrl): Promise<void> {

    // eslint-disable-next-line no-console
    console.log('cima url inside auth logout method', cimaUrl);

    cimaUrl.redirectToLogout();

    return Promise.reject(Rejection.aborted('state never allowed to enter'));
}

export function HandleBuyErrorReasons(transition: Transition, httpError: HttpError, reason: BuyErrorReasons): Promise<void> {
    const error: ApiError = httpError && httpError.errors ? httpError.errors[0] : undefined;

    transition?.abort();
    if (error && error.code === 'NON_PRIMARY_USER') {
        transition?.router.stateService.go('non-xfinity-customer', { error_type: UserErrorType.SECONDARY_USER });
    } else {
        transition?.router.stateService.go('error', { reason });
    }

    return Promise.reject();
}

// Helper functions
export function handleMyAccount403Error(state: StateService, options?: WatchSecondaryUserRouteOptions): Promise<void> {
    if (options && options.isWatch) {
        state.go('watch.non-xfinity-user', { ...options.params, previousStateName: options.previousStateName });
    } else {
        state.go('non-xfinity-customer', { error_type: UserErrorType.SECONDARY_USER });
    }

    return Promise.resolve();
}
