/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Observable, throwError } from 'rxjs';
import { LogService } from './log.service';
import { LogProperties, LogTimeFields, OperationType } from './model/LogFields';
import { HttpHeaders, HttpParams } from '@angular/common/http';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable-next-line max-len */
export function GetLogDetails(error: any, apiUrl: string, operationType: OperationType, message?: string, properties?: any, request?: string, response?: string, logTimeFields?: LogTimeFields, errorType: string = 'API error', baseUrl: string = window.location.href, severity: string = 'High', statusCode: string = '200'): LogProperties{
    let logProperties: LogProperties = {};

    if (message && typeof message === 'object') {
        // eslint-disable-next-line no-param-reassign
        message = JSON.stringify(message);
    }    

    logProperties = {
        apiUrl,
        baseUrl,
        message, 
        errorType,
        operationType, 
        statusCode: error && error.status ? JSON.stringify(error.status) : statusCode, 
        severity,        
        request: request,
        response: error && error.errors ? JSON.stringify(error.errors) : response,
        properties, 
        logTimeFields
    };

    return logProperties;
}

export function logAndHandleError(error: any, apiUrl: string, operationType: OperationType, cbmGUID?: string, request?: string, _headers?: HttpHeaders, _params?: HttpParams, sessionId?: string): Observable<never>{
    const logProperties = GetLogDetails(error, apiUrl, operationType, undefined, { globalTrackingId: String(cbmGUID), sessionId: sessionId }, request );
    LogService.logError(logProperties);
  
    return throwError(error);
}

export function logAndHandleMessage(messageDetails: any, apiUrl: string, operationType: OperationType, messageText?: string, request?: string, response?: string, logTimeFields?: LogTimeFields, statusCode?: string): void {
    let totalSeconds: number = 0;

    if (logTimeFields) {
        const startTimeInMilliSeconds: number = logTimeFields.StartTimeUtc.getTime() / 1000;
        const endTimeUtcInMilliSeconds: number = logTimeFields.EndTimeUtc.getTime() / 1000;
        totalSeconds = Math.abs(startTimeInMilliSeconds - endTimeUtcInMilliSeconds);
    }
    const logProperties = GetLogDetails(messageDetails, apiUrl, operationType, messageText, { messageDetails, duration: totalSeconds }, request, response, logTimeFields, undefined, undefined, undefined, statusCode );
    
    LogService.logMessage(logProperties);
}

export function getOperationType(type: string): OperationType {
    const operationType = type?.toUpperCase();

    switch (operationType) {
        case 'GET': 
            return OperationType.GET;
        case 'POST': 
            return OperationType.POST;
        case 'DELETE': 
            return OperationType.DELETE;
        case 'UPDATE':
            return OperationType.UPDATE;
    }                   
}

export function getCurrentEnvironment(): string {

    const local = /business.local.comcast.com/;
    const dev = /business.dev.comcast.com/;
    const dev5 = /business.dev5.cable.comcast.com/;
    const integrated = /business-mobile-shared-app-dev.np.digital.business.comcast.com/;
    const qa = /business.qa.comcast.com/;
    const qa5 = /business.qa5.cable.comcast.com/;
    const qa_app = /business-mobile-buy-app-qa.np.digital.business.comcast.com/;
    const prodqa = /business.pqa.comcast.com/;
    const stage = /business.stg.comcast.com/;
    const prod = /business.comcast.com/;
    const currentLocation= window.location.hostname;
    let dataEnvironment='';
    
    switch (true) {
        case local.test(currentLocation):
            dataEnvironment = 'local';
            break;
        case integrated.test(currentLocation):
            dataEnvironment = 'integrated';
            break;
        case dev.test(currentLocation):
            dataEnvironment = 'dev';
            break;
        case dev5.test(currentLocation):
            dataEnvironment = 'dev';
            break;
        case qa.test(currentLocation):
            dataEnvironment = 'qa';
            break;
        case qa5.test(currentLocation):
            dataEnvironment = 'qa';
            break;
        case qa_app.test(currentLocation):
            dataEnvironment = 'qa';
            break;
        case prodqa.test(currentLocation):
            dataEnvironment = 'pqa';
            break;
        case stage.test(currentLocation):
            dataEnvironment = 'stg';
            break;
        case prod.test(currentLocation):
            dataEnvironment = 'prod';
            break;
        default:
            dataEnvironment = 'prod';
    }

    return dataEnvironment;
}

export function removePropertiesWithNullValues(properties: any): any{
    return Object.fromEntries(Object.entries(properties).filter(([ , value]) => value !== undefined));    
}

export function getCurrentDomain(): string {

    const local = /business.local.comcast.com/;
    const dev = /business.dev.comcast.com/;
    const dev5 = /business.dev5.cable.comcast.com/;
    const integrated = /business-mobile-shared-app-dev.np.digital.business.comcast.com/;
    const qa = /business.qa.comcast.com/;
    const qa5 = /business.qa5.cable.comcast.com/;
    const qa_app = /business-mobile-buy-app-qa.np.digital.business.comcast.com/;
    const prodqa = /business.pqa.comcast.com/;
    const stage = /business.stg.comcast.com/;
    const prod = /business.comcast.com/;
    const currentLocation = window.location.hostname;
    let dataEnvironment='';
    
    switch (true) {
        case local.test(currentLocation):
            dataEnvironment = 'business.local.comcast.com';
            break;
        case integrated.test(currentLocation):
            dataEnvironment = 'business-mobile-shared-app-dev.np.digital.business.comcast.com';
            break;
        case dev.test(currentLocation):
            dataEnvironment = 'business.dev.comcast.com';
            break;
        case dev5.test(currentLocation):
            dataEnvironment = 'business.dev5.cable.comcast.com';
            break;
        case qa.test(currentLocation):
            dataEnvironment = 'business.qa.comcast.com';
            break;
        case qa5.test(currentLocation):
            dataEnvironment = 'business.qa5.cable.comcast.com';
            break;
        case qa_app.test(currentLocation):
            dataEnvironment = 'business-mobile-buy-app-qa.np.digital.business.comcast.com';
            break;
        case prodqa.test(currentLocation):
            dataEnvironment = 'business.pqa.comcast.com';
            break;
        case stage.test(currentLocation):
            dataEnvironment = 'business.stg.comcast.com';
            break;
        case prod.test(currentLocation):
            dataEnvironment = 'business.comcast.com';
            break;
        default:
            dataEnvironment = 'business.comcast.com';
    }

    return dataEnvironment;
}
/* eslint-enable @typescript-eslint/no-explicit-any */
