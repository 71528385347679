import { InjectionToken } from '@angular/core';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { LocalDB } from './local-db';
import { StoreAction, XmAction } from './actions';
import {
    cmsABTestReducer,
    cmsAccessoryToastReducer,
    cmsByodReducer,
    cmsCartReducer,
    cmsCheckoutReducer,
    cmsContactBarReducer,
    cmsCoreReducer,
    CmsCustomerEligibilityReducer,
    cmsCustomizerReducer,
    cmsErrorsReducer,
    cmsExtendedPromoBuyReducer,
    cmsFinancingOptionsReducer,
    cmsHopReducer,
    cmsLandingReducer,
    CmsLandingV2Reducer,
    cmsLegalLandingReducer,
    cmsLoginReducer,
    cmsNetworkReducer,
    cmsnonXfinityCustomerReducer,
    cmsOrderConfirmationReducer,
    cmsPdpReducer,
    cmsRetentionPromoReducer,
    cmsRupReducer,
    cmsShopReducer,
    cmsSmbReducer,
    cmsSupportCategoryLandingReducer,
    cmsSupportDeviceTroubleshootingReducer,
    cmsSupportNotificationBannerReducer,
    cmsTradeInReducer,
    cmsWatchesReducer,
    cmsXmbComingSoonReducer
} from './cms/reducer';
import { creditCardReducer } from './credit-card/reducer';
import { hopInitReducer } from './hop/reducer';
import { orderDetailReducer } from './order/reducer';
import { cartReducer, navCartReducer, shippingMethodReducer } from './cart/reducer';
import { broadbandLabelsReducer, insurancePlansReducer, planCollectionReducer, planEligibilityBTGReducer, planEligibilityReducer } from './plan/reducer';
import { productCatalogReducer, productReducer } from './product/reducer';
import {
    popularSearchCategoryReducer,
    searchResultReducer,
    supportArticleReducer,
    supportCategoryCollectionReducer,
    supportCategoryDetailReducer
} from './support/reducer';
import { userReducer } from './user/reducer';
import { lineEligibilityReducer, ndelEligibilityReducer } from './ndel/reducer';
import { authByodEligibilityReducer, byodProductReducer, simCompatibilityReducer, unauthByodEligibilityReducer } from './byod/reducer';
import { pastDueReducer } from './past-due/reducer';
import { watchesActivationRequestReducer, watchesAddressReducer, watchesAddressValidationReducer, watchesPairEligibilityRequestIdReducer } from './watches/reducer';
import { User } from './user/models';
import { creditCheckReducer } from './credit-check/reducer';
import { customizeItemReducer } from './customize-item/reducer';
import { lineCollectionReducer } from './line/reducer';
import { tradeInFlowDetailsReducer, tradeInMakeAndModelReducer } from './trade-ins/reducer';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const reducers: ActionReducerMap<LocalDB, XmAction<any>> = {
    cmsCore: cmsCoreReducer,
    cmsAccessoryToast: cmsAccessoryToastReducer,
    cmsABTest: cmsABTestReducer,
    cmsByod: cmsByodReducer,
    cmsErrors: cmsErrorsReducer,
    cmsLanding: cmsLandingReducer,
    cmsLandingV2: CmsLandingV2Reducer,
    cmsLegalLanding: cmsLegalLandingReducer,
    cmsCart: cmsCartReducer,
    cmsCustomerEligibility: CmsCustomerEligibilityReducer,
    cmsCustomizer: cmsCustomizerReducer,
    cmsOrderConfirmation: cmsOrderConfirmationReducer,
    cmsPdp: cmsPdpReducer,
    cmsTradeIn: cmsTradeInReducer,
    tradeInMakeAndModel: tradeInMakeAndModelReducer,
    cmsRetentionPromo: cmsRetentionPromoReducer,
    cmsShop: cmsShopReducer,
    cmsNetwork: cmsNetworkReducer,
    cmsCheckout: cmsCheckoutReducer,
    cmsLogin: cmsLoginReducer,
    cmsHop: cmsHopReducer,
    cmsXmbComingSoon: cmsXmbComingSoonReducer,
    cmsNonXfinityCustomer: cmsnonXfinityCustomerReducer,
    cmsContactBar: cmsContactBarReducer,
    cmsSupportDeviceTroubleshooting: cmsSupportDeviceTroubleshootingReducer,
    cmsSupportNotificationBanner: cmsSupportNotificationBannerReducer,
    cmsSupportCategoryLanding: cmsSupportCategoryLandingReducer,
    cmsExtendedPromoBuyCollection: cmsExtendedPromoBuyReducer,
    cmsSmb: cmsSmbReducer,
    cmsRup: cmsRupReducer,
    productCatalog: productCatalogReducer,
    productDetails: productReducer,
    user: userReducer,
    creditCheck: creditCheckReducer,
    orderDetail: orderDetailReducer,
    planCollection: planCollectionReducer,
    planEligibility: planEligibilityReducer,
    planBTGEligibility: planEligibilityBTGReducer,
    lineCollection: lineCollectionReducer,
    cart: cartReducer,
    navCart: navCartReducer,
    shippingMethodCollection: shippingMethodReducer,
    lineEligibility: lineEligibilityReducer,
    ndelEligibility: ndelEligibilityReducer,
    authByodEligibility: authByodEligibilityReducer,
    unauthByodEligibility: unauthByodEligibilityReducer,
    simCompatibility: simCompatibilityReducer,
    imeiProductDetails: byodProductReducer,
    hopInit: hopInitReducer,
    creditCard: creditCardReducer,
    supportArticle: supportArticleReducer,
    supportCategoryCollection: supportCategoryCollectionReducer,
    popularSearchCategory: popularSearchCategoryReducer,
    supportCategoryDetail: supportCategoryDetailReducer,
    searchResult: searchResultReducer,
    pastDue: pastDueReducer,
    cmsWatches: cmsWatchesReducer,
    watchesAddressValidation: watchesAddressValidationReducer,
    watchesAddress: watchesAddressReducer,
    watchesPairEligibilityRequestId: watchesPairEligibilityRequestIdReducer,
    watchesActivationRequest: watchesActivationRequestReducer,
    customizeItem: customizeItemReducer,
    cmsFinancingOptions: cmsFinancingOptionsReducer,
    tradeInFlowDetails: tradeInFlowDetailsReducer,
    broadbandLabels: broadbandLabelsReducer,
    insurancePlans: insurancePlansReducer
};

export const REDUCER_TOKEN: InjectionToken<ActionReducerMap<XmAction<any>>> = new InjectionToken<ActionReducerMap<XmAction<any>>>('reducers');

export function clearState(reducer: ActionReducer<LocalDB>): ActionReducer<LocalDB> {
    return (state: LocalDB, action: Action): LocalDB => {
        if (action.type === StoreAction.CLEAR_ON_SUBMIT) {
            Object.keys(state).forEach((key: string) => {
                if (key === 'user') {
                    if (state[key]) {
                        state[key] = new User({ account: state[key].account });
                    }
                } else if (key !== 'orderDetail' && key !== 'authByodEligibility' && !key.startsWith('cms')) {
                    state[key] = undefined;
                }
            });
        }

        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<LocalDB>[] = [ clearState ];
/* eslint-enable @typescript-eslint/no-explicit-any */
