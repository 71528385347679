import { ModelBase } from 'store/model-base';
import { ByodCompatibilityStatus, ByodEsimCapatibilityStatus, ByodImeiCapability } from 'core/constants';

export class DeviceInfo extends ModelBase {
    public static storeName: string = 'deviceInfo';

    public byodCompatibilityStatus: ByodCompatibilityStatus;
    public imei: string;
    public isCompatible: boolean;
    public isIncompatible: boolean;
    public isCompatibleUserAction: boolean;
    public eSimCapable: boolean;
    public isEsimEligible: boolean;
    public dualSim: boolean;
    public isPSim: boolean;
    public isESim: boolean;
    public isPSimOrESim: boolean;
    public alternateImeiCapability: string;
    public esimcapabilityStatus: string;
    public imeiCapability: string;
    public isEsimImeiNeeded: boolean;
    public deviceType: string;
    public isEsimStatusNotConfirmed: boolean;
    public esimConfirmed: boolean;

    public static create<T extends ModelBase>(initData: object = {}): T {
        const toReturn: DeviceInfo = super.create<DeviceInfo>(initData);

        toReturn.isCompatible = toReturn.byodCompatibilityStatus === ByodCompatibilityStatus.COMPATIBLE;
        toReturn.isIncompatible = toReturn.byodCompatibilityStatus === ByodCompatibilityStatus.INCOMPATIBLE;
        toReturn.isCompatibleUserAction = toReturn.byodCompatibilityStatus === ByodCompatibilityStatus.COMPATIBLE_USER_ACTION;
        toReturn.eSimCapable = ((toReturn.imeiCapability === ByodImeiCapability.ESIM || toReturn.imeiCapability === ByodImeiCapability.PSIM_ESIM) || 
        (toReturn.alternateImeiCapability === ByodImeiCapability.ESIM || toReturn.alternateImeiCapability === ByodImeiCapability.PSIM_ESIM));
        toReturn.isPSim = toReturn.imeiCapability === ByodImeiCapability.PSIM;
        toReturn.isESim = toReturn.imeiCapability === ByodImeiCapability.ESIM;
        toReturn.isPSimOrESim = toReturn.imeiCapability === ByodImeiCapability.PSIM_ESIM;
        toReturn.isEsimEligible = (toReturn.isESim || toReturn.isPSimOrESim);
        toReturn.isEsimImeiNeeded = toReturn.isPSim && toReturn.alternateImeiCapability === ByodImeiCapability.ESIM;
        toReturn.esimConfirmed = toReturn.esimcapabilityStatus === ByodEsimCapatibilityStatus.CONFIRMED;
        toReturn.isEsimStatusNotConfirmed = (toReturn.isCompatible || toReturn.isCompatibleUserAction) && (toReturn.isESim || toReturn.isPSimOrESim) && !toReturn.esimConfirmed;

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }
}
