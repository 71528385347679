import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Formatter {

    public static getFinanceDisplayPrice(price: number): string {
        return Number(price) ? `$${price}/mo` : '';
    }

    public static getDisplayPrice(price: number): string {
        return Number(price) ? `$${price}` : '';
    }

    public static formatCycleDuration(cycleStartDate: string, cycleEndDate: string, withYear: boolean = false): string {
        const startDate: string = new Date(cycleStartDate).toLocaleDateString('en-EN', {
            month: 'short',
            day: 'numeric'
        });

        const endDate: string = new Date(cycleEndDate).toLocaleDateString('en-EN', {
            month: 'short',
            day: 'numeric',
            year: withYear ? 'numeric' : undefined
        });

        return `${startDate} - ${endDate}`;
    }

    // format date in YYYY-MM-DD
    public static formatDate(date: string): string {
        const d: Date = new Date(date);
        let month: string = '' + (d.getMonth() + 1).toString();
        let day: string = '' + d.getDate().toString();
        const year: number = d.getFullYear();
        const TWO: number = 2;

        if (month.length < TWO) {
            month = '0' + month;
        }

        if (day.length < TWO) {
            day = '0' + day;
        }

        return [ year, month, day ].join('-');
    }
}
