import { Injectable } from '@angular/core';
import { NgControl } from '@angular/forms';
import { XmRadioControl } from 'shared/directives/radio';

@Injectable({
    providedIn: 'root'
})
export class RadioControlRegistry {
    private controlPairs: [ NgControl, XmRadioControl ][] = [];

    public add(control: NgControl, accessor: XmRadioControl): void {
        this.controlPairs.push([ control, accessor ]);
    }

    public remove(radioControl: XmRadioControl): void {
        this.controlPairs.some((controlPair: [ NgControl, XmRadioControl ], index: number) => {
            if (controlPair[1] === radioControl) {
                this.controlPairs.splice(index, 1);

                return true;
            }
        });
    }

    public select(radioControl: XmRadioControl): void {
        this.controlPairs.forEach((controlPair: [ NgControl, XmRadioControl ]) => {
            if (this._isSameGroup(controlPair, radioControl) && controlPair[1] !== radioControl) {
                controlPair[1].writeValue(radioControl.value);
            }
        });
    }

    private _isSameGroup(controlPair: [ NgControl, XmRadioControl ], radioControl: XmRadioControl): boolean {
        // all controls with no parent control are considered the same grouping
        if (!controlPair[0] || !controlPair[0].control) {
            return controlPair[1].name === radioControl.name;
        }

        return radioControl.formControl.control && controlPair[0].control.parent === radioControl.formControl.control.parent && controlPair[1].name === radioControl.name;
    }
}
