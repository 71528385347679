import { ChildModel, ModelBase } from 'store/model-base';
import { NutritionLabel } from './nutrition-label';

export class BroadbandLabels extends ModelBase {
    public static storeName: string = 'broadbandLabels';

    public labels: NutritionLabel[];

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'nutritionLabel',
            model: NutritionLabel
        }];
    }

    public static create<BroadbandLabels extends ModelBase>(initData: TransformedData): BroadbandLabels {
        return super.create<BroadbandLabels>(initData);
    }
}