import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripHtml'
})
export class StripHtml implements PipeTransform {
    public transform(input: string = ''): string {
        // TLDR: magic.
        return input.replace(/<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[\^'">\s]+))?)+\s*|\s*)\/?>/g, '');
    }
}
