import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
    selector: '[xmAriaInvalid]'
})
export class XmAriaInvalid implements OnInit, OnDestroy {
    @Input() public id: string = '';
    @Input() public additionalDescId: string = '';
    private observer: MutationObserver;
    private element: HTMLElement;
    private ariaErrorValue: string = '';

    constructor(element: ElementRef) {
        Object.assign(this, { element: element.nativeElement });
    }

    public ngOnInit(): void {
        this.observer = new MutationObserver(this.processObserver.bind(this));
        this.observer.observe(this.element, {
            attributes: true,
            attributeFilter: [ 'class' ]
        });
        this.ariaErrorValue = this.additionalDescId ? `${this.additionalDescId} aria-error-${this.id}` : `aria-error-${this.id}`;
    }

    public ngOnDestroy(): void {
        this.observer.disconnect();
    }

    private processObserver(records: MutationRecord[]): void {
        const classList: DOMTokenList = this.element.classList;

        records.forEach(() => {
            if (classList.contains('ng-untouched') || classList.contains('ng-pristine')) {
                return;
            }

            if (classList.contains('ng-valid')) {
                this.element.removeAttribute('aria-invalid');
                this.element.removeAttribute('aria-describedby');
                if (this.additionalDescId) {
                    this.element.setAttribute('aria-describedby', this.additionalDescId);
                }
            } else {
                this.element.setAttribute('aria-invalid', 'true');
                this.element.setAttribute('id', this.id);
                this.element.setAttribute('aria-describedby', this.ariaErrorValue);
            }
        });
    }
}
