import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Subscription } from 'rxjs';

import { CmsLogin } from 'store/cms/models';
import { StorageToken } from 'core/constants';
import { Accessibility, CimaToken, SessionStorage, Util, XmStore, XmStoreUtil } from 'core/services';

@Component({
    selector: 'xm-login',
    templateUrl: './login.html',
    styleUrls: [ './login.scss' ]
})
export class XmLoginComponent implements OnInit, OnDestroy {
    public headline: string;
    public subhead: string;

    @ViewChild('message', { static: true }) private message: ElementRef;
    private sessionStorage: SessionStorage;
    private state: StateService;
    private xmStore: XmStore;
    private subscriptions: Subscription[] = [];
    private accessibility: Accessibility;
    private cimaToken: CimaToken;

    constructor(sessionStorage: SessionStorage, state: StateService, xmStore: XmStore, accessibility: Accessibility, cimaToken: CimaToken) {
        Object.assign(this, { sessionStorage, state, xmStore, accessibility, cimaToken });
    }

    public ngOnInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsLogin>(CmsLogin), (response: CmsLogin) => {
            this.headline = response.headline;
            this.subhead = response.subhead;
        }));

        this.accessibility.focusElement(this.message.nativeElement);

        const savedState: SavedState = this.cimaToken.isLoggedIn ? this.sessionStorage.get<SavedState>(StorageToken.SAVED_STATE) : this.sessionStorage.get<SavedState>(StorageToken.CBM_SAVED_LAST_STATE);
        if (savedState && savedState.state) {
            this.state.go(savedState.state, savedState.params || {})
                .then(() => { 
                    this.sessionStorage.destroy(StorageToken.SAVED_STATE);
                    this.sessionStorage.destroy(StorageToken.CBM_SAVED_LAST_STATE);
                })
                .catch(() => Promise.resolve(undefined));
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }
}
