import { Injectable, RendererFactory2 } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TransitionService } from '@uirouter/core';
import { Observable } from 'rxjs';

import { CmsCore } from 'store/cms/models';
import { XmStore, XmStoreUtil } from './store';

import { Accessibility } from './accessibility';
import { DescriptiveModal } from 'shared/modal/descriptive/descriptive';
import { AlertModal } from 'shared/modal/alert/alert';
import { MultiImageModal } from 'shared/modal/image/multi/multi';
import { SingleImageModal } from 'shared/modal/image/single/single';
import { UtilityModal } from 'shared/modal/utility/utility';

@Injectable({
    providedIn: 'root'
})
export class Modal {
    private dialog: MatDialog;
    private xmStore: XmStore;
    private previousFocusElement: HTMLElement;
    private accessibility: Accessibility;
    private delayDialogClose: boolean;

    constructor(accessibility: Accessibility, dialog: MatDialog, overlayContainer: OverlayContainer, rendererFactory: RendererFactory2, transition: TransitionService, xmStore: XmStore) {
        Object.assign(this, { accessibility, dialog, xmStore });

        // angular animations renderer hooks up the logic to disable animations into setProperty
        rendererFactory.createRenderer(undefined, undefined).setProperty(overlayContainer.getContainerElement(), '@.disabled', true);

        // this is blocking the route until the modal has fully closed. this only works because we disabled all animations from the line above.
        transition.onStart({}, () => {
            if (this.dialog.openDialogs.length && !this.delayDialogClose) {
                this.dialog.closeAll();

                return new Promise((resolve: (value?: void | PromiseLike<void>) => void) => setTimeout(resolve, 0));
            }
        });

        transition.onFinish({}, () => {
            this.delayDialogClose = false;
            if (this.dialog.openDialogs.length) {
                this.dialog.closeAll();

                return new Promise((resolve: (value?: void | PromiseLike<void>) => void) => setTimeout(resolve, 0));
            }
        });

        this.dialog.afterAllClosed.subscribe(() => {
            if (this.previousFocusElement) {
                this.previousFocusElement.focus();
                this.previousFocusElement = undefined;
            }
        });
    }

    public descriptive(data: DescriptiveModalData): Observable<ModalResponse> {
        return this.open<DescriptiveModalData>(this.dialog, DescriptiveModal, { data });
    }

    public utility(data: UtilityModalData): Observable<ModalResponse> {
        return this.open<UtilityModalData>(this.dialog, UtilityModal, { data });
    }

    public alert(data: AlertModalData): Observable<ModalResponse> {
        return this.open<AlertModalData>(this.dialog, AlertModal, { data });
    }

    public multiImage(data: MultiImageModalData): Observable<ModalResponse> {
        return this.open<MultiImageModalData>(this.dialog, MultiImageModal, { data });
    }

    public singleImage(data: SingleImageModalData): Observable<ModalResponse> {
        return this.open<SingleImageModalData>(this.dialog, SingleImageModal, { data });
    }

    public tradeInModal(): Observable<ModalResponse> {
        let tradeInData: MultiImageModalData;

        XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore, { firstOnly: true }), (core: CmsCore) => {
            tradeInData = core.modals.tradeins;
        });

        return this.multiImage(tradeInData);
    }

    public setPreviousFocusElement(ele: HTMLElement): void {
        this.previousFocusElement = ele;
    }

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    // D => Type definition for the data object
    // R => [OPTIONAL] Type for return data. You can give a strong type now or cast the any to a return type after the subscription
    // C => [OPTIONAL] Component/JS Class. It is inherently determined from the second parameter
    public open<D, R = any, C = any>(modalService: MatDialog, component: ComponentType<C>, dataOptions: MatDialogConfig<D>, delayClose: boolean = false): Observable<R> {
        this.accessibility.announceReaderText('', false, true);

        const dialogReference: MatDialogRef<C> = (modalService || this.dialog).open<C, D>(component, dataOptions);
        this.delayDialogClose = delayClose;

        return dialogReference.afterClosed();
    }
    /* eslint-enable  @typescript-eslint/no-explicit-any */
}
