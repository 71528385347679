import { Plan } from 'store/plan/models/option/plan';
import { PlanPrice } from '../plan-price';
import { RatePlanVariant } from '../variant/rate';
import { ChildModel } from 'store/model-base';

export class RatePlan extends Plan {
    public variants: RatePlanVariant[];

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'variants',
            model: RatePlanVariant
        }];
    }

    // These are for My Account only
    public get isUnlimited(): boolean {
        return this.variants.some((variant: RatePlanVariant) => variant.isUnlimited);
    }

    public get price(): PlanPrice {
        return this.variants[0].price;
    }
}
