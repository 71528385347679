import { ChildModel, ModelBase } from 'store/model-base';
import { FullAddress } from 'store/user/models';

export class CreditCard extends ModelBase {
    public static storeName: string = 'creditCard';
    public static idAttribute: string = 'token';

    public expiresIn: string;
    public ccType: string;
    public ccLastFour: string;
    public isExpired: boolean;
    public token: string;
    public billingAddress: FullAddress;

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'billingAddress',
            model: FullAddress
        }];
    }

    public get formattedExpiration(): string {
        return new Date(this.expiresIn).toLocaleString('en-us', { timeZone: 'UTC', month: 'numeric', year: '2-digit' });
    }
}
