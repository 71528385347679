import { StoreAction, XmAction } from 'store/actions';
import { AuthByodEligibility, ImeiProductDetails, SimCompatibility, UnauthByodEligibility } from './models';

export function unauthByodEligibilityReducer(state: UnauthByodEligibility, action: XmAction<UnauthByodEligibility>): UnauthByodEligibility {
    switch (action.type) {
        case StoreAction.GET_BYOD_ELIGIBILITY:
            return action.payload;
        case StoreAction.CLEAR_BYOD_ELIGIBILITY:
            return undefined;
        default:
            return state;
    }
}

export function authByodEligibilityReducer(state: AuthByodEligibility, action: XmAction<AuthByodEligibility>): AuthByodEligibility {
    switch (action.type) {
        case StoreAction.GET_AUTH_BYOD_ELIGIBILITY:
            return action.payload;
        case StoreAction.CLEAR_AUTH_BYOD_ELIGIBILITY:
            return undefined;
        default:
            return state;
    }
}

export function byodProductReducer(state: ImeiProductDetails, action: XmAction<ImeiProductDetails>): ImeiProductDetails {
    switch (action.type) {
        case StoreAction.GET_BYOD_PRODUCT_DETAIL:
            return action.payload;
        default:
            return state;
    }
}

export function simCompatibilityReducer(state: SimCompatibility, action: XmAction<SimCompatibility>): SimCompatibility {
    switch (action.type) {
        case StoreAction.GET_SIM_COMPATIBILITY:
            return action.payload;
        default:
            return state;
    }
}
