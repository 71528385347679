import { Inject, Injectable, isDevMode } from '@angular/core';
import { CONFIG_TOKEN, StorageToken } from 'core/constants';
import { CimaToken } from './token';
import { Util } from '../util';
import { SessionStorage } from '../storage/session';
import { IXMOptions } from 'core/interfaces';
import { StateDeclaration, StateService } from '@uirouter/core';

@Injectable({
    providedIn: 'root'
})
export class CimaUrl {

    private cimaToken: CimaToken;
    private config: IXMOptions;
    private sessionStorage: SessionStorage;
    private stateService: StateService;

    constructor(cimaToken: CimaToken, sessionStorage: SessionStorage, @Inject(CONFIG_TOKEN) config: IXMOptions, stateService: StateService) {
        Object.assign(this, { cimaToken, sessionStorage, config, stateService });
    }

    public redirectToLogin(returnState: SavedRouteState, additionalPath: string = ''): void {
        const savedState: SavedRouteState = this.verifyReturnState(returnState);

        this.sessionStorage.set(StorageToken.SAVED_STATE, JSON.stringify(savedState));
        window.location.assign(this.loginString(additionalPath));
    }

    public loginString(additionalPath: string = ''): string {
        const queryParams: string = Util.createQueryParams({
            client_id: this.config.CLIENT_ID,
            redirect_uri: this.browserRedirectUri(additionalPath),
            response_type: 'code'
        });

        return `${this.config.CIMA_BASE_URL}/oauth/authorize${queryParams}`;
    }

    public redirectToLogout(returnURI: string = ''): void {

        this.cimaToken.logoutCleanup();
        this.sessionStorage.destroy(StorageToken.CUSTOMER_TYPE);
        this.sessionStorage.destroy(StorageToken.USER_DETAIL);
        this.sessionStorage.destroy(StorageToken.VISITOR_SESSION_ID);
        this.sessionStorage.destroy(StorageToken.CBM_TEST_USER);


        window.location.assign(this.logoutString(returnURI));
    }

    public logoutString(returnURI: string = ''): string {
        const params: CimaLogout = {
            client_id: this.config.CLIENT_ID
        };

        // only if coming from a comcast domain will we set a state AND redirect to business.comcast.com
        if (!isDevMode() && window.location.origin && window.location.origin.includes('comcast.com')) {
            // params.state = encodeURIComponent(`${window.location.origin}${this.config.BASE_URL}${this.config.CIMA_PATH_URL}${returnURI ? '/' + returnURI : ''}`);
            params.state = encodeURIComponent(`${window.location.origin}${returnURI ? '/' + returnURI : ''}`);
        }

        const queryParams: string = Util.createQueryParams(params);

        return `${this.config.CIMA_BASE_URL}/oauth/sp-logout${queryParams}`;
    }

    public browserRedirectUri(additionalPath: string = ''): string {
        const endUrlPath: string = `${window.location.origin}${this.config.BASE_URL}${this.config.CIMA_PATH_URL}${additionalPath ? '/' + additionalPath : ''}/login`;

        if (this.config.CIMA_RETURN_URL) {
            return encodeURIComponent(`${this.config.CIMA_RETURN_URL}?state=${endUrlPath}`);
        } else {
            return encodeURIComponent(endUrlPath);
        }
    }

    public apiRedirectUri(isPassiveFlow: boolean = false): string {
        if (isPassiveFlow) {
            return `${window.location.origin}${this.config.BASE_URL}${this.config.CIMA_PATH_URL}/cima.html`;
        } else if (this.config.CIMA_RETURN_URL) {
            return `${this.config.CIMA_RETURN_URL}?state=${window.location.href.split('?')[0]}`;
        } else {
            return window.location.href.split('?')[0];
        }
    }

    private verifyReturnState(returnState: SavedRouteState): SavedRouteState {
        if (returnState?.state) {
            return returnState;
        }

        //Get the list of available states, find the state matching with current page url path
        const currentState = this.stateService.get()?.find((state: StateDeclaration) => state.url === window.location.pathname);
        returnState.state = currentState?.redirectTo ? currentState.redirectTo.toString() : currentState?.name;
        returnState.params = currentState?.params;

        return returnState;
    }
}
