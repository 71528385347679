import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ModelBase } from 'store/model-base';
import { ApiChannel } from 'core/constants';
import { buildHeaders, XmStore } from 'core/services';
import { CreditCard } from './models';
import { StoreAction } from 'store/actions';
import { BuyInfo, User } from 'store/user/models';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class CreditCardApi {
    public static getCreditCardInfo(_xmStore: XmStore, http: HttpClient): Observable<CreditCard> {
        return http.get('/account/paymentinstrument', { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((response: CreditCard) => CreditCard.create<CreditCard>(response), 
                catchError(err => logAndHandleError(err, '/account/paymentinstrument', OperationType.GET)))
        );
    }

    public static postCreditCardInfo(xmStore: XmStore, http: HttpClient, payload: ApiParams): Observable<CreditCard> {
        return http.post('/account/paymentinstrument', payload, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            mergeMap(() => {
                xmStore.peekChildPromise<BuyInfo, User>('buyInfo', BuyInfo, User, { returnUndefined: true }).then((buyInfo: BuyInfo) => {
                    if (buyInfo) {
                        xmStore.persist<BuyInfo>(StoreAction.UPDATE_BUY_INFO, { paymentInstrument: { paymentToken: payload.token } });
                    }
                });

                return xmStore.query<CreditCard>(StoreAction.GET_CREDIT_CARD, CreditCard); // temp fix until new api is available
            }),
            catchError(err => logAndHandleError(err, '/account/paymentinstrument', OperationType.POST, undefined, JSON.stringify(payload)))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_CREDIT_CARD] = CreditCardApi.getCreditCardInfo;
ModelBase.persistMapping[StoreAction.POST_CREDIT_CARD] = CreditCardApi.postCreditCardInfo;
