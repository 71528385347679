import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

import { Accessibility, Util } from 'core/services';
import { KeyboardEventKeys } from 'core/constants';

@Component({
    selector: 'xm-autocomplete',
    styleUrls: [ './autocomplete.scss' ],
    templateUrl: './autocomplete.html'
})
export class XmAutoCompleteComponent implements OnDestroy, AfterViewInit, OnInit, OnChanges {
    @Input() public items: AutocompleteItem[] = [];
    @Input() public disabledItems: AutocompleteItem[] = [];
    @Input() public id: string;
    @Input() public ariaLabel: string;
    @Input() public disableCloseSearchButton: boolean;
    @Output() public onSelect: EventEmitter<AutocompleteItem> = new EventEmitter<AutocompleteItem>();

    @ViewChild('optionsBox') public optionsElement: ElementRef<HTMLElement>;

    public selectedIndex: number = -1;
    public resultsVisible: boolean = false;
    public imageOptions: MediaImageOptions;

    private accessibility: Accessibility;
    private autocompleteEle: HTMLElement;
    private inputElement: HTMLInputElement;
    private subscriptions: Subscription[] = [];

    constructor(accessibility: Accessibility, ele: ElementRef<HTMLElement>) {
        Object.assign(this, { accessibility, autocompleteEle: ele.nativeElement });
    }

    public ngOnInit(): void {
        this.imageOptions = {
            mobile: {
                base: 'close_24_black.svg',
                version: '1500575503',
                icon: true
            }
        };
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public ngAfterViewInit(): void {
        this.inputElement = this.autocompleteEle.querySelector<HTMLInputElement>('input');

        if (!this.id || !this.inputElement) {
            throw new Error('Please give \'id\' and pass an input element to xm-autocomplete');
        }

        if (this.inputElement) {
            this.inputElement.setAttribute('aria-describedby', `${this.id}-instructions`);
            this.inputElement.setAttribute('id', `${this.id}`);
            this.inputElement.setAttribute('autocomplete', 'off');
            this.inputElement.setAttribute('aria-expanded', 'false');
            this.inputElement.setAttribute('aria-autocomplete', 'list');

            this.subscriptions.push(fromEvent(this.inputElement, 'focus').subscribe(() => this.openResults()));
            this.subscriptions.push(fromEvent(this.inputElement, 'keydown').subscribe(this.onKeyDown.bind(this)));
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.items && !changes.items.firstChange) {
            this.selectedIndex = -1;
            this.inputElement.removeAttribute('aria-activedescendant');
            this.accessibility.announceReaderText(`Got ${this.items.length} results`);

            this.setAriaExpanded();

            // if changes are happening and focus is still on input...make sure its open
            if (document.activeElement === this.inputElement) {
                this.openResults();
            }
        }
    }

    public emitSelectedOption(item: AutocompleteItem): void {
        this.onSelect.emit(item);
        this.closeResults();

        if (item && item.label) {
            this.inputElement.value = item.value || item.label;
        }
    }

    public clearInput(): void {
        this.inputElement.value = '';
        this.inputElement.focus();
        this.closeResults();
    }

    public get showOptionsDropdown(): boolean {
        return Boolean(this.resultsVisible && (this.items.length || this.disabledItems.length));
    }

    private openResults(): void {
        if (this.resultsVisible) {
            return;
        }

        this.selectedIndex = -1;
        this.resultsVisible = true;
        this.setAriaExpanded();
    }

    private closeResults(): void {
        if (!this.resultsVisible) {
            return;
        }

        this.resultsVisible = false;
        this.optionsElement.nativeElement.scrollTop = 0;
        this.inputElement.setAttribute('aria-expanded', this.resultsVisible.toString());
        this.inputElement.removeAttribute('aria-activedescendant');
    }

    private onKeyDown(event: KeyboardEvent): void {
        switch (true) {
            case event.key === KeyboardEventKeys.ARROW_DOWN:
                if (this.items.length > 0) {
                    this.selectedIndex = (this.selectedIndex + 1) === this.items.length ? 0 : this.selectedIndex + 1;
                }

                this.updateForNewSelection();

                event.preventDefault();
                break;

            case event.key === KeyboardEventKeys.ARROW_UP:
                if (this.items.length > 0) {
                    this.selectedIndex = (this.selectedIndex - 1) < 0 ? this.items.length - 1 : this.selectedIndex - 1;
                }

                this.updateForNewSelection();

                event.preventDefault();
                break;

            case event.key === KeyboardEventKeys.ENTER:
                this.closeResults();

                if (this.selectedIndex >= 0) {
                    this.emitSelectedOption(this.items[this.selectedIndex]);
                } else {
                    this.emitSelectedOption({ label: this.inputElement.value, value: this.inputElement.value, isEnter: true });
                }

                event.preventDefault();
                break;

            case event.key === KeyboardEventKeys.ESCAPE:
                this.closeResults();

                event.preventDefault();
                break;
        }
    }

    private setAriaExpanded(): void {
        if (this.items.length > 0 || this.disabledItems.length > 0) {
            this.inputElement.setAttribute('aria-expanded', this.resultsVisible.toString());
        }
    }

    private updateForNewSelection(): void {
        if (this.selectedIndex === -1) {
            return;
        }

        const idValue: string = `${this.id}-${this.selectedIndex}-option`;
        this.inputElement.setAttribute('aria-activedescendant', idValue);
        this.optionsElement.nativeElement.scrollTop = document.getElementById(idValue).offsetTop;
    }
}
