<div #dropdownRoot [ngClass]="{'filter-dropdown': customClass =='filter-dropdown', 'pagination-dropdown': customClass =='pagination-dropdown'}" *ngIf="useCustomDropDown" class="container" tabindex="-1" (focusout)="buttonOrListBlur($event)">
    <div #button
        xmAriaInvalid
        role="combobox"
        aria-haspopup="listbox"
        class="select-box"
        [ngClass]="{'filter-default-changed': isDefaultOptionChanged}"
        tabindex="0"
        [attr.aria-describedby]="ariaDescribedBy"
        [attr.aria-owns]="ariaLabelId + '_dropdown_list'"
        [attr.aria-labelledby]="buttonAttrAriaLabel"
        [attr.aria-expanded]="expanded"
        [attr.aria-activedescendant]="ariaActiveDescendId"
        (click)="toggle()">
        <span [attr.id]="buttonAttrId" [innerHtml]="selection.label"></span>
        <xm-media-image aria-hidden="true" [ngClass]="{'expanded': expanded}" class="arrow" center="vert" [imageOptions]="caretImageOptions"></xm-media-image>
    </div>
    <ul #listElement
        role="listbox"
        [attr.id]="ariaLabelId + '_dropdown_list'"
        [attr.aria-labelledby]="ariaLabelId"
        [ngClass]="{ 'hidden': !expanded }"
        [ngStyle]="listStyle">
        <li #itemElement
            class="option"
            *ngFor="let item of optionsList; let i = index"
            [attr.id]="item.label + i"
            [attr.tabindex]="item.selected ? 0 : -1"
            [attr.role]="item.isDecorator ? 'presentation' : 'option'"
            [attr.aria-hidden]="item.isDecorator ? 'true' : null"
            [attr.aria-selected]="item.selected"
            [ngClass]="{ 'focused': item.selected }"
            (click)="onOptionClick(item)"
            [innerHtml]="item.label"></li>
    </ul>
</div>
<select #nativeSelect
    xmAriaInvalid
    class="select-box"
    *ngIf="!useCustomDropDown"
    [attr.aria-labelledby]="buttonAttrAriaLabel"
    [attr.aria-describedby]="ariaDescribedBy"
    [ngStyle]="selectStyle"
    (blur)="buttonOrListBlur($event)">
    <option disabled selected *ngIf="placeholderItem">{{ placeholderItem.label }}</option>
    <option *ngFor="let item of optionsList" [attr.selected]="item.selected ? 'selected' : null"
        [attr.disabled]="item.isDecorator ? true : null" [innerHtml]="item.label"></option>
</select>
<xm-media-image *ngIf="!useCustomDropDown" aria-hidden="true" [ngClass]="{'expanded': expanded}" class="arrow" center="vert" [imageOptions]="caretImageOptions"></xm-media-image>
