import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'xm-breadcrumbs',
    styleUrls: [ './breadcrumbs.scss' ],
    templateUrl: './breadcrumbs.html',
    animations: [
        trigger('isActive', [
            state('true', style({
                transform: 'translateX(0)'
            })),
            state('false', style({
                transform: 'translateX(-101%)'
            })),
            transition('* => *', animate('250ms'))
        ])
    ]
})
export class XmBreadcrumbsComponent implements OnDestroy, OnChanges {
    @Input() public breadcrumbs: BreadcrumbState[];
    public activeBreadcrumbIndex: number;
    public activeBreadcrumbName: string;

    private stateService: StateService;
    private transitionService: TransitionService;
    private onSuccessDeregister: Function;

    constructor(stateService: StateService, transitionService: TransitionService) {
        Object.assign(this, { stateService, transitionService });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.breadcrumbs) {
            this.updateBreadcrumbs();
        }

        this.onSuccessDeregister = this.transitionService.onSuccess({}, () => {
            this.updateBreadcrumbs();
        });
    }

    public ngOnDestroy(): void {
        this.onSuccessDeregister();
    }

    private updateBreadcrumbs(): void {
        this.activeBreadcrumbIndex = -1;
        this.breadcrumbs.forEach((breadcrumb: BreadcrumbState, index: number) => {
            if (this.stateService.current.name === breadcrumb.name) {
                this.activeBreadcrumbIndex = index;
                this.activeBreadcrumbName = breadcrumb.displayName;
            }            
        });
        
        if (this.stateService.current.name === 'byod.compatibility.imei2') {
            this.activeBreadcrumbIndex = 2;
            this.activeBreadcrumbName = 'Step';
        }

        this.breadcrumbs.forEach((breadcrumb: BreadcrumbState, index: number) => {
            breadcrumb.active = index <= this.activeBreadcrumbIndex;
        });
    }
}
