import { StoreAction, XmAction } from 'store/actions';
import { CreditCard } from './models';

export function creditCardReducer(state: CreditCard, action: XmAction<CreditCard>): CreditCard {
    switch (action.type) {
        case StoreAction.GET_CREDIT_CARD:
        case StoreAction.POST_CREDIT_CARD:
            return action.payload;
        case StoreAction.CLEAR_CREDIT_CARD:
            return undefined;
        default:
            return state;
    }
}
