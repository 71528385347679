import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiChannel } from 'core/constants';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { buildHeaders, XmStore } from 'core/services';
import { StoreAction } from 'store/actions';
import { ModelBase } from 'store/model-base';

import {
    CmsABTest,
    CmsAccessoryToast,
    CmsByod,
    CmsCart,
    CmsCheckout,
    CmsContactBar,
    CmsCore,
    CmsCustomerEligibility,
    CmsCustomizer,
    CmsErrors,
    CmsExtendedPromoBuyCollection,
    CmsFinancingOptions,
    CmsHop,
    CmsLanding,
    CmsLandingV2,
    CmsLegalLanding,
    CmsLogin,
    CmsNetwork,
    CmsNonXfinityCustomer,
    CmsOrderConfirmation,
    CmsPdp,
    CmsRetentionPromo,
    CmsRup,
    CmsShop,
    CmsSmb,
    CmsSupportCategoryLanding,
    CmsSupportDeviceTroubleshooting,
    CmsSupportNotificationBanner,
    CmsTradeIn,
    CmsWatches,
    CmsXmbComingSoon
} from './models';
import { IXMOptions } from 'core/interfaces';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class CmsApi {
    public static getCore(_xmStore: XmStore, http: HttpClient): Observable<CmsCore> {
        const requests: Observable<object>[] = [
            http.get('/core/footer.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/core/modals.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/core/meta-data', { headers: buildHeaders({ apiChannel: ApiChannel.SITECORE_URL }) }),
            http.get('/core/nav.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/core/product-card.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/core/icons.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/core/carousel.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/core/schema.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/core/rate-plan.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/pages/marketing/byod-promo.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/pages/marketing/utility-banner.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) })
        ];

        return forkJoin(requests).pipe(
            map((response: [ SiteFooter, SiteModals, SiteMetaData, SiteNav, CmsProductCard, CmsIcons, CmsCarousel, CmsSchema, CmsRatePlanName, CmsByodPromoBanner, CmsUtilityPromoBanner ]) => {
                const [ footer, modals, metaData, nav, productCard, icons, carousel, schema, ratePlanName, byodPromo, utilityPromo ]:
                [ SiteFooter, SiteModals, SiteMetaData, SiteNav, CmsProductCard, CmsIcons, CmsCarousel, CmsSchema, CmsRatePlanName, CmsByodPromoBanner, CmsUtilityPromoBanner ] = response;

                return CmsCore.create<CmsCore>({ footer, modals, metaData, nav, productCard, icons, carousel, schema, ratePlanName, byodPromo, utilityPromo });

            }), catchError(err=> logAndHandleError(err, 'getCore', OperationType.GET))
        );
    }

    public static getRetentionPromo(_xmStore: XmStore, http: HttpClient, _params: ApiParams): Observable<CmsRetentionPromo> {
        return http.get('/pages/marketing/retention-promo.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS, handleCmsError: true }) }).pipe(
            map((response: CmsRetentionPromo) => CmsRetentionPromo.create<CmsRetentionPromo>(response)),
            catchError(() => of(undefined))
        );
    }

    public static getPlanThreeLearn(_xmStore: XmStore, http: HttpClient, _params: ApiParams): Observable<CmsLandingV2> {
        return http.get('/pages/landing_v2.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsLandingV2) => CmsLandingV2.create<CmsLandingV2>(response)),
            catchError(err=> logAndHandleError(err, '/pages/landing_v2.json', OperationType.GET))
        );
    }

    public static getNetwork(_xmStore: XmStore, http: HttpClient): Observable<CmsNetwork> {
        return http.get('/pages/network.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsNetwork) => CmsNetwork.create<CmsNetwork>(response)),
            catchError(err=> logAndHandleError(err, '/pages/network.json', OperationType.GET))
        );
    }

    public static getXmbComingSoon(_xmStore: XmStore, http: HttpClient): Observable<CmsXmbComingSoon> {
        return http.get('/pages/xmb-coming-soon.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsXmbComingSoon) => CmsXmbComingSoon.create<CmsXmbComingSoon>({ errorData: response })),
            catchError(err=> logAndHandleError(err, '/pages/xmb-coming-soon.json', OperationType.GET))
        );
    }

    public static getNonXfinityCustomerPage(_xmStore: XmStore, http: HttpClient): Observable<CmsNonXfinityCustomer> {
        return http.get('/pages/non-xfinity-customer.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsNonXfinityCustomer) => CmsNonXfinityCustomer.create<CmsNonXfinityCustomer>(response)),
            catchError(err=> logAndHandleError(err, '/pages/non-xfinity-customer.json', OperationType.GET))
        );
    }

    public static getByod(_xmStore: XmStore, http: HttpClient): Observable<CmsByod> {
        return http.get('/pages/byod.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsByod) => CmsByod.create<CmsByod>(response)),
            catchError(err=> logAndHandleError(err, '/pages/byod.json', OperationType.GET))
        );
    }

    public static getErrorsPage(_xmStore: XmStore, http: HttpClient): Observable<CmsErrors> {
        return http.get('/pages/errors.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsErrors) => CmsErrors.create<CmsErrors>(response)),
            catchError(err=> logAndHandleError(err, '/pages/errors.json', OperationType.GET))
        );
    }

    public static getLandingPage(_xmStore: XmStore, http: HttpClient): Observable<CmsLanding> {

        const requests: Observable<object>[] = [
            http.get('/pages/landing.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/pages/landing-articles.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/pages/marketing/landing-aspot.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS, handleCmsError: true }) }).pipe(
                catchError(() => of(undefined))
            ),
            http.get('/pages/marketing/side-by-side-banner.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS, handleCmsError: true }) }).pipe(
                catchError(() => of(undefined))
            )
        ];
        
        return forkJoin(requests).pipe(
            map((response: [ CmsLandingModules, CmsArticles, CmsLandingCarousel, CmsSideBySideInfoBanners ]) => {
                const [ landingModules, landingArticles, carousel, sideBySideInfoBanner ]: [
                    CmsLandingModules, CmsArticles, CmsLandingCarousel, CmsSideBySideInfoBanners
                ] = response;

                return CmsLanding.create<CmsLanding>({
                    ...landingModules,
                    landingArticles,
                    carousel,
                    sideBySideInfoBanner
                });
            })
        );
    }

    public static getCustomizerPage(_xmStore: XmStore, http: HttpClient): Observable<CmsCustomizer> {
        return http.get('/pages/customizer.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsCustomizer) => CmsCustomizer.create<CmsCustomizer>(response)),
            catchError(err=> logAndHandleError(err, '/pages/customizer.json', OperationType.GET))
        );
    }

    public static getCheckout(_xmStore: XmStore, http: HttpClient, _param: StoreFetchOptions = {}, _config: IXMOptions): Observable<CmsCheckout> {
        return http.get('/pages/checkout.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsCheckout) => CmsCheckout.create<CmsCheckout>(response)),
            catchError(err => logAndHandleError(err, '/pages/checkout.json', OperationType.GET))
        );
    }

    public static getShop(_xmStore: XmStore, http: HttpClient): Observable<CmsShop> {

        const requests: Observable<object>[] = [
            http.get('/pages/shop.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/pages/marketing/phone-footer-banners.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS, handleCmsError: true }) }).pipe(
                catchError(() => of(undefined))
            ),
            http.get('/pages/marketing/phone-top-banners.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS, handleCmsError: true }) }).pipe(
                catchError(() => of(undefined))
            ),
            http.get('/pages/marketing/tablet-top-banners.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS, handleCmsError: true }) }).pipe(
                catchError(() => of(undefined))
            ),
            http.get('/pages/marketing/watches-top-banners.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS, handleCmsError: true }) }).pipe(
                catchError(() => of(undefined))
            ),
            http.get('/pages/marketing/accessories-top-banners.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS, handleCmsError: true }) }).pipe(
                catchError(() => of(undefined))
            )
        ];

        return forkJoin(requests).pipe(
            map((response: [ CmsShop, CmsDeviceFooterBanner, CmsCarouselBanners, CmsCarouselBanners, CmsCarouselBanners, CmsCarouselBanners ]) => {
                const [ shopModules, phoneFooterBanner, phoneBanners, tabletBanners, watchBanners, accessoriesBanner ]: [
                    CmsShop, CmsDeviceFooterBanner, CmsCarouselBanners, CmsCarouselBanners, CmsCarouselBanners, CmsCarouselBanners
                ] = response;

                return CmsShop.create<CmsShop>({
                    ...shopModules,
                    phoneFooterBanner,
                    phoneBanners,
                    tabletBanners,
                    watchBanners,
                    accessoriesBanner
                });
            })
        );
    }

    public static getPdpPage(_xmStore: XmStore, http: HttpClient): Observable<CmsPdp> {
        const requests: Observable<object>[] = [
            http.get('/pages/product.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }),
            http.get('/pages/marketing/product-promo.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS, handleCmsError: true }) }).pipe(
                catchError(() => of(undefined))
            ),
            http.get('/pages/marketing/product-secondary-promo.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS, handleCmsError: true }) }).pipe(
                catchError(() => of(undefined))
            )
        ];

        return forkJoin(requests).pipe(
            map((response: [CmsPdp, CmsProductPromo, CmsProductSecondaryPromo]) => {
                const [ cmsPdp, cmsProductPromo, cmsProductSecondaryPromo ]: [
                    CmsPdp,
                    CmsProductPromo,
                    CmsProductSecondaryPromo
                ] = response;
                cmsPdp.pdp = { ...cmsPdp.pdp, ...cmsProductPromo, ...cmsProductSecondaryPromo };

                return CmsPdp.create<CmsPdp>(cmsPdp);
            })
        );
    }

    public static getCart(_xmStore: XmStore, http: HttpClient): Observable<CmsCart> {
        return http.get('/pages/cart.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsCart) => CmsCart.create<CmsCart>(response)),
            catchError(err=> logAndHandleError(err, '/pages/cart.json', OperationType.GET))
        );
    }

    public static getLogin(_xmStore: XmStore, http: HttpClient): Observable<CmsLogin> {
        return http.get('/pages/login.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsLogin) => CmsLogin.create<CmsLogin>(response)),
            catchError(err=> logAndHandleError(err, '/pages/login.json', OperationType.GET))
        );
    }

    public static getHop(_xmStore: XmStore, http: HttpClient): Observable<CmsHop> {
        return http.get('/core/hop.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsHop) => CmsHop.create<CmsHop>(response)),
            catchError(err=> logAndHandleError(err, '/core/hop.json', OperationType.GET))
        );
    }

    public static getOrderConfirmation(_xmStore: XmStore, http: HttpClient): Observable<CmsOrderConfirmation> {
        return http.get('/pages/confirmation.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsOrderConfirmation) => CmsOrderConfirmation.create<CmsOrderConfirmation>(response)),
            catchError(err=> logAndHandleError(err, '/pages/confirmation.json', OperationType.GET))
        );
    }

    public static getContactBar(_xmStore: XmStore, http: HttpClient): Observable<CmsContactBar> {
        return http.get('/core/contact-bar.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsContactBar) => CmsContactBar.create<CmsContactBar>(response)),
            catchError(err=> logAndHandleError(err, '/core/contact-bar.json', OperationType.GET))
        );
    }

    public static getCmsCustomerEligibility(_xmStore: XmStore, http: HttpClient): Observable<CmsCustomerEligibility> {
        return http.get('/core/eligibility.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsCustomerEligibility) => CmsCustomerEligibility.create<CmsCustomerEligibility>(response)),
            catchError(err=> logAndHandleError(err, '/core/eligibility.json', OperationType.GET))
        );
    }

    public static getLegalLanding(_xmStore: XmStore, http: HttpClient): Observable<CmsLegalLanding> {
        return http.get('/pages/legal.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsLegalLanding) => CmsLegalLanding.create<CmsLegalLanding>(response)),
            catchError(err=> logAndHandleError(err, '/pages/legal.json', OperationType.GET))
        );
    }

    public static getWatches(_xmStore: XmStore, http: HttpClient): Observable<CmsWatches> {
        return http.get('/pages/watches.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsWatches) => CmsWatches.create<CmsWatches>(response)),
            catchError(err=> logAndHandleError(err, '/pages/watches.json', OperationType.GET))
        );
    }

    public static getABTest(_xmStore: XmStore, http: HttpClient): Observable<CmsABTest> {
        return http.get('/tests/ab-test.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsABTest) => CmsABTest.create<CmsABTest>(response)),
            catchError(err=> logAndHandleError(err, '/tests/ab-test.json', OperationType.GET))
        );
    }

    public static getSupportDeviceTroubleshooting(_xmStore: XmStore, http: HttpClient): Observable<CmsSupportDeviceTroubleshooting> {
        return http.get('/pages/support/device-troubleshooting.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsSupportDeviceTroubleshooting) => CmsABTest.create<CmsSupportDeviceTroubleshooting>(response)),
            catchError(err=> logAndHandleError(err, '/pages/support/device-troubleshooting.json', OperationType.GET))
        );
    }

    public static getCmsSupportNotificationBanner(_xmStore: XmStore, http: HttpClient): Observable<CmsSupportNotificationBanner> {
        return http.get('/pages/support/notification-banner.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsSupportNotificationBanner) => CmsABTest.create<CmsSupportNotificationBanner>(response)),
            catchError(err=> logAndHandleError(err, '/pages/support/notification-banner.json', OperationType.GET))
        );
    }

    public static getSupportCategoryLanding(_xmStore: XmStore, http: HttpClient): Observable<CmsSupportCategoryLanding> {
        return http.get('/pages/support/category-landing.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsSupportCategoryLanding) => CmsABTest.create<CmsSupportCategoryLanding>(response)),
            catchError(err=> logAndHandleError(err, '/pages/support/category-landing.json', OperationType.GET))
        );
    }

    public static getAccessoryToast(_xmStore: XmStore, http: HttpClient): Observable<CmsAccessoryToast> {
        return http.get('/pages/accessory-toast.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsAccessoryToast) => CmsAccessoryToast.create<CmsAccessoryToast>(response)),
            catchError(err=> logAndHandleError(err, '/pages/accessory-toast.json', OperationType.GET))
        );
    }

    public static getExtendedPromoBuy(_xmStore: XmStore, http: HttpClient): Observable<CmsExtendedPromoBuyCollection> {
        return http.get('/pages/ep.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsExtendedPromoBuyCollection) => CmsExtendedPromoBuyCollection.create<CmsExtendedPromoBuyCollection>({ promos: response })),
            catchError(err=> logAndHandleError(err, '/pages/ep.json', OperationType.GET))
        );
    }

    public static getCmsSmb(_xmStore: XmStore, http: HttpClient): Observable<CmsSmb> {
        return http.get('/pages/smb.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsSmb) => CmsSmb.create<CmsSmb>(response)),
            catchError(err=> logAndHandleError(err, '/pages/smb.json', OperationType.GET))
        );
    }

    public static getCmsRup(_xmStore: XmStore, http: HttpClient, _param: StoreFetchOptions = {}): Observable<CmsRup> {
        return http.get('/pages/rup.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsRup) => CmsRup.create<CmsRup>(response)),
            catchError(err=> logAndHandleError(err, '/pages/rup.json', OperationType.GET))
        );        
    }

    public static getCmsTradeIn(_xmStore: XmStore, http: HttpClient, _param: StoreFetchOptions = {}): Observable<CmsTradeIn> {
        return http.get('/pages/marketing/trade-in.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsTradeIn) => CmsTradeIn.create<CmsTradeIn>(response)),
            catchError(err => logAndHandleError(err, '/pages/marketing/trade-in.json', OperationType.GET))
        );
    }

    public static getFinancingOptions(_xmStore: XmStore, http: HttpClient): Observable<CmsFinancingOptions> {
        return http.get('/pages/financing-options.json', { headers: buildHeaders({ apiChannel: ApiChannel.CMS }) }).pipe(
            map((response: CmsFinancingOptions) => CmsFinancingOptions.create<CmsFinancingOptions>(response)),
            catchError(err=> logAndHandleError(err, '/pages/financing-options.json', OperationType.GET))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_CORE] = CmsApi.getCore;
ModelBase.fetchMapping[StoreAction.GET_ERRORS] = CmsApi.getErrorsPage;
ModelBase.fetchMapping[StoreAction.GET_LANDING] = CmsApi.getLandingPage;
ModelBase.fetchMapping[StoreAction.GET_CUSTOMIZER] = CmsApi.getCustomizerPage;
ModelBase.fetchMapping[StoreAction.GET_CHECKOUT] = CmsApi.getCheckout;
ModelBase.fetchMapping[StoreAction.GET_PDP] = CmsApi.getPdpPage;
ModelBase.fetchMapping[StoreAction.GET_CMS_CART] = CmsApi.getCart;
ModelBase.fetchMapping[StoreAction.GET_LOGIN] = CmsApi.getLogin;
ModelBase.fetchMapping[StoreAction.GET_HOP] = CmsApi.getHop;
ModelBase.fetchMapping[StoreAction.GET_SHOP] = CmsApi.getShop;
ModelBase.fetchMapping[StoreAction.GET_BYOD] = CmsApi.getByod;
ModelBase.fetchMapping[StoreAction.GET_PLAN_THREE_LEARN] = CmsApi.getPlanThreeLearn;
ModelBase.fetchMapping[StoreAction.GET_ORDER_CONFIRMATION] = CmsApi.getOrderConfirmation;
ModelBase.fetchMapping[StoreAction.GET_CONTACT_BAR] = CmsApi.getContactBar;
ModelBase.fetchMapping[StoreAction.GET_CUSTOMER_ELIGIBILITY] = CmsApi.getCmsCustomerEligibility;
ModelBase.fetchMapping[StoreAction.GET_LEGAL_LANDING] = CmsApi.getLegalLanding;
ModelBase.fetchMapping[StoreAction.GET_WATCHES] = CmsApi.getWatches;
ModelBase.fetchMapping[StoreAction.GET_AB_TEST] = CmsApi.getABTest;
ModelBase.fetchMapping[StoreAction.GET_SUPPORT_DEVICE_TROUBLESHOOTING] = CmsApi.getSupportDeviceTroubleshooting;
ModelBase.fetchMapping[StoreAction.GET_SUPPORT_NOTIFICATION_BANNER] = CmsApi.getCmsSupportNotificationBanner;
ModelBase.fetchMapping[StoreAction.GET_SUPPORT_CATEGORY_LANDING] = CmsApi.getSupportCategoryLanding;
ModelBase.fetchMapping[StoreAction.GET_CMS_ACCESSORY_TOAST] = CmsApi.getAccessoryToast;
ModelBase.fetchMapping[StoreAction.GET_EXTENDED_PROMOS_BUY] = CmsApi.getExtendedPromoBuy;
ModelBase.fetchMapping[StoreAction.GET_SMB_CMS] = CmsApi.getCmsSmb;
ModelBase.fetchMapping[StoreAction.GET_CMS_XMB_COMING_SOON] = CmsApi.getXmbComingSoon;
ModelBase.fetchMapping[StoreAction.GET_CMS_NON_XFINITY_CUSTOMER] = CmsApi.getNonXfinityCustomerPage;
ModelBase.fetchMapping[StoreAction.GET_RETENTION_PROMO] = CmsApi.getRetentionPromo;
ModelBase.fetchMapping[StoreAction.GET_CMS_RUP] = CmsApi.getCmsRup;
ModelBase.fetchMapping[StoreAction.GET_CMS_TRADE_IN] = CmsApi.getCmsTradeIn;
ModelBase.fetchMapping[StoreAction.GET_FINANCING_OPTIONS] = CmsApi.getFinancingOptions;
