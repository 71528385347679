import { ChildModel, ModelBase } from 'store/model-base';
import { StreetAddress } from 'store/user/models/address/street';
import { AccountType, BuyInfoAccountType, UserState } from 'core/constants';

export class Account extends ModelBase {
    public static storeName: string = 'account';
    public static idAttribute: string = 'id';

    public id: Id;
    public firstName: string;
    public lastName: string;
    public cableAccountNumber: string;
    public state: UserState;
    public nextCycleDate: Date;
    public serviceAddress: StreetAddress;
    public user: PrimaryContact;
    public notificationContacts: NotificationContact;
    public level: AccountType;
    public buyInfoAccountType: BuyInfoAccountType;
    public custGuid: string;
    public barId: string;
    public geoCode: string;
    
    public isActive: boolean;
    public isNonPaySuspended: boolean;
    public isHotlined: boolean;
    public isGrandfathered: boolean;
    public isSegmentThree: boolean;
    public canNDEL: boolean;
    public displayName: string;
    public isSegmentTwo: boolean;
    public fullServiceAddress: string;
    public isSegmentFour: boolean;   
    public isSegmentFive: boolean;
    public accountMdu: string;
    public collectionState: string;

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'serviceAddress',
            model: StreetAddress
        }];
    }

    public static create<T extends ModelBase>(data: ApiResponse): T {
        data.isActive = data.state === UserState.ACTIVE;
        data.isNonPaySuspended = data.state === UserState.NON_PAY_SUSPENDED;
        data.isHotlined = data.state === UserState.HOTLINED;
        data.canNDEL = data.state === UserState.ACTIVE;

        const firstName: string = data.firstName || '';
        const lastName: string = data.lastName || '';

        data.displayName = firstName && lastName && `${firstName} ${lastName}`;
        data.isGrandfathered = data.level === AccountType.LEVEL_1;
        data.isSegmentTwo = data.level === AccountType.LEVEL_2;
        data.isSegmentThree = data.level === AccountType.LEVEL_3;
        data.isSegmentFour = data.level === AccountType.LEVEL_4;
        data.isSegmentFive = data.level === AccountType.LEVEL_5;
        const address: StreetAddress = data.serviceAddress;
        address.address2 = address.address2 || '';
        data.fullServiceAddress = `${address.address1} ${address.address2} ${address.city} ${address.state} ${address.zip}`;
        data.buyInfoAccountType = data.accountType;
        
        return super.create<T>(data);
    }
}
