import { Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { CimaCore, Cloudinary, Decorator, GlobalNav, LinkDecorationInfo, Util, XmStore, XmStoreUtil } from 'core/services';
import { CmsCore } from 'store/cms/models';
import { BreakpointWidth, MouseEventType, NavDisplayName } from 'core/constants';

@Component({
    selector: 'xm-nav-menu-item',
    styleUrls: ['./menu-item.scss'],
    templateUrl: './menu-item.html'
})
export class XmNavMenuItemComponent implements OnInit, OnDestroy, DoCheck {
    @Input() public pageData: CmsNavMenuItems;
    @Input() public myAccountChildItems: boolean;
    @Input() public showChildren: boolean;
    @Input() public inheritParentWidth: boolean;
    // DO NOT DELETE this input variable (triggerOnChanges) check primary.html for usage
    @Input() public triggerOnChanges: boolean;
    @Input() public isFromProductMenu: boolean;
    @Input() public isFromLeftMainMenu: boolean;
    @Input() public isFromRightMainMenu: boolean;
    @Input() public isFromAccountMainMenu: boolean;
    @Input() public isMobile?: boolean;
    @Output() public toggleMobileNav: EventEmitter<boolean> = new EventEmitter<boolean>();

    public menuIcon: MediaImageOptions;
    public tempMenuIcon: string;
    public caretIcon: MediaImageOptions;
    public showChildItems: boolean;
    public showSubChildItems: boolean;
    public smallIcon: boolean;
    
    public isChat: boolean;
    public isCart: boolean;
    public isSeparator: boolean;

    public isMyAccountBgColor: boolean = false;
    public isLeftMenuItemsHover: boolean = false;

    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;
    private globalNav: GlobalNav;
    private cimaCore: CimaCore;
    private decorator: Decorator;
    
    constructor(globalNav: GlobalNav, xmStore: XmStore, decorator: Decorator, cimaCore: CimaCore) {
        Object.assign(this, { globalNav, xmStore, decorator, cimaCore });
    }

    public ngOnInit(): void {
        this.smallIcon = this.isMobile;
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (result: CmsCore) => {
            this.caretIcon = { mobile: Cloudinary.generateMediaFromCms(result.nav.caretIcon) };
        }));

        if (this.pageData.icon) {
            if (this.pageData.icon.temp) {
                this.tempMenuIcon = this.pageData.icon.base;
            }
            this.menuIcon = { mobile: Cloudinary.generateMediaFromCms(this.pageData.icon) };
        }
        this.isChat = this.pageData?.name?.toUpperCase() === NavDisplayName.CHAT;
        this.isCart = this.pageData?.name?.toUpperCase() === NavDisplayName.CART;
        this.isSeparator = this?.pageData?.name?.toUpperCase() === NavDisplayName.SEPARATOR;
        this.buildLinkItems();
    }

    public ngDoCheck(): void {
        this.setHoverLeftMainMenuItems();
    }

    public setHoverLeftMainMenuItems(): void {
        if (this.isFromLeftMainMenu) {
            if (!this.stateActive()) {
                this.isLeftMenuItemsHover = true;
            }
        } 
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public stateActive(): boolean {
        if (this.pageData) {
            return this.globalNav.stateActive(this.pageData);
        }
    }

    public generateCmsMediaImage(icon: CmsMediaImage): MediaImageOptions {
        if (!icon) {
            return undefined;
        }

        return { mobile: Cloudinary.generateMediaFromCms(icon) };
    }

    public generateUiParams(paramsString: string): object {
        return Util.getObjectFromQueryString(paramsString);
    }

    public closeMobileMenu(): void {
        this.toggleMobileNav.emit();
        this.showChildItems = false;
    }

    public closeMobileSubMenu(): void {
        this.toggleMobileNav.emit();
        this.showSubChildItems = false;
    }

    public expandChildItems(): void {
        this.showChildItems = !this.showChildItems;
    }

    public expandSubChildItems(): void {
        this.showSubChildItems = !this.showSubChildItems;
    }

    public mouseHoverEvent(event: Event): void {
        this.showChildItems = event.type === MouseEventType.MOUSE_ENTER;
    }

    public mouseHoverSubChildEvent(): void {
        this.showSubChildItems = !this.showSubChildItems;
    }

    public mouseLeaveSubChildEvent(): void {
        this.showSubChildItems = false;
    }

    public hasSubChild(event: CmsNavMenuItems): boolean {
        if (event.children && event.children.length) {
            return true;
        }
    }

    public get isBusinessUser(): boolean {
        return this.cimaCore && this.cimaCore.isBusinessUser;
    }

    private buildLinkItems(): void {
        if (this.pageData.linkDecoration) {
            const deco: LinkDecorationInfo = this.decorator.parseLinkDecoration(this.pageData.linkDecoration)[0];
            this.pageData.uiParams = Util.createQueryParams(deco.uiParams).replace('?', '');
            this.pageData.url = deco.absoluteUrl;
            this.pageData.name = deco.displayText;

            if (this.pageData.name === 'Sign in' && window.innerWidth > BreakpointWidth.SMALL_DESKTOP) {
                this.isMyAccountBgColor = true;
            }
        }

        if (this.pageData.children) {
            this.pageData.children = this.pageData.children.map((navMenuItem: CmsNavMenuItems) => {
                const deco: LinkDecorationInfo = this.decorator.parseLinkDecoration(navMenuItem.linkDecoration)[0];
                navMenuItem.uiParams = Util.createQueryParams(deco.uiParams).replace('?', '');
                navMenuItem.url = deco.absoluteUrl;
                navMenuItem.name = deco.displayText;

                return navMenuItem;
            });
        }
    }
}
