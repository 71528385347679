import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Analytics } from 'core/services';

@Component({
    selector: 'xm-validation',
    styleUrls: [ './validation.scss' ],
    templateUrl: './validation.html'
})
export class XmValidationComponent {
    @Input() public formCtrl: FormControl;
    @Input() public options: XmInputOptions;

    public errorToShow: string;

    private analytics: Analytics;

    constructor(analytics: Analytics) {
        Object.assign(this, { analytics });
    }

    public get errorsToShow(): string {
        let errorMessage: string = '';

        if (this.options.errors && this.options.errors.length) {
            this.options.errors.forEach((errorOption: XmInputError) => {
                const isRequired: boolean = this.formCtrl.errors[errorOption.code];
                if (this.formCtrl.errors.code === errorOption.code || isRequired) {
                    errorMessage = errorOption.message || this.formCtrl.errors.message;

                    if (errorMessage && errorOption.code) {
                        this.analytics.fireValidationError(`${ errorOption.code }: ${ errorMessage }`);
                    }
                }
            });
        }

        return errorMessage;
    }
}
