import { Action } from '@ngrx/store';

export class XmAction<T> implements Action {
    public type: string;
    public payload?: T;
    public filters?: StoreFilters;
    public params?: ApiParams;
}

export enum StoreAction {
    // CALLBACK
    GET_CALLBACK_TIME = 'GET_CALLBACK_TIME',
    SUBMIT_CALLBACK_NUMBER = 'SUBMIT_CALLBACK_NUMBER',

    SAVE_CUSTOMIZE_CONFIG = 'SAVE_CUSTOMIZE_CONFIG',

    // CLEAR
    CLEAR_ON_SUBMIT = 'CLEAR_ON_SUBMIT',

    // CART
    GET_CART = 'GET_CART',
    GET_CART_COUNT = 'GET_CART_COUNT',
    ADD_ITEM = 'ADD_ITEM',
    UPDATE_CART = 'UPDATE_CART',
    DELETE_CART = 'DELETE_CART',
    REMOVE_ITEM = 'REMOVE_ITEM',
    GET_SHIPPING_METHOD = 'GET_SHIPPING_METHOD',
    UPDATE_SHIPPING = 'UPDATE_SHIPPING',
    CHECK_PORT_IN_ELIGIBILITY = 'CHECK_PORT_IN_ELIGIBILITY',

    // CMS
    GET_BYOD = 'GET_BYOD',
    GET_BYOD_OLD = 'GET_BYOD_OLD',
    GET_CORE = 'GET_CORE',
    GET_CUSTOMIZER = 'GET_CUSTOMIZER',
    GET_ERRORS = 'GET_ERRORS',
    GET_LANDING = 'GET_LANDING',
    GET_NETWORK = 'GET_NETWORK',
    GET_LEGAL_LANDING = 'GET_LEGAL_LANDING',
    GET_CHECKOUT = 'GET_CHECKOUT',
    GET_PDP = 'GET_PDP',
    GET_PLAN_THREE_LEARN = 'GET_PLAN_THREE_LEARN',
    GET_CMS_CART = 'GET_CMS_CART',
    GET_LOGIN = 'GET_LOGIN',
    GET_HOP = 'GET_HOP',
    GET_SHOP = 'GET_SHOP',
    GET_RETENTION_PROMO = 'GET_RETENTION_PROMO',
    GET_ORDER_CONFIRMATION = 'GET_ORDER_CONFIRMATION',
    GET_CONTACT_BAR = 'GET_CONTACT_BAR',
    GET_CUSTOMER_ELIGIBILITY = 'GET_CUSTOMER_ELIGIBILITY',
    GET_COMPETITOR_PRICES = 'GET_COMPETITOR_PRICES',
    GET_SAVINGS_CALCULATOR = 'GET_SAVINGS_CALCULATOR',
    GET_WATCHES = 'GET_WATCHES',
    GET_AB_TEST = 'GET_AB_TEST',
    GET_SUPPORT_DEVICE_TROUBLESHOOTING = 'GET_SUPPORT_DEVICE_TROUBLESHOOTING',
    GET_SUPPORT_CATEGORY_LANDING = 'GET_SUPPORT_CATEGORY_LANDING',
    GET_SUPPORT_NOTIFICATION_BANNER = 'GET_SUPPORT_NOTIFICATION_BANNER',
    GET_CMS_ACCESSORY_TOAST = 'GET_CMS_ACCESSORY_TOAST',
    GET_EXTENDED_PROMOS_BUY = 'GET_EXTENDED_PROMOS_BUY',
    GET_SMB_CMS = 'GET_SMB_CMS',

    // CREDIT CARD
    GET_CREDIT_CARD = 'GET_CREDIT_CARD',
    POST_CREDIT_CARD = 'POST_CREDIT_CARD',
    CLEAR_CREDIT_CARD = 'CLEAR_CREDIT_CARD',

    // HOP
    GET_HOP_INIT = 'GET_HOP_INIT',

    // LINE
    POST_DPP_PAYMENT = 'DPP_PAYMENT',
    GET_LINE_COLLECTION = 'GET_LINE_COLLECTION',
    GET_ACTIVATION_INFO_FOR_LINE = 'GET_ACTIVATION_INFO_FOR_LINE',

    // NDEL
    GET_NDEL_ELIGIBILITY = 'GET_NDEL_ELIGIBILITY',
    GET_LINE_NDEL_ELIGIBILITY = 'GET_LINE_NDEL_ELIGIBILITY',
    CLEAR_NDEL_ELIGIBILITY = 'CLEAR_NDEL_ELIGIBILITY',
    CLEAR_LINE_NDEL_ELIGIBILITY = 'CLEAR_LINE_NDEL_ELIGIBILITY',

    // BYOD
    GET_BYOD_ELIGIBILITY = 'GET_BYOD_ELIGIBILITY',
    CLEAR_BYOD_ELIGIBILITY = 'CLEAR_BYOD_ELIGIBILITY',
    GET_AUTH_BYOD_ELIGIBILITY = 'GET_AUTH_BYOD_ELIGIBILITY',
    CLEAR_AUTH_BYOD_ELIGIBILITY = 'CLEAR_AUTH_BYOD_ELIGIBILITY',
    GET_BYOD_PRODUCT_DETAIL = 'GET_BYOD_PRODUCT_DETAIL',

    // SIMLED BYOD
    GET_SIM_COMPATIBILITY = 'GET_SIM_COMPATIBILITY',

    // ORDER
    SUBMIT_ORDER = 'SUBMIT_ORDER',
    GET_ORDER_DETAIL = 'GET_ORDER_DETAIL',
    DELETE_ORDER_DETAIL = 'DELETE_ORDER_DETAIL',

    // Past Due
    GET_PAST_DUE = 'GET_PAST_DUE',
    CLEAR_PAST_DUE = 'CLEAR_PAST_DUE',

    // PLAN
    GET_PLAN_COLLECTION = 'GET_PLAN_COLLECTION',
    GET_AUTHENTICATED_PLAN_COLLECTION = 'GET_AUTHENTICATED_PLAN_COLLECTION',
    GET_PLAN_ELIGIBILITY = 'GET_PLAN_ELIGIBILITY',
    GET_BTG_PLAN_ELIGIBILITY = 'GET_BTG_PLAN_ELIGIBILITY',
    GET_BROADBAND_LABELS = 'GET_BROADBAND_LABELS',

    // XMC and ACP
    GET_INSURANCE_PLANS = 'GET_INSURANCE_PLANS',

    // TARGETED PROMOTIONS
    GET_AUTHENTICATED_TARGETED_PROMOTION = 'GET_AUTHENTICATED_TARGETED_PROMOTION',
    GET_UNAUTHENTICATED_TARGETED_PROMOTION = 'GET_UNAUTHENTICATED_TARGETED_PROMOTION',
    GET_TARGETED_PROMOTION = 'GET_TARGETED_PROMOTION',
    SAVE_TARGETED_PROMOTION_FROM_CART = 'SAVE_TARGETED_PROMOTION_FROM_CART',
    SAVE_TARGETED_PROMOTIONS = 'SAVE_TARGETED_PROMOTIONS',
    DELETE_TARGETED_PROMOTION = 'DELETE_TARGETED_PROMOTION',

    // PRODUCT
    GET_CATALOG = 'GET_CATALOG',
    GET_PRODUCT_DETAIL = 'PRODUCT_DETAIL',
    GET_COMPATIBLE_PRODUCTS = 'GET_COMPATIBLE_PRODUCTS',

    // SUPPORT
    GET_ARTICLE = 'GET_ARTICLE',
    GET_CATEGORIES = 'GET_CATEGORIES',
    GET_SEARCH_RESULTS = 'GET_SEARCH',
    GET_SUGGESTIONS = 'GET_SUGGESTIONS',
    GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID',
    SEND_CLICK = 'SEND_CLICK',
    GET_POPULAR_SEARCH_CATEGORY = 'GET_POPULAR_SEARCH_CATEGORY',

    // USER
    GET_BUY_INFO = 'BUY_INFO',
    GET_ALLOWED_LINES = 'ALLOWED_LINES',
    CLEAR_ALLOWED_LINES = 'CLEAR_ALLOWED_LINES',
    UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO',
    GET_ACCOUNT_INFO = 'ACCOUNT_INFO',
    GET_ACCOUNT_SUMMARY = 'ACCOUNT_SUMMARY',
    GET_CREDIT_CHECK = 'CREDIT_CHECK',
    UPDATE_BUY_INFO = 'UPDATE_BUY_INFO',
    GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS',

    // WATCHES
    GET_WATCH_ADDRESS_VALIDATION = 'GET_WATCH_ADDRESS_VALIDATION',
    GET_WATCH_ADDRESS = 'GET_WATCH_ADDRESS',
    PERSIST_WATCH_ADDRESS = 'PERSIST_WATCH_ADDRESS',
    POST_WATCH_ADDRESS = 'POST_WATCH_ADDRESS',
    POST_WATCH_ACTIVATION = 'POST_WATCH_ACTIVATION',
    GET_ONGOING_ACTIVATION_REQUEST = 'GET_ONGOING_ACTIVATION_REQUEST',
    GET_WATCH_ACTIVATION_STATUS = 'GET_WATCH_ACTIVATION_STATUS',
    GET_WATCH_PAIR_ELIGIBILITY = 'GET_WATCH_PAIR_ELIGIBILITY',
    GET_WATCH_PAIR_ELIGIBITLITY_REQUESTID = 'GET_WATCH_PAIR_ELIGIBITLITY_REQUESTID',

    // CUSTOMIZING ITEM
    SAVE_CUSTOMIZE_ITEM = 'SAVE_CUSTOMIZE_ITEM',
    CLEAR_CUSTOMIZE_ITEM = 'CLEAR_CUSTOMIZE_ITEM',

    // SMB COMING SOON
    GET_CMS_XMB_COMING_SOON = 'GET_CMS_XMB_COMING_SOON',

    // APPLE PROMO
    GET_APPLE_PROMO = 'GET_APPLE_PROMO',
    POST_COMMERCIAL_LEAD_INFO = 'POST_COMMERCIAL_LEAD_INFO',
    GET_CMS_NON_XFINITY_CUSTOMER = 'GET_CMS_NON_XFINITY_CUSTOMER',

    // RUP
    GET_CMS_RUP = 'GET_CMS_RUP',

    // TRADE IN
    GET_UNAUTH_TRADE_IN_DEVICE_DETAILS = 'GET_UNAUTH_TRADE_IN_DEVICE_DETAILS',
    GET_AUTH_TRADE_IN_DEVICE_DETAILS = 'GET_AUTH_TRADE_IN_DEVICE_DETAILS',
    GET_UNAUTH_TRADE_IN_DEVICE_OFFER = 'GET_UNAUTH_TRADE_IN_DEVICE_OFFER',
    GET_AUTH_TRADE_IN_DEVICE_OFFER = 'GET_AUTH_TRADE_IN_DEVICE_OFFER',
    DELETE_TRADE_IN_OFFER = 'DELETE_TRADE_IN_OFFER',
    DELETE_TRADE_IN_DEVICE_DETAILS = 'DELETE_TRADE_IN_DEVICE_DETAILS',
    GET_FIND_MY_DEVICE_STATUS = 'GET_FIND_MY_DEVICE_STATUS',
    PERSIST_CBM_TRADE_IN_DETAILS = 'PERSIST_CBM_TRADE_IN_DETAILS',
    DELETE_CBM_TRADE_IN_DETAILS = 'DELETE_CBM_TRADE_IN_DETAILS',
    PERSIST_TRADE_IN_FLOW_DETAILS = 'PERSIST_TRADE_IN_FLOW_DETAILS',
    DELETE_TRADE_IN_FLOW_DETAILS = 'DELETE_TRADE_IN_FLOW_DETAILS',
    GET_CMS_TRADE_IN = 'GET_CMS_TRADE_IN',
    
    // TRADE-IN PROMOTION
    GET_TRADE_IN_MAKE_AND_MODEL = 'GET_TRADE_IN_MAKE_AND_MODEL',
    GET_UNAUTH_TRADE_IN_DEVICE_STORAGE_CARRIER = 'GET_UNAUTH_TRADE_IN_DEVICE_STORAGE_CARRIER',
    GET_AUTH_TRADE_IN_DEVICE_STORAGE_CARRIER = 'GET_AUTH_TRADE_IN_DEVICE_STORAGE_CARRIER',
    

    //lOG
    POST_LOG = 'POST_LOG',
    LOG_INFO = 'LOG_INFO',
    LOG_ERROR = 'LOG_ERROR',

    // ELIGIBILITY
    GET_FINANCING_OPTIONS = 'GET_FINANCING_OPTIONS',

    // SITECORE
    GET_SITECORE_CLIENT = 'GET_SITECORE_CLIENT'
}
