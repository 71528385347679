import { ModelBase } from 'store/model-base';

export class CmsTradeIn extends ModelBase {
    public static storeName: string = 'cmsTradeIn';

    public base: CmsTradeInBase;
    public tradeInLearnMoreModal: CmsTradeInLearnMore;
    public result: CmsTradeInResult;
    public imei: CmsTradeInImei;
    public conditionCheck: CmsConditionCheck;
    public ineligible: CmsTradeInIneligible;
    public detailConfirm: CmsTradeInDetailConfirm;
    public imeiModalData: CmsTradeInImeiFinder;
    public removeModalData: CmsRemoveTradeInModal;
    public removeDeviceModal: CmsRemoveDeviceModal;
    public tradeInExistsInCartModal: CmsTradeInExistsInCartModal;
    public howTradeInWorksModal: CmsHowTradeInWorksModal;
    public tradeInPromo: CmsTradeInPromo;
    public tradeInPage: CmsTradeInPage;
    /** This property checks for duplicate trade-in when trying to cart */
    public doesTradeInExistInCart: boolean;
    public handoverMethod: {
        title: string;
        description: string;
        howTradeInWorksButton: string;
        requiredErrorMessage: string;
        handInOptionLabel: string;
        handInOptionDescription1: string;
        handInOptionDescription2: string;
        handInOptionDescription3: string;
        toShipOptionLabel: string;
        toShipOptionDescription1: string;
        toShipOptionDescription2: string;
        toShipOptionDescription3: string;
        continueButton: string;
    };
}
