import { Injectable } from '@angular/core';
import { AbTestService } from 'services/abTest/a-b-test-service';
import { AbTestVersion } from 'services/abTest/constants';
import { CAROUSEL_DEFAULT_AUTOPLAY_INTERVAL } from 'core/constants';

@Injectable({
    providedIn: 'root'
})
export class BannerService {
    private abTestService: AbTestService;

    constructor(abTestService: AbTestService) {
        Object.assign(this, { abTestService });
    }

    public getAbTestTargetBanners(bannersData: CmsCarouselBanners, testName?: string): CmsBanner[] {
        if (!testName) {
            return bannersData.default;
        }

        const testVersion: string = this.abTestService.getTestVersion(testName);

        if (!testVersion || testVersion === AbTestVersion.DEFAULT) {
            return bannersData.default;
        }

        return bannersData[testVersion] || bannersData.default;
    }

    /**
     * A function to delay hero banner `autoplay` duration.
     * Required Params:-
     * autoplayInterval: number in milliseconds that you want to delay || 3000 default.
     * testName: name of the A/B Test asking for.
     * isVersionSpecific: flag to check if delay applies to all version (Except BAU) or specific one like A|B. 'false' will apply the delay to both A and B.
     * Optional Params:-
     * abTestVersion: if `isVersionSpecific` is true, specify the exact version like A|B.
     */
    public getAutoplayIntervalForABTest(autoplayInterval: number, testName: string, isVersionSpecific: boolean, abTestVersion?: string): number {
        const testVersion: string = this.abTestService.getTestVersion(testName);

        if (!testName || !testVersion) {
            return CAROUSEL_DEFAULT_AUTOPLAY_INTERVAL;
        }

        if (!isVersionSpecific && testVersion !== AbTestVersion.DEFAULT) {
            return autoplayInterval;
        }

        if (abTestVersion && testVersion === abTestVersion) {
            return autoplayInterval;
        }

        return CAROUSEL_DEFAULT_AUTOPLAY_INTERVAL;
    }
}
