import { ChildModel, ModelBase } from 'store/model-base';
import { DeviceInfo } from './device-info';
import { ByodEligibilityPages } from 'services/analytics/digital-data';

export class UnauthByodEligibility extends ModelBase {
    public static storeName: string = 'unauthByodEligibility';

    public device: DeviceInfo;
    public pageName: string;

    public static create<T extends ModelBase>(initData: object = {}): T {
        const toReturn: UnauthByodEligibility = super.create<UnauthByodEligibility>(initData);

        toReturn.pageName = this.getPageName(toReturn);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    public static get hasOne(): ChildModel[] {
        return [{
            attrName: 'device',
            model: DeviceInfo
        }];
    }

    public static getPageName(eligibility: UnauthByodEligibility): string {
        if (eligibility.device.isIncompatible) {
            return ByodEligibilityPages.incompatible;
        } else if (eligibility.device.isCompatibleUserAction) {
            return ByodEligibilityPages.compatibleUserAction;
        } else if (eligibility.device.isCompatible) {
            return ByodEligibilityPages.compatible;
        }
    }
}
