import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[xmAriaDescBy]'
})
export class XmAriaDescribedBy implements OnInit {
    @Input() public id: string = '';
    @Input() public additionalDescId: string = '';

    private element: HTMLElement;

    constructor(element: ElementRef) {
        Object.assign(this, { element: element.nativeElement });
    }

    public ngOnInit(): void {
        this.element.setAttribute('id', this.id);
        if (this.additionalDescId) {
            this.element.setAttribute('aria-describedby', this.additionalDescId);
        }
    }
}
