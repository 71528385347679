import { ModelBase } from 'store/model-base';
import { TradeInDeviceDetail } from 'store/trade-ins/models';

export class TradeInInfo extends ModelBase {
    public static storeName: string = 'tradeinInfo';

    public tier: string;
    public remorseCredit: number;
    public valueTotal: number;
    public devices: TradeInDeviceDetail[];
}
