import { ModelBase } from 'store/model-base';
import { PromotionType } from 'core/constants';
import { PromoEligibilityServices } from 'store/promotions/models/promo-eligibility';

export class OrderItemPromotion extends ModelBase {
    public type: PromotionType;
    public id: string;
    public value: number;
    public valueTotal: number;
    public percentValue?: number;
    public startDate: string;
    public endDate: string;
    public promotionName?: string;
    public promoDescription?: string;
    public promoCode?: string;
    public promoBOID?: string;
    public manualBOID?: string;
    public promotionPrice?: number;
    public promotionterm?: number;
    public eligibilityServices?: PromoEligibilityServices;
    public isAmount: boolean;
    public isPercentage: boolean;
    public isPrepaid: boolean;
    public isExtendedPromo: boolean;
    public isExtendedPromoDollar: boolean;
    public isExtendedPromoPercentage: boolean;
    public isTradeInPromo: boolean;

    public static create<T extends ModelBase>(data: ApiResponse = {}): T {
        data.isAmount = data.type === PromotionType.DOLLAR_AMOUNT;
        data.isPercentage = data.type === PromotionType.PERCENTAGE;
        data.isPrepaid = data.type === PromotionType.GIFT_CARD;
        data.isExtendedPromo = data.type === PromotionType.EXTENDED_PROMO;
        data.isExtendedPromoDollar = data.type === PromotionType.EXTENDED_PROMO_DOLLAR;
        data.isExtendedPromoPercentage = data.type === PromotionType.EXTENDED_PROMO_PCT;
        data.isTradeInPromo = data.type === PromotionType.TRADEIN_PROMO;

        return super.create<T>(data);
    }
}