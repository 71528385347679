import { ChildModel, ModelBase } from 'store/model-base';
import { DataPlan } from './data-plan';

export class PlanEligibility extends ModelBase {
    public static storeName: string = 'planEligibility';

    public plans: DataPlan[];

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'plans',
            model: DataPlan
        }];
    }
}