import { ModelBase } from 'store/model-base';
import { ProductCard } from 'store/product/models';

export class CmsShop extends ModelBase {
    public static storeName: string = 'cmsShop';
    public shopAriaHeadingText: string;
    public freeShipping: string;
    public apiLoadingText: string;
    public carouselDetails: CmsCarouselDetails;
    public noProductsText: string;
    public apiErrorHeader: string;
    public apiErrorDescription: string;
    public maintenanceIcon: CmsMediaImage;
    public byodDeviceProductCard: ProductCard;
    public byodWatchProductCard: ProductCard;
    public byodTabletProductCard: ProductCard;
    public phoneBanners: CmsCarouselBanners;
    public tabletBanners: CmsCarouselBanners;
    public watchBanners: CmsCarouselBanners;
    public defaultBanners: CmsCarouselBanners;
    public accessoriesBanner: CmsCarouselBanners;
    public phoneFooterBanner: CmsDeviceFooterBanner;
    public retailDisclaimer: CmsShopRetailDisclaimer;
}
