import { ModelBase } from 'store/model-base';

export class SupportCategorySummary extends ModelBase {
    public static storeName: string = 'supportCategorySummary';

    public slug: string;
    public title: string;
    public categoryIcons: SupportIcon[];
    public rank: number;
    public featuredArticles: SupportFeaturedArticle[];
}
