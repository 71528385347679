import { NgModule } from '@angular/core';

import { XmFooterComponent } from './footer';
import { SharedModule } from '../module';

@NgModule({
    declarations: [
        XmFooterComponent
    ],
    exports: [
        XmFooterComponent
    ],
    imports: [
        SharedModule
    ]
})
export class XmFooterModule {}
