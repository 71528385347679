import { Inject, Injectable, OnDestroy } from '@angular/core';
import { StateDeclaration, StateService, Transition, TransitionService, UIRouter } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';

import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN, SCREEN_READER_TEXT_LOADING } from 'core/constants';
import { Accessibility } from 'services/accessibility';
import { Seo } from 'services/seo';
import { Scroll } from 'services/scroll';
import { Util } from 'services/util';
import { DataLayer } from 'core/services';

@Injectable()
export class CoreRoute implements OnDestroy {
    private static LOADING_BAR_DELAY: number = 500;
    private static SCROLL_EXCEPTIONS: string[] = [
        'shop.catalog'
    ];

    private loadingBar: LoadingBarService;
    private transition: TransitionService;
    private uiRouter: UIRouter;

    private config: IXMOptions;
    private accessibility: Accessibility;
    private seo: Seo;
    private scroll: Scroll;
    private dataLayer: DataLayer;
    private subscriptions: Subscription[] = [];
    private stateRunning: boolean = false;
    private onBeforeDeregister: Function;
    private onStartDeregister: Function;
    private onSuccessDeregister: Function;
    private onErrorDeregister: Function;

    constructor(
    @Inject(CONFIG_TOKEN) config: IXMOptions,
        loadingBar: LoadingBarService,
        transition: TransitionService,
        uiRouter: UIRouter,
        accessibility: Accessibility,
        seo: Seo,
        scroll: Scroll,
        dataLayer: DataLayer
    ) {
        Object.assign(this, { config, loadingBar, transition, uiRouter, accessibility, seo, scroll, dataLayer });
    }

    public init(): boolean {
        this.onBeforeDeregister = this.transition.onBefore({}, (trans: Transition) => this.routeGuard(true, this.uiRouter, trans));

        this.onStartDeregister = this.transition.onStart({}, (trans: Transition) => {
            // if transition occurs on the same route
            if (!this.stateRunning && !trans.dynamic()) {
                this.accessibility.announceReaderText(SCREEN_READER_TEXT_LOADING);
                this.startTransition();
            }
        });

        this.onSuccessDeregister = this.transition.onSuccess({}, (trans: Transition) => {
            //this.dataLayer.sendPageDataOnTransition(trans);
            const metaData: MetaDataDefault = trans.getResolveTokens().includes('metaData') ? trans.injector(trans.to().name).get('metaData') : {};
            const focusElement: string = trans.getResolveTokens().includes('focusElement') ? trans.injector(trans.to().name).get('focusElement') : '';

            this.stateRunning = false;

            // if transition occurs on the same route
            if (!trans.dynamic()) {
                window.scrollTo(0, 0);

                setTimeout(() => {
                    // if another state already started, don't complete the progress bar
                    if (!this.stateRunning) {
                        this.loadingBar.stop();
                    }
                }, CoreRoute.LOADING_BAR_DELAY);

                if (trans.from().name !== '') {
                    this.setFocusOnLoad(focusElement);
                }
            } else if (CoreRoute.SCROLL_EXCEPTIONS.includes(trans.to().name) && !this.accessibility.usingKeyboard) {
                // if its in the exception list, then scroll to the top anyways
                window.scrollTo(0, 0);
            }

            this.seo.updateMetaTags(metaData);
        });

        this.onErrorDeregister = this.transition.onError({}, (_transition: Transition) => {
            this.stateRunning = false;
            setTimeout(() => {
                // if another state already started, don't complete the progress bar
                if (!this.stateRunning) {
                    this.loadingBar.stop();
                }
            }, CoreRoute.LOADING_BAR_DELAY);
        });

        this.startTransition();

        return this.routeGuard(false, this.uiRouter);
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
        this.onBeforeDeregister();
        this.onStartDeregister();
        this.onSuccessDeregister();
        this.onErrorDeregister();
    }

    private startTransition(): void {
        this.stateRunning = true;
        this.loadingBar.start();
        this.dataLayer.triggerPageStartForDataLayer();
    }

    private setFocusOnLoad(focusElement: string): void {
        const hash: string = this.uiRouter.urlService.hash();
        // if hash exist then call to scroll which will focus for us
        if (hash) {
            this.scroll.toHash(hash, {
                offset: 0,
                delay: 700
            });
        } else if (focusElement) {
            this.accessibility.focusHash(focusElement);
        } else {
            // if no hash then call to focus based on the transition
            this.accessibility.focusHeader(this.isAccountChildRoute());
        }
    }

    /**
     * The route guard is an array of state names or partial names (also supports RegExp) to block
     */
    private routeGuard(fromHook: boolean, uiRouter: UIRouter, trans?: Transition): boolean {
        const state: StateService = uiRouter.stateService;
        const transition: Transition = fromHook ? trans : uiRouter.globals.transition;

        if (!transition) {
            return;
        }

        // always allow entry into downtime page
        if (transition.to().name === 'downtime') {
            return;
        }

        const matches: boolean = this.config.ROUTE_GUARDS_REG.some((name: RegExp) => {
            const stateName: string = transition.to().name;

            // the parent could be a string or a state object so pick the one you want
            let parentName: string = '';
            if (transition.to().parent) {
                parentName = typeof transition.to().parent === 'string' ? <string> transition.to().parent : (<StateDeclaration> transition.to().parent).name;
            }

            return Boolean(stateName.match(name)) || Boolean(parentName.match(name));
        });

        if (matches) {
            state.go('error', { type: 'guard' });

            return false;
        }

        return true;
    }

    /**
     *  Check whether subpage of account
     */
    private isAccountChildRoute(): boolean {
        const state: StateService = this.uiRouter.stateService;

        return this.walkAccountStates(state.current.name);
    }

    private walkAccountStates(stateName: string): boolean {
        if (stateName.startsWith('account')) {
            return !stateName.endsWith('landing');
        }

        // pull the parent state from the current name
        const stateMatches: RegExpMatchArray = stateName.match(/(.*)\.(.*)/);

        if (stateMatches) {
            return this.walkAccountStates(stateMatches[1]);
        } else {
            const state: StateService = this.uiRouter.stateService;
            const parentState: string | StateDeclaration = state.get(stateName).parent;

            if (parentState) {
                return this.walkAccountStates(typeof parentState === 'string' ? parentState : (<StateDeclaration> parentState).name);
            }

            return false;
        }
    }
}
