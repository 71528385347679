import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { RejectType, StateService, Transition, TransitionService, UrlService } from '@uirouter/core';

import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { LocalStorage } from 'services/storage/local';

@Injectable()
export class XmErrorHandler implements ErrorHandler {
    private state: StateService;
    private route: UrlService;
    private config: IXMOptions;
    private localStorage: LocalStorage;
    private transition: TransitionService;
    private transitionErrorFrom: string;
    private transitionErrorTo: string;

    constructor(state: StateService, route: UrlService, localStorage: LocalStorage, @Inject(CONFIG_TOKEN) config: IXMOptions, transition: TransitionService) {
        Object.assign(this, { state, route, localStorage, config, transition });

        this.transition.onStart({ from: 'error' }, () => {
            this.transitionErrorFrom = undefined;
            this.transitionErrorTo = undefined;
        });

        this.transition.onError({}, (trans: Transition) => {
            if (trans.error().type === RejectType.ERROR) {
                this.transitionErrorFrom = trans.from().name || 'app_load';
                this.transitionErrorTo = trans.to().name;
            }
        });
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    /* eslint-disable no-console */
    public handleError(error: any): void {
        if (error.stack && (error.stack.includes('assets.adobedtm.com') || error.stack.includes('satelliteLib') || error.stack.search(/giggy.*.js/) >= 0)) {
            return;
        }

        const stateName: string = this.state.current.name;

        // to prevent an infinite loop
        if (this.route.url().includes('error')) {
            return;
        }

        // do not redirect if the app is running locally
        if (this.config.BASE_URL !== '/') {
            if (stateName === 'shop.device' || stateName === 'shop.accessory') {

                console.log('Generic Product handleError', error);
                if (!this.config.DISABLE_DOWNTIME) {
                    this.state.go('product-downtime');
                } else {
                    // eslint-disable-next-line no-console
                    console.log('XM-OPTIONS: DISABLE_DOWNTIME is', this.config.DISABLE_DOWNTIME);
                }

                return;
            }

            // Redirect to activation error page for activation issues
            if (this.route.url().includes('activate')) {
                this.state.go('activation-error');

                return;
            }

            if (!this.localStorage.hasKey('xm-error')) {
                this.localStorage.set('xm-error', JSON.stringify(error.stack || error));
            }

            const reason: string = this.transitionErrorFrom ? `${this.transitionErrorFrom}-${this.transitionErrorTo}` : stateName;

            const url: string = `${window.location.protocol}//${window.location.host}${this.config.BASE_URL}error?reason=${reason}`;
            window.location.href = url;
        } else {
            console.error(error);
        }
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
    /* eslint-enable @typescript-eslint/explicit-module-boundary-types */
    /* eslint-enable no-console */
}
