export enum InbentaSearchType {
    SUGGESTION = 'SUGGESTION',
    RESULT = 'RESULT'
}

export enum CategoryIconType {
    WEB = 'web-desktop'
}

export const XFINITY_MOBILE_CATEGORY_SLUG: string = 'xfinity-mobile';

export enum SupportDeviceSectionId {
    LG = 'LG',
    APPLE = 'Apple',
    SAMSUNG = 'Samsung',
    OTHER= 'Other'
}

export const ARTICLE_PREVIEW_LENGTH: number = 246;

export enum InbentaSourceType {
    QELP = 'qelp',
    ARTICLE = 'support_article'
}
