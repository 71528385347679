import { StoreAction, XmAction } from 'store/actions';
import { ModelBase } from 'store/model-base';
import { Account, AllowedLines, BuyInfo, User } from './models';
import { AccountDetails } from './models/account-details';

export function userReducer(state: User, action: XmAction<ModelBase>): User {
    let newState: User;
    switch (action.type) {
        case StoreAction.GET_BUY_INFO:
            newState = new User(state);
            newState.buyInfo = <BuyInfo> action.payload;

            return newState;
        case StoreAction.GET_ACCOUNT_INFO:
            newState = new User(state);
            newState.account = <Account> action.payload;

            return newState;
        case StoreAction.GET_ACCOUNT_DETAILS:
            newState = new User(state);
            newState.accountDetails = <AccountDetails> action.payload;

            return newState;
        case StoreAction.UPDATE_ACCOUNT_INFO:
        case StoreAction.UPDATE_BUY_INFO:
            return <User> action.payload;
        case StoreAction.GET_ALLOWED_LINES:
            newState = new User(state);
            newState.allowedLines = <AllowedLines> action.payload;

            return newState;
        case StoreAction.CLEAR_ALLOWED_LINES:
            newState = new User(state);
            newState.allowedLines = undefined;

            return newState;
        default:
            return state;
    }
}
