import { ModelBase } from 'store/model-base';

export class StreetAddress extends ModelBase {
    public address1: string;
    public address2?: string;
    public city: string;
    public state: string;
    public zip: string;
    public country: string;

    public get addressLine1(): string {
        let address: string = this.address1 || '';
        address += this.address2 ? `, ${this.address2}` : '';

        return address;
    }

    public get addressLine2(): string {
        let address: string = this.city;
        address += `, ${this.state}`;
        address += `, ${this.zip}`;

        return address;
    }

    public getFullAddress(): string {
        return `${this.addressLine1}, ${this.addressLine2}`;
    }

    public equals(addr: StreetAddress): boolean {
        return Object.keys(this).every((key: string) => this[key] === addr[key]);
    }
}
