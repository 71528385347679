import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { StateService, UrlService } from '@uirouter/core';

import { GlobalNav, Util, XmStore, XmStoreUtil } from 'core/services';
import { User } from 'store/user/models';
import { CmsCore } from 'store/cms/models';

@Component({
    selector: 'xm-nav-profile',
    styleUrls: [ './profile.scss' ],
    templateUrl: './profile.html'
})
export class XmNavProfileComponent implements OnInit, OnDestroy {
    @Output() public toggleMobileNav: EventEmitter<void> = new EventEmitter<void>();

    public displayName: string;
    public displayNameFirstCharacter: string;
    public pageData: CmsNavMenuItems;

    private xmStore: XmStore;
    private subscriptions: Subscription[] = [];
    private globalNav: GlobalNav;

    constructor(globalNav: GlobalNav, route: UrlService, state: StateService, xmStore: XmStore) {
        Object.assign(this, { globalNav, route, state, xmStore });
    }

    public ngOnInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<User>(User, { returnUndefined: true }), (user: User) => {
            this.displayName = user ? user.displayName : '';
            this.displayNameFirstCharacter = this.displayName && this.displayName.slice(0, 1);
        }));

        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (result: CmsCore) => {
            this.pageData = result.nav.desktopMyAccountLinksAuthenticated;
        }));
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public closeMobileNav(): void {
        this.toggleMobileNav.emit();
    }

    public generateUiParams(paramsString: string): object {
        return Util.getObjectFromQueryString(paramsString);
    }

    public stateActive(item: CmsNavMenuItems): boolean {
        return this.globalNav.stateActive(item);
    }
}
