import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ModelBase } from 'store/model-base';
import { buildHeaders, buildParams, XmStore } from 'core/services';
import { ApiChannel } from 'core/constants';
import { HopInit } from './models';
import { StoreAction } from 'store/actions';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class HopApi {
    public static getHopInit(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<HopInit> {
        return http.get('/customer/account/payment/hop/initialization', {
            headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }),
            params: buildParams(params)
        }).pipe(
            map((response: HopInit) => HopInit.create<HopInit>(response)), 
            catchError(err => logAndHandleError(err, '/customer/account/payment/hop/initialization', OperationType.GET))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_HOP_INIT] = HopApi.getHopInit;
