import { ModelBase } from 'store/model-base';

export class CmsCheckout extends ModelBase {
    public static storeName: string = 'cmsCheckout';

    public address: CmsAddressFields;
    public shipping: CmsCheckoutShipping;
    public payment: CmsPayment;
    public review: CmsReview;
    public identityCheck: CmsIdentityCheck;
    public eligibility: CmsEligibility;
    public existingCard: CmsExistingCard;
    public acceptTradeInOnOrderReview: CmsAcceptTradeInOnOrderReview;
    public acceptEarlyUpgradeOnOrderReview: CmsAcceptTradeInOnOrderReview;
}
