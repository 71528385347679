import { ModelBase } from 'store/model-base';
import { CmsNonXfinityCustomer } from './non-xfinity-customer';

export class CmsErrors extends ModelBase {
    public static storeName: string = 'cmsErrors';

    public error: CmsError;
    public error404: CmsError404;
    public downtime: CmsDowntime;
    public pdpDowntime: CmsPdpDowntime;
    public maintenance: CmsMaintenance;
    public secondaryUser: CmsNonXfinityCustomer;
    public supportError: CmsError404;
    public activationError: CmsError404;
    public noProductFoundError: CmsNoProductFound;
}
