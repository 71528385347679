import { ModelBase } from 'store/model-base';

export class CmsNonXfinityCustomer extends ModelBase {
    public static storeName: string = 'cmsNonXfinityCustomer';

    public header: string;
    public subheader: string[];
    public action: string;
    public backgroundImage: CmsMediaImage;
    public icon: CmsMediaImage;
    public endNote: string;
    public endNoteLink: string;
}
