import { Pipe, PipeTransform } from '@angular/core';
import { Util } from 'core/services/util';

@Pipe({
    name: 'convertUnit'
})
export class ConvertUnitPipe implements PipeTransform {
    public transform(value: number = 0, args: ConvertUnitArg): string {
        if (!Util[args.methodName]) {
            return '';
        }

        return Util[args.methodName](value || 0, args.round || false);
    }
}
