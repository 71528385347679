import { Inject, Injectable, OnDestroy } from '@angular/core';
import { StateService, Transition, UIRouter } from '@uirouter/core';
import { Subscription } from 'rxjs';

import { Util } from 'services/util';
import { XmStoreUtil } from 'services/store';
import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import get from 'lodash-es/get';
import words from 'lodash-es/words';
import shuffle from 'lodash-es/shuffle';

@Injectable({
    providedIn: 'root'
})
export class Game implements OnDestroy {
    private config: IXMOptions;
    private state: StateService;
    private uiRouter: UIRouter;
    private subscriptions: Subscription[] = [];
    /* eslint-disable @typescript-eslint/no-explicit-any */
    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, uiRouter: UIRouter) {
        Object.assign(this, { config, uiRouter });

        // giggy game expectes loadash `_` to be a method for window object
        (<any> window)._ = {
            get,
            words,
            shuffle
        };
    }

    public getScripts(): void {
        if (this.config.GIGGY_URL && this.config.GIGGY_ASSET_BASE) {
            const s: HTMLScriptElement = window.document.createElement('script');

            // attach gig asset base to the window
            (<any> window).GG = {
                assetBase: this.config.GIGGY_ASSET_BASE
            };

            // load script
            s.src = this.config.GIGGY_URL;
            s.onload = this.checkTransitionUrl.bind(this);
            window.document.head.appendChild(s);
        }
    }

    public checkTransitionUrl(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.uiRouter.globals.success$, (transition: Transition) => {
            // To start Game from script
            (<any> window).initGame();

            // To get the 'activation' CTAs we detect if
            // the page was opened from the 'Activation' page,
            // under 'My Account'. Verifying if
            // game is accessed from activation.
            const referral: string = transition.from().url.includes('activate') ? 'activation' : undefined;
            (<any> window).GG.Main.setReferral(referral);
            (<any> window).GG.Main.setState(this.state);
        }));
        // don't forget to destroy giggy assets from component level onDestroy
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
}
