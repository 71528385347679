import { ChildModel, ModelBase } from 'store/model-base';
import { ProductCard } from './card';

export class Catalog extends ModelBase {
    public static storeName: string = 'productCatalog';

    public count: number;
    public offset: number;
    public totalNumberOfRecords: number;
    public products: ProductCard[];
    public sortOptions: CatalogSortFilter;

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'products',
            model: ProductCard
        }];
    }
}
