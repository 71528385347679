import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { Chat, Cloudinary, Util, XmStore, XmStoreUtil } from 'core/services';
import { CmsCore } from 'store/cms/models';
import { MouseEventType } from 'core/constants';

@Component({
    selector: 'xm-nav-chat',
    styleUrls: [ './chat.scss' ],
    templateUrl: './chat.html'
})
export class XmNavChatComponent implements OnInit, OnDestroy {
    @Output() public toggleMobileNav: EventEmitter<void> = new EventEmitter<void>();

    public pageData: CmsNavMenuItems;
    public displayIcon: MediaImageOptions;
    public chatIcon: MediaImageOptions;
    public chatHoverIcon: MediaImageOptions;

    private chat: Chat;
    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;

    constructor(chat: Chat, xmStore: XmStore) {
        Object.assign(this, { chat, xmStore });
    }

    public ngOnInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (result: CmsCore) => {
            this.pageData = result.nav.chat;
            this.chatIcon = { mobile: Cloudinary.generateMediaFromCms(this.pageData.icon) };
            if (this.pageData.hoverIcon) {
                this.chatHoverIcon = { mobile: Cloudinary.generateMediaFromCms(this.pageData.hoverIcon) };
            }

            this.displayIcon = this.chatIcon;
        }));
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public openChat(): void {
        this.toggleMobileNav.emit();
        this.chat.openChat();
    }

    public mouseHoverEvent(event: Event): void {
        this.displayIcon = event.type === MouseEventType.MOUSE_ENTER ? this.chatHoverIcon : this.chatIcon;
    }
}
