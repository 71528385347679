import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';

import { SharedModule } from 'shared/module';
import { ModalCloseButton } from './close/close';
import { DescriptiveModal } from './descriptive/descriptive';
import { AlertModal } from './alert/alert';
import { MultiImageModal } from './image/multi/multi';
import { SingleImageModal } from './image/single/single';
import { UtilityModal } from './utility/utility';
import { BreakpointWidth } from 'core/constants';

@NgModule({
    providers: [
        // set configs for all modals
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: Object.assign(new MatDialogConfig(), {
            disableClose: true,
            maxWidth: BreakpointWidth.SMALL_DESKTOP
        })
        }
    ],
    declarations: [
        AlertModal,
        DescriptiveModal,
        MultiImageModal,
        ModalCloseButton,
        SingleImageModal,
        UtilityModal
    ],
    exports: [
        AlertModal,
        MatDialogModule,
        DescriptiveModal,
        MultiImageModal,
        SingleImageModal,
        ModalCloseButton,
        UtilityModal
    ],
    imports: [
        MatDialogModule,
        SharedModule
    ]
})
export class ModalModule {}
