<span *ngFor="let deco of decorationInfoArray">
    <span *ngIf="deco.displayText && deco.isPlainText"
        [innerHtml]="deco.displayText" 
        [ngClass]="{
            'bold': deco.isBold,
            'strike-through': deco.isStrikeThrough
        }"
    ></span>

    <!-- TODO Need to be updated from "xm-btn-primary-light-banner" to "xm-btn-primary-light" once the standardization of buttons should be done  -->

    <a *ngIf="deco.displayText && !deco.isPlainText && deco.uiSref"
        [innerHtml]="deco.displayText"
        class="{{ deco.isPrimary? '' : theme }}"
        [ngClass]="{
            'xm-btn-link-light': deco.isLink && decorationInfoArray.length === 1,
            'inline-link': deco.isLink && decorationInfoArray.length > 1,
            'xm-btn-primary-light-banner': deco.isPrimary,
            'xm-btn-secondary-light': deco.isSecondary
        }"
        [target]="deco.newTab ? '_blank' : ''"
        [uiSref]="deco.uiSref"
        [uiParams]="deco.uiParams"
    ></a>
    <a *ngIf="deco.displayText && !deco.isPlainText && deco.absoluteUrl"
        [innerHtml]="deco.displayText"
        class="{{ deco.isPrimary? '' : theme }}"
        [ngClass]="{
            'xm-btn-link-light': deco.isLink && decorationInfoArray.length === 1,
            'inline-link': deco.isLink && decorationInfoArray.length > 1,
            'xm-btn-primary-light': deco.isPrimary,
            'xm-btn-secondary-light': deco.isSecondary
        }"
        [target]="deco.newTab ? '_blank' : ''"
        [href]="safeUrl(deco.absoluteUrl)"
    ></a>
</span>
<ng-content></ng-content>
