import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { CimaToken, Cloudinary, GlobalNav, Util, XmStore, XmStoreUtil } from 'core/services';
import { CmsCore } from 'store/cms/models';
import { MouseEventType } from 'core/constants';
import { Cart, NavCart } from 'store/cart/models';
import { StoreAction } from 'store/actions';

@Component({
    selector: 'xm-nav-cart',
    styleUrls: [ './cart.scss' ],
    templateUrl: './cart.html'
})
export class XmNavCartComponent implements OnInit, OnDestroy {
    @Output() public toggleMobileNav: EventEmitter<void> = new EventEmitter<void>();

    public pageData: CmsNavMenuItems;
    public displayIcon: MediaImageOptions;
    public cartIcon: MediaImageOptions;
    public cartHoverIcon: MediaImageOptions;
    public cartCountText: string;
    public cartAriaLabel: string;

    private cimaToken: CimaToken;
    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;
    private globalNav: GlobalNav;

    constructor(cimaToken: CimaToken, globalNav: GlobalNav, xmStore: XmStore) {
        Object.assign(this, { cimaToken, globalNav, xmStore });
    }

    public ngOnInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (result: CmsCore) => {
            this.pageData = result.nav.cart;
            this.cartAriaLabel = this.pageData.name;
            this.cartIcon = { mobile: Cloudinary.generateMediaFromCms(this.pageData.icon) };
            if (this.pageData.hoverIcon) {
                this.cartHoverIcon = { mobile: Cloudinary.generateMediaFromCms(this.pageData.hoverIcon) };
            }

            this.displayIcon = this.cartIcon;
        }));

        if (this.cimaToken.isLoggedIn) {
            // if the real cart has already been called, no need to call cart count then
            this.xmStore.peekPromise<Cart>(Cart)
                .catch(() => XmStoreUtil.defaultCatch(this.xmStore.fetchPromise<NavCart>(StoreAction.GET_CART_COUNT).then((navCart: NavCart) => {
                    if (navCart && navCart.itemCount) {
                        this.cartCountText = navCart.itemCount.toString();
                    }
                })));
        }

        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<Cart>(Cart, { returnUndefined: true }), (cart: Cart) => {
            if (cart) {
                this.cartCountText = cart.items.length ? cart.items.length.toString() : undefined;
                this.cartAriaLabel += `, ${this.cartCountText} items`;
            }
        }));
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public stateActive(item: CmsNavMenuItems): boolean {
        return this.globalNav.stateActive(item);
    }

    public generateUiParams(paramsString: string): object {
        return Util.getObjectFromQueryString(paramsString);
    }

    public toggleNavMenu(): void {
        this.toggleMobileNav.emit();
    }

    public mouseHoverEvent(event: Event): void {
        this.displayIcon = event.type === MouseEventType.MOUSE_ENTER ? this.cartHoverIcon : this.cartIcon;
    }
}
