import { ChildModel, ModelBase } from 'store/model-base';
import { PaymentCard } from './card';

export class PaymentInstrument extends ModelBase {
    public card: PaymentCard;
    public paymentToken: string;

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'card',
            model: PaymentCard
        }];
    }
}
