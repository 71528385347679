import { Plan } from 'store/plan/models/option/plan';
import { ProtectionPlanVariant } from '../variant/protection';
import { ChildModel } from 'store/model-base';

export class ProtectionPlan extends Plan {
    public variants: ProtectionPlanVariant[];

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'variants',
            model: ProtectionPlanVariant
        }];
    }
}
