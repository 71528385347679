import { Injectable } from '@angular/core';

import { Accessibility } from 'services/accessibility';
import { GlobalNav } from './global-nav';

@Injectable({
    providedIn: 'root'
})
export class Scroll {
    public static DEFAULT_TIME: number = 300;

    private accessibility: Accessibility;

    private startTime: number = Date.now();
    private elapsedTime: number = 0;
    private globalNav: GlobalNav;

    constructor(accessibility: Accessibility, globalNav: GlobalNav) {
        Object.assign(this, { accessibility, globalNav });
    }

    public toHash(hash: string, options: ScrollHashOptions = {}): void {
        const offset: number = (options.offset + this.globalNav.navHeight()) || this.globalNav.navHeight();
        const scrollTime: number = options.scrollTime || Scroll.DEFAULT_TIME;
        const delay: number = options.delay || 0;

        setTimeout(() => {
            const element: HTMLElement = window.document.getElementById(hash);
            if (element) {
                this.linear(element.getBoundingClientRect().top + this.getScrollPosition() - offset, scrollTime).then(() => {
                    this.accessibility.focusElement(element);
                });
            }
        }, delay);
    }

    public linear(toPosition: number, duration: number = Scroll.DEFAULT_TIME, logicCallback?: () => void): Promise<number> {
        this.resetStartTime();

        return new Promise((resolve: (value?: number | PromiseLike<number>) => void) => {
            const callback: () => void = () => {
                // ensure the scroll position was reached
                this.resolvePromise(resolve, toPosition, logicCallback);
            };

            const start: number = this.getScrollPosition();
            const change: number = toPosition - start;

            window.requestAnimationFrame(() => {
                this.linearStep(callback, logicCallback, start, change, duration);
            });
        });
    }

    public easeInOut(toPosition: number, duration: number = Scroll.DEFAULT_TIME, logicCallback?: () => void): Promise<number> {
        this.resetStartTime();

        return new Promise((resolve: (value?: number | PromiseLike<number>) => void) => {
            const callback: () => void = () => {
                // ensure the scroll position was reached
                this.resolvePromise(resolve, toPosition, logicCallback);
            };

            const start: number = this.getScrollPosition();
            const change: number = toPosition - start;

            window.requestAnimationFrame(() => {
                this.easeInOutStep(callback, logicCallback, start, change, duration);
            });
        });
    }

    private linearStep(callback: () => void, logicCallback: (value: number) => void, start: number, change: number, duration: number): void {
        const finished: boolean = this.isScrollComplete(duration);
        const nextPosition: number = change * this.elapsedTime / duration + start;

        this.setScrollPosition(nextPosition);
        this.updateLogicCallback(nextPosition, logicCallback);

        this.scrollNextFrame(finished, () => {
            this.linearStep(callback, logicCallback, start, change, duration);
        }, callback);
    }

    private easeInOutStep(callback: () => void, logicCallback: (value: number) => void, start: number, change: number, duration: number): void {
        const finished: boolean = this.isScrollComplete(duration);
        let nextPosition: number = 0;

        let currentTime: number = this.elapsedTime / (duration / 2);
        if (currentTime < 1) {
            nextPosition = change / 2 * currentTime * currentTime + start;
        } else {
            currentTime -= 1;
            nextPosition = -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
        }

        this.setScrollPosition(nextPosition);
        this.updateLogicCallback(nextPosition, logicCallback);

        this.scrollNextFrame(finished, () => {
            this.easeInOutStep(callback, logicCallback, start, change, duration);
        }, callback);
    }

    private setScrollPosition(position: number): void {
        window.scrollTo(0, position);
    }

    private getScrollPosition(): number {
        return window.pageYOffset;
    }

    private resolvePromise(resolve: (value?: {} | PromiseLike<{}>) => void, toPosition: number, logicCallback?: (value: number) => void): void {
        this.setScrollPosition(toPosition);
        this.updateLogicCallback(toPosition, logicCallback);
        resolve(toPosition);
    }

    private updateLogicCallback(nextPosition: number, logicCallback?: (value: number) => void): void {
        if (logicCallback) {
            logicCallback(nextPosition);
        }
    }

    private isScrollComplete(duration: number): boolean {
        this.elapsedTime = Date.now() - this.startTime;

        return this.elapsedTime > duration;
    }

    private resetStartTime(): void {
        this.startTime = Date.now();
    }

    private scrollNextFrame(finished: boolean, stepFunction: () => void, callback: () => void): void {
        if (!finished) {
            window.requestAnimationFrame(stepFunction);
        } else {
            callback();
        }
    }
}
