import { StoreAction, XmAction } from 'store/actions';
import { HopInit } from './models';

export function hopInitReducer(state: HopInit, action: XmAction<HopInit>): HopInit {
    switch (action.type) {
        case StoreAction.GET_HOP_INIT:
            return action.payload;
        default:
            return state;
    }
}
