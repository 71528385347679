import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiChannel } from 'core/constants';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { buildHeaders } from 'services/http/options';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';
import { InjectorInstance } from 'src/xm/module';
import { StoreAction } from 'store/actions';
import { CmsExtendedPromoBuyCollection } from 'store/cms/models/extended-promo';
import { ModelBase } from 'store/model-base';

@Injectable({
    providedIn: 'root'
})

export class SitecoreClient {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    public static getData: any;   
    /* eslint-disable @typescript-eslint/no-explicit-any */
    public getData(path: string): Promise<any> {

        const httpClient = InjectorInstance.get<HttpClient>(HttpClient);

        return new Promise((resolve, reject) => {
            httpClient.get(path, {
                headers: buildHeaders({
                    apiChannel: ApiChannel.SITECORE_URL
                })
            }).subscribe(data => {
                resolve(data);
            }, (error) => {             
                logAndHandleError(error, path, OperationType.GET);
                reject(error);
            });
        });
    }

    public getBannerPromoData(path: string): Observable<CmsExtendedPromoBuyCollection> {

        const httpClient = InjectorInstance.get<HttpClient>(HttpClient);

        return httpClient.get(path, 
            { headers: buildHeaders({ 
                apiChannel: ApiChannel.SITECORE_URL })
            }).pipe(
            map((result: CmsExtendedPromoBuyCollection) => CmsExtendedPromoBuyCollection.create<CmsExtendedPromoBuyCollection>(result)), 
            catchError(err => logAndHandleError(err, 'getPromoBannerContent', OperationType.GET)));
    }
}

ModelBase.fetchMapping[StoreAction.GET_SITECORE_CLIENT] = SitecoreClient.getData;