import { ChildModel, ModelBase } from 'store/model-base';
import { SupportCategorySummary } from './category-summary';

export class SupportCategoryCollection extends ModelBase {
    public static storeName: string = 'supportCategoryCollection';

    public categories: SupportCategorySummary[];

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'categories',
            model: SupportCategorySummary
        }];
    }
}
