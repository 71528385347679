import { StoreAction, XmAction } from 'store/actions';
import { Catalog, ProductDetails } from './models';

export function productCatalogReducer(state: Catalog, action: XmAction<Catalog>): Catalog {
    switch (action.type) {
        case StoreAction.GET_CATALOG:
            return action.payload;
        default:
            return state;
    }
}

export function productReducer(state: ProductDetails[] = [], action: XmAction<ProductDetails>): ProductDetails[] {
    switch (action.type) {
        case StoreAction.GET_PRODUCT_DETAIL:
            return [ ...state, action.payload ];

        default:
            return state;
    }
}
