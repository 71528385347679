import { StoreAction, XmAction } from 'store/actions';

import {
    CmsABTest,
    CmsAccessoryToast,
    CmsByod,
    CmsCart,
    CmsCheckout,
    CmsContactBar,
    CmsCore,
    CmsCustomerEligibility,
    CmsCustomizer,
    CmsErrors,
    CmsExtendedPromoBuyCollection,
    CmsFinancingOptions,
    CmsHop,
    CmsLanding,
    CmsLandingV2,
    CmsLegalLanding,
    CmsLogin,
    CmsNetwork,
    CmsNonXfinityCustomer,
    CmsOrderConfirmation,
    CmsPdp,
    CmsRetentionPromo,
    CmsRup,
    CmsShop,
    CmsSupportCategoryLanding,
    CmsSupportDeviceTroubleshooting,
    CmsSupportNotificationBanner,
    CmsTradeIn,
    CmsWatches,
    CmsXmbComingSoon
} from './models';
import { CmsSmb } from './models/smb';

export function cmsCoreReducer(state: CmsCore, action: XmAction<CmsCore>): CmsCore {
    return action.type === StoreAction.GET_CORE ? action.payload : state;
}

export function cmsByodReducer(state: CmsByod, action: XmAction<CmsByod>): CmsByod {
    return action.type === StoreAction.GET_BYOD ? action.payload : state;
}

export function cmsErrorsReducer(state: CmsErrors, action: XmAction<CmsErrors>): CmsErrors {
    return action.type === StoreAction.GET_ERRORS ? action.payload : state;
}

export function cmsTradeInReducer(state: CmsTradeIn, action: XmAction<CmsTradeIn>): CmsTradeIn {
    return action.type === StoreAction.GET_CMS_TRADE_IN ? action.payload : state;
}

export function cmsLandingReducer(state: CmsLanding, action: XmAction<CmsLanding>): CmsLanding {
    return action.type === StoreAction.GET_LANDING ? action.payload : state;
}

export function cmsNetworkReducer(state: CmsNetwork, action: XmAction<CmsNetwork>): CmsNetwork {
    return action.type === StoreAction.GET_NETWORK ? action.payload : state;
}

export function CmsLandingV2Reducer(state: CmsLandingV2, action: XmAction<CmsLandingV2>): CmsLandingV2 {
    return action.type === StoreAction.GET_PLAN_THREE_LEARN ? action.payload : state;
}

export function cmsCustomizerReducer(state: CmsCustomizer, action: XmAction<CmsCustomizer>): CmsCustomizer {
    return action.type === StoreAction.GET_CUSTOMIZER ? action.payload : state;
}

export function cmsPdpReducer(state: CmsPdp, action: XmAction<CmsPdp>): CmsPdp {
    return action.type === StoreAction.GET_PDP ? action.payload : state;
}

export function cmsCheckoutReducer(state: CmsCheckout, action: XmAction<CmsCheckout>): CmsCheckout {
    return action.type === StoreAction.GET_CHECKOUT ? action.payload : state;
}

export function cmsCartReducer(state: CmsCart, action: XmAction<CmsCart>): CmsCart {
    return action.type === StoreAction.GET_CMS_CART ? action.payload : state;
}

export function cmsLoginReducer(state: CmsLogin, action: XmAction<CmsLogin>): CmsLogin {
    return action.type === StoreAction.GET_LOGIN ? action.payload : state;
}

export function cmsHopReducer(state: CmsHop, action: XmAction<CmsHop>): CmsHop {
    return action.type === StoreAction.GET_HOP ? action.payload : state;
}

export function cmsContactBarReducer(state: CmsContactBar, action: XmAction<CmsContactBar>): CmsContactBar {
    return action.type === StoreAction.GET_CONTACT_BAR ? action.payload : state;
}

export function cmsShopReducer(state: CmsShop, action: XmAction<CmsShop>): CmsShop {
    return action.type === StoreAction.GET_SHOP ? action.payload : state;
}

export function cmsRetentionPromoReducer(state: CmsRetentionPromo, action: XmAction<CmsRetentionPromo>): CmsRetentionPromo {
    return action.type === StoreAction.GET_RETENTION_PROMO ? action.payload : state;
}

export function cmsOrderConfirmationReducer(state: CmsOrderConfirmation, action: XmAction<CmsOrderConfirmation>): CmsOrderConfirmation {
    return action.type === StoreAction.GET_ORDER_CONFIRMATION ? action.payload : state;
}

export function CmsCustomerEligibilityReducer(state: CmsCustomerEligibility, action: XmAction<CmsCustomerEligibility>): CmsCustomerEligibility {
    return action.type === StoreAction.GET_CUSTOMER_ELIGIBILITY ? action.payload : state;
}

export function cmsLegalLandingReducer(state: CmsLegalLanding, action: XmAction<CmsLegalLanding>): CmsLegalLanding {
    return action.type === StoreAction.GET_LEGAL_LANDING ? action.payload : state;
}

export function cmsWatchesReducer(state: CmsWatches, action: XmAction<CmsWatches>): CmsWatches {
    return action.type === StoreAction.GET_WATCHES ? action.payload : state;
}

export function cmsABTestReducer(state: CmsABTest, action: XmAction<CmsABTest>): CmsABTest {
    return action.type === StoreAction.GET_AB_TEST ? action.payload : state;
}

export function cmsSupportDeviceTroubleshootingReducer(state: CmsSupportDeviceTroubleshooting, action: XmAction<CmsSupportDeviceTroubleshooting>): CmsSupportDeviceTroubleshooting {
    return action.type === StoreAction.GET_SUPPORT_DEVICE_TROUBLESHOOTING ? action.payload : state;
}

export function cmsSupportNotificationBannerReducer(state: CmsSupportNotificationBanner, action: XmAction<CmsSupportNotificationBanner>): CmsSupportNotificationBanner {
    return action.type === StoreAction.GET_SUPPORT_NOTIFICATION_BANNER ? action.payload : state;
}

export function cmsSupportCategoryLandingReducer(state: CmsSupportCategoryLanding, action: XmAction<CmsSupportCategoryLanding>): CmsSupportCategoryLanding {
    return action.type === StoreAction.GET_SUPPORT_CATEGORY_LANDING ? action.payload : state;
}

export function cmsAccessoryToastReducer(state: CmsAccessoryToast, action: XmAction<CmsAccessoryToast>): CmsAccessoryToast {
    return action.type === StoreAction.GET_CMS_ACCESSORY_TOAST ? action.payload : state;
}

export function cmsExtendedPromoBuyReducer(state: CmsExtendedPromoBuyCollection, action: XmAction<CmsExtendedPromoBuyCollection>): CmsExtendedPromoBuyCollection {
    return action.type === StoreAction.GET_EXTENDED_PROMOS_BUY ? action.payload : state;
}

// XMB Coming Soon Page
export function cmsXmbComingSoonReducer(state: CmsXmbComingSoon, action: XmAction<CmsXmbComingSoon>): CmsXmbComingSoon {
    return action.type === StoreAction.GET_CMS_XMB_COMING_SOON ? action.payload : state;
}

export function cmsnonXfinityCustomerReducer(state: CmsNonXfinityCustomer, action: XmAction<CmsNonXfinityCustomer>): CmsNonXfinityCustomer {
    return action.type === StoreAction.GET_CMS_NON_XFINITY_CUSTOMER ? action.payload : state;
}

export function cmsSmbReducer(state: CmsSmb, action: XmAction<CmsSmb>): CmsSmb {
    return action.type === StoreAction.GET_SMB_CMS ? action.payload : state;
}

export function cmsRupReducer(state: CmsRup, action: XmAction<CmsRup>): CmsRup {
    return action.type === StoreAction.GET_CMS_RUP ? action.payload : state;
}

export function cmsFinancingOptionsReducer(state: CmsFinancingOptions, action: XmAction<CmsFinancingOptions>): CmsFinancingOptions {
    return action.type === StoreAction.GET_FINANCING_OPTIONS ? action.payload : state;
}