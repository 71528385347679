import { TradeInConditionOptions } from 'buy/constants';
import { TradeInProgram } from 'core/constants';
import { ChildModel, ModelBase } from 'store/model-base';
import { TradeInDeviceDetail } from 'store/trade-ins/models/device-detail';
import { OfferDetail } from 'store/trade-ins/models/offer-detail';

export class TradeInFlowState extends ModelBase {
    public static storeName: string = 'tradeInFlowDetails';

    public condition: ConditionInfo;
    public imei: string;
    public details: DetailsInfo;
    public device: TradeInDeviceDetail;
    public program: string;
    public offer: OfferDetail;
    //isResultFormAvailable is not related to trade in info, this is added only to update the form availability for PDP changes
    public isResultFormAvailable: boolean;

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'offer',
            model: OfferDetail
        }, {
            attrName: 'condition',
            model: ConditionInfo
        }, {
            attrName: 'details',
            model: DetailsInfo
        }, {
            attrName: 'device',
            model: TradeInDeviceDetail
        }];
    }

    public get hasOfferAndAEID(): boolean {
        return Boolean(this.offer.amount && this.device.aeid);
    }

    public get makeModelComplete(): boolean {
        return Boolean(this.device.make && this.device.model);
    }

    public get isPhoneConditionComplete(): boolean {
        return Boolean(this.condition.power === TradeInConditionOptions.YES && this.condition.damage === TradeInConditionOptions.YES);
    }

    public get conditionComplete(): boolean {
        return Boolean(this.condition.power && this.condition.damage);
    }

    public get imeiComplete(): boolean {
        return Boolean(this.imei);
    }

    public get detailsComplete(): boolean {
        return Boolean(this.details.storage && this.details.carrier);
    }

    public get isTipsFlowCompleted(): boolean {
        return Boolean(this.makeModelComplete && this.isPhoneConditionComplete);
    }

    public get isTradeInFormCompleted(): boolean {
        return this.isTip ? this.isTipsFlowCompleted : Boolean(this.isTipsFlowCompleted && this.detailsComplete);
    }

    public get isTradeInFlowCompleted(): boolean {
        return Boolean(this.isTradeInFormCompleted && this.device.price);
    }

    public get isTip(): boolean {
        return this.program === TradeInProgram.TIP;
    }

    public get isIti(): boolean {
        return this.program === TradeInProgram.ITI;
    }

    public get itiPrice(): number {
        return this.device.price ? this.device.price : -1;
    }

    public get isProgramAvailable(): boolean {
        return this.isIti || this.isTip;
    }

    public static create<T extends ModelBase>(initData: object = {}): T {
        const toReturnFlow: TradeInFlowState = super.create<TradeInFlowState>(initData);
        toReturnFlow.condition.power = toReturnFlow.condition.power ? toReturnFlow.condition.power : '';
        toReturnFlow.condition.damage = toReturnFlow.condition.damage ? toReturnFlow.condition.damage : '';
        toReturnFlow.imei = toReturnFlow.imei ? toReturnFlow.imei : '';
        toReturnFlow.details.storage = toReturnFlow.details.storage ? toReturnFlow.details.storage : '';
        toReturnFlow.details.carrier = toReturnFlow.details.carrier ? toReturnFlow.details.carrier : '';
        toReturnFlow.device.make = toReturnFlow.device.make ? toReturnFlow.device.make : '';
        toReturnFlow.device.model = toReturnFlow.device.model ? toReturnFlow.device.model : '';
        toReturnFlow.device.groupId = toReturnFlow.device.groupId ? toReturnFlow.device.groupId : '';
        toReturnFlow.device.aeid = toReturnFlow.device.aeid ? toReturnFlow.device.aeid : '';
        toReturnFlow.isResultFormAvailable = toReturnFlow.isResultFormAvailable ? toReturnFlow.isResultFormAvailable : false;

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturnFlow;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }
}

export class DetailsInfo extends ModelBase {
    public storage: string;
    public carrier: string;
}

export class ConditionInfo extends ModelBase {
    public power: string;
    public damage: string;
}
