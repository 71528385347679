import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { buildHeaders, XmStore } from 'core/services';
import { NdelEligibility } from 'store/ndel/models/eligibility';
import { LineEligibility } from 'store/ndel/models/line';
import { StoreAction } from 'store/actions';
import { ApiChannel } from 'core/constants';
import { ModelBase } from 'store/model-base';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class NdelApi {
    public static lineNdelEligibility(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<LineEligibility> {
        return http.get(`/lines/${params.lineId}/imei/${params.imei}/ndeleligibility`, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((response: ApiResponse) => LineEligibility.create<LineEligibility>(response), 
                catchError(err => logAndHandleError(err, '/lines/{params.lineId}/imei/{params.imei}/ndeleligibility', OperationType.GET)))
        );
    }

    public static ndelEligibility(_xmStore: XmStore, http: HttpClient): Observable<NdelEligibility> {
        return http.get('/account/ndelEligible', { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((response: ApiResponse) => NdelEligibility.create<NdelEligibility>({ ndelLineEligibilities: response }), 
                catchError(err => logAndHandleError(err, '/account/ndelEligible', OperationType.GET)))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_LINE_NDEL_ELIGIBILITY] = NdelApi.lineNdelEligibility;
ModelBase.fetchMapping[StoreAction.GET_NDEL_ELIGIBILITY] = NdelApi.ndelEligibility;
