import { ModelBase } from 'store/model-base';

export class PastDue extends ModelBase {
    public static storeName: string = 'pastDue';

    public totalPastDue: number;
    public orderBalance: number;
    public paymentBalance: number;

    public hasPastDue: boolean;

    public static create<T extends ModelBase>(initData: object): T {
        const pastDue: PastDue = super.create<PastDue>(initData);
        pastDue.hasPastDue = pastDue.totalPastDue > 0;

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> pastDue;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }
}
