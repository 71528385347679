import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Util } from 'services/util';
import { Cloudinary } from 'services/cloudinary';
import { WindowReference } from 'services/window';
import { ImageFit } from 'core/constants';

@Component({
    selector: 'xm-responsive-image',
    styleUrls: [ './responsive-image.scss' ],
    templateUrl: './responsive-image.html'
})
export class XmResponsiveImageComponent implements OnChanges, OnDestroy {
    private static SCALAR: number = 60;
    private static RETINA_SCALE: number = 2;

    @Input() public imageOptions: CmsResponsiveImage;

    public imageUrl: string;
    public imageUrl2x: string = '';
    public isSVG: boolean;
    public fitByHeight: boolean;
    public fitByWidth: boolean;
    public fitByContain: boolean;

    private currentBreakpoint: Breakpoint;
    private element: ElementRef;
    private subscriptions: Subscription[] = [];
    private triedSecondBounding: boolean = false;

    constructor(element: ElementRef, windowReference: WindowReference) {
        Object.assign(this, { element });

        this.subscriptions.push(windowReference.breakpoint.subscribe((breakpoint: Breakpoint) => {
            this.currentBreakpoint = breakpoint;
            this.checkForBreakpoints();
        }));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.imageOptions) {
            this.checkForBreakpoints();
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    private checkForBreakpoints(): void {
        if (!this.imageOptions) {
            return;
        }

        let currentImage: SingletonResponsiveImage;

        switch (true) {
            case (this.currentBreakpoint.isMobile):
                currentImage = this.findMobile();
                break;
            case (this.currentBreakpoint.isTablet):
                currentImage = this.findTablet();
                break;
            case (this.currentBreakpoint.isSmallDesktop):
                currentImage = this.findSmallDesktop();
                break;
            default:
                currentImage = this.findLargeDesktop();
        }

        this.isSVG = currentImage.url.endsWith('.svg');
        this.setupImageWrapper(currentImage);
        this.createImagePaths(currentImage);
    }

    private setupImageWrapper(currentImage: SingletonResponsiveImage): void {
        this.fitByHeight = this.priorityHeight(currentImage.fit);
        this.fitByWidth = this.priorityWidth(currentImage.fit);
        this.fitByContain = this.priorityContain(currentImage.fit);
    }

    private createImagePaths(currentImage: SingletonResponsiveImage): void {
        if (currentImage.useBoundingBox) {
            const box: ClientRect = this.element.nativeElement.getBoundingClientRect();
            const width: number = this.roundUp(box.width);
            const height: number = this.roundUp(box.height);

            if (width > 0 || height > 0) {
                if (this.priorityHeight(currentImage.fit) && height > 0) {
                    this.imageUrl = Cloudinary.generateResponsiveUrl(-1, height, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateResponsiveUrl(-1, height * XmResponsiveImageComponent.RETINA_SCALE, currentImage)} 2x`;
                } else if (this.priorityWidth(currentImage.fit) && width > 0) {
                    this.imageUrl = Cloudinary.generateResponsiveUrl(width, -1, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateResponsiveUrl(width * XmResponsiveImageComponent.RETINA_SCALE, -1, currentImage)} 2x`;
                } else {
                    this.imageUrl = Cloudinary.generateResponsiveUrl(width, height, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateResponsiveUrl(width * XmResponsiveImageComponent.RETINA_SCALE, height * XmResponsiveImageComponent.RETINA_SCALE, currentImage)} 2x`;
                }
            } else if (!this.triedSecondBounding) {
                this.triedSecondBounding = true;
                setTimeout(() => {
                    this.checkForBreakpoints();
                });
            }

            this.imageUrl = Cloudinary.generateResponsiveUrl(0, 0, currentImage);
        } else {
            this.imageUrl = Cloudinary.generateResponsiveUrl(0, 0, currentImage);
            this.imageUrl2x = `${this.imageUrl} 2x`;
        }
    }

    private roundUp(value: number): number {
        return Math.ceil(value / XmResponsiveImageComponent.SCALAR) * XmResponsiveImageComponent.SCALAR;
    }

    private findLargeDesktop(): SingletonResponsiveImage {
        if (this.imageOptions.xlargeUrl) {
            return {
                url: this.imageOptions.xlargeUrl,
                useBoundingBox: this.imageOptions.xlargeUseBoundingBox,
                trimTransparency: this.imageOptions.xlargeTrimTransparency,
                fit: this.imageOptions.xlargeImageFit
            };
        }

        return this.findSmallDesktop();
    }

    private findSmallDesktop(): SingletonResponsiveImage {
        if (this.imageOptions.largeUrl) {
            return {
                url: this.imageOptions.xlargeUrl,
                useBoundingBox: this.imageOptions.largeUseBoundingBox,
                trimTransparency: this.imageOptions.largeTrimTransparency,
                fit: this.imageOptions.largeImageFit
            };
        }

        return this.findTablet();
    }

    private findTablet(): SingletonResponsiveImage {
        if (this.imageOptions.mediumUrl) {
            return {
                url: this.imageOptions.mediumUrl,
                useBoundingBox: this.imageOptions.mediumUseBoundingBox,
                trimTransparency: this.imageOptions.mediumTrimTransparency,
                fit: this.imageOptions.mediumImageFit
            };
        }

        return this.findMobile();
    }

    private findMobile(): SingletonResponsiveImage {
        return {
            url: this.imageOptions.smallUrl,
            useBoundingBox: this.imageOptions.smallUseBoundingBox,
            trimTransparency: this.imageOptions.smallTrimTransparency,
            fit: this.imageOptions.smallImageFit
        };
    }

    private priorityContain(fit: string): boolean {
        return fit === ImageFit.CONTAIN;
    }

    private priorityHeight(fit: string): boolean {
        return fit === ImageFit.HEIGHT;
    }

    private priorityWidth(fit: string): boolean {
        return fit === ImageFit.WIDTH;
    }
}
