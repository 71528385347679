import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ModelBase } from 'store/model-base';
import { buildHeaders, buildParams, XmStore } from 'core/services';
import { StoreAction } from 'store/actions';
import { ApiChannel, TrialFeatures } from 'core/constants';
import { BuyInfo, User } from 'store/user/models';
import { CalculatedTax, Cart, NavCart, ShippingMethod, ShippingMethodCollection } from './models';
import { CustomizeItem } from 'store/customize-item/models';
import { IXMOptions } from 'core/interfaces';
import {logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';
import { Util } from 'services/util';

@Injectable()
export class CartApi {
    public static get(xmStore: XmStore, http: HttpClient, _params: ApiParams = {}, config?: IXMOptions): Observable<Cart> {
        const url = '/cart';
        const cbmGUID = Util.generateUuid();

        return xmStore.peekChild<BuyInfo, User>('buyInfo', BuyInfo, User).pipe(
            mergeMap((buyInfo: BuyInfo) =>
                http.get(url, {
                    headers: buildHeaders({
                        apiChannel: ApiChannel.GATEWAY_FOR_GUID,
                        sessionID: buyInfo.sessionId,
                        'X-global-tracking-id': cbmGUID
                    }),
                    params: buildParams({
                        RUPTrial: config.ENABLE_RUP_TRIAL,
                        trialFeatures: config.ENABLE_SERVICE_PROMO_TRIAL ? TrialFeatures.SERVICE_PROMO : undefined
                    })
                }).pipe(mergeMap((response: Cart) => {
                    const cart: Cart = Cart.create<Cart>(response);

                    return CartApi.getTaxes(http, buyInfo, cart);
                }), 
                catchError(err => logAndHandleError(err, url, OperationType.GET, cbmGUID, undefined, undefined, undefined, buyInfo.sessionId)))
            ),
            take(1)
        );
    }

    public static getCartCount(_xmStore: XmStore, http: HttpClient): Observable<NavCart> {
        const url = '/cart';
        const cbmGUID = Util.generateUuid();

        return http.get(url, {
            headers: buildHeaders({ 
                apiChannel: ApiChannel.GATEWAY_FOR_GUID,
                'X-global-tracking-id': cbmGUID
            })
        }).pipe(map((response: Cart) => {
            const cart: Cart = Cart.create<Cart>(response);

            return NavCart.create<NavCart>({ itemCount: cart.items.length });
        }), 
        catchError(err => logAndHandleError(err, url, OperationType.GET, cbmGUID)));
    }

    public static getShippingMethod(xmStore: XmStore, http: HttpClient): Observable<ShippingMethodCollection> {
        const url = '/cart/shippingmethod';
        const cbmGUID = Util.generateUuid();

        return xmStore.peekChild<BuyInfo, User>('buyInfo', BuyInfo, User).pipe(
            mergeMap((buyInfo: BuyInfo) => http.get(url, {
                headers: buildHeaders({
                    apiChannel: ApiChannel.GATEWAY_FOR_GUID,
                    sessionID: buyInfo.sessionId,
                    'X-global-tracking-id': cbmGUID
                })
            }).pipe(
                map((response: ShippingMethodCollection) => ShippingMethodCollection.create<ShippingMethodCollection>({ shippingMethods: response })), 
                catchError(err => logAndHandleError(err, url, OperationType.GET, cbmGUID, undefined, undefined, undefined, buyInfo.sessionId)))
            ),
            take(1)
        );
    }

    public static postPortInEligibility(xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<ApiResponse> {
        const url = '/cart/portineligibility';
        const cbmGUID = Util.generateUuid();

        return xmStore.peekChild<BuyInfo, User>('buyInfo', BuyInfo, User).pipe(
            mergeMap((buyInfo: BuyInfo) => http.post(url, params, {
                headers: buildHeaders({
                    apiChannel: ApiChannel.GATEWAY_FOR_GUID,
                    sessionID: buyInfo.sessionId,
                    'X-global-tracking-id': cbmGUID
                })
            }).pipe(
                catchError(err => logAndHandleError(err, url, OperationType.POST, cbmGUID, JSON.stringify(params), undefined, undefined, buyInfo.sessionId)))
            )
        );
    }

    public static delete(xmStore: XmStore, http: HttpClient, _options: StorePersistOptions = {}, config: IXMOptions): Observable<Cart> {
        const url = '/cart/delete';
        const cbmGUID = Util.generateUuid();

        return xmStore.peek<User>(User).pipe(
            mergeMap((storedUser: User) => http.post(url, undefined, {
                headers: buildHeaders({
                    apiChannel: ApiChannel.GATEWAY_FOR_GUID,
                    sessionID: storedUser.buyInfo.sessionId,
                    storeAction: _options.storeAction,
                    'X-global-tracking-id': cbmGUID
                }),
                params: buildParams({
                    RUPTrial: config.ENABLE_RUP_TRIAL
                })
            }).pipe(
                map(() => Cart.create<Cart>({})),
                catchError(err => logAndHandleError(err, url, OperationType.POST, cbmGUID, storedUser.buyInfo.sessionId)))
            ),
            take(1)
        );
    }

    public static addCartItem(xmStore: XmStore, http: HttpClient, item: CustomizeItem, options: StorePersistOptions, config: IXMOptions): Observable<Cart> {
        return CartApi.updateCartItem(xmStore, http, item.toApi(), options, config);
    }

    public static removeCartItem(xmStore: XmStore, http: HttpClient, item: ApiParams, _options: StorePersistOptions, config: IXMOptions): Observable<Cart> {
        const url = `/cart/items/${item.id}/delete`;
        const cbmGUID = Util.generateUuid();

        return xmStore.peekChild<BuyInfo, User>('buyInfo', BuyInfo, User).pipe(
            mergeMap((buyInfo: BuyInfo) => http.post(url, undefined, {
                headers: buildHeaders({
                    apiChannel: ApiChannel.GATEWAY_FOR_GUID,
                    sessionID: buyInfo.sessionId,
                    storeAction: _options.storeAction,
                    'X-global-tracking-id': cbmGUID
                }),
                params: buildParams({
                    validateCart: item.validateCart,
                    RUPTrial: config.ENABLE_RUP_TRIAL
                })
            }).pipe(
                mergeMap((response: Cart) => {
                    const cart: Cart = Cart.create<Cart>(response);

                    return CartApi.getTaxes(http, buyInfo, cart);
                })).pipe(
                catchError(err => logAndHandleError(err, url, OperationType.POST, cbmGUID, undefined, undefined, undefined, buyInfo.sessionId))
            )
            ),
            take(1)
        );
    }

    public static updateCartItem(xmStore: XmStore, http: HttpClient, item: object, _options: StorePersistOptions, config: IXMOptions): Observable<Cart> {
        const url = '/cart';
        const cbmGUID = Util.generateUuid();

        return xmStore.findChild<BuyInfo, User>(StoreAction.GET_BUY_INFO, 'buyInfo', BuyInfo, User).pipe(
            mergeMap((buyInfo: BuyInfo) => http.post(url, item, {
                headers: buildHeaders({
                    apiChannel: ApiChannel.GATEWAY_FOR_GUID,
                    sessionID: buyInfo.sessionId,
                    'X-global-tracking-id': cbmGUID
                }),
                params: buildParams({
                    RUPTrial: config.ENABLE_RUP_TRIAL,
                    trialFeatures: config.ENABLE_SERVICE_PROMO_TRIAL ? TrialFeatures.SERVICE_PROMO : undefined
                })
            }).pipe(
                mergeMap((response: Cart) => {
                    const cart: Cart = Cart.create<Cart>(response);

                    return CartApi.getTaxes(http, buyInfo, cart);
                })).pipe(
                catchError(err => logAndHandleError(err, url, OperationType.POST, cbmGUID, JSON.stringify(item), undefined, undefined, buyInfo.sessionId))
            )
            )
        );
    }

    public static updateShipping(_xmStore: XmStore, _http: HttpClient, shipping: ShippingMethod): Observable<ShippingMethod> {
        return of(shipping);
    }

    private static getTaxes(http: HttpClient, buyInfo: BuyInfo, cart: Cart): Observable<Cart> {
        const url = '/cart/taxes';
        const cbmGUID = Util.generateUuid();

        return http.get(url, {
            headers: buildHeaders({
                apiChannel: ApiChannel.GATEWAY_FOR_GUID,
                sessionID: buyInfo.sessionId,
                'X-global-tracking-id': cbmGUID
            })
        }).pipe(map((taxes: CalculatedTax) => {
            cart.taxes = CalculatedTax.create<CalculatedTax>(taxes);

            return cart;
        }), catchError(() => of(cart)));
    }
}

ModelBase.fetchMapping[StoreAction.GET_CART] = CartApi.get;
ModelBase.fetchMapping[StoreAction.GET_CART_COUNT] = CartApi.getCartCount;
ModelBase.persistMapping[StoreAction.UPDATE_CART] = CartApi.updateCartItem;
ModelBase.persistMapping[StoreAction.ADD_ITEM] = CartApi.addCartItem;
ModelBase.persistMapping[StoreAction.REMOVE_ITEM] = CartApi.removeCartItem;
ModelBase.deleteMapping[StoreAction.DELETE_CART] = CartApi.delete;
ModelBase.persistMapping[StoreAction.UPDATE_SHIPPING] = CartApi.updateShipping;
ModelBase.fetchMapping[StoreAction.GET_SHIPPING_METHOD] = CartApi.getShippingMethod;
ModelBase.persistMapping[StoreAction.CHECK_PORT_IN_ELIGIBILITY] = CartApi.postPortInEligibility;