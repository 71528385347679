<div class="container">
    <div id="{{ id }}-instructions" class="accessibility-hidden">When autocomplete results are available use up and down arrows or swipe gestures to navigate the list.</div>
    <label for="{{ id }}" class="accessibility-hidden">{{ ariaLabel }}</label>
    <ng-content></ng-content>
    <button class="outline" *ngIf="!disableCloseSearchButton && showOptionsDropdown" (click)="clearInput()" aria-label="Close search results" >
        <xm-media-image [imageOptions]="imageOptions"></xm-media-image>
    </button>
    <div #optionsBox class="options" [ngClass]="{ 'visible': showOptionsDropdown }">
        <ul id="{{ id }}-results" role="listbox">
            <li *ngFor="let disabledItem of disabledItems" disabled="true" role="presentation" [ngStyle]="disabledItem.styles">
                <span class="item-label" [innerHtml]="disabledItem.label | safeHtml"></span>
            </li>
            <li *ngFor="let item of items; let i = index;" role="option" id="{{ id }}-{{ i }}-option" (click)="emitSelectedOption(item)" [ngClass]="{ 'selected': selectedIndex === i}" [attr.aria-selected]="selectedIndex === i">
                <span class="item-label" [innerHtml]="item.label | safeHtml"></span>
            </li>
        </ul>
    </div>
</div>
