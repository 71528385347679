import { Component, Input } from '@angular/core';

@Component({
    selector: 'xm-notification',
    styleUrls: [ './notification.scss' ],
    templateUrl: './notification.html'
})

export class XmNotificationComponent {
    @Input() public notificationHeader: string;
    @Input() public notificationMessage: string;
    @Input() public isError: boolean;
}