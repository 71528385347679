import { ModelBase } from 'store/model-base';
import { ActivationStatus } from 'src/xm/watches/constants';

export class WatchesActivationStatus extends ModelBase {
    public static storeName: string = 'watchesActivationStatus';

    public iccId: string;
    public requestId: string;
    public status: ActivationStatus;
    public pairingError: WatchesActivationStatusError;

    public errorCode: string;

    public static create<T extends ModelBase>(data: ApiResponse): T {
        data.errorCode = data.pairingError && data.pairingError.errorCode;

        return super.create<T>(data);
    }
}
