import { Directive } from '@angular/core';
import { Modal } from 'core/services';

@Directive({
    selector: '[xmAriaFocus]',
    host: {
        '(click)': 'onClick($event)'
    }
})
export class XmAriaFocus {
    private modal: Modal;

    constructor(modal: Modal) {
        Object.assign(this, { modal });
    }

    public onClick(event: MouseEvent): void {
        this.modal.setPreviousFocusElement(<HTMLElement> event.target);
    }
}
