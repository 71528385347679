import { ModelBase } from 'store/model-base';

export class SupportCategoryDetail extends ModelBase {
    public static storeName: string = 'supportCategoryDetail';
    public static idAttribute: string = 'id';

    public id: string;
    public categoryTitle: string;
    public categoryDescription: string;
    public subCategories: SupportSubCategory[];
}
