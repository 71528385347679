import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'xm-cms-action',
    styleUrls: [ './cms-action.scss' ],
    templateUrl: './cms-action.html'
})
export class XmCmsActionComponent {
    @Input() public options: XmCmsActionOptions;
    @Output() public onClick: EventEmitter<void> = new EventEmitter<void>();

    private sanitizer: DomSanitizer;

    constructor(sanitizer: DomSanitizer) {
        Object.assign(this, { sanitizer });
    }

    public clickCallback(): void {
        this.onClick.emit();
    }

    public safeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}
