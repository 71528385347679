<section>
    <xm-modal-close *ngIf="data.close" (onClose)="close('close')"></xm-modal-close>
    <h2 mat-dialog-title>{{ data.header }}</h2>
    <div class="content">
        <div *ngFor="let info of data.content">
            <h3>{{ info.header }}</h3>
            <cms-text class="description" *ngFor="let subPoint of info.description" [originalText]="subPoint"></cms-text>
        </div>
    </div>
    <div class="buttons">
        <button *ngIf="data.primaryButton" class="xm-btn-primary-light mobile" (click)="close('primary')">{{ data.primaryButton }}</button>
        <button *ngIf="data.secondaryButton" class="xm-btn-secondary-light" (click)="close('secondary')">{{ data.secondaryButton }}</button>
        <button *ngIf="data.primaryButton" class="xm-btn-primary-light desktop" (click)="close('primary')">{{ data.primaryButton }}</button>
    </div>
</section>
