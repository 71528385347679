import { ModelBase } from 'store/model-base';
import { PlanSku } from 'core/constants';

export class PlanPrice extends ModelBase {
    public monthlyCharge: number;
    public incrementalPrice?: number;
    public monthlyTax: number;
    public oneTimeCharge: number;
    public oneTimeTax: number;
    public priceType?: PlanSku;
}
