import { ModelBase } from 'store/model-base';
import { AccountType } from 'core/constants';
import { PlanGroups } from 'store/plan/models/group/plan-groups';

export class AccountSummary extends ModelBase {
    public static storeName: string = 'accountSummary';
    public static idAttribute: string = 'id';

    public level: AccountType;
    public cycleEndDate: string;
    public cycleDaysLeft: number;
    public planGroups: PlanGroups[];
}
