<button type="button"
        class="hamburger"
        aria-label="Navigation menu"
        (click)="toggleMobileNav()"
        [ngClass]="{'close-hamburger': isMobileNavOpen}"
        [attr.aria-expanded] ="isMobileNavOpen">
    <span [ngClass]="{ 'close-hamburger': isMobileNavOpen }" class="hamburger-stripes"></span>
</button>

<div id="mobile-menu-list" class="mobile-menu-list" [ngClass]="{ 'pages-visible': isMobileNavOpen }" >
    <div class="mobile-menu-top-container" *ngIf="menuStateHistory.length">
        <button *ngIf="menuStateHistory.length" class="mobile-menu-top-back" (click)="handleBackClick()">
            <xm-media-image  [staticImagePath]="'arrow_left_white.svg'" [imageSource]="'buy_static_base_url'" [staticImageAlt]="'inner-drop-down-icon'" aria-hidden="true" size="height" class="caret-icon-back-button"></xm-media-image>
            <span>Back</span>
        </button>
        <div *ngIf="menuStateHistory.length" class="mobile-menu-top-heading">{{ mobileMenuTopHeading }}</div>
    </div>
    <div class="mobile-menu-items">
        <!-- <xm-nav-profile class="easing" (toggleMobileNav)="toggleMobileNav()"></xm-nav-profile> -->
        <div *ngFor="let menuItem of currentMenuState;" class="mobile-menu-item easing" [ngClass]="{'transition': transition}">
            <xm-nav-mobile-menu-item (click)="handleNavMenuItemClick(menuItem)" [pageData]="menuItem"></xm-nav-mobile-menu-item>
        </div>
        <div *ngIf="!menuStateHistory.length" class="account-container mobile-menu-item easing" [ngClass]="{'transition': transition}">
            <div *ngFor="let linkItem of accountLinkItems">
            <a class="account-link" (click)="toggleMobileNav()">{{linkItem.name}}</a>
            </div>
        </div>
    </div>
    
    <!--L0 topmenu -->
    <div class="business-containter">
        <xm-business-nav (toggleMobileNav)="toggleMobileNav()"></xm-business-nav>
    </div>
</div>
