export enum ActivationStatus {
    SUBMITTED = 'SUBMITTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}

export enum WatchesEligibilityCode {
    ONGOING_ACTIVATION = 'ONGOING_ACTIVATION',
    ALREADY_PAIRED = 'ALREADY_PAIRED',
    LINE_PAIR_EXISTS = 'LINE_PAIR_EXISTS',
    ACCOUNT_NOT_ACTIVE = 'ACCOUNT_NOT_ACTIVE',
    PAIRED_WITH_SAME_LINE = 'PAIRED_WITH_SAME_LINE',
    PAIRED_WITH_DIFFERENT_LINE = 'PAIRED_WITH_DIFFERENT_LINE',
    LINE_NOT_FOUND = 'LINE_NOT_FOUND',
    DEVICE_NOT_IN_DMD = 'DEVICE_NOT_IN_DMD',
    DEVICE_IN_PIB = 'DEVICE_IN_PIB',
    DEVICE_IN_LOST_STOLEN = 'DEVICE_IN_LS',
    SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG'
}

export enum WatchEligibilityTransactionStatus {
    SUBMITTED = 'SUBMITTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}

export enum AnalyticsErrorMessageTemplates {
    ACTIVATION_FAILED = 'Error|Activation|[TYPE]'
}

export enum DeviceActivationType {
    WATCH = 'Pair Watch|[ERRORCODE]'
}

export const WATCH_STATUS_RECHECK_INTERVAL: number = 5000;
