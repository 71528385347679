import { ChildModel, ModelBase } from 'store/model-base';
import { WatchEligibilityTransactionStatus } from 'src/xm/watches/constants';
import { WatchesPairEligibilityDetails } from './pair-eligibility-details';

export class WatchesPairEligibility extends ModelBase {
    public static storeName: string = 'watchesPairEligibility';

    public transactionStatus: WatchEligibilityTransactionStatus;
    public eligibility: WatchesPairEligibilityDetails;

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'eligibility',
            model: WatchesPairEligibilityDetails
        }];
    }
}
