import { ModelBase } from 'store/model-base';

export class CmsExtendedPromoBuyCollection extends ModelBase {
    public static storeName: string = 'cmsExtendedPromoBuyCollection';

    public promos: CmsExtendedPromoBuy[];

    public static create<T extends ModelBase>(initData: object): T {
        const toReturn: CmsExtendedPromoBuyCollection = <CmsExtendedPromoBuyCollection> super.create<CmsExtendedPromoBuyCollection>(initData);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }
    
    public findPromo(promoId: string): CmsExtendedPromoBuy {
        return this.promos.find((promo: CmsExtendedPromoBuy) => promoId === promo.promoId);
    }
}
