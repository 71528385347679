import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ActiveRequests } from './requests';

@Injectable()
export class ShareRequestInterceptor implements HttpInterceptor {
    private activeRequests: ActiveRequests;

    constructor(activeRequests: ActiveRequests) {
        Object.assign(this, { activeRequests });
    }

    public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
        let newRequest: HttpRequest<object> = request;

        const storeAction: string = request.headers.get('storeAction');
       
        if (storeAction) {
            newRequest = newRequest.clone({ headers: request.headers.delete('storeAction') });

            return this.activeRequests.addChainedRequest(storeAction, next.handle(newRequest), true);
        }

        if (this.activeRequests.hasActiveRequest(request.urlWithParams)) {
            return this.activeRequests.getActiveRequest(request.urlWithParams).observable;
        }

        return this.activeRequests.setActiveRequest(request.urlWithParams, next.handle(newRequest), true);
    }
}
