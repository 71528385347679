<section>
    <xm-modal-close *ngIf="data.close" (onClose)="close('close')"></xm-modal-close>
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <h3>{{ data.header }}</h3>
    <div class="promo-steps">
        <div *ngFor="let step of data.eligibilitySteps; index as index" class="step-wrapper">
            <div class="step-container">
                <xm-responsive-image class="icon" [imageOptions]="step.icon"></xm-responsive-image>
                <cms-text class="step-text" [originalText]="step.body"></cms-text>
            </div>
        </div>
    </div>
    <div class="disclaimer">{{ data.eligibilityDisclaimer }}</div>
    <div class="separator"></div>
    <div class="content">
        <div *ngFor="let info of data.content">
            <h4>{{ info.header }}</h4>
            <cms-text class="description" *ngFor="let subPoint of info.description" [originalText]="subPoint"></cms-text>
        </div>
    </div>
</section>
 

