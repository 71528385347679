<section *ngIf="displayName">
    <div *ngIf="stateActive(pageData)" class="active-state"></div>
    <div class="avatar">
        <div class="dot"></div>
        <p class="first-char">{{ displayNameFirstCharacter }}</p>
    </div>
    <div>
        <p class="name">{{ pageData.welcomeMessage }} {{ displayName }}</p>
        <xm-decorated-text class="my-account" [inputString]="pageData.linkDecoration" (click)="closeMobileNav()"></xm-decorated-text>
    </div>
</section>
