import { ModelBase } from 'store/model-base';
import { CmsNonXfinityCustomer } from './non-xfinity-customer';

export class CmsWatches extends ModelBase {
    public static storeName: string = 'cmsWatches';

    public nav: CmsWatchNav;
    public termsConditions: CmsWatchTermsConditions;
    public address: CmsAddressFields;
    public addAddress: CmsWatchAddress;
    public verifyAddress: CmsWatchVerifyAddress;
    public savedAddress: CmsWatchSavedAddress;
    public progress: CmsWatchProgress;
    public eligibility: CmsWatchEligibility;
    public getStarted: CmsWatchGetStarted;
    public welcomeBack: CmsWatchWelcomeBack;
    public errors: CmsWatchError[];
    public comingSoon: CmsWatchComingSoon;
    public secondaryUser: CmsNonXfinityCustomer;
}
