import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NegotiableItem } from 'buy/new-eligibility/partial/negotiable';

@Component({
    selector: 'xm-negotiable-item',
    styleUrls: [ './negotiable-item.scss' ],
    templateUrl: './negotiable-item.html'
})

export class XmNegotiableItemComponent implements OnInit{
    @Input() public negotiableItem: NegotiableItem;
    @Input() public isSelected: boolean;
    @Input() public mitigationData: CmsEligibilityMitigation;    
    
    @Output() public negotiableItemChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('paidFullButton') public paidFullElement: ElementRef<HTMLElement>;
    @ViewChild('financeButton') public financeElement: ElementRef<HTMLElement>;


    public isSelectedItem: boolean;
    public render: Renderer2;
    public validateCart: boolean;
    public negotiableItems: NegotiableItem[] = [];

    constructor(render: Renderer2) {
        Object.assign(this, { render });
    }

    public ngOnInit(): void {
        setTimeout(() => {

            if (!this.isSelected) {

                this.render.removeClass(this.paidFullElement.nativeElement, 'item-selected');

                this.render.addClass(this.financeElement.nativeElement, 'item-selected');

            }

        }, 1);

    }


    public selectNegotiableItem(status: boolean): void {
        if (status) {
            this.render.addClass(this.paidFullElement.nativeElement, 'item-selected');
            this.render.removeClass(this.financeElement.nativeElement, 'item-selected');
        } else {
            this.render.removeClass(this.paidFullElement.nativeElement, 'item-selected');
            this.render.addClass(this.financeElement.nativeElement, 'item-selected');
        }
        this.negotiableItemChanged.emit(status);
       
    }
}