import { ModelBase } from 'store/model-base';

// Content definition for Regressive Unlimited Pricing
export class CmsRup extends ModelBase {
    public static storeName: string = 'cmsRup';

    public unlimitedMultiLinePricing: CmsUnlimitedMultiLinePricing;
    public editCartModalDataOption: CmsEditCartModalDataOption;
    public unlimitedDataOption: CmsPlanOptionTile;
    public upgradePlanNote: UpgradePlanNote;
    public unlPricingModal: UnlPricingModalData;
    public fiveGBadge: FiveGBadge;
}
