import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'xm-status',
    styleUrls: [ './status.scss' ],
    templateUrl: './status.html'
})

export class XmStatusComponent {
    @Input() public statusMessage: string;
    @Input() public imagePath: string;
    @Input() public imageAlt: string;
    @Input() public showIcon: boolean;

    @Output() public buttonClicked = new EventEmitter<void>(); // Define an EventEmitter

    public statusMessageCta(): void {
        this.buttonClicked.emit();
    }

}