import { ModelBase } from 'store/model-base';

export class OfferDetail extends ModelBase {
    public static storeName: string = 'tradeInOffer';
    public static idAttribute: string = 'id';

    public offer: TradeInSelectedOffer;
    public id: string;
    public amount: number;
}
