<xm-modal-close *ngIf="data.close" (onClose)="close('close')"></xm-modal-close>
<div class="heading-container">
    <xm-media-image class="header-icon" [staticImagePath]="'add_protection_plan.svg'" [imageSource]="'buy_static_base_url'" [staticImageAlt]="'Add protection plan'"></xm-media-image>
    <div class="heading-text">
        <h2 mat-dialog-title>{{ data.title }}</h2>
        <cms-text class="subheading" [originalText]="data.price"></cms-text>
        <button *ngIf="data.displayAddXmcCta" class="xm-btn-primary-light" (click)="close('primary')">{{ data.primaryButton }}</button>
    </div>
</div>
<div class="data-align">
      <p [innerHtml]="data.titleBody"></p>
    <div *ngFor="let content of data.content" class="content-body">
        <xm-media-image *ngIf="content.iconOptions" class="content-icon" [staticImagePath]="'apple_care.svg'" [imageSource]="'buy_static_base_url'" [staticImageAlt]="'Apple care'"></xm-media-image>
        <h3>{{ content.title }}</h3>
        <div class="body-description">{{ content.description }}</div>
    </div>
    <p class="data-disclaimer">{{ data.disclaimer }}</p>
    <cms-text class="more-details" [originalText]="data.footnote"></cms-text>
</div>
 

