import { Injectable } from '@angular/core';
import { Resolvable, StateDeclaration, StateService, Transition, TransitionService } from '@uirouter/core';

import { BREADCRUMB_TOKEN } from 'core/constants';

@Injectable({
    providedIn: 'root'
})
export class Breadcrumbs {
    public steps: AccountBreadcrumb[] = [];

    private state: StateService;
    private transition: TransitionService;

    constructor(state: StateService, transition: TransitionService) {
        Object.assign(this, { state, transition });
    }

    public init(): void {
        this.transition.onSuccess({}, (trans: Transition) => {
            this.buildBreadcrumb(trans.to().name, trans);
        });
    }

    public updateNickname(oldNickname: string, newNickname: string): void {
        this.steps = this.steps.map((step: AccountBreadcrumb) => {
            if (step.label === oldNickname) {
                step.label = newNickname;
            }

            return step;
        });
    }

    private buildBreadcrumb(stateName: string, trans: Transition): void {
        this.steps = [];
        this.buildSingleBreadcrumb(stateName, trans);
    }

    private buildSingleBreadcrumb(stateName: string, trans: Transition): void {
        if (!stateName) {
            return;
        }

        try {
            if (this.hasBreadcrumbData(this.state.get(stateName))) {
                const step: AccountBreadcrumb = trans.injector(stateName).get(BREADCRUMB_TOKEN);
                if (step) {
                    step.stateParams = step.stateParams || {};
                    this.steps.unshift(step);
                }
            }

            this.buildSingleBreadcrumb(this.nextState(stateName), trans);
        } catch (err) {
            this.buildSingleBreadcrumb(this.nextState(stateName), trans);
        }
    }

    private nextState(stateName: string): string {
        const matches: RegExpMatchArray = stateName.match(/(.+)\..+/);
        const newState: string = matches ? matches[1] : '';

        if (!newState) {
            const parentState: string = <string> this.state.get(stateName).parent;

            return parentState || '';
        } else {
            return newState;
        }
    }

    private hasBreadcrumbData(state: StateDeclaration): boolean {
        if (state.resolve && Array.isArray(state.resolve)) {
            return state.resolve.some((resolve: Resolvable) => resolve.token === BREADCRUMB_TOKEN);
        } else {
            return false;
        }
    }
}
