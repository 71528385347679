import { ModelBase } from 'store/model-base';

export class CmsContactBar extends ModelBase {
    public static storeName: string = 'cmsContactBar';

    public header: string;
    public subheader: string;
    public chat: ContactOption;
    public call: ContactOption;
    public modal: CallbackModalData;
}
