import { ModelBase } from 'store/model-base';

export class TargetedPromotion extends ModelBase {
    public static storeName: string = 'targetedPromotion';
    public static idAttribute: string = 'promoId';

    // promo id return from API
    public promoId: string;
    // promo code that user enters, eg: thanksgiving01
    public code: string;
    public timestamp: number;
    // flag to check whether promo has been authenticated or not
    public authCheck: boolean;

    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const toReturn: TargetedPromotion = super.create<TargetedPromotion>(initData);
        toReturn.timestamp = new Date().getTime();

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }
}
