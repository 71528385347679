import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Util, XmStore, XmStoreUtil } from 'core/services';
import { CmsCore } from 'store/cms/models';
import { BreakpointWidth } from 'core/constants';


@Component({
    selector: 'xm-business-nav',
    styleUrls: ['./business-nav.scss'],
    templateUrl: './business-nav.html'
})
export class XmBusinessNavComponent implements OnInit, OnDestroy {
    @Output() public toggleMobileNav: EventEmitter<void> = new EventEmitter<void>();

    public pageData: CmsNavMenuItems[];

    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;

    constructor(xmStore: XmStore) {
        Object.assign(this, { xmStore });
    }

    public ngOnInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (result: CmsCore) => {
            this.pageData = result.nav.businessNav.items;
            this.pageData.forEach((item: CmsNavMenuItems) => {
                item.isVisible = false;
            });
        }));
    }

    public activeLink(name: string): void {
        this.pageData.forEach((item: CmsNavMenuItems) => {
            if (window.innerWidth < BreakpointWidth.SMALL_DESKTOP) {
                item.isVisible = item.name === name ? true : false;
                
                return;
            }

            item.isSelectable = item.name === name ? true : false;
        });
    }

    public closeMobileMenu(): void {
        this.toggleMobileNav.emit();
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }
}
