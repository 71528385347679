import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Accessibility } from 'services/accessibility';
import { CimaUrl, WindowReference } from 'services/index';
import { SitecoreClient } from 'services/sitecore/SitecoreClient';

@Component({
    selector: 'cbm-sign-in-modal',
    templateUrl: 'sign-in.html',
    styleUrls: ['sign-in.scss']
})

export class CbmSignInModal implements OnInit {

    public modalData: FlexibleImageBannerPageData;
    public actions: CmsBannerActions;
    public shopInternetLink: string;
    public isMobile: boolean;
    public isTablet: boolean;

    private dialogRef: MatDialogRef<CbmSignInModal>;
    private subscriptions: Subscription[] = [];
    private windowReference: WindowReference;

    constructor(accessibility: Accessibility, dialogRef: MatDialogRef<CbmSignInModal>,
        @Inject(MAT_DIALOG_DATA) modalData: FlexibleImageBannerPageData, cimaUrl: CimaUrl, sitecoreWebClient: SitecoreClient, windowReference: WindowReference) {
        Object.assign(this, { accessibility, modalData, dialogRef, cimaUrl, sitecoreWebClient, windowReference });
    }

    public ngOnInit(): void {
        this.subscriptions.push(this.windowReference.breakpoint.subscribe((breakpoint: Breakpoint) => {
            this.isMobile = breakpoint.isMobile;
            this.isTablet = breakpoint.isTablet;
        }));

        this.actions = this.modalData.actions;
        this.shopInternetLink = this.actions.links[0].link;
    }

    public close(action: string): void {
        const response: ModalResponse = {
            close: action === 'close',
            primary: action === 'primary',
            secondary: action === 'secondary'
        };
        this.dialogRef.close(response);
    }
}