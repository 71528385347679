import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ModelBase } from 'store/model-base';
import { buildHeaders, XmStore } from 'core/services';
import { ApiChannel } from 'core/constants';
import { StoreAction } from 'store/actions';
import { Injectable } from '@angular/core';

@Injectable()
export class XmbApi {
    public static submitCommercialLeadInfo(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<string> {

        return http.post('/commercialleadinfo', params, {
            responseType: 'text',
            headers: buildHeaders({
                apiChannel: ApiChannel.GATEWAY
            })
        });
    }
}

ModelBase.persistMapping[StoreAction.POST_COMMERCIAL_LEAD_INFO] = XmbApi.submitCommercialLeadInfo;
