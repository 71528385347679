import { ChildModel, ModelBase } from 'store/model-base';
import { WatchesAddress } from './address';

export class WatchesAddressValidation extends ModelBase {
    public static storeName: string = 'watchesAddressValidation';

    public enteredAddress: WatchesAddress;
    public isValid: boolean;
    public suggestedAddresses: WatchesAddress[];

    public static create<T extends ModelBase>(data: ApiResponse): T {
        if (!data.isValid) {
            data.isValid = false;
        }

        return super.create<T>(data);
    }

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'enteredAddress',
            model: WatchesAddress
        }];
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'suggestedAddresses',
            model: WatchesAddress
        }];
    }
}
