import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import {Accessibility} from 'core/services';

@Directive({
    selector: '[cbmTooltip]'
})

export class CbmTooltip {
    @Input('cbmTooltip') public tooltipText: string;
    @Input() public position: string;
    @Input() public duality: boolean; //This is set to tell the directive whether the tooltip is attached to a div that has multiple functionality or not (whether it doubles as a button)
    public accessibility: Accessibility;
    public tooltip: HTMLElement;
    public element: ElementRef;
    public render: Renderer2;
    private offset = 10; 

    constructor(element: ElementRef, render: Renderer2, accessibility: Accessibility) {
        Object.assign(this, {element, render, accessibility });
    } 


    @HostListener('mouseenter') public onMouseEnter(): void {
        if (!this.tooltip) { 
            this.show();
            this.accessibility.announceReaderText(this.tooltipText); 
        }
    }

    @HostListener('mouseleave') public onMouseLeave(): void {
        if (this.tooltip) { 
            this.hide(); 
        }
    }

    //if the tooltip is a button we want to block the tooltip click event (hovering in desktop will not be effected)
    @HostListener('click') public onClick(): void {
        if (this.tooltip && this.duality) { 
            this.hide(); 
        }
        if (!this.tooltip && !this.duality) {
            this.show();
        }
    }

    public create(): void {
        this.tooltip = this.render.createElement('span');
        this.render.appendChild(document.body, this.tooltip);

        // render the given text inside tooltip
        this.render.appendChild(
            this.tooltip,
            this.render.createText(this.tooltipText) 
        );

        this.render.addClass(this.tooltip, 'ng-tooltip');
        this.render.addClass(this.tooltip, `ng-tooltip-${this.position}`);
        this.render.setStyle(this.tooltip, '-webkit-transition', 'opacity 500ms');
        this.render.setStyle(this.tooltip, '-moz-transition', 'opacity 500ms');
        this.render.setStyle(this.tooltip, '-o-transition', 'opacity 500ms');
        this.render.setStyle(this.tooltip, 'transition', 'opacity 500ms');
    }

    public show(): void {
        this.create();
        this.setPosition();
        this.render.addClass(this.tooltip, 'ng-tooltip-show');
    }

    public hide(): void {
        this.render.removeClass(this.tooltip, 'ng-tooltip-show');
        window.setTimeout(() => {
            this.render.removeChild(document.body, this.tooltip);
            this.tooltip = undefined;
        }, 500);
    }

    public setPosition(): void {
        const hostPos: DOMRect = this.element.nativeElement.getBoundingClientRect();
        const tooltipPos: DOMRect = this.tooltip.getBoundingClientRect();

        const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        let top: number, left: number; // to modify horizontal and vertical offset

        if (this.position === 'top') {
            top = hostPos.top - tooltipPos.height - this.offset;
            left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        }

        if (this.position === 'bottom') {
            top = hostPos.bottom + this.offset;
            left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        }

        if (this.position === 'left') {
            top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
            left = hostPos.left - tooltipPos.width - this.offset;
        }

        if (this.position === 'right') {
            top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
            left = hostPos.right + this.offset;
        }

        this.render.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
        this.render.setStyle(this.tooltip, 'left', `${left}px`);
    }
}