import { StoreAction, XmAction } from 'store/actions';
import { WatchesActivationRequest, WatchesAddress, WatchesAddressValidation, WatchesPairEligibilityRequestId } from './models';

export function watchesAddressValidationReducer(state: WatchesAddressValidation, action: XmAction<WatchesAddressValidation>): WatchesAddressValidation {
    switch (action.type) {
        case StoreAction.GET_WATCH_ADDRESS_VALIDATION:
            return action.payload;
        default:
            return state;
    }
}

export function watchesAddressReducer(state: WatchesAddress, action: XmAction<WatchesAddress>): WatchesAddress {
    switch (action.type) {
        case StoreAction.PERSIST_WATCH_ADDRESS:
        case StoreAction.POST_WATCH_ADDRESS:
        case StoreAction.GET_WATCH_ADDRESS:
            return action.payload;
        default:
            return state;
    }
}

export function watchesPairEligibilityRequestIdReducer(state: WatchesPairEligibilityRequestId, action: XmAction<WatchesPairEligibilityRequestId>): WatchesPairEligibilityRequestId {
    switch (action.type) {
        case StoreAction.GET_WATCH_PAIR_ELIGIBITLITY_REQUESTID:
            return action.payload;
        default:
            return state;
    }
}

export function watchesActivationRequestReducer(state: WatchesActivationRequest, action: XmAction<WatchesActivationRequest>): WatchesActivationRequest {
    switch (action.type) {
        case StoreAction.POST_WATCH_ACTIVATION:
        case StoreAction.GET_ONGOING_ACTIVATION_REQUEST:
            return action.payload;
        default:
            return state;
    }
}
