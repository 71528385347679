import { ModelBase } from 'store/model-base';
import { CustomerType } from 'core/constants';

export class AllowedLines extends ModelBase {
    public static storeName: string = 'allowedLines';
    public static idAttribute: string = 'maxLines';

    public activeLines: number;
    public maxLines: number;
    public allowedLines: number;
    private customerType: string;
    private pendingLines: number;

    public get canAddLine(): boolean {
        return this.allowedLines > 0;
    }

    public get hasLines(): boolean {
        return Boolean(this.activeLines + this.pendingLines);
    }

    public get isNewNewCustomer(): boolean {
        return this.customerType === CustomerType.NEW_NEW_CUSTOMER;
    }
}
