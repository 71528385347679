import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Transition } from '@uirouter/core';

import { CimaCore, Cloudinary, Util, XmStore, XmStoreUtil } from 'core/services';
import { CmsCore } from 'store/cms/models';
import { CONFIG_TOKEN, TransitionHooks } from 'core/constants';
import { IXMOptions } from 'core/interfaces';

const SPANISH_PATH: string = 'es.xfinity.com/mobile/';
const ENGLISH_PATH: string = 'www.xfinity.com/mobile/';

@Component({
    selector: 'xm-footer',
    styleUrls: [ './footer.scss' ],
    templateUrl: './footer.html'
})
export class XmFooterComponent implements OnInit, OnDestroy {
    public pageData: SiteFooter;
    public spanishLink: string;
    public englishLink: string;

    private xmStore: XmStore;
    private cimaCore: CimaCore;
    private subscriptions: Subscription[] = [];
    private transitionSuccess: Observable<Transition>;
    private config: IXMOptions;
    
    constructor(xmStore: XmStore, cimaCore: CimaCore, @Inject(CONFIG_TOKEN) config: IXMOptions, @Inject(TransitionHooks.SUCCESS) transitionSuccess: Observable<Transition>) {
        Object.assign(this, { xmStore, cimaCore, config, transitionSuccess });
    }

    public ngOnInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (result: CmsCore) => {
            this.pageData = result.footer;
        }));

        this.subscriptions.push(XmStoreUtil.subscribe(this.transitionSuccess, () => {
            this.buildSpanishLinks();
        }));
        
        this.updateCopyrightYear();
    }

    public get footerLinks(): CmsSiteFooterLink[] {
        return this.pageData.businessLinks;
    }

    public get isBusinessUser(): boolean {
        return this.cimaCore && this.cimaCore.isBusinessUser;
    }

    public buildIcon(icon: CmsMediaImage): MediaImageOptions {
        return { mobile: Cloudinary.generateMediaFromCms(icon) };
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    private buildSpanishLinks(): void {
        const href: string = window.location.href;
        const rootUrlPath: string = `${window.location.host}${this.config.BASE_URL}`;

        this.spanishLink = href.replace(rootUrlPath, SPANISH_PATH);
        this.englishLink = href.replace(rootUrlPath, ENGLISH_PATH);
    }

    private updateCopyrightYear(): void {
        const currentYear: number = new Date().getFullYear();
        this.pageData.copyright = this.pageData.copyright.replace('[Year]', currentYear.toString());
    }
}
