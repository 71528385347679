import { ModelBase } from 'store/model-base';

export class CmsCart extends ModelBase {
    public static storeName: string = 'cmsCart';

    public emptyCart: CmsCartEmptyCart;
    public filledCart: CmsCartFilledCart;
    public planGrouping: CmsCartPlanGrouping;
    public tradeInModule: CmsTradeInCart;
    public estimatedStatement: CmsEstimatedStatement;
}
