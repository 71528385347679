import { ModelBase } from 'store/model-base';

export class CmsRetentionPromo extends ModelBase {
    public static storeName: string = 'cmsRetentionPromo';
    public enablePromo: boolean;
    public promotions: CmsPromotion[];

    public getRetentionPromo(skuCode: string): CmsPromotion {
        return this.enablePromo && this.promotions && this.promotions.length && this.promotions.find((promo: CmsPromotion) => promo.skuCodes.includes(skuCode));
    }
}
