import { ModelBase } from 'store/model-base';

export class WatchesAddress extends ModelBase {
    public static storeName: string = 'watchesAddress';

    public streetAddress: string;
    public city: string;
    public state: string;
    public zip: string;

    public get isValid(): boolean {
        return Boolean(this.streetAddress && this.streetAddress.trim());
    }

    public get formatted(): string {
        return `${this.streetAddress} ${this.city}, ${this.state} ${this.zip}`;
    }

    public get cityStateZip(): string {
        return `${this.city}, ${this.state} ${this.zip}`;
    }
}
