import { ModelBase } from 'store/model-base';
import { ActivationStatus, ActivationType } from 'core/constants';

export class ActivationInfo extends ModelBase {
    public static storeName: string = 'activationInfo';
    public static idAttribute: string = 'id.key';

    public id: Id;
    public iccId: string;
    public status: ActivationStatus;
    public type: ActivationType;
    public errorCode: string;
    public lineNumber: string;

    public static create<T extends ModelBase>(data: ActivationInfo): T {
        if (data.lineNumber === 'unknown') {
            data.lineNumber = '';
        }

        return super.create<T>(data);
    }

    public get isRFA(): boolean {
        return this.status === ActivationStatus.RFA;
    }
}
