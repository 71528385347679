import { StoreAction, XmAction } from 'store/actions';
import { OrderDetail } from './models';

export function orderDetailReducer(state: OrderDetail[] = [], action: XmAction<OrderDetail>): OrderDetail[] {
    let newState: OrderDetail[];
    switch (action.type) {
        case StoreAction.GET_ORDER_DETAIL:
            // delete old order if present
            newState = state.filter((item: OrderDetail) => item.id.key !== action.payload.id.key);

            return [ ...newState, action.payload ];
        case StoreAction.DELETE_ORDER_DETAIL:
            return state.filter((orderDetail: OrderDetail) => orderDetail.id.key !== action.payload.id.key);
        case StoreAction.SUBMIT_ORDER:
            return [ ...state, action.payload ];
        default:
            return state;
    }
}
