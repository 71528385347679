import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mask'
})
export class MaskPipe implements PipeTransform {

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public transform(value: string, replaceChar?: string): any {
        // eslint-disable-next-line
        if (value === undefined || value === null) {

            return value;
        }
        // Replace with the specified character
        if (replaceChar) {
            return replaceChar.repeat(value.length);
        }
        
        // Replace value with asterisks
        return '*'.repeat(value.length);
    }
}