<xm-modal-close *ngIf="data.close" (onClose)="close('close')"></xm-modal-close>
<h2 mat-dialog-title>{{ data.header }}</h2>
<div class="subheader">{{ data.subheader }}</div>
<div class="content-header" *ngIf="data.contentHeader"><span class="content-header-text">{{ data.contentHeader }}</span></div>
<div class="content">
    <div *ngFor="let info of data.content" class="tile" [ngClass]="{ 'double': data.content.length === 2, 'triple': data.content.length === 3 }">
        <xm-media-image [staticImagePath]="info.image.base" [imageSource]="'buy_static_base_url'" size="full"></xm-media-image>
        <h3>{{ info.header }}</h3>
        <cms-text class="body" [originalText]="info.body"></cms-text>
    </div>
</div>
<div class="ruler" *ngIf="data.showRuler"></div>
<div class="buttons">
    <cms-text *ngIf="data.primaryButton" class="mobile" [originalText]="data.primaryButton" (onButtonClick)="close('primary')"></cms-text>
    <cms-text *ngIf="data.secondaryButton" class="secondary" [originalText]="data.secondaryButton" (onButtonClick)="close('secondary')"></cms-text>
    <cms-text *ngIf="data.primaryButton" class="desktop" [originalText]="data.primaryButton" (onButtonClick)="close('primary')"></cms-text>
</div>
<cms-text class="footnote" [originalText]="data.footnote"></cms-text>
 

