import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { CimaToken, Cloudinary, Util, WindowReference, XmStore, XmStoreUtil } from 'core/services';
import { CmsCore } from 'store/cms/models';
import { User } from 'store/user/models';

@Component({
    selector: 'xm-nav-hamburger',
    styleUrls: [ './hamburger.scss' ],
    templateUrl: './hamburger.html'
})
export class NavHamburgerComponent implements OnInit, OnChanges, OnDestroy {
    public static DELAY_POSITION: number = 300;
    @Input() public hideLearnBuy: boolean;
    // DO NOT DELETE this input variable (triggerOnChanges) check primary.html for usage
    @Input() public triggerOnChanges: boolean;
    @Output() public isMobileNavExpand: EventEmitter<boolean> = new EventEmitter<boolean>();

    public isMobileNavOpen: boolean = false;
    public pageData: SiteNav;
    public currentMenuState: CmsNavMenuItems[];
    public menuStateHistory: CmsNavMenuItems[][] = [];
    public mobileMenuItems: CmsNavMenuItems[] = [];
    public accountLinkItems: CmsNavMenuItems[];
    public headerHistory: string[] = [];
    public mobileMenuTopHeading: string;
    public transition: boolean = false;

    private windowRef: WindowReference;
    private htmlBody: HTMLElement;
    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;
    private cimaToken: CimaToken;

    constructor(cimaToken: CimaToken, windowRef: WindowReference, xmStore: XmStore) {
        Object.assign(this, { cimaToken, windowRef, xmStore });
    }

    public ngOnInit(): void {
        this.htmlBody = window.document.getElementsByTagName('body')[0];

        this.subscriptions.push(this.windowRef.breakpoint.subscribe((breakpoint: Breakpoint) => {
            if (this.isMobileNavOpen) {
                if (!breakpoint.isMobile && !breakpoint.isTablet) {
                    this.setStyleMobileView(false);
                } else {
                    this.setStyleMobileView(true);
                }
            }
        }));

        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (result: CmsCore) => {
            this.pageData = result.nav;
            this.currentMenuState = this.pageData.mobileMenuItems;
        }));

        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<User>(User, { returnUndefined: true }), (user: User) => {
            this.accountLinkItems = this.cimaToken.isLoggedIn && Boolean(user && user.displayName) ? this.pageData.mobileMenuAccountLinksAuthenticated : this.pageData.mobileMenuAccountLinksUnauthenticated;
        }));
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public ngOnChanges(): void {
        if (this.isMobileNavOpen) {
            this.toggleMobileNav();
        }
    }

    public toggleMobileNav(): void {
        this.isMobileNavOpen = !this.isMobileNavOpen;
        this.isMobileNavExpand.emit(this.isMobileNavOpen);
        this.setStyleMobileView(this.isMobileNavOpen);
    }

    public generateCmsMediaImage(icon: CmsMediaImage): MediaImageOptions {
        if (!icon) {
            return undefined;
        }

        return { mobile: Cloudinary.generateMediaFromCms(icon) };
    }

    public generateUiParams(paramsString: string): object {
        return Util.getObjectFromQueryString(paramsString);
    }

    public handleNavMenuItemClick(clickedItem: CmsNavMenuItems): void {
        if (clickedItem.children) {
            this.transition = true;
            this.menuStateHistory.push([...this.currentMenuState]);
            this.currentMenuState = clickedItem.children;
            this.mobileMenuTopHeading = clickedItem.name;
            this.headerHistory.push(clickedItem.name);
            setTimeout(()=> this.transition = false, 0);
        } else {
            this.toggleMobileNav();
        }
    }
    
    public handleBackClick(): void {
        this.headerHistory.pop();
        this.currentMenuState = this.menuStateHistory.pop();
        this.mobileMenuTopHeading = this.headerHistory[this.headerHistory.length - 1];
    }

    private setStyleMobileView(isMobileNavOpen: boolean = false): void {
        if (isMobileNavOpen) {
            this.htmlBody.style['overflow-y'] = 'hidden';
            window.setTimeout(() => {
                this.htmlBody.style.position = 'fixed';
            }, NavHamburgerComponent.DELAY_POSITION);
        } else {
            this.htmlBody.style.removeProperty('overflow-y');
            this.htmlBody.style.removeProperty('position');
        }
    }

}
