import { ModelBase } from 'store/model-base';

export class CmsLanding extends ModelBase {
    public static storeName: string = 'cmsLanding';

    public ariaHeadingText: string;
    public freeShipping: string;
    public shopModule: CmsShopModule;
    public bSpot: CmsBanner;
    public cSpot: CmsBanner;
    public tabletInfoBanner: CmsBanner;
    public watchesInfoBanner: CmsBanner;
    public sideBySideInfoBanner: CmsSideBySideInfoBanners;
    public carousel: CmsLandingCarousel;
    public coverageChecker: CmsCoverageChecker;
    public coverageModule: CmsLandingCoverageModule;
    public resiRedirect: CmsResiRedirect;
    public contactCard: CmsContactCard;
    public landingArticles: CmsArticles;
    public disclaimer: string;
    public imageBanner: FlexibleImageBannerPageData;
    public findWhatYouNeedFast: CmsFindWhatYouNeedFast;
}
