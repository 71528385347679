import { ChildModel, ModelBase } from 'store/model-base';
import { PlanSku, PromotionType } from 'core/constants';
import { Promotion } from 'store/promotions/models/promotion';

export class PriceOption extends ModelBase {
    public id: PlanSku;
    public originalPrice: number;
    public promotionId: string;
    public promotionPrice: number;
    public promotionalType: PromotionType;
    public promotionalValue: number;
    public promotionalValueTotal: number;
    public term: number;
    public downPayment: number;
    public payOffAmount: number;
    public promotions: Promotion[];

    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const toReturn: PriceOption = super.create<PriceOption>(initData);

        toReturn.originalPrice = parseFloat(initData.originalPrice) || 0;
        toReturn.promotionPrice = parseFloat(initData.promotionPrice) || 0;
        toReturn.promotionalValue = parseFloat(initData.promotionalValue) || 0;
        toReturn.promotionalValueTotal = parseFloat(initData.promotionalValueTotal) || 0;
        toReturn.downPayment = parseFloat(initData.downPayment) || 0;

        return <T> <ModelBase> toReturn;
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'promotions',
            model: Promotion
        }];
    }

    public get isFinanced(): boolean {
        return this.id === PlanSku.FINANCED;
    }

    public get isFull(): boolean {
        return this.id === PlanSku.PAY_IN_FULL;
    }

    public get total(): number {
        return this.isSubsidyPromo ? this.promotionPrice : this.originalPrice;
    }

    public get savings(): number {
        return this.promotionalType && !this.isSubsidyPromo ? this.promotionalValue : 0;
    }

    public get type(): string {
        return this.isFinanced ? 'Pay monthly' : 'Pay in full';
    }

    public get isPrepaidPromo(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isPrepaid);
    }

    public get isDollarDiscount(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isDollarAmount);
    }

    public get isPercentPromo(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isPercentage);
    }

    public get isExtendedPromo(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isExtendedPromo || promotion.isExtendedPromoPhase2);
    }

    public get isExtendedDDAPromo(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isExtendedDDAPromo);
    }

    public get extendedPromo(): Promotion {
        return this.promotions.find((promotion: Promotion) => promotion.isExtendedPromo || promotion.isExtendedPromoPhase2);
    }

    public get extendedDDAPromo(): Promotion {
        return this.promotions.find((promotion: Promotion) => promotion.isExtendedDDAPromo);
    }

    public get tradeInPromo(): Promotion {
        return this.promotions.find((promotion: Promotion) => promotion.isTradeInPromoNewLine);
    }

    public get subsidyPromo(): Promotion {
        return this.promotions.find((promotion: Promotion) => promotion.isSubsidy);
    }

    public get isSubsidyPromo(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isSubsidy);
    }

    public get isTradeInPromo(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isTradeInPromoNewLine);
    }

    public get activePrice(): number {
        return this.originalPrice;
    }

    public get promoEligible(): boolean {
        return this.promotions.some((promotion: Promotion) => promotion.isExtendedPromo || promotion.isExtendedPromoPhase2 || promotion.isSubsidy);
    }

    public get dollarPromoPrice(): number {
        const promos: Promotion[] = this.promotions.length > 0 ? this.promotions.filter((promo: Promotion) => promo.isExtendedPromoDollar || promo.isSubsidy) : [];

        return promos.length > 0 ? Math.max(...promos.map(promo => promo.promotionPrice)) : 0;
    }

    public get highestEPPromoWithTip(): Promotion {
        const promos: Promotion[] = this.promotions.length > 0 ? this.promotions.filter((promo: Promotion) => promo.isExtendedPromo || promo.isExtendedPromoPhase2 || promo.isTradeInPromoNewLine) : [];
        
        return promos.length > 0 ? promos.reduce((prev, current) => (prev.valueTotal > current.valueTotal) ? prev : current) : undefined;
    }

    public get highestEPPromoWithTipAndDDA(): Promotion {
        const promos: Promotion[] = this.promotions.length > 0 ? this.promotions.filter((promo: Promotion) => promo.isExtendedPromo || promo.isExtendedPromoPhase2 || promo.isTradeInPromoNewLine || promo.isExtendedDDAPromo) : [];
        
        return promos.length > 0 ? promos.reduce((prev, current) => (prev.valueTotal > current.valueTotal) ? prev : current) : undefined;
    }

    public get highestEPPromoWithDDA(): Promotion {
        const promos: Promotion[] = this.promotions.length > 0 ? this.promotions.filter((promo: Promotion) => promo.isExtendedPromo || promo.isExtendedPromoPhase2 || promo.isExtendedDDAPromo) : [];
        
        return promos.length > 0 ? promos.reduce((prev, current) => (prev.valueTotal > current.valueTotal) ? prev : current) : undefined;
    }
}
