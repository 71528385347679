import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
    selector: 'xm-modal-close',
    templateUrl: 'close.html',
    styleUrls: [ 'close.scss' ]
})

export class ModalCloseButton implements OnInit {
    @Output() public onClose: EventEmitter<void> = new EventEmitter<void>();
    public imageOptions: MediaImageOptions;
    public render: Renderer2;
    public element: ElementRef;
    
    constructor(element: ElementRef) {
        Object.assign(this, {element});

    }
    public ngOnInit(): void {
        this.imageOptions = {
            mobile: {
                base: 'close_24_black.svg',
                version: '1500575503',
                icon: true
            }
        };
    }

    @HostListener('document:keydown', ['$event']) public onKeydownHandler(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.closeClick();
        }
    }

    public closeClick(): void {
        this.onClose.emit();
    }
}
