import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { StateService } from '@uirouter/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN } from 'core/constants';

@Injectable()
export class CmsErrorInterceptor implements HttpInterceptor {
    private config: IXMOptions;
    private state: StateService;

    constructor(state: StateService, @Inject(CONFIG_TOKEN) config: IXMOptions) {
        Object.assign(this, { state, config });
    }

    public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
        let newRequest: HttpRequest<object> = request;

        const handleCmsError: boolean = request.headers.has('handleCmsError');
        newRequest = newRequest.clone({ headers: request.headers.delete('handleCmsError') });

        return next.handle(newRequest).pipe(
            catchError((error: HttpErrorResponse) => {
                if (!handleCmsError && error.url.includes(this.config.CMS_URL)) {
                    // eslint-disable-next-line no-console
                    console.log('CMS error', error);
                    if (!this.config.DISABLE_DOWNTIME) {
                        this.state.go('downtime');
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('XM-OPTIONS: DISABLE_DOWNTIME is', this.config.DISABLE_DOWNTIME);
                    }

                    return EMPTY;
                }

                return throwError(error);
            })
        );
    }
}
