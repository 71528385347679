import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { iif, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { Util } from 'services/util';
import { Account, AllowedLines, BuyInfo, User } from './models';
import { StoreAction } from 'store/actions';
import { ApiChannel, TrialFeatures } from 'core/constants';
import { buildHeaders, buildParams, getCombinedTrialFeatures, XmStore } from 'core/services';
import { ModelBase } from '../model-base';
import { logAndHandleError, logAndHandleMessage } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';
import { AccountSummary } from './models/account-summary';
import { AccountDetails } from './models/account-details';
import { IXMOptions } from 'core/interfaces';


@Injectable()
export class UserApi {
    public static getAccountInfo(_xmStore: XmStore, http: HttpClient, _params: StoreFetchOptions = {}): Observable<Account> {
        const url ='/customer/account';
        const cbmGUID = Util.generateUuid();
        const headers = buildHeaders({ apiChannel: ApiChannel.GATEWAY_FOR_GUID, 'X-global-tracking-id': cbmGUID });

        return http.get(url, { headers: headers, observe: 'response' }).pipe(
            map((response: HttpResponse<Object>) => Account.create<Account>(response.body as Account), 
                catchError(err => logAndHandleError(err, url, OperationType.GET, cbmGUID))));
    }


    public static getAccountSummary(_xmStore: XmStore, http: HttpClient, _params: ApiParams): Observable<AccountSummary> {

        const url ='/account/summary';
        const cbmGUID = Util.generateUuid();
        const headers = buildHeaders({ apiChannel: ApiChannel.GATEWAY_FOR_GUID, 'X-global-tracking-id': cbmGUID });
        const params = buildParams({ checkSubscriptionStatus: false, RUPTrial: true});

        return http.get(url, { headers: headers, params: params }).pipe(
            map((response: AccountSummary) => AccountSummary.create<AccountSummary>(response as AccountSummary), 
                catchError(err => logAndHandleError(err, url, OperationType.GET, cbmGUID, undefined, headers, params)))
        );
    }
    
    public static getBuyInfo(_xmStore: XmStore, http: HttpClient, _params: ApiParams, _config: IXMOptions): Observable<BuyInfo> {

        const url ='/account/buyinfo';
        const cbmGUID = Util.generateUuid();

        const trialFeatures: string = getCombinedTrialFeatures([
            _config.ENABLE_DDA_PROMO ? TrialFeatures.DDA : undefined
        ]);

        const headers = buildHeaders({ apiChannel: ApiChannel.GATEWAY_FOR_GUID, 'X-global-tracking-id': cbmGUID });
        const params = buildParams({ runEligibility: true, trialFeatures: trialFeatures });

        return http.get(url, { headers: headers, params: params, observe: 'response' }).pipe(
            map((response: HttpResponse<Object>) => BuyInfo.create<BuyInfo>(response.body as BuyInfo), 
                catchError(err => logAndHandleError(err, url, OperationType.GET, cbmGUID)))
        );
    }
    

    public static getAllowedLines(_xmStore: XmStore, http: HttpClient): Observable<AllowedLines> {

        const url ='/account/allowedlines';


        const cbmGUID = Util.generateUuid();

        const headers = buildHeaders({ apiChannel: ApiChannel.GATEWAY_FOR_GUID, 'X-global-tracking-id': cbmGUID });
        const params = buildParams({ checkCredibility: true });

        return http.get(url, { headers: headers, params: params }).pipe(
            map((response: ApiResponse) => AllowedLines.create<AllowedLines>(response)),
            catchError(() => of(AllowedLines.create<AllowedLines>({ maxLines: 0, allowedLines: 0 })))
        );
    }

    public static updateBuyInfo(xmStore: XmStore, http: HttpClient, info: object, _config: IXMOptions): Observable<User> {

        const url ='/account/buyinfo';
        const cbmGUID = Util.generateUuid();


        return xmStore.find<User>(StoreAction.GET_BUY_INFO, User).pipe(
            mergeMap((storedUser: User) => http.post(url, info, {
                headers: buildHeaders({
                    apiChannel: ApiChannel.GATEWAY_FOR_GUID,
                    'X-global-tracking-id': cbmGUID,
                    sessionID: storedUser.buyInfo.sessionId
                })
            }).pipe(
                map((response: BuyInfo) => {
                    storedUser.buyInfo = BuyInfo.create<BuyInfo>(response);
                    const buyInfoLogDetail = {
                        sessionId: response.sessionId,
                        liabilityType: response.liabilityType,
                        messages: response.messages,
                        identityInfoProvided: response.identityInfoProvided
                    };
                    logAndHandleMessage(buyInfoLogDetail, url, OperationType.POST, 'Update buyinfo');

                    return storedUser;
                }), 
                catchError(err => logAndHandleError(err, url, OperationType.POST, cbmGUID, JSON.stringify(info), undefined, undefined, storedUser.buyInfo.sessionId)))
            )
        );
    }

    public static updateAccountInfo(xmStore: XmStore, http: HttpClient, notificationContact: NotificationContact): Observable<User> {

        const cbmGUID = Util.generateUuid();

        const headers = buildHeaders({ apiChannel: ApiChannel.GATEWAY_FOR_GUID, 'X-global-tracking-id': cbmGUID });

        return http.post('/account/settings/notification', notificationContact, { headers: headers }).pipe(
            mergeMap(() => xmStore.peek<User>(User).pipe(
                map((storedUser: User) => {
                    storedUser.account.notificationContacts = notificationContact;

                    return storedUser;
                }),
                take(1),                 
                catchError(err => logAndHandleError(err, '/account/settings/notification', OperationType.POST, cbmGUID))
            ))
        );
    }

    public static dppPayment(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<unknown> {

        const url =`/dpps/${params.deviceId}/payment`;
        const cbmGUID = Util.generateUuid();

        const headers = buildHeaders({ apiChannel: ApiChannel.GATEWAY_FOR_GUID, 'X-global-tracking-id': cbmGUID });

        return http.post(url, { amount: params.amount }, { headers: headers })
            .pipe(catchError(err => logAndHandleError(err, url, OperationType.POST, cbmGUID, JSON.stringify(params))));
    }

    public static checkAccountIdExist(xmStore: XmStore): Observable<Account> {
        // check if any account details have been fetched previously
        return xmStore.peekChild<Account, User>('account', Account, User, { returnUndefined: true }).pipe(
            // IF   account id exists just return the peeked data
            // ELSE fetch/re-fetch latest account details
            mergeMap((account: Account) => iif(() => Boolean(account?.id), of(account), xmStore.fetch<Account>(StoreAction.GET_ACCOUNT_INFO))),
            take(1)
        );
    }

    public static getAccountDetails(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<AccountDetails> {
        const acctRegApi = `/v4/identity/${params.email}`;
        
        return http.get(acctRegApi, { headers: buildHeaders({ apiChannel: ApiChannel.ACCOUNT_REGISTRATION }) }).pipe(
            map((response: AccountDetails) => AccountDetails.create<AccountDetails>(response),
                catchError(err => logAndHandleError(err, acctRegApi, OperationType.GET))));
    }

    public static checkAccountExist(xmStore: XmStore): Observable<Account> {
        // check if any account details have been fetched previously
        return xmStore.peekChild<Account, User>('account', Account, User, { returnUndefined: true }).pipe(
            // IF   account id exists just return the peeked data
            // ELSE fetch/re-fetch latest account details
            mergeMap((account: Account) => iif(() => Boolean(account?.id?.key), of(account), xmStore.fetch<Account>(StoreAction.GET_ACCOUNT_INFO))),
            take(1),
            catchError(err => logAndHandleError(err, 'account/accountidexists', OperationType.GET))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_ACCOUNT_INFO] = UserApi.getAccountInfo;
ModelBase.fetchMapping[StoreAction.GET_ACCOUNT_DETAILS] = UserApi.getAccountDetails;
ModelBase.fetchMapping[StoreAction.GET_ACCOUNT_SUMMARY] = UserApi.getAccountSummary;
ModelBase.persistMapping[StoreAction.UPDATE_ACCOUNT_INFO] = UserApi.updateAccountInfo;
ModelBase.fetchMapping[StoreAction.GET_BUY_INFO] = UserApi.getBuyInfo;
ModelBase.persistMapping[StoreAction.UPDATE_BUY_INFO] = UserApi.updateBuyInfo;
ModelBase.fetchMapping[StoreAction.GET_ALLOWED_LINES] = UserApi.getAllowedLines;
ModelBase.persistMapping[StoreAction.POST_DPP_PAYMENT] = UserApi.dppPayment;
