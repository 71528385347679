import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@uirouter/core';
import { XmStore } from './store';
import { BuyInfo, User } from 'store/user/models';
import { LocalStorage } from './storage/local';
import { CreditCheck } from 'store/credit-check/models';
import { CimaToken } from './cima/token';
import { SELECTED_OFFER, StorageToken } from 'core/constants';
import { SessionStorage } from './storage/session';

@Injectable({
    providedIn: 'root'
})
export class DownPayment {
    private _tps: string;
    private cimaToken: CimaToken;
    private xmStore: XmStore;
    private sessionStorage: SessionStorage;
    private _hasNewUpdate: boolean;
    private _isCreditEligible: boolean;

    constructor(cimaToken: CimaToken, localStorage: LocalStorage, sessionStorage: SessionStorage, xmStore: XmStore) {
        Object.assign(this, { cimaToken, localStorage, sessionStorage, xmStore });
    }

    public init(): void {
        if (this.cimaToken.isLoggedIn) {
            this.loadAuthenticatedTps();
        }

        this.xmStore.peekChild<BuyInfo, User>('buyInfo', BuyInfo, User, { returnUndefined: true }).subscribe((buyInfo: BuyInfo) => {
            if (buyInfo) {
                this.setTps(buyInfo.tps);
                this.CheckEligibility(buyInfo);
            }
        });

        this.xmStore.peek<CreditCheck>(CreditCheck, { returnUndefined: true }).subscribe((creditCheck: CreditCheck) => {
            if (creditCheck) {
                this.setTps(creditCheck.tps);
            }
        });
    }

    public loadAuthenticatedTps(): void {
        this._tps = this.sessionStorage.get(StorageToken.TPS);
    }

    public get tps(): string {
        return this._tps;
    }

    public get hasChanged(): boolean {
        return this._hasNewUpdate;
    }

    public get creditEligible(): boolean {
        return this._isCreditEligible;
    }

    public setDownPaymentChanged(hasNewUpdate: boolean): void {
        this._hasNewUpdate = hasNewUpdate;
    }

    private setTps(tps: string): void {
        this.sessionStorage.set(StorageToken.TPS, tps);
        this._tps = tps;
    }

    private CheckEligibility(buyInfo: BuyInfo): void {
        if (isNullOrUndefined(buyInfo)) {
            this._isCreditEligible = false;
        }

        if (isNullOrUndefined(buyInfo.creditCheckOffer?.selectedOffer)) {
            this._isCreditEligible = buyInfo.creditCheckOffer?.ilc?.allowedFinancedLines > 0 || buyInfo.creditCheckOffer?.blEcc?.allowedFinancedLines > 0 || buyInfo.creditCheckOffer?.plEcc?.allowedFinancedLines > 0;
        } else {
            let allowedFinanceAmount: number = 0;
            switch (buyInfo.creditCheckOffer.selectedOffer) {
                case SELECTED_OFFER.BLECC:
                    allowedFinanceAmount = buyInfo.creditCheckOffer?.blEcc?.allowedFinancedLines;
                    break;
                case SELECTED_OFFER.PLECC:
                    allowedFinanceAmount = buyInfo.creditCheckOffer?.plEcc?.allowedFinancedLines;
                    break;
                default:
                    allowedFinanceAmount = buyInfo.creditCheckOffer?.ilc?.allowedFinancedLines;
                    break;
            }
            this._isCreditEligible = allowedFinanceAmount > 0;
        }
    }
}
