import { Injector, NgModule, NgModuleFactoryLoader, SystemJsNgModuleLoader } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { UIRouter, UrlParts } from '@uirouter/core';
import { Ng2StateDeclaration, StatesModule, UIRouterModule } from '@uirouter/angular';
import { UIRouterRx } from '@uirouter/rx';
// import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import { Visualizer } from '@uirouter/visualizer';

import { XmLoginComponentModule } from 'shared/login/module';
import { XmAppComponent } from './app/app';
import { BaseComponent } from './base/base';
import { CoreModule } from 'core/module';
import { XM_ROUTES } from './routes';
import { NavModule } from 'shared/nav/module';
import { XmFooterModule } from 'shared/footer/module';
import { MARKETING_PARAMS, MARKETING_STATES, ParamType } from 'core/constants';

export let InjectorInstance: Injector;

export function handle404Error(_matchValue: boolean, _url: UrlParts, router: UIRouter): void {
    router.stateService.go('404', {}, { location: false });
}

export function configRouter(router: UIRouter, _injector: Injector, module: StatesModule): void {
    router.plugin(UIRouterRx);
    // router.plugin(Visualizer);

    router.urlService.config.type(ParamType.encoded, {
        encode: (val: string) => val && decodeURIComponent(val.toString()),
        decode: (val: string) => val && decodeURIComponent(val.toString()),
        is: (val: string) => typeof val === 'string',
        equals: (a: string, b: string) => a === b
    });

    // Below is for marketing to get internal tracking codes applied on all required routes

    const additionalParams: string = MARKETING_PARAMS.join('&');

    XM_ROUTES.forEach((state: Ng2StateDeclaration) => {
        const isMatch: boolean = MARKETING_STATES.some((stateRegex: RegExp) => stateRegex.test(state.name));
        if (isMatch) {
            // add on additional params
            state.url = state.url.includes('?') ? `${state.url}&${additionalParams}` : `${state.url}?${additionalParams}`;

            // confirm the param object exists
            state.params = state.params || {};

            // configure each param for the type encoded
            MARKETING_PARAMS.forEach((paramName: string) => {
                state.params[paramName] = { type: ParamType.encoded };
            });
        }
    });

    module.states = XM_ROUTES;

    // end marketing work
}

@NgModule({
    bootstrap: [ XmAppComponent ],
    declarations: [
        XmAppComponent,
        BaseComponent
    ],
    imports: [
        BrowserAnimationsModule,
        CoreModule,
        NavModule,
        XmLoginComponentModule,
        XmFooterModule,
        LoadingBarModule,
      
        UIRouterModule.forRoot({ states: XM_ROUTES, otherwise: handle404Error, config: configRouter })
        // StoreDevtoolsModule.instrument()
    ],
    providers: [
        {
            provide: NgModuleFactoryLoader,
            useClass: SystemJsNgModuleLoader
        }
    ]
})


export class XMModule {

    //eslint-disable-next-line
    constructor(private injector: Injector) {
        InjectorInstance = this.injector;
    }
}
