import { ModelBase } from 'store/model-base';
import { Util } from 'services/util';

export class PaymentInfo extends ModelBase {
    public static storeName: string = 'paymentInfo';
    public static idAttribute: string = 'id.key';

    public id: Id;
    public createdDate: string;
    public authCode: string; // will not be provided in case of estimated statement
    public paymentTransactionId: string;
    public ccLastFour: string;
    public ccType: string;
    public amount: number;
    public isExpired: boolean;

    public get localeCreatedDate(): string {
        return Util.dateFormat(this.createdDate, 'MMM dd');
    }
}
