import { StoreAction, XmAction } from 'store/actions';
import { PlanCollection, PlanEligibility, PlanEligibilityBTG } from './models';
import { BroadbandLabels } from './models/broadband-label';
import { InsurancePlans } from './models/insurance-plans';

export function planCollectionReducer(state: PlanCollection[] = [], action: XmAction<PlanCollection>): PlanCollection[] {
    switch (action.type) {
        case StoreAction.GET_PLAN_COLLECTION:
        case StoreAction.GET_AUTHENTICATED_PLAN_COLLECTION:
            return [ ...state, action.payload ];
        default:
            return state;
    }
}

export function planEligibilityBTGReducer(state: PlanEligibilityBTG, action: XmAction<PlanEligibilityBTG>): PlanEligibilityBTG {
    switch (action.type) {
        case StoreAction.GET_BTG_PLAN_ELIGIBILITY:
            return action.payload;
        default:
            return state;
    }
}

export function planEligibilityReducer(state: PlanEligibility, action: XmAction<PlanEligibility>): PlanEligibility {
    switch (action.type) {
        case StoreAction.GET_PLAN_ELIGIBILITY:
            return action.payload;
        default:
            return state;
    }
}

export function broadbandLabelsReducer(state: BroadbandLabels, action: XmAction<BroadbandLabels>): BroadbandLabels {
    switch (action.type) {
        case StoreAction.GET_BROADBAND_LABELS:
            return action.payload;
        default:
            return state;
    }
}

export function insurancePlansReducer(state: InsurancePlans, action: XmAction<InsurancePlans>): InsurancePlans {
    switch (action.type) {
        case StoreAction.GET_INSURANCE_PLANS:
            return action.payload;
        default:
            return state;
    }
}