import { ModelBase } from 'store/model-base';

export class HopInit extends ModelBase {
    public static storeName: string = 'hopInit';

    /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
    public billTo_firstName: string;
    public billTo_lastName: string;
    public billTo_street1: string;
    public billTo_street2: string;
    public billTo_city: string;
    public billTo_state: string;
    public billTo_postalCode: string;
    public billTo_country: string;
    public parentDomain: string;
    public requestAction: string;
    public token: string;
    public hopTransId: string;
    public comcastCustomerGuid: string;
    public customerID: string;
    public merchantReferenceCode: string;
    public srcSystem_name: string;
    public comcast_applicationChannel: string;
    public comcast_account: string;
    public billingSystem: string;
    public originatingSystem_code: string;
    public purchaseTotals_currency: string;
    public initUrl: string;
    public createTokenFlag: string;
    public orderPage_version: string;
    public orderPage_serialNumber: string;
    public orderPage_timestamp: string;
    public orderPage_signaturePublic: string;
    public orderPage_signedFields: string;
    public requestIP: string;
    /* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
}
