import { Injectable, OnDestroy } from '@angular/core';
import { LocalStorage } from './storage/local';
import { ClearTradeInOfferSource, StorageToken, TradeInProgram } from 'core/constants';
import { TradeInDetail } from 'store/trade-ins/models';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { XmStore } from './store';
import { StoreAction } from 'store/actions';
import { SessionStorage } from './storage/session';
import { CimaToken } from './cima/token';
import { TradeInFlowState } from 'buy/trade-in/models/flow-state';
import { BuyErrorReasons, LineSelectType, TRADE_IN_OFFER_EXPIRY_INTERVAL, TRADE_IN_OFFER_EXPIRY_TIME, TradeInConditionOptions } from 'buy/constants';
import { HttpError } from './http/error';
import { ProductDetails } from 'store/product/models';
import { StateObject, StateService } from '@uirouter/angular';
import { DownPayment } from './down-payment';
// import { PromoEligibleArgs } from 'store/promotions/models';
import { TargetedPromoService } from './targeted-promotions';
import { logAndHandleError } from './log/LogHelper';
import { OperationType } from './log/model/LogFields';
import { PromoEligibleArgs } from 'store/promotions/models';
import { CustomizeItem } from 'store/customize-item/models';

@Injectable({
    providedIn: 'root'
})
export class TradeIn implements OnDestroy {
    public tradeInDetailFlowState: TradeInFlowState;
    public tradeInDetail: TradeInDetail;
    private tradeInScript: HTMLScriptElement;
    private cimaToken: CimaToken;
    private localStorage: LocalStorage;
    private sessionStorage: SessionStorage;
    private isAcctLed: boolean;
    private isConditionIneligible: boolean;
    private xmStore: XmStore;
    private loginSubscription: Subscription;
    private tradeInExpiry: number;
    private storage: SessionStorage | LocalStorage;
    private state: StateService;
    private downPayment: DownPayment;
    private targetedPromos: TargetedPromoService;
    private imeiForDisplay = new BehaviorSubject<string>('');
    private imeiDataObservable = this.imeiForDisplay.asObservable();

    constructor(cimaToken: CimaToken, downPayment: DownPayment, localStorage: LocalStorage, sessionStorage: SessionStorage, state: StateService, targetedPromos: TargetedPromoService, xmStore: XmStore) {
        Object.assign(this, { cimaToken, downPayment, localStorage, sessionStorage, state, targetedPromos, xmStore });
        this.changeStorageType();
        this.tradeInScript = window.document.createElement('script');
        this.tradeInScript.type = 'tracking-data-page';
        this.tradeInScript.id = 'trade-in-estimation-details';
        window.document.body.appendChild(this.tradeInScript);
        this.loadStorageTradeInDetailsToXmStore();

        this.loginSubscription = this.cimaToken.loginChange.subscribe(() => {
            this.loadOffer();
            this.loadFlowState();
        });

    }


    public ngOnDestroy(): void {
        this.loginSubscription.unsubscribe();
        window.document.body.removeChild(this.tradeInScript);
    }

    public getImeiData(): Observable<string> {
        return this.imeiDataObservable;
    }

    public setImeiData(imei: string): void {
        return this.imeiForDisplay.next(imei);
    }
    
    public loadStorageTradeInDetailsToXmStore(): void {
        const details: TradeInDetail = this.tradeInFromStorage();
        this.tradeInDetail = TradeInDetail.create<TradeInDetail>(details);
        if (details) {
            this.storeTradeInDetails(details);
            this.loadDetails();
        } else {
            this.xmStore.persist<TradeInDetail>(StoreAction.PERSIST_CBM_TRADE_IN_DETAILS, this.tradeInDetail);
        }
    }

    public loadStorageTradeInFlowDetailsToXmStore(): void {
        const flowdetails: TradeInFlowState = this.tradeInFlowFromStorage();
        this.tradeInDetailFlowState = TradeInFlowState.create<TradeInFlowState>(flowdetails);
        if (flowdetails) {
            this.storeTradeInFlowDetails(flowdetails);
        } else {
            this.xmStore.persist<TradeInFlowState>(StoreAction.PERSIST_TRADE_IN_FLOW_DETAILS, this.tradeInDetailFlowState);
        }
    }

    public removeTradeInDetails(source?: string, customizeItem?: CustomizeItem): void {
        this.setEstimationAcceptedDetails(false, source);
        this.storage.destroy(StorageToken.TOAST_HIDDEN);
        this.storage.destroy(StorageToken.CBM_TRADE_IN_DETAILS);

        this.xmStore.delete(StoreAction.DELETE_TRADE_IN_DEVICE_DETAILS);
        this.xmStore.delete(StoreAction.DELETE_TRADE_IN_OFFER);

        this.tradeInDetail = TradeInDetail.create<TradeInDetail>({});
        this.xmStore.persist<TradeInDetail>(StoreAction.DELETE_CBM_TRADE_IN_DETAILS, this.tradeInDetail);
        if (customizeItem)
            this.xmStore.persist<CustomizeItem>(StoreAction.SAVE_CUSTOMIZE_ITEM, customizeItem);
            
        this.clearInterval();
    }

    public hideToast(): void {
        this.storage.set(StorageToken.TOAST_HIDDEN, 'true');
    }

    public keepToastHidden(): boolean {
        return this.storage.get(StorageToken.TOAST_HIDDEN);
    }

    public loadDetails(): void {
        const presentTimestamp: Date = new Date();
        if (this.tradeInDetail && this.tradeInDetail.timestamp) {
            const timeDiff: number = presentTimestamp.getTime() - this.tradeInDetail.timestamp;
            if (timeDiff <= TRADE_IN_OFFER_EXPIRY_TIME) {
                this.setTradeInExpiryInterval();
            } else {
                this.removeTradeInDetails();
            }
        }
    }

    public loadOffer(): void {
        this.tradeInDetail.offer.amount !== undefined ? this.setEstimationAcceptedDetails(true) : this.setNotEstimated();
    }

    public setNotEstimated(reason?: HttpError): void {
        const errorDetail: string = (reason && reason.errors.length > 0 && reason.errors[0].code && reason.errors[0].message) ? `${reason.errors[0].code}:${reason.errors[0].message}` : undefined;

        const estimatedDetails = {
            attributes: {
                TI: {
                    estimated: 'No',
                    errorMessage: errorDetail
                }
            }
        };

        this.tradeInScript.textContent = JSON.stringify(estimatedDetails);
    }

    public setEstimationAcceptedDetails(isAccepted?: boolean, source?: string): void {
        if (source === ClearTradeInOfferSource.ADDTOCART) {
            this.tradeInScript.textContent = JSON.stringify({});
        } else {
            const acceptedEstimatedDetails = {
                attributes: {
                    TI: {
                        accepted: ((isAccepted && this.tradeInDetail.amount) || source === ClearTradeInOfferSource.REMOVE) ? TradeInConditionOptions.YES : TradeInConditionOptions.NO,
                        estimatedAmt: this.tradeInDetail.amount,
                        phoneType: this.tradeInDetail.device.model,
                        currentCarrier: this.tradeInDetail.device.carrier,
                        estimated: this.tradeInDetail.amount !== undefined ? TradeInConditionOptions.YES : TradeInConditionOptions.NO,
                        deviceStorage: this.tradeInDetail.device.storage,
                        removed: source === ClearTradeInOfferSource.REMOVE ? TradeInConditionOptions.YES : undefined
                    }
                }
            };
            this.tradeInScript.textContent = JSON.stringify(acceptedEstimatedDetails);
        }
    }

    public storeTradeInDetails(details: TradeInDetail, isSetOffer?: boolean, isSetEstimationAcceptedDetails: boolean = false): void {
        this.tradeInDetail = TradeInDetail.create<TradeInDetail>(details);
        if (isSetOffer) {
            this.setTradeInOffer(isSetEstimationAcceptedDetails);
        }
        this.storage.set(StorageToken.CBM_TRADE_IN_DETAILS, JSON.stringify(details));
        this.xmStore.persist<TradeInDetail>(StoreAction.PERSIST_CBM_TRADE_IN_DETAILS, details);
    }

    public setTradeInOffer(isSetEstimationAcceptedDetails?: boolean): void {
        if (this.tradeInDetail.offer.id) {
            if (!this.tradeInDetail.timestamp) {
                const timestamp: Date = new Date();
                this.tradeInDetail.timestamp = timestamp.getTime();
            }
            this.setTradeInExpiryInterval();
        }

        this.setEstimationAcceptedDetails(isSetEstimationAcceptedDetails);
        this.storage.destroy(StorageToken.TOAST_HIDDEN);
    }

    public loadFlowState(): void {
        this.changeStorageType();
        this.loadStorageTradeInFlowDetailsToXmStore();
    }

    public storeTradeInFlowDetails(details: TradeInFlowState): void {
        this.xmStore.persist<TradeInFlowState>(StoreAction.PERSIST_TRADE_IN_FLOW_DETAILS, details).then((result: TradeInFlowState) => {
            this.storage.set(StorageToken.CBM_TRADE_IN_FLOW_STATE, JSON.stringify(result));
            this.tradeInDetailFlowState = TradeInFlowState.create<TradeInFlowState>(result);
        });
    }

    public toggleProgramInFlowState(): void {
        const tradeInDetailFlowState: TradeInFlowState = this.tradeInDetailFlowState;
        tradeInDetailFlowState.program = this.tradeInDetailFlowState.isTip && this.tradeInDetailFlowState.device.aeid ? TradeInProgram.ITI : TradeInProgram.TIP;
        this.xmStore.persist<TradeInFlowState>(StoreAction.PERSIST_TRADE_IN_FLOW_DETAILS, tradeInDetailFlowState).then((result: TradeInFlowState) => {
            this.storage.set(StorageToken.CBM_TRADE_IN_FLOW_STATE, JSON.stringify(result));
            this.tradeInDetailFlowState = TradeInFlowState.create<TradeInFlowState>(result);
        });
    }

    public clearFlowState(): void {
        this.storage.destroy(StorageToken.CBM_TRADE_IN_FLOW_STATE);

        this.tradeInDetailFlowState = TradeInFlowState.create<TradeInFlowState>({});
        this.xmStore.persist<TradeInFlowState>(StoreAction.DELETE_TRADE_IN_FLOW_DETAILS, this.tradeInDetailFlowState);
    }

    public getProductDetails(groupId: string = ''): Promise<void | StateObject | ProductDetails> {
        return this.xmStore.fetchPromise<ProductDetails>(StoreAction.GET_PRODUCT_DETAIL, {
            filters: { slug: this.state.params.slug, tps: this.downPayment.tps, tradeinDeviceGroupId: groupId},
            params: { slug: this.state.params.slug, tps: this.downPayment.tps, promotion: this.targetedPromos.joinedPromoIds(), tradeinDeviceGroupId: groupId }
        }).catch((error: HttpError) => {
            logAndHandleError(error, 'ProductDetails', OperationType.GET); 
            this.state.go('product-downtime', {
                reason: BuyErrorReasons.PRODUCT_DETAILS_FAILED.replace('[SLUG]', this.state.params.slug),
                statusCode: error.status
            }, { location: false });
        });
    }

    public openHowItWorksModal(lineType: string, productDetails: ProductDetails): boolean {
        const promoEligibility: PromoEligibleArgs = {
            newLine: lineType === LineSelectType.NEW
        };

        return Boolean(productDetails.getTradeInPromo(promoEligibility));
    }

    private tradeInFromStorage(): TradeInDetail {
        return this.storage.get(StorageToken.CBM_TRADE_IN_DETAILS);
    }

    private tradeInFlowFromStorage(): TradeInFlowState {
        return this.storage.get(StorageToken.CBM_TRADE_IN_FLOW_STATE);
    }

    private changeStorageType(): void {
        this.storage = this.cimaToken.isLoggedIn ? this.localStorage : this.sessionStorage;
        const lastStorage: SessionStorage | LocalStorage = !this.cimaToken.isLoggedIn ? this.localStorage : this.sessionStorage;
        const flowState: TradeInFlowState = lastStorage.get<TradeInFlowState>(StorageToken.CBM_TRADE_IN_FLOW_STATE);
        const tradeInDetail: TradeInDetail = lastStorage.get<TradeInDetail>(StorageToken.CBM_TRADE_IN_DETAILS);
        if (flowState) {
            if (lastStorage === this.sessionStorage) {
                this.storage.set(StorageToken.CBM_TRADE_IN_FLOW_STATE, JSON.stringify(flowState));
            }
            lastStorage.destroy(StorageToken.CBM_TRADE_IN_FLOW_STATE);
        }

        if (tradeInDetail) {
            if (lastStorage === this.sessionStorage) {
                this.storage.set(StorageToken.CBM_TRADE_IN_DETAILS, JSON.stringify(tradeInDetail));
            }
            lastStorage.destroy(StorageToken.CBM_TRADE_IN_DETAILS);
        }
    }

    private setTradeInExpiryInterval(): void {
        this.clearInterval();
        this.tradeInExpiry = window.setInterval(() => {
            const currentTime: Date = new Date();
            if ((currentTime.getTime() - this.tradeInDetail.timestamp) > (TRADE_IN_OFFER_EXPIRY_TIME - TRADE_IN_OFFER_EXPIRY_INTERVAL)) {
                this.removeTradeInDetails();
            }
        }, TRADE_IN_OFFER_EXPIRY_INTERVAL);
    }

    private clearInterval(): void {
        if (this.tradeInExpiry) {
            window.clearInterval(this.tradeInExpiry);
            this.tradeInExpiry = undefined;
        }
    }

    public set conditionIneligible(isConditionIneligible: boolean) {
        this.setNotEstimated();
        this.isConditionIneligible = isConditionIneligible;
    }

    public get conditionIneligible(): boolean {
        return this.isConditionIneligible;
    }

    public set accountLedFlow(isAcctLed: boolean) {
        this.isAcctLed = isAcctLed;
    }

    public get accountLedFlow(): boolean {
        return this.isAcctLed;
    }

    public get tradeInGroupId(): string {
        return this.tradeInDetailFlowState.isTradeInFlowCompleted ? this.tradeInDetailFlowState.device.groupId : '';
    }
}
