import { ModelBase } from 'store/model-base';

export class PromoEligibilityServices extends ModelBase {
    public static storeName: string = 'promoEligibilityServices';

    public portIn: boolean;
    public newNum: boolean;
    public btg: boolean;
    public unl: boolean;
    public dpp: boolean;
    public xmpp: boolean;
    public byod: boolean;

    // 12-2023: Not factored into our Buyflow yet
    public promoCodeEligible: boolean;
    public newLine: boolean;
    public ndel: boolean;
    public upgrade: boolean;
    public pif: boolean;
    public tip: boolean; // tip: Trade-in promo
}
