export enum PhoneSelectionType {
    KEEP = 'keep',
    NEW = 'new'
}

// TODO - Check with Saravana on what cbm carrier name might be
export const CBM_CARRIER_NAME: string = 'XFINITY';

export enum LineSelectType {
    NDEL = 'ndel',
    NEW = 'new-line'
}

export enum TradeInConditionOptions {
    YES = 'Yes',
    NO = 'No'
}

export enum PhoneCondition {
    PHONE_CONDITION = 'phoneCondition',
    PHONE_DAMAGE = 'phoneDamage'
}

// 2 hours
export const TARGETED_PROMOTIONS_EXPIRY_TIME: number = 7200000;

// 22 Hours
export const TRADE_IN_OFFER_EXPIRY_TIME: number = 79200000;

export const TRADE_IN_OFFER_EXPIRY_INTERVAL: number = 10000;

export enum CustomizerFormNames {
    CAPACITY = 'capacity',
    COMPANY = 'company',
    PAYMENT = 'payment',
    LINE_TYPE = 'lineType',
    NDEL = 'ndel',
    PROTECTION = 'protection',
    RATEPLAN = 'dataPlan',
    BY_THE_GIG_VARIANT = 'byTheGigVariant',
    UNLIMITED_VARIANT = 'unlimitedVariant',
    HAS_TRADE_IN = 'hasTradeIn',
    PORT = 'portType',
    PHONE = 'phone',
    CARRIER = 'carrier',
    NICKNAME = 'nickname',
    STORAGE = 'storage',
    FINANCE_OPTIONS = 'financeOptions',
    TRADE_IN_OPTIONS = 'tradeInOptions',
    ELIGIBILITY_OPTIONS = 'eligibilityOptions',
    MAKE = 'make',
    MODEL = 'model',
    IMEI = 'imei',
    ACP = 'acp'
}

export enum CompanyFormNames {
    COMPANYNAME = 'companyname',    
    EINNUMBER= 'einNumber'    
}

export enum PortFlag {
    YES = 'Y',
    NO = 'N'
}

export enum CartType {
    SALES = 'SALES',
    SIMLED_BYOD = 'SIMLED_BYOD',
    NDEL = 'NDEL',
    UPGRADE = 'UPGRADE'
}

export enum GigabyteOptions {
    ONEGB = '1 GB',
    THREEGB = '3 GB',
    TENGB = '10 GB'
}

export const SHOP_FILTER_SORTBY: string = 'sortBy';
export const SHOP_FILTER_BRAND: string = 'brand';

export const IGNORE_PREORDER_BACKORDER: string = 'BOTH';

export const DOWNPAYMENT_SUGGESTIONS_LIMIT: number = 4;

export const DEFAULT: string = 'Default';

export enum BuyErrorReasons {
    CART_PAGE_CART_OR_BUYINFO_FAILED = 'cart-page-cart-or-buyinfo-failed',
    CHECKOUT_ELIGIBILITY_CREDITCHECK_UNKNOWN = 'checkout-eligibility-creditcheck-unknown',
    CHECKOUT_ELIGIBILITY_CREDITCHECK_MISSING = 'checkout-eligibility-creditcheck-missing',
    CHECKOUT_ELIGIBILITY_BUYINFO_MISSING = 'checkout-eligibility-buyinfo-missing',
    CHECKOUT_SHIPPING_SHIPPINGMETHOD_MISSING = 'checkout-shipping-shippingmethod-missing',
    CHECKOUT_SHIPPING_SERVICEADDRESS_MISSING_FROM_ACCOUNT = 'checkout-shipping-serviceaddress-missing-from-account',
    CHECKOUT_SHIPPING_ACCOUNT_MISSING = 'checkout-shipping-account-missing',
    CHECKOUT_CONTACT_INFO_ACCOUNT_MISSING = 'checkout-contact-info-account-missing',
    CUSTOMIZER_PHONE_ACCOUNT_FAILED = 'customizer-phone-account-failed',
    CUSTOMIZER_TABLET_ACCOUNT_FAILED = 'customizer-tablet-account-failed',
    TRADE_IN_DEVICE_DETAILS_FAILED = 'trade-in-device-details-failed',
    TRADE_IN_IMEI_NOT_FOUND = 'trade-in-imei-not-found',
    CUSTOMIZER_COMBINED_ACCOUNT_MISSING = 'customizer-account-missing',
    CUSTOMIZER_LINE_ACCOUNT_MISSING = 'customizer-line-account-missing',
    CUSTOMIZER_PLAN_ACCOUNT_MISSING = 'customizer-plan-account-missing',
    CUSTOMIZER_PLAN_CANNOT_SAVE_CUSTOMIZING_ITEM = 'customizer-plan-cannot-save-customizing-item',
    CUSTOMIZER_PLAN_ELIGIBILITY_FAILED = 'customizer-plan-eligibility-failed',
    BUYINFO_FAILED = 'customizer-buyinfo-failed',
    PLANS_ELIGIBILITY_FAILED = 'plans-eligibility-failed',
    CUSTOMIZER_LINE_NO_FULFILLMENT_METHOD = 'customizer-line-no-fullfillment-method',
    BTG_PLAN_ELIGIBILITY_FAILED = 'btg-plan-eligibility-failed',
    PRODUCT_DETAILS_FAILED = '[SLUG]-product-details-failed'
}

export enum DeviceCategory {
    PHONES = 'phones',
    TABLETS = 'tablets',
    WATCHES = 'watches',
    ACCESSORIES = 'accessories'
}

export enum UserErrorType {
    NOT_USER = 'non-user',
    SECONDARY_USER = 'secondary-user',
    XMB_COMING_SOON = 'xmb-coming-soon'
}

export enum CpcResponse {
    CPC_ERROR = 'something went wrong, please try again!',
    CPC_SUCCESS = 'success',
    CPC_NO_CHANGE_MESSAGE = 'No change in the existing information found, no api call has been made.'
}

export enum Theme {
    DARK = 'dark',
    DARK_GREY = 'dark-grey',
    LIGHT = 'light',
    LIGHT_GREY = 'light-grey'
}

export enum NoProductFoundError {
    NO_PRODUCT_FOUND = 404
}
