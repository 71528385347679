<div class="step-name">
    <div>{{ activeBreadcrumbName }} {{ activeBreadcrumbIndex + 1 }}/{{ breadcrumbs.length }}</div>
</div>
<section>
    <div class="breadcrumbs">
        <div class="crumb" *ngFor="let breadcrumb of breadcrumbs" [ngClass]="{ 'active': breadcrumb.active }">
            <div class="bar">
                <div class="fill" [@isActive]="breadcrumb.active"></div>
            </div>
            <div class="name">{{ breadcrumb.displayName }}</div>
        </div>
    </div>
</section>
