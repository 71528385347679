import { Injectable } from '@angular/core';
import { StateObject, StateService } from '@uirouter/core';
import { Subscription } from 'rxjs';

import { CimaToken } from './cima/token';
import { Modal } from './modal';
import { XmStore, XmStoreUtil } from './store';

import { BuyInfo, User } from 'store/user/models';
import { CmsCore } from 'store/cms/models';
import { MBCMessageCodes } from 'core/constants';

import { logAndHandleMessage } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable({
    providedIn: 'root'
})
export class UserStatus {
    public static LOADING_DELAY: number = 750;
    public modalData: SiteModals;
    
    private subscriptions: Subscription[] = [];
    private sessionLockedModalData: DescriptiveModalData;
    private sessionExpiredModalData: DescriptiveModalData;
    private isLocked: boolean = false;

    private cimaToken: CimaToken;
    private modal: Modal;
    private state: StateService;
    private xmStore: XmStore;

    constructor(
        cimaToken: CimaToken,
        modal: Modal,
        state: StateService,
        xmStore: XmStore
    ) {
        Object.assign(this, {
            cimaToken,
            modal,
            state,
            xmStore
        });
    }

    public watch(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peekChild<BuyInfo, User>('buyInfo', BuyInfo, User, { returnUndefined: true }), (buyInfo: BuyInfo) => {
            this.userHandler(buyInfo);
        }));
    }

    public openSessionLockedModal(buyInfo: BuyInfo): void {
        this.userHandler(buyInfo);
    }

    public needsRetry(buyInfo: BuyInfo): boolean {
        return buyInfo && buyInfo.hasMessage(MBCMessageCodes.RETRY);
    }

    public isSessionLocked(buyInfo: BuyInfo): boolean {
        return buyInfo && buyInfo.hasMessage(MBCMessageCodes.SESSIONLOCKED);
    }

    public openSessionExpiryModal(): Promise<void | StateObject | ModalResponse> {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore, { returnUndefined: true }), (cmsCore: CmsCore) => {
            this.sessionExpiredModalData = cmsCore.modals.sessionExpiryModal;
        }));

        return this.modal.descriptive(this.sessionExpiredModalData).toPromise().then((response: ModalResponse) => {
            if (response.primary) {
                return this.state.go('logout');
            }
        });
    }

    private userHandler(buyInfo: BuyInfo): void {
        if (this.isLocked || !buyInfo || !this.cimaToken.isLoggedIn) {
            return;
        }

        if (this.isSessionLocked(buyInfo)) {
            this.isLocked = true;
            logAndHandleMessage({ apiresult: 'MBC-8027 session is locked' }, '/buyinfo', OperationType.GET);

            this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore, { returnUndefined: true }), (cmsCore: CmsCore) => {
                this.cmsHandler(cmsCore);
            }));
        }
    }

    private cmsHandler(cmsCore: CmsCore): void {
        this.sessionLockedModalData = cmsCore.modals.sessionLockedModal;
        setTimeout(() => {
            this.openLockedSessionModal();
        }, UserStatus.LOADING_DELAY);
    }

    private openLockedSessionModal(): void {
        this.modal.descriptive(this.sessionLockedModalData).toPromise()
            .then(() => {
                this.state.go('logout');
            });
    }
}
