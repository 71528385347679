import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keys'
})
export class KeysPipe implements PipeTransform {
    public transform(value: object): string[] {
        return value ? Object.keys(value) : [];
    }
}
