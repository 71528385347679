import { ModelBase } from 'store/model-base';

export class AuthByodEligibility extends ModelBase {
    public static storeName: string = 'authByodEligibility';

    public conditionalReasons: EligibilityReason[] = [];
    public denialReasons: EligibilityReason[] = [];
    // not using yet for future use
    // public eligibleStatus: string;
    // public byodEligible: boolean;
    // public sku: string;

    public byodReasons: EligibilityReason[];
    public isGSXDown: boolean;

    public static create<T extends ModelBase>(initData: object = {}): T {
        const toReturn: AuthByodEligibility = super.create<AuthByodEligibility>(initData);

        toReturn.byodReasons = [ ...toReturn.denialReasons, ...toReturn.conditionalReasons ];
        toReturn.isGSXDown = toReturn.byodReasons.some((reason: EligibilityReason) => reason.code === 'BYO2100' || reason.code === 'BYO2000');

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }
}
