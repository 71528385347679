import { Inject, Injectable } from '@angular/core';
import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN } from 'core/constants';

@Injectable({
    providedIn: 'root'
})
export class Fingerprint {

    public sessionId: number;
    public initialized: boolean = false;
    private fingerprintConfig: FingerPrintConfig;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions) {
        Object.assign(this, {
            fingerprintConfig: config.FINGERPRINT_CONFIG
        });
    }

    public initialize(): void {
        if (this.initialized || !this.fingerprintConfig) {
            return;
        }

        try {
            this.sessionId = this.loadScript();
            this.initialized = true;
        } catch (e) {
            this.initialized = false;
        }
    }

    private loadScript(): number {
        const script: HTMLScriptElement = document.createElement('script');
        const sessionID: number = Math.floor(Math.random() * 100) + Date.now();

        script.src = `https://${this.fingerprintConfig.dns}/fp/tags.js?org_id=${this.fingerprintConfig.orgId}&session_id=${this.fingerprintConfig.merchantId}${sessionID}`;

        document.getElementsByTagName('head')[0].appendChild(script);

        return sessionID;
    }
}
