import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
    private static TIMEOUT: number = 30000; // 30 seconds

    public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
        let newRequest: HttpRequest<object> = request;
        let timeoutDuration: number = TimeoutInterceptor.TIMEOUT;

        if (request.headers.has('timeout')) {
            timeoutDuration = parseInt(request.headers.get('timeout'));
            newRequest = newRequest.clone({ headers: request.headers.delete('timeout') }); // should not be used after this interceptor
        }

        return next.handle(newRequest).pipe(
            timeout(timeoutDuration)
        );
    }
}
