<xm-modal-close (onClose)="close('close')"></xm-modal-close>
<section>
    <div class="text-container">
        <h2 class="modal-title">{{ modalData?.text?.header }}</h2>
        <p class="modal-content">{{ modalData?.text?.description }}</p>

        <div class="actions">
            <button class="xm-btn-primary-light" (click)="close('primary')">{{ modalData?.ctaLinkText }}</button>
            <div class="button-decorated-text">
                <xm-decorated-text *ngIf="shopInternetLink" [theme]=""
                    [inputString]="shopInternetLink"></xm-decorated-text>
            </div>
        </div>
    </div>

    <div *ngIf="modalData?.images && !isMobile && !isTablet" class="product-image">
        <xm-media-image [pixelImageOptions]="modalData?.images" size="auto" [isStaticSize]="true"></xm-media-image>
    </div>

</section>