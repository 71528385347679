import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Cloudinary } from 'services/cloudinary';
import { Util } from 'services/util';

@Component({
    selector: 'xm-multi-image-modal',
    templateUrl: 'multi.html',
    styleUrls: [ 'multi.scss' ]
})

export class MultiImageModal implements OnInit {
    public data: MultiImageModalData;     

    private dialogRef: MatDialogRef<MultiImageModal>;

    constructor(dialogRef: MatDialogRef<MultiImageModal>, @Inject(MAT_DIALOG_DATA) data: MultiImageModalData) {
        Object.assign(this, { data, dialogRef });

        document.addEventListener('click', (clickEvent: MouseEvent) => {
            this.handleClick(clickEvent);
        });
    }

    public ngOnInit(): void {
        // clone the data just in case the data passed in is not copied
        this.data = Util.cloneProperties(this.data);

        if (this.data.primaryButton) {
            this.data.primaryButton = this.data.primaryButton.replace(/\[(.+?)\]/g, '[$$$$$1$$$$]');
        }

        this.data.content.forEach((content: MultiImageModalContent) => {
            content.imageOptions = {
                mobile: Cloudinary.generateMediaFromCms(content.image)
            };
        });
    }

    public close(action: string): void {
        const response: ModalResponse = {
            close: action === 'close',
            primary: action === 'primary',
            secondary: action === 'secondary'
        };

        this.dialogRef.close(response);
    }

    public handleClick(event: Event): void {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const target = event.target as HTMLTextAreaElement;

        const hasClass = target.classList.contains('cdk-overlay-backdrop-showing');

        if (hasClass) {
            this.dialogRef.close('close');
        }
    }
}
