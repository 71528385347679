import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'xm-utility-modal',
    templateUrl: 'utility.html',
    styleUrls: [ 'utility.scss' ]
})

export class UtilityModal {
    public data: UtilityModalData;

    private dialogRef: MatDialogRef<UtilityModal>;

    constructor(dialogRef: MatDialogRef<UtilityModal>, @Inject(MAT_DIALOG_DATA) data: UtilityModalData) {
        Object.assign(this, { data, dialogRef });

        document.addEventListener('click', (clickEvent: MouseEvent) => {
            this.handleClick(clickEvent);
        });
    }

    public close(action: string): void {
        const response: ModalResponse = {
            close: action === 'close',
            primary: action === 'primary',
            secondary: action === 'secondary'
        };

        this.dialogRef.close(response);
    }

    public handleClick(event: Event): void {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const target = event.target as HTMLTextAreaElement;

        const hasClass = target.classList.contains('cdk-overlay-backdrop-showing');

        if (hasClass) {
            this.dialogRef.close('close');
        }
    }
}
