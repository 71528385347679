import { Directive } from '@angular/core';

@Directive({
    selector: '[xmIgnoreGenericClickEvent]',
    host: {
        '(click)': 'onClick($event)'
    }
})
export class XmIgnoreGenericClickEvent {
    public onClick(event: Event): void {
        event.stopPropagation();
    }
}
