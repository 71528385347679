import { Inject, Injectable } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/core';

import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN } from 'core/constants';

@Injectable({
    providedIn: 'root'
})
export class DataLayer {

    private config: IXMOptions;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, uiRouter: UIRouter) {
        Object.assign(this, {
            config,
            uiRouter
        });

        window.trackingDebug = false;
    }

    public loadDataLayer(): Promise<void> {
        if (!this.config.DATA_LAYER_URL) {
            return Promise.resolve();
        }

        return new Promise((resolve: (value?: void | PromiseLike<void>) => void) => {

            if (!this.config.DISABLE_DEV_TOOLS) {
                window.trackingDebug = true;
            }

            const dataLayerScript: HTMLScriptElement = window.document.createElement('script');
            dataLayerScript.type = 'text/javascript';
            dataLayerScript.charset = 'UTF-8';
            dataLayerScript.src = this.config.DATA_LAYER_URL;
            dataLayerScript.async = true;
            dataLayerScript.onload = this.onload.bind(this, resolve);
            window.document.head.appendChild(dataLayerScript);
            resolve();
        });
    }

    public sendPageDataOnTransition(trans: Transition, detail?: TDigitalData): void {
        if (!this.canSendToDataLayer) {
            return;
        }

        document.dispatchEvent(new CustomEvent('c-tracking-log-page', {
            detail: {
                siteInfo: detail && detail.siteInfo ? detail.siteInfo : '',
                page: {
                    pageInfo: {
                        screenName: trans?.params()?.category || trans?.to().data?.screenName,
                        language: 'en'
                    },
                    category: {
                        primaryCategory: trans?.to().data?.primaryCategory,
                        subCategory1: trans?.to().data?.subCategory1,
                        subCategory2: trans?.to().data?.subCategory2 || trans?.params()?.slug || '',
                        siteType: 'sales'
                    },
                    attributes: detail && detail.page?.attributes ? detail.page?.attributes : ''
                },
                transaction: {
                    attributes: {
                        funnelType: trans?.to().data?.funnelType || undefined
                    }
                },
                users: detail && detail.users ? detail.users : '',
                cart: detail && detail.cart ? detail.cart : '',
                creditCheck: detail && detail.creditCheck ? detail.creditCheck : '',
                profileStatus: detail && detail.profileStatus ? detail.profileStatus : '',
                event: detail && detail.event ? detail.event : undefined
            },
            bubbles: true
        }));


    }

    public sendCustomEventTransition(trans: Transition, detail?: TDigitalData): void {
        if (!this.canSendToDataLayer) {
            return;
        }

        document.dispatchEvent(new CustomEvent('c-tracking-log-page', {
            detail: {
                siteInfo: detail && detail.siteInfo ? detail.siteInfo : '',
                page: {
                    pageInfo: {
                        screenName: trans?.params()?.category || trans?.to().data?.screenName,
                        language: 'en'
                    },
                    category: {
                        primaryCategory: trans?.to().data?.primaryCategory,
                        subCategory1: trans?.to().data?.subCategory1,
                        subCategory2: trans?.to().data?.subCategory2 || trans?.params()?.slug || '',
                        siteType: 'sales'
                    },
                    attributes: detail && detail.page?.attributes? detail.page?.attributes : ''
                },
                transaction: {
                    attributes: {
                        funnelType: trans?.to().data?.funnelType || undefined
                    }
                },
                users: detail && detail.users ? detail.users : '',
                cart: detail && detail.cart ? detail.cart : '',
                creditCheck: detail && detail.creditCheck ? detail.creditCheck : '',
                profileStatus: detail && detail.profileStatus ? detail.profileStatus : '',
                event: detail && detail.event ? detail.event : undefined
            },
            bubbles: true
        }));
    }

    public sendUpdate(params?: object): void {
        document.body.dispatchEvent(new CustomEvent('c-tracking-log-update', {
            bubbles: true,
            detail: params
        }));
    }

    public triggerPageStartForDataLayer(): void {
        if (!this.canSendToDataLayer) {
            return;
        }
        document.dispatchEvent(new CustomEvent<TDigitalDataEventParam>('c-tracking-log-page-start', {
            bubbles: true
        }));
    }

    private log(data: string): void {
        if (window.trackingDebug) {
            /* eslint-disable  no-console */
            console.log(data);
            /* eslint-enable  no-console */
        }
    }

    private onload(resolve: (value?: void | PromiseLike<void>) => void): void {

        window.dataLayerLoaded = true;
        this.log('Data Layer has been successfully loaded');
        resolve();
    }

    private get canSendToDataLayer(): boolean {
        return Boolean(window.dataLayerLoaded);
    }    
}