import { ModelBase } from 'store/model-base';

export class LineDevice extends ModelBase {
    public static storeName: string = 'lineDevice';
    public static idAttribute: string = 'id.key';

    public id: Id;
    public devicePaymentPlan: {
        id: Id;
    };
    public imei: string;
    public iccId: string;
    public byod: boolean;
    public orderId: Id;
}
