import { PlanVariant } from './plan';
import { Util } from 'core/services/util';
import { CENTS, PlanSku } from 'core/constants';

export class RatePlanVariant extends PlanVariant {
    public bucketId: string;
    public id: string;
    public planId: string;
    public isRegressive: boolean;
    public numberOfLines: number;
    public monthlyCost: string;

    public get gigs(): string {
        return Math.round(Number(Util.mbToGb(this.sharedData))).toString();
    }

    public get isByTheGig(): boolean {
        return this.isValid && !this.unlimitedData;
    }

    public get isUnlimited(): boolean {
        return this.isValid && this.unlimitedData;
    }

    public get isWatchData(): boolean {
        return this.isValid && this.id === PlanSku.SMART_WATCH_DATA;
    }

    public get statementDisplayPrice(): string {
        return this.isByTheGig ? 'Based on use' : `$${this.price.monthlyCharge.toFixed(CENTS).toString()}`;
    }

    public get rateMonthlyPrice(): number {
        return this.legacyPlan && !this.isUnlimited ? 0 : ((this.isRegressive && this.price.incrementalPrice) || this.price.monthlyCharge);
    }

    public get displayPlanData(): string {
        return Math.floor(parseInt(Util.mbToGb(this.sharedData))).toString();
    }

    public get displayPriceOverage(): string {
        return `$${this.overage.oneTimeCharge}`;
    }

    public get priceGrandfather(): string {
        if (this.isByTheGig) {
            return `$${Math.round(this.price.monthlyCharge)}/GB`;
        } else {
            return `$${Math.round(this.price.monthlyCharge)}/line`;
        }
    }

    public get priceNonGrand(): string {
        if (this.isByTheGig) {
            return `$${Math.round(this.price.monthlyCharge)}/mo`;
        } else {
            const perUnit: string ='/mo';

            return this.price.incrementalPrice ? `$${Math.round(this.price.incrementalPrice)}${perUnit}` : `$${Math.round(this.price.monthlyCharge)}${perUnit}`;
        }
    }

    public get tabletPriceNonGrand(): string {
        return `$${Math.round(this.price.monthlyCharge)}/mo`;
    }

    public get variantMapKey(): string {
        return `RANK_${this.rank}`;
    }

    /**
     * Will display full length price with terms.
     * Ex: `$60/line per month` for Unlimited.
     *     `$12/mo` for byTheGig.
     */
    public get fullPlanPrice(): string {
        if (this.isUnlimited) {
            const perUnit: string = this.isRegressive ? '' : 'per month';

            return `${this.legacyPlan ? this.priceGrandfather : this.priceNonGrand} ${perUnit}`;
        }

        return this.legacyPlan ? this.priceGrandfather : this.priceNonGrand;
    }
}
