import { ModelBase } from 'store/model-base';
import { XFINITY_MOBILE_CATEGORY_SLUG } from 'support/constants';

export class SupportArticle extends ModelBase {
    public static storeName: string = 'supportArticle';
    public static idAttribute: string = 'id';

    public id: string;
    public category: SupportArticleCategory;
    public docuemntId: string;
    public channel: string;
    public title: string;
    public overview: string;
    public metadata: SupportArticleMetaData;
    public relatedArticles: SupportRelatedArticle[];

    public urlAlias: string;
    public publishedDate: number;
    public categoryTitle: string;
    public categorySlug: string;
    public subCategory: string;

    public isMobileArticle: boolean;

    public content: LegacySupportArticleContent;

    public static create<T extends ModelBase>(data: ApiResponse): T {
        data.urlAlias = (data.content && data.content.metadata && data.content.metadata.urlAlias) || (data.metadata && data.metadata.urlAlias);
        data.id = data.urlAlias;
        data.title = (data.content && data.content.title) || data.title;
        data.overview = (data.content && data.content.introduction) || data.overview;
        data.category = (data.content && data.content.category) || data.category;
        data.categoryTitle = data.category && data.category.title;
        data.categorySlug = data.category && data.category.slug;
        data.subCategory = (data.content && data.content.channel) || data.channel;
        data.relatedArticles = (data.content && data.content.relatedArticles) || data.relatedArticles || [];
        data.isMobileArticle = data.categorySlug === XFINITY_MOBILE_CATEGORY_SLUG;

        // The published date from api is in milliseconds since epoch
        data.publishedDate = (data.content && data.content.metadata.publishedDate) || (data.metadata && data.metadata.lastModifiedDate) || '';

        return super.create<T>(data);
    }
}
