import { ModelBase } from 'store/model-base';

export class CmsLandingV2 extends ModelBase {
    public static storeName: string = 'cmsLandingV2';

    public landingTouts: CmsTouts;
    public coverageChecker: CmsCoverageChecker;
    public faqs: CmsLandingFaqs;
    public landingPlanFeatures: CmsLandingPlanFeatures;
    public authenticIdVerificationInProgress: CmsAuthenticIdVerificationInProgress;
    public marketingImageBanner: FlexibleImageBannerPageData;
}
