<label [for]="labelFor" [ngClass]="{ 'no-padding': option.noLabelPadding }" class="{{labelClass}}">
    <input [id]="labelFor" type="radio" />
    <ng-content></ng-content>
    <div class="label-wrapper" *ngIf="option.label || option.overLabel || option.subLabel">
        <span class="main-label"
            *ngIf="option.label"
            [ngClass]="{ 'adjust-to-sublabel': option.subLabel === '', 'small-main-label': smallMainLabel, 'emphasis': option.overLabel, 'compatibility-checker-label': option.compatibilityCheckerLabel }"
            [innerHtml]="option.label">
        </span>
        <span class="over sub-label" *ngIf="option.overLabel">
            <span class="accessibility-hidden">Previously </span>
            <span class="strike-text" [innerHtml]="option.overLabel"></span>
            <span class="accessibility-hidden">Now </span>
        </span>
        <div class="sub-label" *ngIf="option.subLabel" [innerHtml]="option.subLabel"></div>
    </div>
</label>
