/**
 * An enum for all abtests
 */
export enum AbTestType {
    SLAPSTICK = 'slapstick',
    CAROUSEL_TEST = 'CAROUSEL_TEST',
    DIGITAL_2357_PLAN_CAROUSEL = 'DIGITAL_2357_PLAN_CAROUSEL',
    DIGITAL_3064_SHOP_STRIKEPRICE = 'DIGITAL_3064_SHOP_STRIKEPRICE',
    DIGITAL_3375_PDP_CUSTOMIZER_BANNER = 'DIGITAL_3375_PDP_CUSTOMIZER_BANNER',
    DIGITAL_3519_PRODUCT_CARD = 'DIGITAL_3519_PRODUCT_CARD',
    DIGITAL_4804_EXISTING_PAYMENT_CARD = 'DIGITAL_4804_EXISTING_PAYMENT_CARD',
    DIGITAL_5188_PDP_CTA_CONTENT = 'DIGITAL_5188_PDP_CTA_CONTENT',
    DIGITAL_5450_CHECKOUT_BUTTON_COLOR = 'DIGITAL_5450_CHECKOUT_BUTTON_COLOR',
    DIGITAL_5846_SHOP_QUICK_SEARCH = 'DIGITAL_5846_SHOP_QUICK_SEARCH'
}

/**
 * AB Test Versions
 */
export enum AbTestVersion {
    DEFAULT = 'DEFAULT',
    A = 'A',
    B = 'B',
    C = 'C'
}
