import { ChildModel, ModelBase } from 'store/model-base';
import { TradeInDeviceDetail } from './device-detail';
import { OfferDetail } from './offer-detail';
import { DeviceBrand, TradeInProgram } from 'core/constants';

export class TradeInDetail extends ModelBase {
    public static storeName: string = 'tradeInDetail';
    public static idAttribute: string = 'id';

    public offer: OfferDetail;
    public device: TradeInDeviceDetail;
    public timestamp: number;

    // Added for TIPS where it can be (ITI or TIP) added enum in constants file
    public program: string;

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'offer',
            model: OfferDetail
        }, {
            attrName: 'device',
            model: TradeInDeviceDetail
        }];
    }

    public static create<TradeInDetail extends ModelBase>(initData: TransformedData): TradeInDetail {
        return super.create<TradeInDetail>(initData);
    }

    public get hasOfferAndAEID(): boolean {
        return Boolean(this.offer?.amount && this.device?.aeid);
    }

    public get isApple(): boolean {
        return this.device.make?.toLowerCase() === DeviceBrand.APPLE;
    }

    public get amount(): number {
        return this.offer?.amount;
    }

    public get exists(): boolean {
        return Boolean(this.offer?.id);
    }

    public get hasDeviceDetail(): boolean {
        return Boolean(this.device.make && this.device.model);
    }

    public get hasFullOffer(): boolean {
        return this.exists && this.hasDeviceDetail;
    }

    public get shouldFetchDeviceDetails(): boolean {
        return this.device?.imei && !this.hasDeviceDetail;
    }

    public get isTip(): boolean {
        return this.program === TradeInProgram.TIP;
    }

    public get isEarlyUpgrade(): boolean {
        return this.program === TradeInProgram.EARLY_UPGRADE;
    }

    public get isIti(): boolean {
        return this.program === TradeInProgram.ITI;
    }
}
