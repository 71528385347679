import { ModelBase } from 'store/model-base';

export class CmsOrderConfirmation extends ModelBase {
    public static storeName: string = 'cmsOrderConfirmation';

    public welcome: CmsConfirmationWelcome;
    public field: CmsConfirmationField;
    public simLedStates: CmsSimLedStates;
    public socialShare: CmsConfirmationSocial;
    public keepShopping: CmsKeepShopping;
}
