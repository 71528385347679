import { StoreAction, XmAction } from 'store/actions';
import { CreditCheck } from './models';

export function creditCheckReducer(state: CreditCheck, action: XmAction<CreditCheck>): CreditCheck {
    switch (action.type) {
        case StoreAction.GET_CREDIT_CHECK:
            return action.payload;
        default:
            return state;
    }
}
