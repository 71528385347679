import { Component, Input, OnInit } from '@angular/core';
import { ACCESSORY_ADD_CART_LIMIT } from 'core/constants';

@Component({
    selector: 'xm-quantity-control',
    styleUrls: [ './quantity.scss' ],
    templateUrl: './quantity.html'
})
export class XmQuantityControlComponent implements OnInit {
    @Input() public quantity: number;
    public minusImage: MediaImageOptions;
    public plusImage: MediaImageOptions;

    public ngOnInit(): void {
        this.minusImage = {
            mobile: {
                base: 'circle-minus_32_cool-grey11.svg',
                version: '1518014849',
                alt: 'minus-icon',
                icon: true
            }
        };

        this.plusImage = {
            mobile: {
                base: 'circle-plus_32_cool-grey11.svg',
                version: '1518014849',
                alt: 'plus-icon',
                icon: true
            }
        };
    }

    public get minQuantity(): boolean {
        return this.quantity === 1;
    }

    public get maxQuantity(): boolean {
        return this.quantity === ACCESSORY_ADD_CART_LIMIT;
    }

    public increaseQuantity(): void {
        if (this.quantity < ACCESSORY_ADD_CART_LIMIT) {
            this.quantity++;
        }
    }

    public decreaseQuantity(): void {
        if (this.quantity > 1) {
            this.quantity--;
        }
    }
}
