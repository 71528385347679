import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Util, WindowReference, XmStore } from 'core/services';

@Component({
    selector: 'xm-nav-logo',
    styleUrls: [ './logo.scss' ],
    templateUrl: './logo.html'
})
export class NavLogoComponent implements OnDestroy {
    @Input() public checkoutNavLogo: boolean = false;
    @Output() public closeHamburgerNavFromLogo: EventEmitter<void> = new EventEmitter<void>();
    public imageOptions: MediaImageOptions;
    private subscriptions: Subscription[] = [];

    constructor(xmStore: XmStore, windowRef: WindowReference) {
        Object.assign(this, { xmStore, windowRef });
    }

    public closeNav(): void {
        this.closeHamburgerNavFromLogo.emit();
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }
}
