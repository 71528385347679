import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StateService } from '@uirouter/core';
import { IsSessionExpired } from 'core/services';

@Component({
    selector: 'xm-descriptive-modal',
    templateUrl: 'descriptive.html',
    styleUrls: [ 'descriptive.scss' ]
})

export class DescriptiveModal implements OnInit {
    public data: DescriptiveModalData;

    private dialogRef: MatDialogRef<DescriptiveModal>;
    private state: StateService;

    constructor(dialogRef: MatDialogRef<DescriptiveModal>, @Inject(MAT_DIALOG_DATA) data: DescriptiveModalData, state: StateService) {
        Object.assign(this, { data, dialogRef, state });

        document.addEventListener('click', (clickEvent: MouseEvent) => {
            this.handleClick(clickEvent);
        });
    }
    
    public ngOnInit(): void {
        this.updateCopyrightYear();
    }

    public close(action: string): void {
        const response: ModalResponse = {
            close: action === 'close',
            primary: action === 'primary',
            secondary: action === 'secondary'
        };

        this.dialogRef.close(response);
    }

    public handleClick(event: Event): void {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const target = event.target as HTMLTextAreaElement;

        const hasClass = target.classList.contains('cdk-overlay-backdrop-showing');

        if (hasClass) { //close modal on outside click
            this.dialogRef.close('close');
        }

        //session expiry redirection
        if (IsSessionExpired.getSessionExpiredValue() && hasClass) {
            this.dialogRef.close('close');
            this.state.go('logout');
        }

    }

    private updateCopyrightYear(): void {
        const currentYear = new Date().getFullYear().toString();
        const key = 'length';
        if (this.data.content[key] > 0) {
            const index = this.data.content[key] - 1;
            const description = this.data.content[index].description;
            description.forEach((desc, descIndex) => {
                description[descIndex] = desc ? desc.replace(/YEAR/g, currentYear) : '';
            });
            this.data.content[index].description = description;
        }
    }
}
