import { Injectable, NgZone } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AdobeReporter {
    private ngZone: NgZone;

    constructor(ngZone: NgZone) {
        Object.assign(this, { ngZone });
    }

    public trackTargetEvent(mbox: string, params: object): void {
        this.executeAdobe(window.adobe.target.trackEvent, { mbox, params });
    }

    public trackSatelliteEvent(pageName: string, options?: TDigitalEventInfo): void {
        this.executeAdobe(window._satellite.track, pageName, options);
    }

    public pageBottom(): void {
        this.executeAdobe(window._satellite.pageBottom);
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    private executeAdobe(fn: Function, ...args: any[]): void {
        try {
            this.ngZone.runOutsideAngular(() => {
                fn(...args);
            });
        } catch (error) {
            /* eslint-disable no-console */
            console.error(error);
            /* eslint-enable no-console */
        }
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
}
