import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Decorator, Modal, SessionStorage, Util, XmStore, XmStoreUtil } from 'core/services';
import { CmsCore } from 'store/cms/models';
import { StateService, Transition } from '@uirouter/core';
import { CAMPAIGN_ID, TransitionHooks } from 'core/constants';

@Component({
    selector: 'xm-utility-banner',
    styleUrls: [ './utility-banner.scss' ],
    templateUrl: './utility-banner.html'
})
export class XmUtilityBanner implements OnInit, OnDestroy {
    public pageData: CmsUtilityPromoBanner;
    public showPromo: boolean = false;

    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;
    private modal: Modal;
    private sessionStorage: SessionStorage;
    private transitionSuccess: Observable<Transition>;
    private state: StateService;

    constructor(modal: Modal, sessionStorage: SessionStorage, state: StateService, xmStore: XmStore, @Optional()@Inject(TransitionHooks.SUCCESS) transitionSuccess: Observable<Transition>) {
        Object.assign(this, { modal, sessionStorage, state, xmStore, transitionSuccess });
    }

    public ngOnInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (response: CmsCore) => {
            if (response.utilityPromo && response.utilityPromo.enablePromo) {
                this.pageData = response.utilityPromo;

                // Get Campagin Id's from url and check if that campaign id is eligible
                const urlCampaignId: string = this.state.params.INTCMP || this.state.params.CMP;
                const matchedCampaignId: string = this.pageData.campaignIds.find((id: string) => id === urlCampaignId);

                if (matchedCampaignId) {
                    this.sessionStorage.set(CAMPAIGN_ID, matchedCampaignId);
                }

                const storedCampaignId: string = this.sessionStorage.get(CAMPAIGN_ID);

                if (this.transitionSuccess) {
                    this.subscriptions.push(XmStoreUtil.subscribe(this.transitionSuccess, () => {
                        this.showPromo = this.eligibleForPromo(storedCampaignId);
                    }));
                } else {
                    this.showPromo = this.eligibleForPromo(storedCampaignId);
                }
            }
        }));
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public viewModal(): void {
        this.modal.utility(this.pageData.pricingInfoModal);
    }

    private eligibleForPromo(campaignId: string): boolean {
        return !this.pageData.blacklistedPages.some((url: string) => this.matchCurrentUrl(url)) && campaignId && this.pageData.campaignIds.includes(campaignId);
    }

    private matchCurrentUrl(fullUrl: string): boolean {
        const urlToProcess: URL = new URL(fullUrl);
        const pathWithoutBase: string = urlToProcess.pathname.match(Decorator.XM_PATH)[1];

        return window.location.pathname.includes(pathWithoutBase);
    }
}
