import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HttpStatusCode } from '@angular/common/http';
import { UIRouter } from '@uirouter/core';
import { MARKETING_PARAMS } from 'core/constants';
import { Util } from './util';

@Injectable({
    providedIn: 'root'
})
export class Seo {
    private canonicalTag: HTMLElement;
    private meta: Meta;
    private title: Title;
    private router: UIRouter;

    private defaults: object = {
        title: 'Comcast Business Mobile | Wireless for Small Businesses',
        description: 'Comcast Business Mobile is designed for business with flexible data options, nationwide 5G coverage, and Unlimited Data for $30 per line, per month.',
        robots: 'index, follow',
        og: {
            type: 'website',
            site_name: 'Comcast Business Mobile',
            title: 'Comcast Business Mobile | Wireless for Small Businesses',
            description: 'Comcast Business Mobile is designed for business with flexible data options, nationwide 5G coverage, and Unlimited Data for $30 per line, per month.',    
            image: 'https://cbm-buy-static-prod.digital.business.comcast.com/images/cbm-logo-default.png',
            url: 'https://business.comcast.com/learn/mobile'
        },
        twitter: {
            card: 'summary',
            site: '@comcastbusiness',
            image: 'https://cbm-buy-static-prod.digital.business.comcast.com/images/cbm-logo-default.png',
            title: 'Comcast Business Mobile | Wireless for Small Businesses',
            description: 'Comcast Business Mobile is designed for business with flexible data options, nationwide 5G coverage, and Unlimited Data for $30 per line, per month.',    
            url: 'https://business.comcast.com/learn/mobile'
        },
        canonical: 'https://business.comcast.com/learn/mobile'
    };

    constructor(meta: Meta, title: Title, router: UIRouter) {
        Object.assign(this, { meta, title, router });
    }

    /**
     * Using Prerender.io as our static html provider, we need to set a meta tag to inform when a
     * route cannot be found by our router. This will enable Prerender to send a real 404 status code
     * to web crawlers.
     */
    public addPrerender404Tag(): void {
        this.addPrerenderTag(HttpStatusCode.NotFound);
    }

    /**
     * Using Prerender.io as our static html provider, we need to set a meta tag to inform when a
     * route has been permanently redirected. This will enable Prerender to send a real 301 status code
     * and the new location to web crawlers.
     */
    public addPrerender301Tag(newLocation: string): void {
        this.meta.addTag({ name: 'prerender-header', content: `Location: ${newLocation}` });
        this.addPrerenderTag(HttpStatusCode.MovedPermanently);
    }

    /**
     * Using Prerender.io as our static html provider, we need to set a meta tag to inform when a
     * route has been permanently redirected. This will enable Prerender to send a real XXX status code.
     */
    public addPrerenderTag(statusCode: string | number): void {
        this.meta.addTag({ name: 'prerender-status-code', content: statusCode.toString() });
    }

    /**
     * This removes the Prerender status code tag
     */
    public removePrerenderTag(): void {
        this.meta.removeTag('name=prerender-status-code');
    }

    /**
     * Merges the default SEO Tags with any overrides. This likely only gets called once for each
     * successful navigation.
     */
    public updateMetaTags(metaData: MetaDataDefault = {}): void {
        const { canonicalAllow, canonicalBlock, ...metaTags }: {
            canonicalAllow?: string[];
            canonicalBlock?: string[];
        } = metaData;

        const url: string = location.href.substr(0, location.href.length);
        const computedTags: object = {
            url,
            canonical: this.generateCanonical(canonicalAllow, canonicalBlock) || url,
            og: {
                url
            },
            twitter: {
                url
            }
        };

        this.processMetaTags(Util.mergeDeep(this.defaults, computedTags, metaTags));
    }

    /**
     * By default it allows all params to be used in the canonical except Marketing Params
     *
     * Using the canonicalAllow/canonicalBlock you can control the params
     */
    private generateCanonical(canonicalAllow: string[] = [], canonicalBlock: string[] = []): string {
        // marketing params do not make a page unique and therefore should always be excluded from the canonical url
        const alwaysBlock: string[] = MARKETING_PARAMS;
        const currentParams: object = this.router.globals.params;

        const normalizedParams: UrlQueries = canonicalAllow.length ?
            canonicalAllow
                .filter((paramKey: string) => !alwaysBlock.includes(paramKey)) // exclude keys that should ALWAYS be blocked, even if 'canonicalAllow' asked for it
                .reduce((newParams: { [key: string]: string }, paramKey: string) => { // return new object with allowed key/value router params
                    newParams[paramKey] = currentParams[paramKey];

                    return newParams;
                }, {})
            : Object.entries(currentParams)
                .filter(([ paramKey, _ ]: [ string, unknown ]) => ![ ...canonicalBlock, ...alwaysBlock ].includes(paramKey)) // exclude keys that are blocked
                .reduce((newParams: { [key: string]: string }, [ paramKey, value ]: [ string, string ]) => { // return new object with allowed key/value router params
                    newParams[paramKey] = value;

                    return newParams;
                }, {});

        // return absolute URL to be set as 'canonical' meta tag
        return this.router.stateService.href(this.router.globals.current.name, normalizedParams, { absolute: true, inherit: false });
    }

    private processMetaTags(data: object, previousKey: string = ''): void {
        if (!data) {
            return;
        }

        const metaData: object = data[previousKey] || data; // Try to access the inner object before the outer object
        Object.keys(metaData).forEach((key: string) => {
            const type: string = typeof metaData[key];
            if (type === 'string') {
                const tagName: string = previousKey.length ? `${previousKey}:${key}` : key;
                if (previousKey === '' && key === 'title') {
                    // create an element to get HTML Entities to load correctly
                    let ele: HTMLElement = window.document.createElement('span');
                    ele.innerHTML = metaData[key];
                    this.title.setTitle(ele.innerText);
                    if (ele.remove) {
                        ele.remove();
                    } else {
                        ele = undefined;
                    }
                } else if (previousKey === 'og') {
                    this.meta.updateTag({ property: tagName, content: metaData[key] });
                } else if (key === 'canonical') {
                    if (!this.canonicalTag) {
                        this.canonicalTag = window.document.createElement('link');
                        this.canonicalTag.setAttribute('rel', 'canonical');

                        window.document.head.appendChild(this.canonicalTag);
                    }

                    this.canonicalTag.setAttribute('href', metaData[key]);
                } else {
                    this.meta.updateTag({ name: tagName, content: metaData[key] });
                }
            } else if (type === 'object') {
                this.processMetaTags(metaData[key], key);
            }
        });
    }
}
