import { Plan } from 'store/plan/models/option/plan';
import { ChildModel } from 'store/model-base';
import { PaymentPlanVariant } from '../variant/payment';

export class PaymentPlan extends Plan {
    public variants: PaymentPlanVariant[];

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'variants',
            model: PaymentPlanVariant
        }];
    }
}
