import { ModelBase } from 'store/model-base';

export class TradeInDeviceDetail extends ModelBase {
    public static storeName: string = 'tradeInDeviceDetails';
    public static idAttribute: string = 'aeid';

    public make: string;
    public model: string;
    public storage: string;
    public carrier: string;
    public aeid: string;

    // New field added for TIPS
    public xmSku: string; //Comcast assigned model ID
    public groupId: string; //Assurant assigned model ID
    public price: number;
    public status: string;
    public productCode: string;

    // for cart response
    public imei: string;

    public static create<T extends ModelBase>(data: ApiResponse): T {
        data.carrier = data.carrier?.toUpperCase();

        return super.create<T>(data);
    }
}
