export class DigitalEvent {
    public eventInfo: TDigitalEventInfo;

    constructor(eventName: string, eventAction: string, eventPage: string, custom?: object, eventCategory?: string) {
        this.eventInfo = {
            custom: undefined,
            eventAction: '',
            eventName: '',
            eventPage: '',
            eventCategory: undefined
        };

        Object.assign(this.eventInfo, {
            custom,
            eventAction: this.removeExtraSpaces(eventAction),
            eventName,
            eventPage,
            eventCategory
        });
    }

    public toData(): TDigitalEvent {
        return {
            eventInfo: this.eventInfo
        };
    }

    public toLaunchData(): TDigitalEventInfo {
        return this.eventInfo;
    }

    private removeExtraSpaces(action: string): string {
        let removed: string = action.replace(/[\n\r]+/g, '');
        removed = removed.replace(/\s{2,10}/g, ' ');

        return removed.trim();
    }
}
