import { ChildModel, ModelBase } from 'store/model-base';
import { DataPlanBTG } from './data-plan-btg';

export class PlanEligibilityBTG extends ModelBase {
    public static storeName: string = 'planBTGEligibility';

    public plans: DataPlanBTG[];
    public accountEligibilityStatus: string;
    public isBucketUpgradeRequired: boolean;
    public isChangePlanPending: boolean;
    public currentSharedPlanId: string;
 
    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'plans',
            model: DataPlanBTG
        }];
    }
}