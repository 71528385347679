import { StoreAction, XmAction } from 'store/actions';
import { LineEligibility, NdelEligibility } from './models';

export function ndelEligibilityReducer(state: NdelEligibility, action: XmAction<NdelEligibility>): NdelEligibility {
    switch (action.type) {
        case StoreAction.GET_NDEL_ELIGIBILITY:
            return action.payload;
        case StoreAction.CLEAR_NDEL_ELIGIBILITY:
            return undefined;
        default:
            return state;
    }
}

export function lineEligibilityReducer(state: LineEligibility[] = [], action: XmAction<LineEligibility>): LineEligibility[] {
    switch (action.type) {
        case StoreAction.GET_LINE_NDEL_ELIGIBILITY:
            return [ ...state, action.payload ];
        case StoreAction.CLEAR_LINE_NDEL_ELIGIBILITY:
            return undefined;
        default:
            return state;
    }
}
