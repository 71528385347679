import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalStorage } from './storage/local';
import { StorageToken } from 'core/constants';
import { XmStore, XmStoreUtil } from './store';
import { StoreAction } from 'store/actions';
import { TARGETED_PROMOTIONS_EXPIRY_TIME } from 'buy/constants';
import { TargetedPromotion } from 'store/targeted-promotions/models';
import { Util } from './util';
import { Cart } from 'store/cart/models';

@Injectable({
    providedIn: 'root'
})
export class TargetedPromoService implements OnDestroy {
    private localStorage: LocalStorage;
    private xmStore: XmStore;
    private subscriptions: Subscription[] = [];
    private targetedPromotionQueryScript: HTMLScriptElement;
    private targetedPromotionCodesScript: HTMLScriptElement;

    constructor(localStorage: LocalStorage, xmStore: XmStore) {
        Object.assign(this, { localStorage, xmStore });

        if (!document.getElementById('targeted-promotion-query-details')) {
            this.targetedPromotionQueryScript = window.document.createElement('script');
            this.targetedPromotionQueryScript.type = 'tracking-data-page';
            this.targetedPromotionQueryScript.id = 'targeted-promotion-query-details';
            window.document.body.appendChild(this.targetedPromotionQueryScript);
        }

        if (!document.getElementById('targeted-promotion-code-details')) {
            this.targetedPromotionCodesScript = window.document.createElement('script');
            this.targetedPromotionCodesScript.type = 'tracking-data-page';
            this.targetedPromotionCodesScript.id = 'targeted-promotion-code-details';
            window.document.body.appendChild(this.targetedPromotionCodesScript);
        }

        this.checkStoragePromoExpiration();
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public loadStoragePromosToXmStore(): void {
        this.persistTargetedPromos(this.promosFromStorage, StoreAction.SAVE_TARGETED_PROMOTIONS);

        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peekMany<TargetedPromotion>(TargetedPromotion), () => {
            // whenever promos get added or deleted, Send data to Adobe Analytics 
            this.setPromoCodesDigitalData();
        }));
    }

    public syncPdpAndCartPromos(): void {
        let itemsLength: number;
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<Cart>(Cart, { firstOnly: true }), (result: Cart) => {
            itemsLength = result.items.length;
        }));

        if (itemsLength) {
            XmStoreUtil.defaultCatch(this.xmStore.persist<Cart>(StoreAction.UPDATE_CART, {
                promoCodes: this.joinedPromoCodes()?.split(',') || []
            }));
        }
    }

    public loadCartPromotions(cartPromotions: CartPromotion[]): void {
        if (!Array.isArray(cartPromotions) || !cartPromotions.length) {
            return;
        }

        const promotions: TargetedPromotion[] = [];
        cartPromotions.forEach((cartPromotion: CartPromotion) => {
            promotions.push(TargetedPromotion.create({
                code: cartPromotion.code,
                promoId: cartPromotion.promoId,
                authCheck: true
            }));
        });
        this.persistTargetedPromos(promotions, StoreAction.SAVE_TARGETED_PROMOTION_FROM_CART);
    }

    // promo query returned to product/details API
    public joinedPromoIds(): string {
        return this.promosFromStorage.map((promotion: TargetedPromotion) => promotion.promoId).join(',') || undefined;
    }

    public joinedPromoCodes(): string {
        return this.promosFromStorage.map((promotion: TargetedPromotion) => promotion.code).join(',');
    }
    
    public fetchPromoCodeFromId(promoId: string): string {
        const promoCodeDetails: TargetedPromotion = this.promosFromStorage.find((promos: TargetedPromotion) => promos.promoId === promoId);

        return promoCodeDetails ? promoCodeDetails.code : undefined;
    }

    public setPromoCodeFromQuery(isPromoCodeFromQuery: boolean): void {
        const promoQueryDetails: TDDPage = {
            attributes: {
                promotions: {
                    promoFromQuery: isPromoCodeFromQuery
                }
            }
        };

        this.targetedPromotionQueryScript.textContent = JSON.stringify(promoQueryDetails);
    }

    public setPromoCodesDigitalData(): void {
        const promoCodesDetails: TDDPage = {
            attributes: {
                promotions: {
                    promoCodes: this.joinedPromoCodes()
                }
            }
        };

        this.targetedPromotionCodesScript.textContent = JSON.stringify(promoCodesDetails);
    }

    private persistTargetedPromos(promotions: TargetedPromotion[], storeAction: string): void {
        if (promotions && Array.isArray(promotions)) {
            promotions.forEach(async(promotion: TargetedPromotion) => {
                await this.xmStore.persist<TargetedPromotion>(storeAction, promotion, { filters: { code: promotion.code }});
            });
        }
    }

    private get promosFromStorage(): TargetedPromotion[] {
        return this.localStorage.get(StorageToken.TARGETED_PROMOTIONS) || [];
    }

    private checkStoragePromoExpiration(): void {
        const latestPromoTimestamp: number = this.latestPromoTimestamp;
        if (latestPromoTimestamp && (new Date().getTime() - latestPromoTimestamp) > TARGETED_PROMOTIONS_EXPIRY_TIME) {
            this.xmStore.delete(StoreAction.DELETE_TARGETED_PROMOTION);
        }
    }

    private get latestPromoTimestamp(): number {
        return this.promosFromStorage.map((promotion: TargetedPromotion) => promotion.timestamp).sort((value1: number, value2: number) => value2 - value1)[0];
    }
}
