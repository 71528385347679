import { ModelBase } from 'store/model-base';

export class XmppPlan extends ModelBase {
    public static storeName: string = 'xmppPlan';

    public id: Id;
    public productName: string;
    public planName: string;
    public pricePerCycle: number;
    public status: string;
    public coverageStartDate: string;

    public get displayPrice(): string {
        return this.pricePerCycle ? `$${this.pricePerCycle}/mo` : '';
    }
}
