import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ApiChannel, PlanGroupType, PlansEligibilityIntent, ShopCategory } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { buildHeaders, buildParams, XmStore } from 'core/services';
import { UserApi } from 'store/user/api';
import { ModelBase } from 'store/model-base';
import { Account, BuyInfo, User } from 'store/user/models';
import { BroadbandLabels, PlanCollection, PlanEligibility, PlanEligibilityBTG } from './models';
import { StoreAction } from 'store/actions';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';
import { InsurancePlans } from './models/insurance-plans';

@Injectable()
export class PlanApi {

    public static getPlans(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<PlanCollection> {
        return http.get('/plans/details', {
            headers: buildHeaders({ apiChannel: ApiChannel.PCAT }),
            params: buildParams(params)
        }).pipe(
            map((plans: ApiResponse) => PlanCollection.create<PlanCollection>({ plans, accountId: params.accountId, category: params.category }), 
                catchError(err => logAndHandleError(err, '/plans/details', OperationType.GET)))
        );
    }

    public static getAuthenticatedPlans(xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<PlanCollection> {
        // BuyInfo is required to be called first to ensure the account is created and has an `id`
        return xmStore.findChild<BuyInfo, User>(StoreAction.GET_BUY_INFO, 'buyInfo', BuyInfo, User).pipe(
            // Check that account id exist
            mergeMap(() => UserApi.checkAccountIdExist(xmStore)),
            mergeMap((account: Account) =>
            // if account id does not exist, let API error so back-end sees logs and we'll direct to error page
                http.get(`/accounts/${account.id?.key}/plans/details`, {
                    headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }),
                    params: buildParams(params)
                }).pipe(
                    map((plans: ApiResponse) => PlanCollection.create<PlanCollection>({ plans, accountId: account.id.key, category: params.category })),
                    catchError(error => logAndHandleError(error, '/accounts/{account.id.key}/plans/details', OperationType.GET))
                )
            )
        );
    }

   
    public static getPlanEligibility(xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<PlanEligibility | void> {
        // BuyInfo is required to be called first to ensure the account is created and has an `id`
        return xmStore.findChild<BuyInfo, User>(StoreAction.GET_BUY_INFO, 'buyInfo', BuyInfo, User).pipe(
            // Check that account id exists and map the data
            mergeMap((buyInfo: BuyInfo) => UserApi.checkAccountIdExist(xmStore).pipe(map((account: Account) => [account, buyInfo]))),
            mergeMap(([account, buyInfo]: [Account, BuyInfo]) => {
                const nonSegmentFourTabletCustomer: boolean = !account.isSegmentFour && params.category?.toUpperCase() === ShopCategory.TABLET;

                const nonSegmentFiveTabletCustomer: boolean = !account.isSegmentFive && params.category?.toUpperCase() === ShopCategory.TABLET;
               
                if (!(account.isSegmentThree || account.isSegmentFour || account.isSegmentFive) || (nonSegmentFourTabletCustomer && nonSegmentFiveTabletCustomer) || !params.category ) {
                    return of(undefined);
                }
                

                // if account id does not exist, let API error so back-end sees logs and we'll direct to error page
                return http.get(`/accounts/${account.id.key}/buyinfo/planseligibility`, {
                    headers: buildHeaders({
                        apiChannel: ApiChannel.GATEWAY,
                        sessionID: buyInfo.sessionId
                    }),
                    params: buildParams({
                        category: params.category,
                        planType: PlanGroupType.UNLIMITED,
                        intent: params.isEditFlow && account.isSegmentFour ? PlansEligibilityIntent.EDIT : PlansEligibilityIntent.ADD
                    })
                }).pipe(
                    map((plans: ApiResponse) => PlanEligibility.create<PlanEligibility>(plans)),
                    catchError(error => logAndHandleError(error, '/accounts/{account.id.key}/buyinfo/planseligibility', OperationType.GET))
                );
            })
        );
    }
   
    public static getBTGPlanEligibility(xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<PlanEligibilityBTG | void> {
        // BuyInfo is required to be called first to ensure the account is created and has an `id`
        return xmStore.findChild<BuyInfo, User>(StoreAction.GET_BUY_INFO, 'buyInfo', BuyInfo, User).pipe(
            // Check that account id exist and map the data
            mergeMap((buyInfo: BuyInfo) => UserApi.checkAccountIdExist(xmStore).pipe(map((account: Account) => [account, buyInfo]))),
            mergeMap(([account, buyInfo]: [Account, BuyInfo]) => {
                const nonSegmentFourTabletCustomer: boolean = !account.isSegmentFour && params.category?.toUpperCase() === ShopCategory.TABLET;

                if (!(account.isSegmentThree || account.isSegmentFour) || nonSegmentFourTabletCustomer) {
                    return of(undefined);
                }

                // if account id does not exist, let API error so back-end sees logs and we'll direct to error page
                return http.get( `/accounts/${account.id?.key}/plans/eligibility/btg/${params.isEditFlow ? PlansEligibilityIntent.EDIT : PlansEligibilityIntent.ADD}`, {
                    headers: buildHeaders({
                        apiChannel: ApiChannel.GATEWAY,
                        sessionID: buyInfo.sessionId
                    }),
                    params: buildParams({
                        category: params.category,
                        planType: PlanGroupType.UNLIMITED,
                        intent: params.isEditFlow && account.isSegmentFour ? PlansEligibilityIntent.EDIT : PlansEligibilityIntent.ADD
                    })
                }).pipe(
                    map((plans: ApiResponse) => PlanEligibilityBTG.create<PlanEligibilityBTG>(plans)),
                    catchError(error => logAndHandleError(error, `/accounts/{account.id?.key}/plans/eligibility/btg/${params.isEditFlow ? PlansEligibilityIntent.EDIT : PlansEligibilityIntent.ADD}`, OperationType.GET))
                );
            })
        );
    }

    public static getBroadbandLabels(_xmStore: XmStore, http: HttpClient, info: NutritionLabelRequest, params: ApiParams): Observable<BroadbandLabels | void> {
        return http.post('/v1/nutrition-labels', info, {
            headers: buildHeaders({ apiChannel: ApiChannel.BROADBAND_LABELS }),
            params: buildParams(params)
        }).pipe(
            map((labels: ApiResponse) => BroadbandLabels.create<BroadbandLabels>(labels),
                catchError(err => logAndHandleError(err, '/v1/nutrition-labels', OperationType.POST, undefined, JSON.stringify(info))))
        );
    }
    
    public static getInsurancePlans(_xmStore: XmStore, http: HttpClient, params: ApiParams, _config: IXMOptions): Observable<InsurancePlans> {

        return http.get('/api/insurance/plans/details', {
            headers: buildHeaders({ apiChannel: ApiChannel.PCAT }),
            params: buildParams({ ...params })
        }).pipe(
            map((plans: ApiResponse) => InsurancePlans.create<InsurancePlans>(plans))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_PLAN_COLLECTION] = PlanApi.getPlans;
ModelBase.fetchMapping[StoreAction.GET_AUTHENTICATED_PLAN_COLLECTION] = PlanApi.getAuthenticatedPlans;
ModelBase.fetchMapping[StoreAction.GET_PLAN_ELIGIBILITY] = PlanApi.getPlanEligibility;
ModelBase.fetchMapping[StoreAction.GET_BTG_PLAN_ELIGIBILITY] = PlanApi.getBTGPlanEligibility;
ModelBase.persistMapping[StoreAction.GET_BROADBAND_LABELS] = PlanApi.getBroadbandLabels;
ModelBase.fetchMapping[StoreAction.GET_INSURANCE_PLANS] = PlanApi.getInsurancePlans;
