import { ChildModel, ModelBase } from 'store/model-base';
import { PlanSku, PlanType, ProductCategory } from 'core/constants';
import { Plan } from 'store/plan/models/option/plan';

export class PlanGroup extends ModelBase {
    public type: PlanType;
    public options: Plan[];
    public category: ProductCategory;

    // Cart specific properties
    public price: RecurringPrice;
    public selectedPlanId: PlanSku;
    public serviceId: string;

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'options',
            model: Plan
        }];
    }
}
