import { ChildModel, ModelBase } from 'store/model-base';
import { FraudStatus, OrderItemType, OrderStatus, OS } from 'core/constants';
import { PaymentInfo } from 'store/credit-card/models';
import { OrderItem } from './item';
import { OrderTaxesAndFees } from './taxes-and-fees';
import { OrderChannel } from './order-channel';
import { OrderItemPromotion } from './promotion';

export class OrderDetail extends ModelBase {
    public static storeName: string = 'orderDetail';
    public static idAttribute: string = 'id.key';

    public id: Id;
    public status: OrderStatus;
    public fraudStatus: FraudStatus;
    public paymentAuth: PaymentInfo;
    public orderItems: OrderItem[];
    public submittedDate: string;
    public promotionalPrepaidCardAmount: string;
    public totalUnlimitedPhoneDataCost: string;
    public totalUnlimitedTabletDataCost: string;
    public totalUnlimitedDataCost: string;
    public taxTotal: string;
    public returnTaxTotal: string;
    public taxesAndFees: OrderTaxesAndFees[];
    public promotions: OrderItemPromotion[];
    public orderChannel: OrderChannel;
    public orderItemsByAccessory: OrderItem[];
    public orderItemsByDevice: OrderItem[];
    public orderItemsByByodDevice: OrderItem[];
    public orderItemsWithPromotions: OrderItem[];
    public ndelOrderItem: OrderItem;
    public hasSimLedItem: boolean;
    public isByod: boolean;
    public hasOnlyByod: boolean;
    public isNdelOrder: boolean;
    public isByodLocked: boolean;
    public hasPrepaidPromo: boolean;
    public hasExtendedPromo: boolean;
    public hasSinglePromoType: boolean;
    public hasExtendedPromoOnly: boolean;
    public hasPrepaidPromoOnly: boolean;
    public hasAnyExtendedPromo: boolean;
    public email: string;

    public static create<T extends ModelBase>(initData: object): T {
        const toReturn: OrderDetail = super.create<OrderDetail>(initData);
        toReturn.fraudStatus = toReturn.fraudStatus || FraudStatus.IN_REVIEW;
        toReturn.orderItemsByAccessory = toReturn.orderItems.filter((orderItem: OrderItem) => orderItem.isAccessory);
        toReturn.orderItemsByDevice = toReturn.orderItems.filter((orderItem: OrderItem) => !orderItem.isAccessory);
        toReturn.orderItemsByByodDevice = toReturn.orderItems.filter((orderItem: OrderItem) => orderItem.byod);
        toReturn.isByodLocked = toReturn.orderItemsByByodDevice.some((result: OrderItem) => result.isByodDeviceLocked);
        toReturn.isByod = Boolean(toReturn.orderItems.find((orderItem: OrderItem) => orderItem.byod));
        toReturn.hasOnlyByod = toReturn.orderItems.filter((orderItem: OrderItem) => orderItem.byod).length === toReturn.orderItems.length;
        toReturn.isNdelOrder = Boolean(toReturn.orderItems.find((orderItem: OrderItem) => orderItem.isNdelItem));
        toReturn.hasExtendedPromo = toReturn.orderItems.some((orderItem: OrderItem) => orderItem.hasExtendedPromo);
        toReturn.hasPrepaidPromo = toReturn.orderItems.some((orderItem: OrderItem) => orderItem.hasPrepaidPromo);
        toReturn.orderItemsWithPromotions = toReturn.orderItems.filter((orderItem: OrderItem) => orderItem.promotions.length > 0);
        toReturn.hasSinglePromoType = toReturn.orderItemsWithPromotions.every((orderItem: OrderItem) => orderItem.hasSinglePromo && (orderItem.promotions[0].type === toReturn.orderItemsWithPromotions[0].promotions[0].type));
        toReturn.hasExtendedPromoOnly = toReturn.hasExtendedPromo && toReturn.hasSinglePromoType;
        toReturn.hasPrepaidPromoOnly = toReturn.hasPrepaidPromo && toReturn.hasSinglePromoType;
        toReturn.hasAnyExtendedPromo = toReturn.orderItems.some((orderItem: OrderItem) => orderItem.hasAnyExtendedPromo);
        toReturn.ndelOrderItem = toReturn.orderItems.find((orderItem: OrderItem) => orderItem.isNdelItem || orderItem.isNdelPreOrderItem || orderItem.isNdelBackOrderItem);
        toReturn.hasSimLedItem = toReturn.orderItems.some((item: OrderItem) => item.type === OrderItemType.SIMLED_BYOD);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'paymentAuth',
            model: PaymentInfo
        }];
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'orderItems',
            model: OrderItem
        }, {
            attrName: 'orderItemsByAccessory',
            model: OrderItem
        }, {
            attrName: 'orderItemsByDevice',
            model: OrderItem
        }, {
            attrName: 'orderTaxesAndFees',
            model: OrderTaxesAndFees
        }, {
            attrName: 'promotions',
            model: OrderItemPromotion
        }];
    }

    public get carrierInOrder(): string {
        const hasMultipleCarrier: boolean = Boolean((this.orderItemsByByodDevice.find((result: OrderItem, _index: number, array: OrderItem[]) => result.byodCarrier !== array[0].byodCarrier)));

        return hasMultipleCarrier ? undefined : this.orderItemsByByodDevice.length > 0 && this.orderItemsByByodDevice[0].byodCarrier;
    }

    public get orderTotal(): number {
        return this.paymentAuth.amount;
    }

    public get orderHasAccessory(): boolean {
        return this.orderItems.some((orderItem: OrderItem) => orderItem.isAccessory);
    }

    public get orderHasDevice(): boolean {
        return this.orderItems.some((orderItem: OrderItem) => !orderItem.isAccessory && !orderItem.byod);
    }

    public get orderHasPhone(): boolean {
        return this.orderItems.some((orderItem: OrderItem) => orderItem.isPhone);
    }

    public get orderHasDeviceWithUnlimitedData(): boolean {
        return this.orderItemsByDevice.some((orderItem: OrderItem) => orderItem.isPlanUnlimited);
    }

    public get orderHasAccessoriesOnly(): boolean {
        return this.orderItems.every((orderItems: OrderItem) => orderItems.isAccessory);
    }

    public get deviceCount(): number {
        return this.orderItemsByDevice.length;
    }

    public get accessoryCount(): number {
        return this.orderItemsByAccessory.length;
    }

    public get tabletCount(): number {
        return this.orderItems.filter((orderItems: OrderItem) => orderItems.isTablet).length;
    }

    public get watchCount(): number {
        return this.orderItems.filter((orderItems: OrderItem) => orderItems.isWatch).length;
    }

    public get deviceDisplayName(): string {
        if (this.deviceCount === 1) {
            return this.orderItemsByDevice[0].productName;
        }
    }

    public get accessoryDisplayName(): string {
        if (this.accessoryCount === 1) {
            return this.orderItemsByAccessory[0].productName;
        }
    }

    public get orderHasAndroidDevice(): boolean {
        return this.orderItemsByDevice.some((orderItem: OrderItem) => orderItem.productDetails.os?.toUpperCase() === OS.ANDROID);
    }

    public get orderhasSingleDeviceOs(): boolean {
        const devicesByProductOs: string[] = this.orderItemsByDevice.map((orderItem: OrderItem) => orderItem.productDetails.os);

        return Array.from(new Set(devicesByProductOs)).length === 1;
    }

    public get iosByodImei(): string {
        const iosByodItem: OrderItem = this.orderItems.find((item: OrderItem) => item.byod && item.isIosDevice);

        return iosByodItem && iosByodItem.deviceImei;
    }

    public get isSimLedOrderInReview(): boolean {
        return this.hasSimLedItem && this.fraudStatus === FraudStatus.IN_REVIEW;
    }

    public get isSimLedOrderPassed(): boolean {
        return this.hasSimLedItem && this.fraudStatus === FraudStatus.PASSED;
    }

    public get orderHasEsimOnly(): boolean {
        return this.orderItems.every((orderItems: OrderItem) => orderItems.isEsim) && !this.hasSimLedItem;
    }

    public get getOrderPromotions(): string[] {
        const orderPromotions = [];
        const promoItems = this.orderItems.filter((item: OrderItem) => item.promotions && item.promotions.length > 0);
        for (let i = 0; i < promoItems.length; i++) {
            const item: OrderItem = this.orderItems[i];
            if (item.promotions && promoItems.length > 0) {
                for (let j = 0; j < item.promotions.length; j++) {
                    const itemPromotion: string = item.promotions[j].id;

                    orderPromotions.push(itemPromotion);
                }
            }
        }

        return orderPromotions;
    }

    public get orderMetrics(): object {
        return {
            orderStatus: this.status,
            orderTime: this.submittedDate,
            totalDeviceCount: this.deviceCount + this.tabletCount + this.watchCount,
            deviceCount: this.deviceCount,
            tabletCount: this.tabletCount,
            watchCount: this.watchCount,
            accessoryCount: this.accessoryCount,
            fraudStatus: this.fraudStatus,
            salesChannel: this.orderChannel.salesChannel,
            productPurchasedId: this.orderItems.map((item: OrderItem) => item.productId).join(',')
        };
    }

    public get orderPromosAndCosts(): object {
        return {
            promotions: this.getOrderPromotions,
            promotionalPrepaidCardAmount: this.promotionalPrepaidCardAmount,
            totalUnlimitedPhoneDataCost: this.totalUnlimitedPhoneDataCost,
            totalUnlimitedTabletDataCost: this.totalUnlimitedTabletDataCost,
            totalUnlimitedDataCost: this.totalUnlimitedDataCost
        };
    }

    public get orderTaxes(): object {
        return {
            taxesAndFees: this.taxesAndFees,
            taxTotal: this.taxTotal,
            returnTaxTotal: this.returnTaxTotal
        };
    }
}
