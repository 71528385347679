import { ModelBase } from 'store/model-base';
import { PortFlag } from 'buy/constants';

export class CarrierInfo extends ModelBase {
    public static idAttribute: string = 'portingFlag';

    public phoneNumber: string;
    public carrierName: string;
    public portingFlag: string;
    public phoneNumberId: string;

    public get isPort(): boolean {
        return this.portingFlag === PortFlag.YES;
    }
}
