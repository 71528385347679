import { ModelBase } from 'store/model-base';
import { ChargeType } from 'core/constants';

export class OrderItemCharge extends ModelBase {
    public static storeName: string = 'orderItemCharge';

    public type: ChargeType;
    public price: number;
    public name: string;
    public isUnlimited: boolean;

    public isSubsidy: boolean;
    public isDeviceAccessFee: boolean;
    public isDeviceFinanced: boolean;
    public isDevicePaid: boolean;
    public isLineServicePrimary: boolean;
    public isProtectionPlan: boolean;
    public isAccessory: boolean;
    public isEWasteFee: boolean;
    public isDownPayment: boolean;

    public static create<T extends ModelBase>(initData: ApiResponse): T {
        initData.isDownPayment = initData.type === ChargeType.DOWN_PAYMENT;
        initData.isSubsidy = initData.type === ChargeType.DEVICE_SUBSIDY;
        initData.isDeviceAccessFee = initData.type === ChargeType.DEVICE_ACCESS_FEE;
        initData.isEWasteFee = initData.type === ChargeType.EWASTE_FEE;
        initData.isDeviceFinanced = initData.type === ChargeType.DEVICE_FINANCED;
        initData.isDevicePaid = initData.type === ChargeType.DEVICE_PURCHASED;
        initData.isLineServicePrimary = initData.type === ChargeType.LINE_SERVICE_PRIMARY;
        initData.isProtectionPlan = initData.type === ChargeType.INSURANCE_PLAN;
        initData.isAccessory = initData.type === ChargeType.ACCESSORY;

        return super.create<T>(initData);
    }
}
