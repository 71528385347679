import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Subscription } from 'rxjs';

import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { XmStoreUtil } from '../store';

@Injectable({
    providedIn: 'root'
})
export class AbTestService {
    public tests: AbTest[] = [];
    private config: IXMOptions;
    private subscriptions: Subscription[] = [];
    private uiRouter: UIRouter;
    private initialLoad: boolean = true;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, uiRouter: UIRouter) {
        Object.assign(this, { config, uiRouter });
        window.logAbTests = false;
    }

    public loadTarget(): Promise<string> {
        const offerFunction: boolean = window.AdobeTeamUtils && typeof window.AdobeTeamUtils.getPrefetchedOfferPromise === 'function' && typeof window.AdobeTeamUtils.getCurrentViewMboxList === 'function';

        if (this.config.BLEEDING_EDGE_CORPORATE_SOCIAL_RESPONSIBILITY && offerFunction) {
            this.subscriptions.push(XmStoreUtil.subscribe(this.uiRouter.globals.success$, () => {
                if (!this.initialLoad) {
                    this.getTests();
                } else {
                    this.initialLoad = false;
                }
            }));

            return this.getTests();
        }

        this.logAbTestData('AbTests Adobe Target Library Not Found');

        return Promise.resolve('Adobe Target Library Not Found');
    }

    public getTests(): Promise<string> {
        return new Promise((resolve: Function) => {
            try {
                window.AdobeTeamUtils.getPrefetchedOfferPromise({ mboxList: window.AdobeTeamUtils.getCurrentViewMboxList() }).then((response: AbTestOfferContent[]) => {
                    this.tests = response || [];
                    this.logAbTestData(`AbTests processed responses: ${JSON.stringify(this.tests)}`);
                    resolve('done');
                });
            } catch (error) {
                this.logAbTestData(`AbTests error: ${JSON.stringify(error)}`);
                resolve(error);
            }
        });
    }

    public getTestVersion(testName: string): string {
        const test: AbTest = this.lookupTest(testName);

        return test && test.version && test.version?.toUpperCase();
    }

    private lookupTest(testName: string): AbTest {
        const foundTests: AbTest = Array.isArray(this.tests) && this.tests.find((test: AbTest) => test && test.testName === testName);
        this.logAbTestData(`AbTests search string: ${testName}, found tests: ${JSON.stringify(foundTests)}`);

        return foundTests;
    }

    private logAbTestData(data: string): void {
        if (window.logAbTests) {
            /* eslint-disable  no-console */
            console.log(data);
            /* eslint-enable  no-console */
        }
    }
}
