import { ModelBase } from 'store/model-base';

import { PlanSku, ShopCategory } from 'core/constants';

export class Plan extends ModelBase {
    public static storeName: string = 'plans';

    public id: PlanSku;
    public planGroup: string;
    public planSubgroup: string;
    public details: ProductFeature[];
    public category: ShopCategory;
    public planType: string;
    public selectedPlanId: string;

    public name: string;
    public dataUnlimited: boolean;

    public get isForPhone(): boolean {
        return this.category === ShopCategory.DEVICE;
    }

    public get isForTablet(): boolean {
        return this.category === ShopCategory.TABLET;
    }
}
