
import { ModelBase } from 'store/model-base';
import { StoreAction, XmAction } from 'store/actions';
import { Cart, NavCart, ShippingMethod, ShippingMethodCollection } from './models';

/* eslint-disable prefer-object-spread */
export function cartReducer(state: Cart, action: XmAction<ModelBase>): Cart {
    switch (action.type) {
        case StoreAction.GET_CART:
        case StoreAction.ADD_ITEM:
        case StoreAction.UPDATE_CART:
        case StoreAction.DELETE_CART:
        case StoreAction.REMOVE_ITEM:
            return <Cart> action.payload;
        case StoreAction.UPDATE_SHIPPING:
            state.shippingMethod = <ShippingMethod> action.payload;

            return <Cart> Object.assign({}, state);
        default:
            return state;
    }
}
/* eslint-enable prefer-object-spread */

export function navCartReducer(state: NavCart, action: XmAction<NavCart>): NavCart {
    switch (action.type) {
        case StoreAction.GET_CART_COUNT:
            return action.payload;
        default:
            return state;
    }
}

export function shippingMethodReducer(state: ShippingMethodCollection, action: XmAction<ShippingMethodCollection>): ShippingMethodCollection {
    switch (action.type) {
        case StoreAction.GET_SHIPPING_METHOD:
            return action.payload;
        default:
            return state;
    }
}

