import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common'; 
import { Transition } from '@uirouter/core';
import { Observable, Subscription } from 'rxjs';
import { CONFIG_TOKEN, TransitionHooks } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { Analytics, CimaCore, GlobalNav, Util, WindowReference, XmStoreUtil } from 'core/services';

@Component({
    selector: 'xm-base',
    styleUrls: [ './base.scss' ],
    templateUrl: './base.html'
})
export class BaseComponent implements AfterViewInit, OnInit, OnDestroy {
    private cimaCore: CimaCore;
    private config: IXMOptions;
    private document: Document;
    private subscriptions: Subscription[] = [];
    private windowReference: WindowReference;
    private transitionSuccess: Observable<Transition>;
    private globalNav: GlobalNav;
    private renderer2: Renderer2;

    constructor(
        analytics: Analytics,
        cimaCore: CimaCore,
        globalNav: GlobalNav,
        renderer2: Renderer2,
        windowReference: WindowReference,
        @Inject(CONFIG_TOKEN) config: IXMOptions,
        @Inject(DOCUMENT) document: Document,
        @Inject(TransitionHooks.SUCCESS) transitionSuccess: Observable<Transition>      
    ) {
        Object.assign(this, { cimaCore, config, document, globalNav, windowReference, transitionSuccess, renderer2 });

        analytics.watchClickGlobal();
    }

    public ngOnInit(): void {
        this.cimaCore.initUserData();
        //Don't load global nav in iframes like CIMA OAUTH
        if (window.location === window.parent.location) {
            this.globalNav.initGlobalNav(this.renderer2);
        }
        this.document.getElementById('favicon').setAttribute('href', `${this.config.BUY_STATIC_BASE_URL}/favicon/favicon.ico`);
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public ngAfterViewInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.transitionSuccess, transition => {
            this.addContentMarginForCheckout(transition.to().name?.startsWith('checkout'));
            setTimeout(() => {
                this.setContentBodyStyles();
            });
        }));

        this.subscriptions.push(this.windowReference.windowWidth.subscribe(() => {
            this.setContentBodyStyles();
        }));
    }

    private addContentMarginForCheckout(startsWithCheckout: boolean): void {
        const contentBody: HTMLElement = window.document.getElementById('content');
        if (startsWithCheckout) {
            contentBody.classList.add('content-checkout-margin');
        } else {
            contentBody.classList.remove('content-checkout-margin');
        }
    }

    private setContentBodyStyles(): void {
        const contentBody: HTMLElement = window.document.getElementById('content');

        if (contentBody) {
            // contentBody.style['padding-top'] = `${this.globalNav.navHeight()}px`;
        }
    }
}
