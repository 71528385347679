import { NgModule } from '@angular/core';

import { XmLoginComponent } from './login';

@NgModule({
    declarations: [
        XmLoginComponent
    ],
    exports: [
        XmLoginComponent
    ]
})
export class XmLoginComponentModule {}
