import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { logAndHandleError } from 'services/log/LogHelper';

import { buildHeaders, buildParams, XmStore } from 'core/services';
import { StoreAction } from 'store/actions';
import { ApiChannel } from 'core/constants';
import { ModelBase } from 'store/model-base';
import { OfferDetail, TradeInDeviceDetail, TradeInMakeAndModel } from './models';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class TradeInApi {
    public static getUnauthDeviceDetails(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<TradeInDeviceDetail[]> {
        return http.get(`/tradein/devices/imei/${params.imei}`, {
            headers: buildHeaders({ apiChannel: ApiChannel.TIP_PROXY }),
            params: buildParams({ program: params.program })
        }).pipe(
            map((response: TradeInDeviceDetail[]) => response.map((details: object) => TradeInDeviceDetail.create<TradeInDeviceDetail>(details))),
            catchError(err => logAndHandleError(err, '/tradein/devices/imei/unauth:=true', OperationType.GET))          
            
        );
    }

    public static getAuthDeviceDetails(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<TradeInDeviceDetail[]> {
        return http.get(`/tradein/devices/details/imei/${params.imei}`, {
            headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY })
        }).pipe(
            map((response: TradeInDeviceDetail[]) => response.map((details: object) => TradeInDeviceDetail.create<TradeInDeviceDetail>(details))),
            catchError(err => logAndHandleError(err, `/tradein/devices/imei/${params.imei}/auth:=true`, OperationType.GET))          

        );
    }

    public static getUnauthDeviceOffer(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<OfferDetail> {
        return http.get(`/tradein/offer/aeid/${params.aeid}/imei/${params.imei}`, {
            headers: buildHeaders({ apiChannel: ApiChannel.TIP_PROXY }),
            params: buildParams({ program: params.program })
        }).pipe(
            map((response: ApiResponse) => OfferDetail.create<OfferDetail>(response)),
            catchError(err => logAndHandleError(err, `/tradein/offer/aeid/${params.aeid}/imei/${params.imei}/unauth:=true`, OperationType.GET))          

        );
    }

    public static getAuthDeviceOffer(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<OfferDetail> {
        return http.get(`/tradein/offer/aeid/${params.aeid}/imei/${params.imei}`, {
            headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }),
            params: buildParams({ program: params.program })
        }).pipe(
            map((response: ApiResponse) => OfferDetail.create<OfferDetail>(response)),
            catchError(err => logAndHandleError(err, `/tradein/offer/aeid/${params.aeid}/imei/${params.imei}/auth:=true`, OperationType.GET))          

        );
    }

    public static getMakeAndModel(_xmStore: XmStore, http: HttpClient): Observable<TradeInMakeAndModel> {
        return http.get('/tradein/makemodels', {
            headers: buildHeaders({ apiChannel: ApiChannel.TIP_PROXY })
        }).pipe(
            map((response: ApiResponse) => TradeInMakeAndModel.create<TradeInMakeAndModel>(response)),
            catchError(err => logAndHandleError(err, '/tradein/makemodels', OperationType.GET))          

        );
    }

    public static getUnAuthDeviceStorageAndCarrier(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<TradeInDeviceDetail[]> {
        return http.get(`/tradein/devices?make=${params.make}&model=${params.model}`, {
            headers: buildHeaders({ apiChannel: ApiChannel.TIP_PROXY })
        }).pipe(
            map((response: TradeInDeviceDetail[]) => response.map((details: object) => TradeInDeviceDetail.create<TradeInDeviceDetail>(details))),
            catchError(err => logAndHandleError(err, `/tradein/devices?make=${params.make}&model=${params.model}/unauth:=true`, OperationType.GET))          

        );
    }

    public static getAuthDeviceStorageAndCarrier(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<TradeInDeviceDetail[]> {
        return http.get(`/tradein/devices/details?make=${params.make}&model=${params.model}`, {
            headers: buildHeaders({ apiChannel: ApiChannel.TIP_PROXY })
        }).pipe(
            map((response: TradeInDeviceDetail[]) => response.map((details: object) => TradeInDeviceDetail.create<TradeInDeviceDetail>(details))),
            catchError(err => logAndHandleError(err, `/tradein/devices/details?make=${params.make}&model=${params.model}/auth:=true`, OperationType.GET))          

        );
    }

}

ModelBase.fetchMapping[StoreAction.GET_UNAUTH_TRADE_IN_DEVICE_DETAILS] = TradeInApi.getUnauthDeviceDetails;
ModelBase.fetchMapping[StoreAction.GET_AUTH_TRADE_IN_DEVICE_DETAILS] = TradeInApi.getAuthDeviceDetails;
ModelBase.fetchMapping[StoreAction.GET_UNAUTH_TRADE_IN_DEVICE_OFFER] = TradeInApi.getUnauthDeviceOffer;
ModelBase.fetchMapping[StoreAction.GET_AUTH_TRADE_IN_DEVICE_OFFER] = TradeInApi.getAuthDeviceOffer;
ModelBase.fetchMapping[StoreAction.GET_TRADE_IN_MAKE_AND_MODEL] = TradeInApi.getMakeAndModel;
ModelBase.fetchMapping[StoreAction.GET_UNAUTH_TRADE_IN_DEVICE_STORAGE_CARRIER] = TradeInApi.getUnAuthDeviceStorageAndCarrier;
ModelBase.fetchMapping[StoreAction.GET_AUTH_TRADE_IN_DEVICE_STORAGE_CARRIER] = TradeInApi.getAuthDeviceStorageAndCarrier;
