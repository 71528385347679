import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { CimaCore, Cloudinary, Decorator, GlobalNav, LinkDecorationInfo, Util, XmStore, XmStoreUtil } from 'core/services';
import { CmsCore } from 'store/cms/models';

@Component({
    selector: 'xm-nav-mobile-menu-item',
    styleUrls: ['./mobile-menu-item.scss'],
    templateUrl: './mobile-menu-item.html'
})
export class XmNavMobileMenuItemComponent implements OnInit, OnDestroy {
    @Input() public pageData: CmsNavMenuItems;
    @Input() public inheritParentWidth: boolean;
    // DO NOT DELETE this input variable (triggerOnChanges) check primary.html for usage
    @Input() public triggerOnChanges: boolean;
    @Output() public toggleMobileNav: EventEmitter<boolean> = new EventEmitter<boolean>();

    public menuIcon: MediaImageOptions;
    public tempMenuIcon: string;
    public caretIcon: MediaImageOptions;

    public isLeftMenuItemsHover: boolean = false;

    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;
    private decorator: Decorator;
    
    constructor(globalNav: GlobalNav, xmStore: XmStore, decorator: Decorator, cimaCore: CimaCore) {
        Object.assign(this, { globalNav, xmStore, decorator, cimaCore });
    }

    public ngOnInit(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (result: CmsCore) => {
            this.caretIcon = { mobile: Cloudinary.generateMediaFromCms(result.nav.caretIcon) };
        }));

        if (this.pageData.icon) {
            if (this.pageData.icon.temp) {
                this.tempMenuIcon = this.pageData.icon.base;
            }
            this.menuIcon = { mobile: Cloudinary.generateMediaFromCms(this.pageData.icon) };
        }

        this.buildLinkItems();
    }
    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public generateCmsMediaImage(icon: CmsMediaImage): MediaImageOptions {
        if (!icon) {
            return undefined;
        }

        return { mobile: Cloudinary.generateMediaFromCms(icon) };
    }

    // From decorators
    // public generateUiParams(paramsString: string): object {
    //     return Util.getObjectFromQueryString(paramsString);
    // }

    public closeMobileMenu(): void {
        this.toggleMobileNav.emit();
    }

    public hasSubChild(event: CmsNavMenuItems): boolean {
        if (event.children && event.children.length) {
            return true;
        }
    }


    private buildLinkItems(): void {
        if (this.pageData.linkDecoration) {
            const deco: LinkDecorationInfo = this.decorator.parseLinkDecoration(this.pageData.linkDecoration)[0];
            this.pageData.url = deco.absoluteUrl;
            this.pageData.name = deco.displayText;
        }

        if (this.pageData.children) {
            this.pageData.children = this.pageData.children.map((navMenuItem: CmsNavMenuItems) => {
                if (navMenuItem.linkDecoration) {
                    const deco: LinkDecorationInfo = this.decorator.parseLinkDecoration(navMenuItem.linkDecoration)[0];
                    navMenuItem.url = deco.absoluteUrl;
                    navMenuItem.name = deco.displayText;
                }
                
                return navMenuItem;
            });
        }
    }

}
