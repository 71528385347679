import { ModelBase } from 'store/model-base';

export class CmsByod extends ModelBase {
    public static storeName: string = 'cmsByod';

    public byodBase: CmsByodBase;
    public byodRetryImeiCheck: CmsByodRetryImeiCheck;
    public byodPromoBanner: CmsPromoBanner;
    public deviceCheck: CmsByodDeviceCheck;
    public carrierCheck: CmsByodCarrier;
    public deviceUnavailable: CmsByodDeviceUnavailable;
    public imeiCheck: CmsByodImeiCheck;
    public imei2Check: CmsByodImeiCheck;
    public imei1Check: CmsByodImeiCheck;
    public iccidCheck: CmsByodIccidCheck;
    public joinXm: CmsByodJoinXm;
    public byodUnavailable: CmsByodUnavailable;
    public balloonImageMobileLeft: CmsMediaOptionsImage;
    public balloonImageMobileRight: CmsMediaOptionsImage;
    public balloonImageDesktop: CmsMediaOptionsImage;
    public inCompatible: CmsByodCompatibleCases;
    public simLedCompatibleUserAction: CmsByodCompatibleCases;
    public simLedIncompatible: CmsByodCompatibleCases;
    public compatibleUserAction: CmsByodCompatibleCases;
    public fullCompatible: CmsByodCompatibleCases;
    public tabletCompatible: CmsByodTabletCompatibleCases;
    public watchCompatible: CmsByodCompatibleCases; 
    public simLedFullCompatible: CmsByodCompatibleCases;
    public simAndStore: CmsByodSimAndStore;
    public requestCall: CmsByodRequestCall;
    public deviceImgData: CmsByodDeviceImage;
    public checkout: CmsByodCheckout;
    public actions: CmsByodActions;
    public carrierOtherText: string;
    public yourCarrierText: string;
    public isSimActive: CmsByodCompatibleCases;
    public isNotXmSim: CmsByodCompatibleCases;
    public canActivateSim: CmsByodCompatibleCases;
    public errorLimitReached: CmsByodCompatibleCases;
    public simSetup: CmsByodSimLedSetup;
    public findYourImeiModal: CmsFindYourImeiModal;
    public eSimImeiModal: CmsFindYourImeiModal;
    public checkYourImeiModal: CmsCheckYourImeiModal;
    public byodError: ByodErrorContent;
    public byodTabletIcon?: string;
    public byodPhoneIcon?: string;
    public byodCallIcon?: string;
}
