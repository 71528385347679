import { ModelBase } from 'store/model-base';
import { LineEligibilityConditionalCodes as Codes } from 'core/constants';

export class LineEligibility extends ModelBase {
    public static storeName: string = 'lineEligibility';

    public lineId: Id;
    public ndelEligible: boolean;
    public upgradeEligible: boolean;
    public upgradeFee: number;
    public imei: string;
    public sku: string;
    public upgradeEligibleDppAmountDue: number;
    public currentDevicePurchasePrice: number;
    public currentDeviceRemainingBalance: number;
    public eligibilityConditionalReason: EligibilityReason[];
    public eligibilityDenialReason: EligibilityReason[];

    public paidHalf: boolean;
    public hasProtectionPlan: boolean;
    public isPaidInFull: boolean;

    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const toReturn: LineEligibility = super.create<LineEligibility>(initData);

        toReturn.paidHalf = !toReturn.eligibilityConditionalReason.some((eligibility: EligibilityReason) => eligibility.code === Codes.PAID_HALF);
        toReturn.hasProtectionPlan = toReturn.eligibilityConditionalReason.some((eligibility: EligibilityReason) => eligibility.code === Codes.HAS_XMC);
        toReturn.isPaidInFull = toReturn.eligibilityDenialReason.some((eligibility: EligibilityReason) => eligibility.code === Codes.PAID_IN_FULL);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }
}
