import { Util } from 'services/util';
import { DEFAULT_CART, DEFAULT_EVENT, DEFAULT_PAGE, DEFAULT_SITE_INFO, DEFAULT_USERS} from './constants';

export class DigitalDefault {
    protected page: TDDPage;
    protected siteInfo: TDDSiteInfo;
    protected users: TDDUser[];
    protected cart: TDDCart;
    protected event: TDigitalEvent[];

    public static cloneDefaults(): TDigitalData {
        return {
            cart: Util.cloneProperties(DEFAULT_CART),
            event: Util.cloneProperties(DEFAULT_EVENT),
            page: Util.cloneProperties(DEFAULT_PAGE),
            siteInfo: Util.cloneProperties(DEFAULT_SITE_INFO)
        };
    }

    protected constructor(
        page: TDDPage = Util.cloneProperties(DEFAULT_PAGE),
        siteInfo: TDDSiteInfo = Util.cloneProperties(DEFAULT_SITE_INFO),
        users: TDDUser[] = Util.cloneProperties(DEFAULT_USERS),
        cart: TDDCart = Util.cloneProperties(DEFAULT_CART),
        event: TDigitalEvent[] = Util.cloneProperties(DEFAULT_EVENT)
    ) {
        Object.assign(this, {
            cart,
            event,
            page,
            siteInfo,
            users
        });
    }

    public get content(): TDigitalData {
        return {
            cart: this.cart,
            event: this.event,
            page: this.page,
            siteInfo: this.siteInfo,
            users: this.users
        };
    }
}
