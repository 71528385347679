import { ModelBase } from 'store/model-base';

export class AssociatedProductDetails extends ModelBase {
    public static storeName: string = 'associatedProductDetails';

    public productCode: string;
    public name: string;
    public skuCode: string;
    public slug: string;
    public model: string;
    public brand: string;
    public os: string;
    public capacity: string;
    public colorName: string;
    public category: string;
    public image: Image;
}
