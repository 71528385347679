import { Component, Input } from '@angular/core';

@Component({
    selector: 'xm-schema',
    styleUrls: [ './schema.scss' ],
    templateUrl: './schema.html'
})
export class XmSchemaComponent {
    @Input() public schemaData: string;
}
