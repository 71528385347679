import { ChildModel, ModelBase } from 'store/model-base';
import { FullAddress } from '../address/full';
import { CreditCardType } from 'core/constants';

export class PaymentCard extends ModelBase {
    public billingAddress: FullAddress;
    public cardNumberLast4: string;
    public cardExpiryDate: string;
    public cardType: CreditCardType;

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'billingAddress',
            model: FullAddress
        }];
    }
}
