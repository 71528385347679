import { ChildModel, ModelBase } from 'store/model-base';
import { NdelLineEligibility } from './ndel-line';

export class NdelEligibility extends ModelBase {
    public static storeName: string = 'ndelEligibility';

    public ndelLineEligibilities: NdelLineEligibility[];

    public static get hasMany(): ChildModel[] {
        return [{
            attrName: 'ndelLineEligibilities',
            model: NdelLineEligibility
        }];
    }

    public getNdelLineByValue(value: string): NdelLineEligibility {
        return this.ndelLineEligibilities.find((line: NdelLineEligibility) => line.lineId.value === value);
    }
}
