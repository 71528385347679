import { Inject, Injectable } from '@angular/core';

import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class Chat {
    private config: IXMOptions;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions) {
        Object.assign(this, { config });
    }

    public openChat(): void {
        window.open(this.config.XFINITY_ASSISTANT, 'popup', 'left=10000,top=10000,width=360,height=640,resizable=no');
    }
}
