import { StoreAction, XmAction } from 'store/actions';
import { PastDue } from 'store/past-due/models';

export function pastDueReducer(state: PastDue, action: XmAction<PastDue>): PastDue {
    switch (action.type) {
        case StoreAction.GET_PAST_DUE:
        case StoreAction.CLEAR_PAST_DUE:
            return action.payload;
        default:
            return state;
    }
}
