import { ChildModel, ModelBase } from 'store/model-base';
import { ImeiDevice } from './imei-device';
import { ByodEligibilityPages } from 'services/analytics/digital-data';

export class ByodEligibility extends ModelBase {
    public static storeName: string = 'byodEligibility';

    public device: ImeiDevice;
    public messages: Message[];

    public isCompatible: boolean;
    public isFullCompatible: boolean;
    public isUpdateCompatible: boolean;
    public isAppleLocked: boolean;
    public isAppleLockedBYO2000: boolean;
    public isAndroidLocked: boolean;
    public isLteOnly: boolean;
    public isSlapstickEligible: boolean;
    public isSecondaryImei: boolean;

    public isGSXDown: boolean;
    public isIOSVersionUnknown: boolean;
    public isXfinityMobile: boolean;
    public isLostStolen: boolean;
    public isIncompatible: boolean;

    public deviceImageUrl: string;
    public pageName: string;

    public static create<T extends ModelBase>(initData: object = {}): T {
        const toReturn: ByodEligibility = super.create<ByodEligibility>(initData);

        toReturn.isIncompatible = toReturn.device.byodReasons.some((reason: EligibilityReason) =>
            [ 'BYO1100', 'BYO400', 'BYO1000', 'BYO500', 'BYO1500', 'BYO100', 'BYO1600', 'BYO1700' ].includes(reason.code));
        toReturn.isLostStolen = toReturn.device.byodReasons.some((reason: EligibilityReason) => reason.code === 'BYO900');
        toReturn.isXfinityMobile = toReturn.device.byodReasons.some((reason: EligibilityReason) => reason.code === 'BYO600' || reason.code === 'BYO700' || reason.code === 'BYO800');
        toReturn.isLteOnly = toReturn.device.byodReasons.some((reason: EligibilityReason) => reason.code === 'BYO1300');
        toReturn.isAppleLocked = toReturn.device.byodReasons.some((reason: EligibilityReason) => reason.code === 'BYO200' || reason.code === 'BYO1400');
        toReturn.isIOSVersionUnknown = toReturn.device.byodReasons.some((reason: EligibilityReason) => reason.code === 'BYO2100');
        // Used by MCX team
        toReturn.isAppleLockedBYO2000 = toReturn.device.byodReasons.some((reason: EligibilityReason) => reason.code === 'BYO2000');
        toReturn.isAndroidLocked = toReturn.device.byodReasons.some((reason: EligibilityReason) => reason.code === 'BYO1900');

        toReturn.isGSXDown = toReturn.isIOSVersionUnknown && toReturn.isAppleLockedBYO2000;
        toReturn.isUpdateCompatible = toReturn.device.byodReasons.some((reason: EligibilityReason) => reason.code === 'BYO300' || reason.code === 'BYO1200');
        toReturn.isSecondaryImei = toReturn.device.byodReasons.some((reason: EligibilityReason) => reason.code === 'BYO1700');
        toReturn.isFullCompatible = !toReturn.device.byodReasons.length;
        toReturn.isCompatible = !toReturn.isIncompatible && (toReturn.isFullCompatible || toReturn.isUpdateCompatible || toReturn.isAppleLocked || toReturn.isAndroidLocked || toReturn.isLteOnly || toReturn.isGSXDown);
        toReturn.isSlapstickEligible = toReturn.isCompatible && !toReturn.isIncompatible;

        const index: number = toReturn.device.defaultVariant || 0;
        const images: VariantImage = toReturn.device.product && toReturn.device.product.variants.length && toReturn.device.product.variants[index].images;
        if (images) {
            toReturn.deviceImageUrl = (images.primary && images.primary.url) || (images.hero && images.hero.url);
        }
        toReturn.pageName = this.getPageName(toReturn);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    public static get hasOne(): ChildModel[] {
        return [{
            attrName: 'device',
            model: ImeiDevice
        }];
    }

    public static getPageName(eligibility: ByodEligibility): string {
        if (eligibility.isIncompatible) {
            return ByodEligibilityPages.incompatible;
        } else if (eligibility.isLostStolen) {
            return ByodEligibilityPages.lostStolen;
        } else if (eligibility.isXfinityMobile) {
            return ByodEligibilityPages.existingline;
        } else if (eligibility.isAndroidLocked) {
            return ByodEligibilityPages.isAndroidLocked;
        } else if (eligibility.isAppleLocked) {
            return ByodEligibilityPages.isApplelocked;
        } else if (eligibility.isLteOnly) {
            return ByodEligibilityPages.LTEonly;
        } else if (eligibility.isUpdateCompatible) {
            return ByodEligibilityPages.updatesoftware;
        } else if (eligibility.isFullCompatible) {
            return ByodEligibilityPages.compatible;
        } else if (eligibility.isSecondaryImei) {
            return ByodEligibilityPages.SECONDARY_IMEI;
        }
    }
}
