import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'xm-alert-modal',
    templateUrl: 'alert.html',
    styleUrls: [ 'alert.scss' ]
})
export class AlertModal {
    public data: AlertModalData;

    private dialogRef: MatDialogRef<AlertModal>;

    constructor(dialogRef: MatDialogRef<AlertModal>, @Inject(MAT_DIALOG_DATA) data: AlertModalData) {
        Object.assign(this, { data, dialogRef });
    }

    public close(): void {
        this.dialogRef.close();
    }
}
