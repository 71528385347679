import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionStorage {
    private sessionStorageExists: boolean;

    constructor() {
        this.sessionStorageExists = this.doesSessionStorageExist();
    }

    public set(key: string, value: string): void {
        if (this.sessionStorageExists) {
            window.sessionStorage.setItem(key, value);
        }
    }

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    public get<T>(key: string): T {
        if (this.sessionStorageExists) {
            const value: any = window.sessionStorage.getItem(key);
            try {
                return JSON.parse(<string> value);
            } catch (error) {
                return <T> value;
            }
        }

        return;
    }
    /* eslint-enable  @typescript-eslint/no-explicit-any */

    public hasKey(key: string): boolean {
        if (this.sessionStorageExists) {
            return Boolean(this.get(key));
        }

        return false;
    }

    public destroy(key: string): void {
        if (this.sessionStorageExists) {
            window.sessionStorage.removeItem(key);
        }
    }

    private doesSessionStorageExist(): boolean {
        const testStorage: string = 'test';

        try {
            window.sessionStorage.setItem(testStorage, testStorage);
            window.sessionStorage.removeItem(testStorage);
        } catch (exception) {
            return false;
        }

        return true;
    }
}
