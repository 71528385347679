import { StoreAction, XmAction } from 'store/actions';
import { PopularSearchCategory, SearchResult, SupportArticle, SupportCategoryCollection, SupportCategoryDetail } from './models';

export function supportArticleReducer(state: SupportArticle[] = [], action: XmAction<SupportArticle>): SupportArticle[] {
    switch (action.type) {
        case StoreAction.GET_ARTICLE:
            return [ ...state, action.payload ];
        default:
            return state;
    }
}

export function supportCategoryCollectionReducer(state: SupportCategoryCollection, action: XmAction<SupportCategoryCollection>): SupportCategoryCollection {
    switch (action.type) {
        case StoreAction.GET_CATEGORIES:
            return action.payload;
        default:
            return state;
    }
}

export function searchResultReducer(state: SearchResult[] = [], action: XmAction<SearchResult>): SearchResult[] {
    switch (action.type) {
        case StoreAction.GET_SEARCH_RESULTS:
        case StoreAction.GET_SUGGESTIONS:
            return [ ...state, action.payload ];
        default:
            return state;
    }
}

export function supportCategoryDetailReducer(state: SupportCategoryDetail[] = [], action: XmAction<SupportCategoryDetail>): SupportCategoryDetail[] {
    switch (action.type) {
        case StoreAction.GET_CATEGORY_BY_ID:
            return [ ...state, action.payload ];
        default:
            return state;
    }
}

export function popularSearchCategoryReducer(state: PopularSearchCategory, action: XmAction<PopularSearchCategory>): PopularSearchCategory {
    switch (action.type) {
        case StoreAction.GET_POPULAR_SEARCH_CATEGORY:
            return action.payload;
        default:
            return state;
    }
}
