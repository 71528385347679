import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'xm-radio',
    styleUrls: [ './radio.scss' ],
    templateUrl: './radio.html'
})
export class XmRadioComponent implements OnInit {
    @Input() public option: XmRadioOption;
    @Input() public smallMainLabel: boolean = true;
    @Input() public labelClass?: string = '';

    public labelFor: string;

    public ngOnInit(): void {
        this.labelFor = `${this.option.groupName}-${this.option.labelFor || this.option.label}`.replace(/\s+/g, '');
    }

}
