
import { PlanGroup } from './plan-group';
import { PaymentPlan } from '../option/payment-plan';
import { ChildModel } from 'store/model-base';

export class PaymentPlanGroup extends PlanGroup {
    public options: PaymentPlan[];

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'options',
            model: PaymentPlan
        }];
    }
}
