<section>
    <div class="active-state"></div>
    <div class="nav-mobile-wrapper">
        <a *ngIf="!pageData.children?.length"
            [href] = "pageData.url ? pageData.url : ''"
            class="nav-menu-item nav-menu-items">
            <xm-media-image *ngIf="pageData.icon"
                aria-hidden="true" 
                [attr.aria-label]="pageData.name"
                [imageOptions]="menuIcon"
                [staticImagePath]="tempMenuIcon"
                alt="menu-item-icon"
                size="height"
                class="menu-item-icon">
            </xm-media-image>
            {{ pageData.name }}
        </a>
        <div *ngIf="!pageData.uiSref && !pageData.url || pageData.children?.length"
            class="nav-menu-item nav-menu-items">
            {{pageData.name}}
        </div>
        <button *ngIf="pageData.children?.length"
            class="caret-icon-container"
            [attr.aria-label]="pageData.childrenAriaLabel">
            <xm-media-image  [staticImagePath]="'arrow-right-white.svg'" [imageSource]="'buy_static_base_url'" [staticImageAlt]="'drop-down-icon'" aria-hidden="true" size="height" class="caret-icon"></xm-media-image>
        </button>
    </div>
</section>
