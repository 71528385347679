import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { TimeoutError } from 'rxjs';

export class HttpError {
    public status: number;
    public errors: ApiError[];

    constructor(initData: HttpErrorResponse | TimeoutError) {
        if (initData instanceof TimeoutError) {
            return;
        }

        if (initData.error instanceof ErrorEvent) {
            // This means a client side or network error
            /* eslint-disable  no-console */
            console.error('A client side error occured:', initData.error.message);
            /* eslint-enable  no-console */

            return;
        }

        this.status = initData.status;
        this.errors = [];

        const body: HttpErrorBody = initData.error;

        if (body && body.errors) {
            body.errors.forEach((error: ErrorBodyDetails) => {
                this.errors.push({ code: error.code, message: error.message });
            });
        } else if (body) {
            this.errors.push(body);
        }
    }

    public get isBadRequest(): boolean {
        return this.status === HttpStatusCode.BadRequest;
    }

    public get isNotFound(): boolean {
        return this.status === HttpStatusCode.NotFound;
    }

    public get isInternalServerError(): boolean {
        return this.status === HttpStatusCode.InternalServerError;
    }
}
