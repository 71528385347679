import { ChildModel, ModelBase } from 'store/model-base';
import { TaxSummary } from 'store/cart/models/tax-summary';
import { TaxType } from 'core/constants';

export class CalculatedTax extends ModelBase {
    public static storeName: string = 'calculatedTax';

    public taxSummary: TaxSummary[];
    public monthlyTotal: number;
    public oneTimeTotal: number;

    public monthlyTaxesByTaxCategory: TaxSummary[];
    public monthlyTaxesByFeesCategory: TaxSummary[];
    public oneTimeTaxesByTaxCategory: TaxSummary[];
    public oneTimeTaxesByFeesCategory: TaxSummary[];

    public static create<T extends ModelBase>(initData: object): T {
        const toReturn: CalculatedTax = super.create<CalculatedTax>(initData);

        toReturn.monthlyTaxesByTaxCategory = toReturn.taxSummary.filter((taxSummary: TaxSummary) => taxSummary.monthly !== 0 && taxSummary.category === TaxType.TAX);
        toReturn.monthlyTaxesByFeesCategory = toReturn.taxSummary.filter((taxSummary: TaxSummary) => taxSummary.monthly !== 0 && taxSummary.category === TaxType.FEES);
        toReturn.oneTimeTaxesByTaxCategory = toReturn.taxSummary.filter((taxSummary: TaxSummary) => taxSummary.oneTime !== 0 && taxSummary.category === TaxType.TAX);
        toReturn.oneTimeTaxesByFeesCategory = toReturn.taxSummary.filter((taxSummary: TaxSummary) => taxSummary.oneTime !== 0 && taxSummary.category === TaxType.FEES);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'taxSummary',
            model: TaxSummary
        }, {
            attrName: 'monthlyTaxesByTaxCategory',
            model: TaxSummary
        }, {
            attrName: 'monthlyTaxesByFeesCategory',
            model: TaxSummary
        }, {
            attrName: 'oneTimeTaxesByTaxCategory',
            model: TaxSummary
        }, {
            attrName: 'oneTimeTaxesByFeesCategory',
            model: TaxSummary
        }];
    }
}
