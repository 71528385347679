import { Directive, ElementRef, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Transition, TransitionService } from '@uirouter/angular';
import { Util } from 'core/services';
import { KeyboardEventKeys } from 'core/constants';

/*
2 ways to use this directive:
    1. If the actionable element has form-control, this directive will auto-bind with formControl.disabled property to disable the element
    2. Otherwise you need to bind isDisabled property to something to enable or disable the action element.
*/

@Directive({
    selector: '[xmAriaDisabled]'
})
export class XmAriaDisabled implements OnInit, OnDestroy, OnChanges {
    @Input() public isDisabled: boolean;
    @Input() public uiSref: string;
    @Input() public uiParams: object;
    private element: HTMLElement;
    private injector: Injector;
    private subscriptions: Subscription[] = [];
    private onBeforeDeRegister: Function;
    private transition: TransitionService;
    private originalHref: string;
    private isLink: boolean;

    constructor(element: ElementRef, injector: Injector, transition: TransitionService) {
        Object.assign(this, { element: element.nativeElement, injector, transition });
    }

    public ngOnInit(): void {
        this.originalHref = this.element.getAttribute('href');
        this.isLink = this.element.tagName === 'A';
        const ngControl: NgControl = this.injector.get(NgControl, false);

        if (ngControl && ngControl.disabled || this.isDisabled) {
            this.setDisable();
        }

        if (ngControl) {
            this.subscriptions.push(ngControl.valueChanges.subscribe(() => {
                if (ngControl.disabled) {
                    this.setDisable();
                } else {
                    this.removeDisable();
                }
            }));
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && !changes.isDisabled.firstChange) {
            if (changes.isDisabled.currentValue) {
                this.setDisable();
            } else {
                this.removeDisable();
            }
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);

        if (this.isLink && this.uiSref && this.onBeforeDeRegister) {
            this.onBeforeDeRegister();
        }
    }

    private setDisable(): void {
        this.element.setAttribute('aria-disabled', 'true');
        this.element.addEventListener('click', this.clickListener);
        this.element.addEventListener('keydown', this.keyDownListener);

        if (this.isLink) {
            this.element.setAttribute('href', '#');
            if (this.uiSref) {
                this.onBeforeDeRegister = this.transition.onBefore({
                    to: this.uiSref
                }, (tt: Transition) => {
                    if (this.uiParams && tt.params()) {
                        const paramsSame: boolean = !Object.keys(this.uiParams).some((key: string) => this.uiParams[key] !== tt.params()[key]);

                        if (paramsSame) {
                            tt.abort();
                        }
                    } else if (!this.uiParams && !tt.params()) {
                        tt.abort();
                    }
                });
            }
        }
    }

    private removeDisable(): void {
        this.element.removeAttribute('aria-disabled');

        if (this.isLink) {
            if (this.originalHref) {
                this.element.setAttribute('href', this.originalHref);
            }

            if (this.uiSref && this.onBeforeDeRegister) {
                this.onBeforeDeRegister();
            }
        }
        this.element.removeEventListener('click', this.clickListener);
        this.element.removeEventListener('keydown', this.keyDownListener);
    }

    private clickListener(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    private keyDownListener(event: KeyboardEvent): void {
        if (!event.key || event.key === KeyboardEventKeys.ENTER) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
}
