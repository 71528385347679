import { Directive, ElementRef, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WindowReference } from 'core/services';

const OFFSET_TO_START_SLIDING: number = 200;

@Directive({
    selector: '[xmSlideUp]'
})
export class XmSlideUpComponent implements OnInit {
    private scrollSubscription: Subscription;
    private windowRef: WindowReference;
    private element: HTMLElement;

    constructor(windowRef: WindowReference, element: ElementRef) {
        Object.assign(this, { windowRef, element: element.nativeElement });
    }

    public ngOnInit(): void {
        this.scrollSubscription = this.windowRef.scroll.subscribe(() => {
            if (window.pageYOffset + window.innerHeight > this.element.offsetTop + OFFSET_TO_START_SLIDING && window.pageYOffset < this.element.offsetTop + this.element.clientHeight) {
                this.element.style.transform = 'translate( 0, 0)';
                this.scrollSubscription.unsubscribe();
            }
        });
    }
}
