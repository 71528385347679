<section [ngClass]="{ 'my-account-child-items': myAccountChildItems }" (mouseenter)="mouseHoverEvent($event)" (mouseleave)="mouseHoverEvent($event)">
    <div class="nav-menu-items seperator" *ngIf="isSeparator"></div>
    <xm-nav-chat class="nav-menu-items" *ngIf="isChat" (toggleMobileNav)="closeMobileMenu()"></xm-nav-chat>
    <xm-nav-cart *ngIf="isCart" (toggleMobileNav)="closeMobileMenu()"></xm-nav-cart>
    <div [ngClass]="[ isMyAccountBgColor ? 'myaccount-backgroundcolor' : '', isFromProductMenu ? 'product-nav-menu-items' : 'nav-menu-items', isFromLeftMainMenu ? 'left-mainmenu-items' : 'nav-menu-items', isFromRightMainMenu ? 'right-mainmenu-items' : 'nav-menu-items']" *ngIf="!isSeparator && !isChat && !isCart">
        <a *ngIf="pageData.uiSref" (click)="closeMobileMenu()" [uiSref]="pageData.uiSref" [uiParams]="generateUiParams(pageData.uiParams)" [uiOptions]="{ inherit: false }" class="{ 'menu-bold' :stateActive() }" [attr.aria-label]="pageData.name || pageData.icon?.alt"
        [ngClass]="[isFromProductMenu ? 'product-nav-menu-item' : 'nav-menu-item',  isFromRightMainMenu ? 'right-mainmenu-item' : '', isFromLeftMainMenu ? 'left-mainmenu-item' : '', isFromAccountMainMenu ? 'account-mainmenu-items' : 'nav-menu-items', isLeftMenuItemsHover ? 'nav-menu-items-hover' : '']" >
            <xm-media-image *ngIf="pageData.icon" aria-hidden="true"  [attr.aria-label]="'second link'" [staticImagePath]="tempMenuIcon" [imageOptions]="menuIcon"  [imageSource]="'buy_static_base_url'" [smallIcon]="smallIcon" alt="menu-item-icon" size="height" class="menu-item-icon"></xm-media-image>
            {{ pageData.name }}  <div *ngIf="stateActive()" [ngClass]="{'active-state': isFromProductMenu }"></div>
        </a>
        <a *ngIf="!pageData.uiSref && pageData.url" (click)="closeMobileMenu()" [href]="pageData.url" [attr.aria-label]="pageData.name || pageData.icon?.alt"
        [ngClass]="[isFromProductMenu ? 'product-nav-menu-item' : 'nav-menu-item', isFromRightMainMenu ? 'right-mainmenu-item' : '', isFromLeftMainMenu ? 'left-mainmenu-item' : '', isFromAccountMainMenu ? 'account-mainmenu-items' : 'nav-menu-items',  isLeftMenuItemsHover ? 'nav-menu-items-hover' : '']"> 
            <xm-media-image *ngIf="pageData.icon" aria-hidden="true"  [attr.aria-label]="'first link'" [imageOptions]="menuIcon"  [imageSource]="'buy_static_base_url'" [staticImagePath]="tempMenuIcon" [smallIcon]="smallIcon" alt="menu-item-icon" size="height" class="menu-item-icon"></xm-media-image>
            {{ pageData.name }}  <div *ngIf="stateActive()" [ngClass]="{'active-state': isFromProductMenu }"></div>
        </a>
        <button *ngIf="showChildren && pageData.children?.length" class="caret-icon-container" (click)="expandChildItems()" [attr.aria-label]="pageData.childrenAriaLabel" [attr.aria-expanded]="showChildItems">
            <!-- <xm-media-image [staticImagePath]="caretIcon" aria-hidden="true" size="height" class="caret-icon"></xm-media-image> -->
            <xm-media-image  [staticImagePath]="'arrow-down.svg'" [imageSource]="'buy_static_base_url'" [staticImageAlt]="'drop-down-icon'"  aria-hidden="true" size="height" class="caret-icon"></xm-media-image>
        </button>
        <ul *ngIf="showChildren && pageData.children?.length" class="child-items" [ngClass]="{ 'show-child-items': showChildItems, 'inherit-width': inheritParentWidth }">
            <li *ngFor="let child of pageData.children">
                <div [ngClass]="isFromProductMenu ? 'product-child-list' :'child-list'" (mouseenter)="hasSubChild(child) ? mouseHoverSubChildEvent() : ''" (mouseleave)="hasSubChild(child) ? mouseLeaveSubChildEvent() : ''">
                    <a *ngIf="child.uiSref" (click)="closeMobileMenu()" [uiSref]="child.uiSref" [uiParams]="generateUiParams(child.uiParams)" [uiOptions]="{ inherit: false }" [ngClass]="isFromProductMenu? 'product-child-item' : 'child-item'" >
                        <xm-media-image *ngIf="child.icon" aria-hidden="true"  [imageSource]="'buy_static_base_url'" [staticImagePath]="tempMenuIcon" [imageOptions]="menuIcon" size="height" class="menu-item-icon"  alt="menu-item-icon"></xm-media-image>
                        {{ child.name }}
                    </a>
                    <a *ngIf="!child.uiSref && child.url" (click)="closeMobileMenu()" [href]="child.url" [ngClass]="isFromProductMenu? 'product-child-item' : 'child-item'" >
                        <xm-media-image *ngIf="child.icon" aria-hidden="true" [imageOptions]="menuIcon" [staticImagePath]="tempMenuIcon"  [imageSource]="'buy_static_base_url'" size="height" class="menu-item-icon"  alt="menu-item-icon"></xm-media-image>
                        {{ child.name }}
                    </a>
                    <button *ngIf="child.children?.length" class="caret-icon-container" (click)="expandSubChildItems()" [attr.aria-label]="child.childrenAriaLabel" [attr.aria-expanded]="showSubChildItems">
                        <!-- <xm-media-image [staticImagePath]="caretIcon" aria-hidden="true" size="height" class="caret-icon" ></xm-media-image> -->
                        <xm-media-image [staticImagePath]="showSubChildItems ? 'arrow_right_blue.svg' : 'arrow_right_black.svg'" [imageSource]="'buy_static_base_url'" [staticImageAlt]="'inner-drop-down-icon'"  aria-hidden="true" size="height" class="caret-icon"></xm-media-image>
                    </button>
                    <ul *ngIf="showSubChildItems && child.children?.length" class="sub-child-items" [ngClass]="{ 'show-child-items': showChildItems, 'inherit-width': inheritParentWidth }">
                        <li *ngFor="let subchild of child.children">
                            <div [ngClass]="isFromProductMenu ? 'product-child-list' :'child-list'">
                                <a *ngIf="subchild.uiSref" (click)="closeMobileSubMenu(); closeMobileMenu()" [uiSref]="subchild.uiSref" [uiParams]="generateUiParams(subchild.uiParams)" [uiOptions]="{ inherit: false }" [ngClass]="isFromProductMenu? 'product-child-item' : 'child-item'">
                                    <xm-media-image *ngIf="subchild.icon" aria-hidden="true"  [imageSource]="'buy_static_base_url'" [staticImagePath]="tempMenuIcon" [imageOptions]="menuIcon" size="height" class="menu-item-icon"  alt="menu-item-icon"></xm-media-image>
                                    {{ subchild.name }}
                                </a>
                                <a *ngIf="!subchild.uiSref && subchild.url" (click)="closeMobileSubMenu(); closeMobileMenu()" [href]="subchild.url" [ngClass]="isFromProductMenu? 'product-child-item' : 'child-item'">
                                    <xm-media-image *ngIf="subchild.icon" aria-hidden="true" [staticImagePath]="tempMenuIcon" [imageOptions]="menuIcon"  [imageSource]="'buy_static_base_url'" size="height" class="menu-item-icon"  alt="menu-item-icon"></xm-media-image>
                                    {{ subchild.name }}
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</section>
