import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Cloudinary {
    private static URL: string = 'https://img.xfinitymobile.com/image/';
    private static ICON_NAME: string = 'icon';
    private static IMAGE_NAME: string = 'image';
    private static ILLUSTRATION_NAME: string = 'illustration';

    public static generateMediaFromCms(cmsImage: CmsMediaImage): MediaImage {
        return {
            alt: cmsImage?.alt || '',
            base: cmsImage?.base,
            version: cmsImage?.version,
            width: cmsImage?.width || -1,
            height: cmsImage?.height || -1,
            trimTransparency: cmsImage?.trimTransparency || false,
            useBoundingBox: cmsImage?.useBoundingBox || false,
            icon: cmsImage?.type === Cloudinary.ICON_NAME,
            image: cmsImage?.type === Cloudinary.IMAGE_NAME,
            illustration: cmsImage?.type === Cloudinary.ILLUSTRATION_NAME
        };
    }

    public static generateMediaOptionsFromCms(cmsImages: CmsMediaOptionsImage): MediaImageOptions {
        const options: MediaImageOptions = {};

        if (cmsImages.mobile) {
            options.mobile = Cloudinary.generateMediaFromCms(cmsImages.mobile);
        }

        if (cmsImages.tablet) {
            options.tablet = Cloudinary.generateMediaFromCms(cmsImages.tablet);
        }

        if (cmsImages.smallDesktop) {
            options.smallDesktop = Cloudinary.generateMediaFromCms(cmsImages.smallDesktop);
        }

        if (cmsImages.largeDesktop) {
            options.largeDesktop = Cloudinary.generateMediaFromCms(cmsImages.largeDesktop);
        }

        return options;
    }

    public static generateUrl(width: number, height: number, image: MediaImage): string {
        let url: string = Cloudinary.URL;

        if (typeof image.base === 'object') {
            const base: string = image.base && image.base.url || '';
            const alt: string = image.base && image.base.alt || '';

            image.base = base;
            image.alt = alt;
        }

        if (!image.base) {
            return;
        }

        const regexAbsolutePath: RegExp = /(http:)|(https:)/;
        const matchUrl: RegExpMatchArray = image.base.match(regexAbsolutePath);

        if (matchUrl) {
            url += 'fetch/';
        } else {
            url += 'upload/';
        }

        if (image.trimTransparency) {
            url += 'e_trim/';
        }

        let options: string = '';

        const widthString: string = width.toString();
        const heightString: string = height.toString();

        if (width > 0 && height > 0) {
            options += `w_${widthString},h_${heightString},`;
        } else if (width > 0) {
            options += `w_${widthString},`;
        } else if (height > 0) {
            options += `h_${heightString},`;
        }

        if (!image.base.endsWith('.svg')) {
            options += 'c_fit,f_auto,q_auto,fl_lossy,';
        }

        if (options) {
            options = options.substring(0, options.length - 1);
            url += `${options}/`;
        }

        if (image.version) {
            url += `v${image.version}/`;
        }

        const endUrl: string = matchUrl ? encodeURIComponent(image.base) : image.base;

        if (image.icon) {
            url += `client/v2/icons/${endUrl}`;
        } else if (image.illustration) {
            url += `client/v2/illustrations/${endUrl}`;
        } else if (image.image) {
            url += `client/v2/images/${endUrl}`;
        } else {
            url += endUrl;
        }

        return url;
    }

    public static generateResponsiveUrl(width: number, height: number, currentImage: SingletonResponsiveImage): string {
        let options: string = currentImage.trimTransparency ? 'e_trim/' : '';

        if (width > 0 && height > 0) {
            options += `w_${width},h_${height},`;
        } else if (width > 0) {
            options += `w_${width},`;
        } else if (height > 0) {
            options += `h_${height},`;
        }

        if (!currentImage.url.endsWith('.svg')) {
            options += 'c_fit,f_auto,q_auto,fl_lossy,';
        }

        if (options) {
            options = options.substring(0, options.length - 1);
            options = `${options}/`;
        }

        return currentImage.url.replace('upload/', `upload/${options}`);
    }
}
