<label [for]="labelFor" [ngClass]="{ 'no-padding': option.noLabelPadding, 'remove-bg': isRemoveBgColor }" class="{{labelClass}}">
    <input [id]="labelFor" [attr.aria-describedby]="ariaDescribedby" type="radio" (click)="select($event)"/>
    <ng-content></ng-content>
</label>
<div class="expandable-section" *ngIf="isExpandableEnable">
    <div class="image-wrapper">
        <xm-media-image  class="polygon-image" [staticImagePath]="'polygon.svg'" [imageSource]="'buy_static_base_url'" staticImageAlt="polygon" ></xm-media-image>
    </div>
    <cms-text class="message" [originalText]="radioOption.message2"></cms-text>
    <ul class="list">
        <li class="list-items" *ngFor="let bulletPoints of infoTextBulletPoints">{{bulletPoints}}</li>
    </ul> 
    <cms-text class="cta-link" [originalText]="radioOption?.ctaLink" [isLink]="true" (onButtonClick)="viewEinModal()"></cms-text>
</div>