<div class="global-footer">
    <div class="main-content hidden-small-devices-desktop">
        <div class="footer-logo">
            <a href="{{ pageData.logo.href}}" [class]="pageData.logo.class">
                <xm-media-image  size="full" [staticImagePath]="pageData.logo.base"
                    [staticImageAlt]="pageData.logo.alt" [imageSource]="'buy_static_base_url'"></xm-media-image>
            </a>
        </div>
        <div>
            <a *ngIf="pageData.shopheader" [href]="pageData.shopheader.href" [class]="pageData.shopheader.class"> {{ pageData.shopheader.text }}</a>
            <div *ngFor="let shop of pageData.shop">
                <a *ngIf="shop.link" [href]="shop.link.href" [class]="shop.link.class"> {{ shop.link.text }} </a>
            </div>
        </div>
        <div>
            <a *ngIf="pageData.supportheader" [href]="pageData.supportheader.href" [class]="pageData.supportheader.class"> {{ pageData.supportheader.text
                }}</a>
            <div *ngFor="let support of pageData.support">
                <a *ngIf="support.link" [href]="support.link.href" [class]="support.link.class"> {{ support.link.text }} </a>
            </div>
        </div>
        <div>
            <a *ngIf="pageData.myaccountheader" [href]="pageData.myaccountheader.href" [class]="pageData.myaccountheader.class"> {{ pageData.myaccountheader.text
                }}</a>
            <div *ngFor="let account of pageData.myaccount">
                <a *ngIf="account.link" [href]="account.link.href" [class]="account.link.class"> {{ account.link.text }} </a>
            </div>
        </div>
        <div>
            <a *ngIf="pageData.aboutusheader" [href]="pageData.aboutusheader.href" [class]="pageData.aboutusheader.class"> {{ pageData.aboutusheader.text
                }}</a>
            <div *ngFor="let aboutus of pageData.aboutus">
                <a *ngIf="aboutus.link" [href]="aboutus.link.href" [class]="aboutus.link.class"> {{ aboutus.link.text }} </a>
            </div>
        </div>
        <div>
            <a *ngIf="pageData.partnerheader" [href]="pageData.partnerheader.href" [class]="pageData.partnerheader.class"> {{ pageData.partnerheader.text
                }}</a>
            <div *ngFor="let partner of pageData.partners">
                <a *ngIf="partner.link" [href]="partner.link.href" [class]="partner.link.class"> {{ partner.link.text }} </a>
            </div>
        </div>
    </div>
    <div class="sub-content divider-desktop">
        <div class="order-{{i}}" *ngFor="let item of pageData.horizontallinks; index as i">
            <a *ngIf="item.link" [href]="item.link.href" [class]="item.link.class" rel="noopener" target="_blank"> {{ item.link.text }} </a>
        </div>
    </div>
    <div class="divider-sm"></div>
    <div class="copyright-content">
        <p class="copyright-text"> {{ pageData.copyright }} </p>
    </div>
    <div class="social-content">
        <div *ngFor="let icon of pageData.social">
            <a class="link" href="{{icon.href}}">
                <xm-media-image class="img-icon" size="full" [staticImagePath]="icon.base" staticImageAlt=""
                    [imageSource]="'buy_static_base_url'"></xm-media-image>
            </a>
        </div>
    </div>
</div>