import { Subscription } from 'rxjs';
import { DatePipe, DecimalPipe } from '@angular/common';
import { StripHtml } from 'shared/pipes/strip-html';
import { Height } from 'core/constants';

export class Util {
    private static datePipe: DatePipe;
    private static decimalPipe: DecimalPipe;
    private static stripHtmlPipe: StripHtml;

    public static cloneProperties<T extends {}>(obj: T): T {
        return JSON.parse(JSON.stringify(obj || {}));
    }

    /**
     * Merge sources deeply with the original target
     */
    public static mergeDeepWith<T>(target: T, ...sources: T[]): T {
        if (!sources.length) {
            return target;
        }

        const source: T = sources.shift();
        if (Util.isObject(target) && Util.isObject(source)) {
            for (const key in source) {
                if (Util.isObject(source[key])) {
                    if (!target[key]) {
                        Object.assign(target, { [key]: {} });
                    }

                    Util.mergeDeepWith(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return Util.mergeDeepWith(target, ...sources);
    }

    /**
     * Merge target and sources deeply into a new object
     */
    public static mergeDeep(...sources: object[]): object {
        const mergedData: object = {};
        Util.mergeDeepWith(mergedData, ...sources);

        return mergedData;
    }

    public static escapeRegExp(str: string): string {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    public static normalizeString(str: string): string {
        return str.replace(/[-\s,.:+!@#$%^&*]/g, '')?.toUpperCase();
    }

    public static getObjectFromQueryString(str: string): UrlQueries {
        if (!str) {
            return {};
        }

        const query: string = str.startsWith('?') ? str.substring(1) : str;

        return query.split('&').filter((item: string) => item.includes('='))
            .reduce((prev: UrlQueries, curr: string) => {
                const p: string[] = curr.split('=');
                prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);

                return prev;
            }, {});
    }

    /* eslint-disable no-null/no-null */
    public static createQueryParams(params: object = {}, starter: string = '?'): string {
        let queryParams: string = starter;
        Object.keys(params).forEach((key: string) => {
            if (params[key] !== undefined && params[key] !== null) {
                queryParams = queryParams.concat(key, '=', params[key], '&');
            }
        });

        return queryParams.slice(0, -1); // Remove either the ? or the &
    }
    /* eslint-enable no-null/no-null */

    public static mbToGb(value: number, round: boolean = false): string {
        if (!this.decimalPipe) {
            this.decimalPipe = new DecimalPipe('en-US');
        }

        const GB: number = 1024;
        let gigs: number = value ? (value / GB) : 0;
        gigs = Util.decimalAdjust(gigs, -2);

        return round ? Math.ceil(gigs).toString() : new DecimalPipe('en-US').transform(gigs, '1.2-2');
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    public static dotWalk(obj: any, pathToWalk: string): any {
        if (!obj || !pathToWalk) {
            return undefined;
        }
        const paths: string[] = pathToWalk.split('.');
        let objToWalk: any = obj;

        paths.some((path: string) => {
            objToWalk = objToWalk[path];

            return objToWalk === undefined;
        });

        return objToWalk;
    }
    /* eslint-enable @typescript-eslint/explicit-module-boundary-types */
    /* eslint-enable @typescript-eslint/no-explicit-any */

    public static unsubscribeAll(subscriptions: Subscription[] = []): void {
        if (subscriptions) {
            subscriptions.forEach((sub: Subscription) => {
                if (sub && !sub.closed) {
                    sub.unsubscribe();
                }
            });
        }
    }

    public static dateFormat(date: string, format: string): string {
        if (!this.datePipe) {
            this.datePipe = new DatePipe('en-US');
        }

        return this.datePipe.transform(date, format) || '';
    }

    public static isElementVisible(element: HTMLElement): boolean {
        if (!element) {
            return false;
        }

        const rect: DOMRect = element.getBoundingClientRect();
        const height: number = document.documentElement.clientHeight || window.innerHeight;
        const width: number = document.documentElement.clientWidth || window.innerWidth;

        return rect.top >= Height.NAV_BAR && rect.bottom <= height && rect.left >= 0 && rect.right <= width;
    }

    public static stripHtml(input: string): string {
        if (!this.stripHtmlPipe) {
            this.stripHtmlPipe = new StripHtml();
        }

        return this.stripHtmlPipe.transform(input);
    }

    public static vanitize(input: string): string {
        return this.stripHtml(input).trim().toLowerCase().replace(/\s/g, '-').replace('&', 'and').replace(/[^a-zA-Z0-9\-]/g, '');
    }

    public static sumFloat(args: number[], fixDigit: number = 2): number {
        return args ? parseFloat(args.reduce((sum: number, eachFloat: number) => sum + eachFloat, 0).toFixed(fixDigit)) : 0;
    }

    public static generateUuid(): string {
        return `${Util.uuidPartial()}${Util.uuidPartial()}-${Util.uuidPartial()}-${Util.uuidPartial()}-${Util.uuidPartial()}${Util.uuidPartial()}${Util.uuidPartial()}`;
    }

    public static generateUuid16(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            const r: number = Math.random() * 16 | 0;
            const v: number = c === 'x' ? r : (r & 0x3 | 0x8);
            
            return v.toString(16);
        });
    }

    public static addMonths(date: Date, months: number): Date {
        try {
            date.setMonth(date.getMonth() + months);

            return date;
        } catch {
            //Do Nothing, Return undefined outside the try block
        }

        return undefined;
    }

    public static decodeJWT(token: string): JWT {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch {
            //Do Nothing, Return undefined outside the try block
        }

        return undefined;
    }

    /**
     * @param value number to be format
     * @param exp no of digits you want after decimal point (should be in minus)
     */
    private static decimalAdjust(value: number, exp: number): number {
        const adjusted: number = Math.ceil(+(`${value}e${-exp}`));

        return +(`${adjusted}e${exp}`);
    }

    private static uuidPartial(): string {
        return Math.floor((Math.random() + 1) * 0x10000).toString(16).substring(1);
    }

    private static isObject<T>(item: T): boolean {
        return item && typeof item === 'object' && !(Array.isArray(item));
    }
}
