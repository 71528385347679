import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../module';
import { NavHamburgerComponent } from './hamburger/hamburger';
import { NavLogoComponent } from './logo/logo';
import { NavPrimaryComponent } from './primary/primary';
import { XmNavChatComponent } from './chat/chat';
import { NavCheckoutComponent } from './checkout/checkout';
import { XmNavProfileComponent } from './profile/profile';
import { XmNavMenuItemComponent } from './menu-item/menu-item';
import { XmNavMobileMenuItemComponent } from './mobile-menu-item/mobile-menu-item';
import { XmNavCartComponent } from './cart/cart';
import { UtilityBannerModule } from '../utility-banner/module';
import { XmBusinessNavComponent } from './business-nav/business-nav';
import { XmProductNavComponent } from './product-nav/product-nav';

@NgModule({
    declarations: [
        NavHamburgerComponent,
        NavLogoComponent,
        NavPrimaryComponent,
        XmNavChatComponent,
        NavCheckoutComponent,
        XmNavProfileComponent,
        XmNavMenuItemComponent,
        XmNavMobileMenuItemComponent,
        XmNavCartComponent,
        XmBusinessNavComponent,
        XmProductNavComponent
    ],
    exports: [
        NavHamburgerComponent,
        NavLogoComponent,
        NavPrimaryComponent,
        XmNavChatComponent,
        NavCheckoutComponent,
        XmNavProfileComponent,
        XmNavMenuItemComponent,
        XmNavMobileMenuItemComponent,
        XmNavCartComponent,
        XmBusinessNavComponent,
        XmProductNavComponent
    ],
    imports: [
        SharedModule,
        UIRouterModule,
        UtilityBannerModule
    ]
})
export class NavModule {}
